import cookie from 'cookie';

import { IS_BROWSER } from './const';

const getCookies = () => (IS_BROWSER ? cookie.parse(document.cookie) : {});

export const checkCookie = (name) => name in getCookies();

export const getCookie = (name) => {
  const cookies = getCookies();
  return cookies && cookies[name];
};

export const setCookie = (name, value, maxAgeInHours) => {
  if (!IS_BROWSER) {
    return;
  }

  const stringValue = typeof value !== 'string' ? JSON.stringify(value) : value;
  const expires = getExpirationDateByHours(maxAgeInHours);

  document.cookie = cookie.serialize(name, stringValue, {
    path: '/',
    expires: expires
  });
};

export const removeCookie = (name) => {
  const clearCookie = getCookie(name);

  if (!clearCookie) {
    return;
  }

  const opt = {
    expires: new Date(1970, 1, 1, 0, 0, 1), // eslint-disable-line no-magic-numbers
    maxAge: 0,
    path: '/'
  };
  document.cookie = cookie.serialize(name, '', opt);
};

export const getExpirationDateByHours = (hours) =>
  new Date(Date.now() + hours * 60 * 60 * 1000);
