import { investorsList } from './investorsList';
import './styles.scss';

export const OurInvestors = () => {
  const renderInvestorsList = investorsList.map((item) =>
    item.src ? (
      <div className="content-item">
        <img alt="investor" src={item.src} style={{ maxWidth: '220px' }} />
      </div>
    ) : (
      <div className="content-item">
        <img alt="investor" src={item} />
      </div>
    )
  );

  return (
    <div className="our-investors-wrapper">
      <section className="container">
        <h4 className="title">Our Investors</h4>
        <div className="investors-content">{renderInvestorsList}</div>
      </section>
    </div>
  );
};
