import styled from "styled-components";

import { URLS } from "const";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 0 16px;
`;

const Card = styled.div`
  display: flex;
  align-items: center;

  background: var(--color--white);

  border: 1px solid var(--color-monochrome--light-2);

  box-shadow: 0px 4px 22px rgba(208, 216, 223, 0.2);

  border-radius: 8px;

  padding: 19px 12px 19px 14px;
`;

const CardImage = styled.div`
  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;

  padding: 2px;
  border: solid 1px var(--color-monochrome--light-2);

  img {
    width: 100%;
  }
`;

const CardDetail = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 12px;

  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: var(--color--black);
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    color: var(--color-monochrome--light-4);
  }
`;

const CardPrice = styled(CardDetail)`
  margin-left: auto;

  * {
    text-align: right;
  }

  p {
    color: var(--color--teal);
  }
`;

export const List = (props) => {
  const onClick = (assetKey) => {
    window.open(`${URLS.LANDINGS.ASSETS}/${assetKey}`, "_self");
  };

  return (
    <Container>
      {props.assets.map((asset) => (
        <Card
          onClick={() => onClick(asset.assetKey)}
          data-href={`${URLS.LANDINGS.ASSETS}/${asset.assetKey}`}
          tabindex="0"
          role="link"
          key={asset.slug}
        >
          <CardImage>
            <img alt={asset.slug} src={asset.imageUrl} />
          </CardImage>
          <CardDetail>
            <h1>{asset.title}</h1>
            <p>{asset.slug}</p>
          </CardDetail>
          <CardPrice>
            <h1>
              €{asset.price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </h1>
            <p>{asset.yearlyProfit}%</p>
          </CardPrice>
        </Card>
      ))}
    </Container>
  );
};
