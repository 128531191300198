import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as CookiesIcon } from "../assets/cookies.svg";

const CookiesContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0px;
  z-index: 100;
  background: #ffffff;
  align-items: center;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  max-width: 1200px;
  width: 100%;
  padding: 32px 24px;
  gap: 24px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    height: min-content;
    gap: 22px;
    flex-direction: column;
    padding: 22px 16px;
    align-items: flex-start;
  }
`;

const PolicyInfo = styled.div`
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  color: var(--color--secondary);
  max-width: 725px;
  margin-right: 24px;

  a {
    color: #006af5;
  }
`;

const AcceptButton = styled.button`
  display: flex;
  padding: 14px 32px;
  align-items: center;
  justify-content: center;
  color: var(--color--white);
  min-width: 183px;
  background: var(--color--primary);
  border-radius: 6px;
  height: min-content;
  transition: 0.3s all ease;
  cursor: pointer;
  margin-left: auto;
  font-weight: 700;
  white-space: pre;

  &:hover {
    background: #1068d9;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: unset;
  }
`;

const IconContainer = styled.div`
  display: flex;
  gap: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: var(--color--black);

  svg {
    min-width: 36px;
    height: 36px;
  }

  @media (min-width: 768px) {
    svg {
      min-width: 48px;
      height: 48px;
    }

    span {
      display: none;
    }
  }
`;

export const Cookies = ({ onAcceptCookies }) => {
  return (
    <CookiesContainer>
      <IconContainer>
        <CookiesIcon />
        <span>Cookie settings</span>
      </IconContainer>
      <PolicyInfo>
        By clicking on any item or other links available on the website, you
        accept <a href="/insight/privacy-policy/">Privacy & Policy</a> and{" "}
        <a href="/agreement/terms-conditions/">Terms & Conditions</a>. You accept
        to receive our сookies, to enhance your user experience across
        MyCointainer’s sites.
      </PolicyInfo>
      <AcceptButton onClick={onAcceptCookies}>Accept Cookies</AcceptButton>
    </CookiesContainer>
  );
};
