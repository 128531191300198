import styled from "styled-components";
import arrow from "./img/arrow.svg";

interface ButtonProps {
  variant?: "primary" | "outlined" | "text";
  isPurple?: boolean;
}

export const Button = styled.a<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.variant === "text" ? "flex-start" : "center"};

  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) =>
    props.variant === "outlined"
      ? props.isPurple
        ? "#A8ABD1"
        : props.theme.lightGray
      : props.theme.white};

  min-width: 200px;
  padding: ${(props) => (props.variant === "text" ? 0 : "0 16px")};
  white-space: nowrap;
  height: 50px;

  background: ${(props) =>
    props.variant === "primary"
      ? props.isPurple
        ? "#4F23FF"
        : props.theme.cyan
      : "transparent"};

  border: ${(props) =>
    props.variant === "outlined"
      ? props.isPurple
        ? "1px solid rgba(79, 35, 255, 0.5)"
        : "1px solid rgba(3, 163, 214, 0.5)"
      : null};

  border-radius: 4px;

  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:active {
    background: ${(props) =>
      props.variant === "text"
        ? null
        : props.variant === "outlined"
        ? props.isPurple
          ? " rgba(0, 0, 0, 0.1)"
          : "rgba(3, 163, 214, 0.2)"
        : props.isPurple
        ? "#411CD3"
        : props.theme.cyanDark};
  }

  &:after {
    content: ${(props) => (props.variant === "text" ? `url(${arrow})` : null)};
    margin-left: 8px;
    height: 18px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;
