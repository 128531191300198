import { FC } from "react";
import styled from "styled-components";

export const TermsContent: FC = () => {
  return (
    <Container>
      <div>
        <h1>TERMS AND CONDITIONS</h1>
        <h4>INTRODUCTION</h4>
        <p>
          Welcome to our website! The terms “we”, “us”, “our, “the Company” and “MyCointainer” all refer to MyCointainer,
          a platform that provides an automatic online staking and masternode staking platform and/or mining application.
        </p>
        <p>
          The following constitutes the terms and conditions under which you are allowed to use our website, application and services.
          By registering with us you acknowledge that you have carefully read and understood this document and confirm
          that you are accepting the provisions hereunder. If there is any provision that is not acceptable to you please
          discontinue use of our website and services. We may change our terms and conditions at any time and we will notify
          you whenever possible otherwise we will post notice of changes on the website when they occur.
        </p>
      </div>
      <div>
        <h4>DISCLAIMER</h4>
        <p>
          MyCointainer does not offer, by way of statement or inference, specific advice on the viability or prospects
          of any potential investment. We take all reasonable effort to ensure that data displayed on MyCointainer is
          accurate and up to date. However, we are unable to guarantee the reliability, accuracy, sustainability or veracity
          of any content displayed on MyCointainer at any time. Thus, we do not accept liability for loss or damages that
          arise directly or indirectly from your use of our service nor for your interpretation of any information
          displayed on our site or any of our other communication channels. You are reminded that all investment and
          trading activities contain an element of risk. We strongly suggest that you perform your own research before committing
          to any investment or trade, and exercise responsibility when trading.
        </p>
        <p>
          MyCointainer services are provided on an “as is” basis, based on current commercial conditions.
          We offer no warranty or guarantee regarding the reliability, accuracy, detail or stability of any technologies
          involved. MyCointainer serves purely as a platform for transactions, where information regarding Coins can
          be obtained, and Coin exchanges can be performed. We have no means of controlling the security,
          legality or quality of any Coin involved in any transaction, nor verifying the veracity of information provided
          regarding the transaction, or the ability of the parties involved in the transaction to adhere to their own
          responsibilities regarding the rules. It is your own responsibility to assess the risks of any potential investment,
          including its legality, before using the MyCointainer service to conduct the transaction.
        </p>
      </div>
      <div>
        <h4>PROHIBITION OF USE</h4>
        <p>
          You must be 18 years old and above to access and use our platform.
          Your registration with us constitutes your confirmation that you are of legal age.
        </p>
        <p>
          The information, materials, products, and services provided on this website may not be legally
          accessible for residents of particular jurisdictions, including but not limited to the USA,
          Canada, countries that are under embargoes or sanctions, and/or other blacklisted countries.
          If you fall under any of these restricted regions, accessing this website and availing its services
          is strictly prohibited. We request you to exit the website immediately. We reserve the right to restrict
          or withdraw services to or from any nation at any time.
        </p>
      </div>
      <div>
        <h4>DEFINITION OF TERMS</h4>
        <ol>
          <li>
            Asset/Investment - Any type of digital asset traded via the
            MyCointainer platform such as digital tokens, tokens, and virtual
            currency.
          </li>
          <li>
            Coin/Token/Digital Token/Cryptocurrency - A digital currency in
            which encryption techniques are used to regulate the generation of
            units of currency and verify the transfer of funds, operating
            independently of a central bank.
          </li>
          <li>
            Account - Your individual trading details, which we provide access
            to following completion of the registration process. Your account
            can be used to manage and monitor all of your Assets and
            transactions.
          </li>
          <li>
            Order execution - Asset exchange between any two parties, e.g. a
            MyCointainer user, and a user of an External Service.
          </li>
          <li>
            External Service - Any applicable third party service provider.
          </li>
          <li>
            Invest - The process by which a user may gain an Asset or
            Investment.
          </li>
          <li>
            Mining application – means the software downloaded by the user to the сomputer e.g.
            in order to mine сryptocurrencies.
          </li>
        </ol>
      </div>
      <div>
        <h4>USE OF SERVICE</h4>
        <ol>
          <li>
            MyCointainer provides a platform for the exchange of digital Assets,
            commonly referred to as cryptocurrencies, tokens or digital tokens
            &amp; FIAT currencies. Trade occurs on a crypto-to-crypto basis.
          </li>
          <li>
            We make all reasonable efforts to secure our online platforms and
            ensure that continuity of access to services is maintained. However,
            we cannot held liability for any circumstance we could not have
            foreseen, such as terrorist activity, natural disaster, or targeted
            hacking. Nor can we hold liability for damages or losses suffered
            because of any such activity.
          </li>
          <li>
            User agrees and acknowledges that based on MyCointainer's regulatory
            obligations, MyCointainer has a duty to investigate any activity
            that may be unusual, suspicious, and/or in furtherance of an
            attempted violation of applicable law or MyCointainer policy.
          </li>
          <li>
            If we deem a trading activity to be abnormal - the judgment of which
            is at our sole discretion - we reserve the right to cancel, block or
            reverse that activity.
          </li>
          <li>
            The user further agrees and understands that until a final
            determination or conclusion of the Account investigation occurs,
            MyCointainer may limit access to user’s (freeze) Account or suspend
            user’s Account, including but not limited to, under the following
            scenarios:
            <ol
              style={{
                marginLeft: 20,
                listStyleType: "lower-roman",
              }}
            >
              <li>
                The Account, or authorized user is subject to any pending
                litigation, investigation, or governmental proceeding.
              </li>
              <li>
                The account or authorized user is a named person being
                investigated by a regulatory authority, subject to a court
                order, facially valid subpoena, or binding order of a government
                authority.
              </li>
              <li>The account has a negative balance for any reason.</li>
              <li>
                We believe an unauthorized person is attempting to access the
                User’s account.
              </li>
              <li>There is unusual or suspicious activity on the account.</li>
              <li>
                User’s account has been accessed from a sanctioned jurisdiction
                or a jurisdiction MyCointainer is not authorized to operate.
              </li>
            </ol>
          </li>
          <li>
            MyCointainer may conduct periodic reviews of the user Account
            information as mandated by MyCointainer policies. To ensure
            compliance with the applicable regulations, MyCointainer must
            represent that the personal information and account information used
            to compile the user customer profile is up to date and accurate.
            MyCointainer may use the Account information that MyCointainer has
            on file for the user to contact the user regarding any issues with
            the Account.
          </li>
          <li>
            We will never ask users to transfer funds of any kind not listed on
            the MyCointainer site
          </li>
          <li>
            Your use of MyCointainer or any service thereof constitutes your
            statement that all information supplied by you to us is accurate and
            up to date.
          </li>
          <li>
            The processing time for withdrawals may vary depending on the Asset,
            but never more than is necessary.
          </li>
        </ol>
      </div>
      <div>
        <h4>ACCOUNT REGISTRATION</h4>
        <ol>
          <li>
            All users must complete the registration process before access to
            MyCointainer services can be provided. By completing the
            registration process, you agree to be bound by these Terms and
            Conditions.
          </li>
          <li>
            We reserve the right to reject an application from any user, for any
            reason, at our sole discretion.
          </li>
          <li>
            You state and confirm that all information you provided during the
            registration process is complete, accurate, and up-to-date.
          </li>
          <li>
            You authorize us to make enquiries with third parties as necessary
            in order to verify your identity, and to protect both you and us
            against fraudulent activity. We will inform you when we make such
            enquiries unless we are prevented from providing prior notice by
            law.
          </li>
          <li>
            In order to verify your identity we may request from you details
            such as your full name, your full physical address, and your email
            address.
          </li>
          <li>
            You are the only person authorized to use your MyCointainer account.
            You will not provide access to any other individual or entity. If we
            have reason to suspect access has been provided to a third party, we
            will suspend, limit or terminate your account.
          </li>
          <li>
            Creation or use of additional Accounts without obtaining prior
            express permission from MyCointainer will result in the immediate
            suspension of all respective Accounts, as well as all pending
            buy/sell orders, if any. Any attempt to create multiple Accounts or
            to assist others (users or other third parties) in doing so, or
            distribution of instructions, software or tools for that purpose
            will result in termination of such users’ Accounts. Termination is
            not an exclusive remedy for such a violation and MyCointainer may
            decide to take further action against the user, including referral
            to applicable governmental authorities.
          </li>
          <li>
            It is your responsibility to ensure that all data held by us
            regarding you is accurate and up to date at all times. You therefore
            agree to undertake the timely maintenance of such data.
          </li>
        </ol>
      </div>
      <div>
        <h4>BANK TRANSFER</h4>
        <ol>
          <li>
            In order to make a purchase using a bank transfer you need to
            deposit the minimum amount specified in your account.
          </li>
          <li>
            Your funds will be returned at your cost when:
            <ul
              style={{
                marginLeft: 20,
                listStyle: "none",
              }}
            >
              <li>
                a) Minimum deposited will not reach the threshold required (in
                case funds to be returned are lower than the cost of transfer,
                they will be deducted as a handling cost;
              </li>
              <li>
                b) Account from which funds were deposited do not match with our
                records of your registered account (will be returned in 31
                days).
              </li>
            </ul>
          </li>
          <li>
            Provided that bank transfer has not been performed as specified in
            this document and described in more details within your account a
            handling fee of 10% of the amount deposited will be deducted every
            time.
          </li>
          <li>
            All bank transfers require name, surname, account number (with SWIFT
            in case of international transfer) and address of the account
            holder. In case those details are not provided, it may cause a delay
            in funds being credited to you or a total failure of the bank
            transfer execution.
          </li>
        </ol>
      </div>
      <div>
        <h4>COIN PURCHASE/SALE/STAKING</h4>
        <ol>
          <li>
            By submitting an order, you provide authorization for MyCointainer
            to execute the requested transaction on your behalf. MyCointainer
            will attempt - on the basis of commercially reasonable terms - to
            execute the trade at, or close to, the market exchange rate defined
            within the Services and displayed to the user at a time.
          </li>
          <li>
            You agree and accept that exchange rates can be volatile, and that
            the rates listed within our Services may differ from those available
            to external sources outside of our own Services.
          </li>
          <li>
            During bouts of illiquidity, excess volume, fast movement or other
            volatility within the marketplace for either digital Assets or
            standard currencies, the final rate at which trades are executed may
            differ from the prevailing rate previously indicated. MyCointainer
            reserves the right to withhold processing the transaction in case of
            time out in api request, issues related to 3rd party providers
            including exchanges and tools needed to execute the transaction as
            well as rapid movement in the exchange rate between trading pairs
          </li>
          <li>
            Cancellation: Once an order has been initiated, it can only be
            canceled at any stage before MyCointainer has executed the
            transaction. The order cannot be amended, canceled or withdrawn
            after execution has taken place.
          </li>
          <li>
            MyCointainer may use third party crypto exchanges to facilitate your
            orders for exchange or purchase of coins. Our participation is only
            meant to facilitate. We do not endorse nor vouch for the integrity
            of the coins you are acquiring so we will not assume any liability
            for losses arising therefrom.
          </li>
          <li>
            In some cases (e.g due to heavy market moves) coin withdrawals might
            be subject to a longer delay. This is caused by various factors
            including, but not limited to undelegation of coins from staking,
            extra security measures and extra verification requirements.
          </li>
        </ol>
      </div>
      <div>
        <h4>COINS DURING MAINTENANCE &amp; DELISTING</h4>
        <ol>
          <li>
            Coins traded on the platform may be placed under ‘maintenance’
            conditions at any time. During Coin maintenance, the affected Coin
            cannot be withdrawn or deposited. Rewards based on trading the
            affected Coin may not be generated, depending on the reason for the
            maintenance period. This is part of the risk of trading in Coins.
          </li>
          <li>
            We may place Coins under maintenance for a variety of reasons,
            including but not limited to:
            <ul
              style={{
                marginLeft: 40,
                listStyle: "disc",
              }}
            >
              <li>Requests from developers</li>
              <li>The Coin falling out of sync with blockchain</li>
              <li>Routine maintenance requirements iv. necessary updates</li>
              <li>Any other issue related to blockchain</li>
            </ul>
            <p
              style={{
                marginLeft: 20,
              }}
            >
              As coin maintenance is a standard trading risk, we do not accept
              any direct or indirect liability for losses or damages caused by
              Coins being placed under maintenance.
            </p>
          </li>
          <li>
            MyCointainer reserves the right to delist any coin at any time. A
            notice will be sent out via email or other publicly available
            channel to inform users about such an event. Users have strictly
            specified time stated in the notice to proceed with the withdrawal of funds.
            After this time if funds are not withdrawn they will be lost.
            MyCointainer reserves the right to proceed with withdrawal of funds
            in a native token currency or any other currencies (eg. Bitcoin) as
            a replacement. The value of a replacement is calculated at the moment
            of withdrawal to ensure the value is the same as native coin value.
          </li>
        </ol>
      </div>
      <div>
        <h4>MYCOINTAINER POWER</h4>
        <p>
          The following constitutes the terms and conditions for MyCointainer
          Power (“program”), the special rewards program offered by MyCointainer
          for an unlimited period. This paragraph is supplemental to the main
          Terms and Conditions of MyCointainer’s website.
        </p>
        <p>
          All those who are not prohibited to use the services of MyCointainer
          according to its terms and conditions are eligible to participate in
          this program. You can participate by simply choosing which
          subscription variant you prefer then paying the corresponding fees.
        </p>
        <p>
          The program will run indefinitely, from the moment you subscribe until
          the moment you decide to unsubscribe.
        </p>
        <p>
          Free withdrawals are subject to a 60 days maturing period in order to
          avoid misuse of this benefit.
        </p>
        <p>
          Free withdrawals do not include the network fee applied by the blockchain.
        </p>
        <p>
          During the subscription process we will gather personal information
          such as name, address, credit card and contact number. We treat all
          your personal information with utmost care and confidentiality but
          before you proceed please read our Privacy Policy. If you do not
          accept the provisions of our privacy policy please discontinue use of
          our service.
        </p>
        <p>
          If you have any complaints or questions, please contact
          support@mycointainer.com
        </p>
      </div>
      <div>
        <h4>ACCOUNT SECURITY</h4>
        <ol>
          <li>
            We take all reasonable measures to ensure the safety and security of
            our online platform. You are personally responsible for ensuring
            that your password and login details are kept safely, securely, and
            known only to you.
          </li>
          <li>
            By using MyCointainer, you state and confirm that you will be
            vigilant in checking the details listed in your Account. This
            includes transaction histories, and status of ongoing trades. You
            must inform MyCointainer immediately if you do not recognize any
            detail shown. We cannot be held liable for damages, losses or other
            consequences of your account being accessed by a third party.
          </li>
        </ol>
      </div>
      <div>
        <h4>USAGE AND RIGHT OVER FEEDBACK</h4>
        <ol>
          <li>
            In using our services, you confirm that you will not attempt to
            interfere with, disrupt or manipulate the normal performance of our
            websites, platforms or Mining application.
          </li>
          <li>
            We maintain full and exclusive right - including intellectual
            property right - to all ideas, feedback, suggestions, materials and
            information about our services provided to us by you, regardless of
            the method of communication (termed as “Feedback”). Feedback
            submitted to MyCointainer is not confidential and becomes our sole
            property. As such we are entitled to use it in any manner we see
            fit, without restriction, for any commercial or non-commercial
            purpose. You are not entitled to acknowledgment or compensation for
            any such usage of the Feedback provided. All right you may have to
            any aspect of the Feedback - including any copyrighted content - is
            waived when the Feedback is submitted to us. You should not send us
            Feedback if your motivation for doing so is financial, or if you
            wish to maintain ownership of your ideas. We receive many ideas and
            suggestions, and the Feedback you provide to us may be similar or
            even identical to Feedback received from other users. We therefore
            maintain this policy to ensure that there are no disputes in these
            circumstances. We reserve the right to disclose information which
            identifies you individually to any third party who claims that
            content posted to our website by you violates their own rights,
            whether intellectual or proprietary. We reserve the right to remove
            content you post on our platforms if we have reason to believe it is
            not consistent with the content standards detailed within this
            section.
          </li>
        </ol>
      </div>
      <div>
        <h4>MINING APPLICATION</h4>
        <ol>
          <li>
            You can download the Mining application from the website  https://mycointainer.com/app/mining/ and install
            it on your Computer. To start mining, you need to have a MyCointainer Account.
          </li>
          <li>
            <ul
              style={{
                marginLeft: 40,
                listStyle: "disc",
              }}
            >
              <li>MyCointainer supports and runs on all modern graphics cards that use NVIDIA® or AMD® chips and also on a variety of Intel® and AMD® processors. The easiest way to check if your mining hardware is compatible is to download it and if the installation was successful, you would be able to start mining. This means your hardware is fully supported. If you face some problems during installation and running the application, check the Instructions.</li>
              <li>NVIDIA graphics cards must support CUDA 10.</li>
              <li>
                OS requirements:<br/>
                <b>Windows</b><br/>
                Windows 7 and later are supported (x86/x64), older operating systems are not supported (and do not work).<br/>
                <b>Mac</b><br />
                The minimum macOS version supported is macOS 10.10 (Yosemite), x86_64/arm64.<br/>
                <b>Linux</b><br/>
                Ubuntu 12.04 and later<br/>
                Debian 8 and later
              </li>
              <li>Any other issue related to blockchain</li>
            </ul>
          </li>
          <li>
            Once it is downloaded, the Mining application needs
            to be installed and run on the Computer on which it has been downloaded.
          </li>
          <li>
            Each user can only mine on one computer at a time,
            but there are no limits to devices it can be downloaded on.
          </li>
        </ol>
      </div>
      <div>
        <h4>SERVICE FEES</h4>
        <ol>
          <li>
            We reserve the right to apply service fees for access to our
            services and platforms. We also reserve the right to amend and
            adjust these fees at any time.
          </li>
          <li>
            Changes made in the Fees &amp; Pricing are not amendments to Terms
            &amp; Conditions.
          </li>
          <li>
            Staking Ethereum coins requires ETH to be deposited to your account
            in order to enable staking. If there's not enough ETH as a
            collateral on your address and automatic 30 USD worth of ETH will be
            credited to you and charged against your current balance to enable
            staking.
          </li>
          <li>
            You provide authorization for us, or for our chosen payment
            processor, to deduct or charge any applicable fees related to trades
            completed via the Services against your MyCointainer Account Fund
            balance.
          </li>
          <li>
            All latest fees can be found under the following address
            <a href="https://www.mycointainer.com/fees-and-pricing/">
              mycointainer.com/fees-and-pricing/
            </a>
          </li>
        </ol>
      </div>
      <div>
        <h4>LIMITATION OF LIABILITY</h4>
        <ol>
          <li>
            MyCointainer cannot be held liable for losses or damages related
            directly or indirectly to any of the following events, among others:
            <ul
              style={{
                marginLeft: 20,
                listStyle: "disc",
              }}
            >
              <li>
                Loss of data, usage, goodwill, profit, or other intangible loss;
              </li>
              <li>
                Loss of access or failure in usage of MyCointainer Services;
              </li>
              <li>
                Unauthorized access to your MyCointainer account including
                execution of trade or alteration of information;
              </li>
              <li>
                Your incorrect interpretation of MyCointainer services, or
                information thereupon;
              </li>
              <li>
                Any loss relating to usage of MyCointainer services but not
                directly attributable to us.
              </li>
            </ul>
          </li>
          <li>
            In no circumstances shall we be held liable for failures or delays
            of service attributed to regular maintenance activities, or factors
            beyond our control including but not limited to power outages, acts
            of government, natural disasters or issues with our service
            providers.
          </li>
          <li>
            Unless stipulated by law, in no circumstances shall MyCointainer,
            its directors, employees, agents or members be held liable for any
            direct, indirect, special or consequential damages, nor any other
            damages of any kind, including but not limited to loss of data or
            usage or loss of profit, whether in tort, contract or action arising
            in any other way from or connected to the usage, or inability to
            use, our services or other material belonging to MyCointainer,
            including but not limited to damage resulting from or caused by your
            reliance on information obtained from or provided by MyCointainer,
            or resulting from omissions, errors, interruptions, data loss or
            deletion, viruses, defects, delays in transmission or operation or
            any performance failure, regardless of whether it results from force
            majeure, failure in communications, destruction of our hardware or
            software or unauthorized access (hacking) of MyCointainer records,
            services or programs.
          </li>
          <li>
            Some jurisdictions disallow the exclusion of some warranties, or
            limitation or exclusion of liability for consequential or incidental
            damages. And as such some of the limitations stated here may not be
            applicable to you.
          </li>
          <li>
            To the maximum extent allowed by any applicable law, in no
            circumstances shall the total liability of MyCointainer (including
            all directors, employees, agents and members), whether in tort,
            contract, warranty (including negligence, be it passive, active or
            imputed), strict liability, product liability, or any other theory
            arising from or related to the inability to use (or usage of)
            MyCointainer or to adherence to these terms exceed the total amount
            in fees paid to you by us during the 12 month period immediately
            prior to the date of any claim which gives rise to any such
            liability.
          </li>
        </ol>
      </div>
      <div>
        <h4>RISKS</h4>
        <p>You, as a user, are aware of the risks associated with the use of the Mining application and/or the Platform and accept the risks. Specifically, you are aware of the following:</p>
        <p>● The usage factor of the computer as a result of downloading and subsequent use of the Mining application and third-party libraries, that are included in the Mining application, including the possibility of its damage or destruction, technical requirements of the Mining application, including the consumption of electricity by the computer in connection with mining of cryptocurrencies;</p>
        <p>● Cryptocurrency exchange rate fluctuations, e.g. Bitcoin, including their possible volatility and the consequences arising therefrom;</p>
        <p>● No guarantee of mining cryptocurrencies using the Mining application at all, including at the level expected by you due to the unforeseen issues arising from but are not limited to broken API connections, sudden 3rd party issues and software malfunctions.</p>
        <p>● The innovative nature of the Platform and the Mining application and the resulting possibility of errors in their functioning;</p>
      </div>
      <div>
        <h4>INDEMNIFICATION</h4>
        <p>
          You agree to hold MyCointainer and its licensors, contractors and
          affiliates, and their respective directors, agents, officers and
          employees, free from any damages and claims (including penalties
          imposed by regulatory authorities, fines and attorneys fees), arising
          from our enforcement or your breach of this Agreement. This clause
          also applies to your violation of any applicable laws, regulations, or
          the rights of third parties during your use of MyCointainer services.
        </p>
      </div>
      <div>
        <h4>TERMINATION OF AGREEMENT</h4>
        <p>
          We reserve the right to suspend your account (and any account
          beneficially owned by any related affiliate or entity) without warning
          , lock or freeze all funds in any such account, and suspend or
          withdraw your access to MyCointainer if we have reason to believe any
          account activity violates the Terms of Service, AML/CTF acts, Privacy
          Policy, or any other applicable law or regulation. MyCointainer
          reserves the right to keep and make use of transaction data or any
          other data related to the accounts in question. Suspension, freezing
          or termination may also be performed in the following circumstances:
        </p>
        <p>
          ● The account is currently subject to government proceedings, criminal
          investigations, or other litigation activity;
        </p>
        <p>● We detect activity on the account which we judge to be unusual;</p>
        <p>● We detect unauthorized access to any such account;</p>
        <p>
          ● A court order or command of a government or regulatory body requires
          us to do so.
        </p>
        <p>
          In the event of any of the following circumstances, we maintain the
          right to immediately terminate your agreement and cancel your account
          with us, and also reserve the right to permanently freeze/cancel any
          authorization of your account on MyCointainer services, and withdraw
          the associated MyCointainer account:
        </p>
        <p>● After termination of services to you by MyCointainer;</p>
        <p>
          ● We believe you have registered to use MyCointainer in another
          person’s name, directly or indirectly;
        </p>
        <p>
          ● We find that the information provided to us by you during
          registration is dishonest, outdated, incorrect or incomplete;
        </p>
        <p>
          ● In the event of your rejection of any amendment to these Terms and
          Conditions, of which you have expressly informed MyCointainer;
        </p>
        <p>
          ● Any other circumstance where we deem it to be necessary to terminate
          provision of services to you.
        </p>
        <p>
          In the event of your account being terminated, in order to meet data
          security standards, we are required to store your account details and
          transactional information therein for a period of five years.
          Additionally, if a transaction is incomplete when termination occurs,
          we reserve the right to notify any affected party of the situation as
          and when it occurs.
        </p>
      </div>
      <div>
        <h4>FUNDS REMAINING UPON TERMINATION</h4>
        <p>
          When an account has been closed or terminated, any remaining balance
          on the account - including liabilities or charges owed to MyCointainer
          - becomes the property of MyCointainer. If MyCointainer is holding
          funds in your Account on your behalf, we may remit and report such
          Funds in accordance with relevant state laws on unclaimed property. We
          maintain full custody of all funds and associated user information and
          data, which we have permission to turn over to the relevant
          authorities in the event of your account being suspended or closed due
          to fraud investigation, AML investigation, or your violation of any
          MyCointainer Terms and Conditions, for example conducting trading
          activities on our platform from within a sanctioned country.
        </p>
      </div>
      <div>
        <h4>INACTIVITY FEE</h4>
        <p>
          In the event that a user does not engage in any non-automatic actions
          or log in to the MyCointainer platform for a continuous period of 12
          months, an Inactivity Fee will be imposed.
        </p>
        <p>
          Commencing from the first day following the 12-month period of inactivity,
          this fee will be applied monthly thereafter. The Inactivity Fee will be
          calculated at 10% of each asset held in the user's account, with a minimum
          charge of 1 EUR equivalent for the day of the transaction.
        </p>
        <p>
          It is essential for users to be aware of this policy and take proactive
          measures to prevent inactivity fees, which may impact the overall value of
          their assets.
        </p>
        <p>
          In order not to occur the inactivity penalty on your account user must hold
          at least 10% or more of its portfolio value in $EARN token.
        </p>
        <p>
          MyCointainer reserves the right to modify or adjust the Inactivity Fee policy,
          and users will be duly notified of any changes through appropriate communication
          channels. It is the responsibility of users to stay informed about platform
          policies and take appropriate actions to avoid incurring such fees.
        </p>
      </div>
      <div>
        <h4>COMPLIANCE WITH THE LAW</h4>
        <p>
          It is your own responsibility to abide by relevant local laws relating
          to the usage of MyCointainer within that jurisdiction. You must also
          factor, in accordance with local laws, correct taxation practice,
          including tax collection, withholding of tax, reporting of tax and
          remittance of tax to the relevant authority. All users of this website
          and our services confirm that the source of their own funds is
          legitimate, and does not arise from conducting any illegal activity.
          We maintain a co-operative stance with law enforcement authorities
          around the world, and as such will promptly freeze, seize and
          terminate the funds and accounts and users identified or flagged by
          any legal mandate.
        </p>
      </div>
      <div>
        <h4>COPYRIGHT AND OTHER INTELLECTUAL PROPERTY RIGHTS</h4>
        <ol>
          <li>
            Unless specifically stated otherwise, all copyrights and other
            intellectual property rights in all materials contained in our
            website, or connected to all our services including but not limited to
            the MyCointainer logo and design features such as text, pictures,
            graphics, data, sound files, and software belong to the company and/or
            our suppliers and licensors, and are protected by international
            copyright and intellectual property rights laws.
          </li>
          <li>
            Users are granted a limited, non-exclusive license to access and make
            use of MyCointainer Materials for your own internal business or
            personal use. This license cannot be sublicensed.
          </li>
          <li>
            The Mining application as a whole as well as its individual independent or dependent
            parts are the property of MyCointainer.
          </li>
          <li>
            MyCointainer grants the user a license to use the Mining application.
            This license is revocable, non-exclusive and not further transferable regardless of the paid or
            free nature of the transfer, including not subject to further licensing, limited to the following activities:
            installation or use for own, nonprofit purpose, on the terms and conditions set out in Terms and Conditions.
            In particular, as a result of downloading or using the Application, the User does not become its owner.
            The Mining application remains the property of MyCointainer.
            The User undertakes that the Mining application will not be:
          </li>
          <li>
            The User undertakes that the Mining application will not be:
            <ul
              style={{
                marginLeft: 20,
                listStyle: "disc",
              }}
            >
              <li>sold, rented, lent or otherwise disposed of, made available, including the use of open sources or used for a purpose other than that indicated in the Terms and Conditions, regardless of the paid or free nature of their use,</li>
              <li>copied, transferred,</li>
              <li>modified (including by introducing their improvements), split, decrypted, transformed in any way, reverse engineered or decompiled.</li>
            </ul>
          </li>
          <li>
            MyCointainer reserves the right to modify the Mining application at its sole discretion,
            including improvements to the Mining application, with regard to their functionality or otherwise.
            Any update shall not affect the terms of use of the Mining application by the user, which remain
            in effect for the Mining application after the update.
          </li>
        </ol>
      </div>
      <div>
        <h4>THIRD PARTY CONTENT</h4>
        <p>
          While using our Services, you may encounter content produced by third
          parties, including links to the web pages of said parties, which may
          include links to other sites such as but not limited to social media
          sites like Twitter and Facebook (deemed as “Third Party Content”). We
          have no means of controlling Third Party Content, and therefore do not
          endorse or adopt any of it as our own. We are not responsible for any
          Third Party Content, including but not limited to any misleading,
          incorrect, offensive, objectionable or indecent material.
          Additionally, your own dealings or correspondences with any Third
          Party are conducted solely between you and the Third Party in
          question.
        </p>
      </div>
      <div>
        <h4>COMPLAINTS</h4>
        <p>
          In the event that you have a complaint, wish to provide feedback, or
          have any queries, please email us at support@mycointainer.com and we
          will respond as quickly as possible.
        </p>
        <p>
          <em>latest update: January 01, 2024</em>
        </p>
      </div>
    </Container>
  );
};

export const Container = styled.div`
  & > div {
    font-size: 22px;
    line-height: 2.2rem;
  }

  h1 {
    font-size: 5rem;
    text-align: center;
    line-height: 1;
    margin-bottom: 80px;
  }

  h4 {
    font-size: 22px;
  }

  ol,
  ul {
    padding: 0px;
    list-style-position: inside;
    margin: 0.5em 0px;
  }

  li {
    margin: 0.5em 0px;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  table {
    border-collapse: collapse;

    th,
    td {
      border: 1px solid rgb(0, 0, 0);
      width: 50%;
      padding: 15px;
    }
  }

  @media (max-width: 998px) {
    h1 {
      font-size: 3rem;
    }

    h4,
    p,
    ul,
    li,
    table {
      font-size: 16px;
    }
  }
`;
