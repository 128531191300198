import { Section } from "components/business/Section";
import { FC } from "react";
import {
  List,
  ListItem,
  ListItemTitle,
  ListItemDescription,
} from "components/business/List";
import simplicity from "./img/simplicity.svg";
import support from "./img/support.svg";
import security from "./img/security.svg";

export const WhyMyCointainer: FC = () => {
  return (
    <Section title="Why choose MyCointainer Business?">
      <List>
        <ListItem size={3}>
          <img alt="" src={simplicity} />
          <ListItemTitle>Simplicity</ListItemTitle>
          <ListItemDescription>
            Turn on & off access to various cryptocurrencies that you want to
            use. Keep all your transaction statements and balances at a glance.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={security} />
          <ListItemTitle>Security</ListItemTitle>
          <ListItemDescription>
            We've been battle-testing our solutions on the MyCointainer platform
            for over 3 years on a daily basis to ensure the highest-quality.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={support} />
          <ListItemTitle>Technical & expertise support</ListItemTitle>
          <ListItemDescription>
            We know that getting started with crypto may not always be easy.
            We’re here to give you the best solutions and help you with the
            integration.
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
