import PropTypes from "prop-types";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 10px;

  font-size: 0.875rem;
  line-height: 1.57;
  color: var(--color--black);

  user-select: none;

  cursor: pointer;
`;

const Checker = styled.div`
  width: 44px;
  height: 22px;

  background: ${(props) =>
    props.checked ? "var(--color--teal)" : "var(--color-monochrome--light-3)"};

  border-radius: var(--rounding--large);

  display: flex;
  align-items: center;

  justify-content: ${(props) => (props.checked ? "flex-end" : "flex-start")};

  transition: all 0.2s ease-out;

  p {
    margin: 2px;
    width: 18px;
    height: 18px;
    background-color: var(--color--white);
    border-radius: var(--rounding--circle);
  }
`;

export const Checkbox = (props) => {
  const { value, children, onChange } = props;

  const onClick = () => {
    onChange(!value);
  };

  return (
    <Container onClick={onClick}>
      <Checker checked={value}>
        <p />
      </Checker>
      {children}
    </Container>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
