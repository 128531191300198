import { Section } from "components/business/Section";
import { Questions } from "components/Questions";
import { FC } from "react";

export const Cases: FC = () => {
  return (
    <Section title="Business cases">
      <Questions
        dark
        questions={[
          {
            title: "Staking Platforms",
            description:
              "Provide your users with extra earning capabilities thanks to PoS protcol embeded within the blockchains that we support. ",
          },
          {
            title: "Crypto Exchanges",
            description:
              "You can enable extra coins with earning capabilities for your exchange.",
          },
          {
            title: "Data API",
            description:
              "You can create nodes with various APIs that can be utilized to build any site or platform that needs such data; aggregators, insight platforms, data platforms, comparisons sites, ranking sites and much more.",
          },
          {
            title: "Crypto Wallets",
            description:
              "Having your own nodes allows you to have in-depth control and access to creating your own crypto wallets. You don’t have to worry about your own infrastructure, uptime and maintanance.",
          },
          {
            title: "Blockchain Projects",
            description:
              "If you’re looking for a place to run your testnet or mainnet nodes, use our solutions. If you are already building any project that facilititates connection to other blockchain, feel free to check all the coins blockchain we support. You can easily spin them in a few clicks.",
          },
          {
            title: "dApps Development",
            description:
              "Based on what your app does, you can have your own node with blockchain and API layer that can be used for your dApp development",
          },
        ]}
      />
    </Section>
  );
};
