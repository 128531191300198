import React, { useCallback, useEffect, useState } from "react";

import "./styles.scss";
import { ExchangeFrom } from "./components/ExchangeFrom";
import { ReactComponent as SwapIcon } from "./img/swap.svg";
import { ReactComponent as SwapMobileIcon } from "./img/swap-horizontal.svg";
import { ExchangeTo } from "./components/ExchangeTo";
import { IS_MOBILE, URLS } from "const";

import useSellableCoins from "./useSellableCoins";

export const ExchangeInSeconds = () => {
  const [sellAmount, setSellAmount] = useState("");
  const [buyAmount, setBuyAmount] = useState("");
  const [buyableCoins, setBuyableCoins] = useState([]);
  const [exchangeCoinFrom, setExchangeCoinFrom] = useState(null);
  const [exchangeCoinTo, setExchangeCoinTo] = useState(null);

  const { coins, loading } = useSellableCoins()

  const onFromCoinChange = (coin) => {
    updateBuyableCoins(coin);
    setExchangeCoinFrom(coin);
  };

  const swapCoins = () => {
    setExchangeCoinFrom(exchangeCoinTo)
    setExchangeCoinTo(exchangeCoinFrom)
  };

  const updateBuyableCoins = (coin) => {
    const isBuyableByCoins = coins.filter((c) =>
      c.isBuyableBy.includes(coin.code)
    );
    const isBuyableOnExchange = coins.filter((c) =>
      coin.sellVia?.find((sell) => sell.ExchangeTo === c.code)
    );
    const newBuyableCoins = isBuyableOnExchange.concat(isBuyableByCoins);
    const withoutDuplicates = Object.values(
      Object.fromEntries(newBuyableCoins.map((c) => [c.code, c]))
    );

    setBuyableCoins(withoutDuplicates);
  };

  const rateReq = useCallback(() => {
    setBuyAmount(Math.random() * 10);

    const exchangeCoinPriceInEur =
      exchangeCoinFrom.code === "EUR" ? 1 : exchangeCoinFrom.price?.value;
    const sellAmountInEur = exchangeCoinPriceInEur * +sellAmount;
    const buyAmountCoins = sellAmountInEur / exchangeCoinTo.price?.value;
    setBuyAmount(buyAmountCoins);
  }, [exchangeCoinFrom, exchangeCoinTo, sellAmount]);

  useEffect(() => {
    if (exchangeCoinFrom && exchangeCoinTo) {
      rateReq();
    }
  }, [sellAmount, exchangeCoinFrom, exchangeCoinTo, rateReq]);

  return (
    <div className="exchange-in-seconds-container">
      <div className="title">Crypto exchange in seconds</div>
      <div className="sub-title">
        Select an example asset pair, enter the exchange amount, and see how
        smooth it is
      </div>
      <div className="exchange-container">
        <div className="exchange-from-to-container">
          <div className="select-container">
            <label for="" class="exchange-coin-label">
              From
            </label>
              <ExchangeFrom
                exchangeCoinFrom={exchangeCoinFrom}
                setExchangeCoinFrom={onFromCoinChange}
                sellableCoins={coins}
                sellAmount={sellAmount}
                setSellAmount={setSellAmount}
                isRequesting={loading}
              />
          </div>
          <button
            disabled={!coins?.length}
            onClick={swapCoins}
            class="swap"
          >
            {IS_MOBILE ? <SwapMobileIcon /> : <SwapIcon />}
          </button>
          <div className="select-container">
            <label for="" class="exchange-coin-label">
              To
            </label>
            <ExchangeTo
              exchangeCoinTo={exchangeCoinTo}
              setExchangeCoinTo={setExchangeCoinTo}
              buyableCoins={buyableCoins}
              buyAmount={buyAmount}
            />
          </div>
        </div>
      </div>
      <a className="login-button" href={URLS.SIGNUP}>
        Login to exchange
      </a>
    </div>
  );
};
