import { uris } from "const";
import { BUSINESS_NAVIGATION, CLASSIC_NAVIGATION } from "../navigation";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import logo from "./img/logo.svg";
import chevronDown from "./img/chevronDown.svg";

export const NavigationTop: FC = () => {
  const isBusiness = location.pathname.includes(uris.business.index);

  const isDevelopers = location.pathname.includes(uris.developers.index);

  const type = isBusiness
    ? "business"
    : isDevelopers
    ? "coinDeveloper"
    : "classic";

  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setIsHidden(window.scrollY > 16);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <Container isHidden={isHidden}>
      <Navigation type={type}>
        <a
          className={!isBusiness && !isDevelopers ? "active" : void 0}
          href="/"
        >
          PERSONAL
        </a>
        <a className={isBusiness ? "active" : void 0} href="/business">
          BUSINESS
        </a>
        <a className={isDevelopers ? "active" : void 0} href="/coin-developers">
          COIN DEVELOPERS
        </a>
      </Navigation>
      <Header type={type}>
        <a
          role="img"
          href={`/${
            type === "classic"
              ? ""
              : type === "coinDeveloper"
              ? "developers"
              : "business"
          }`}
        >
          <img id="logo" alt="" src={logo} />
        </a>
        {(type === "classic"
          ? CLASSIC_NAVIGATION
          : BUSINESS_NAVIGATION
        ).HEADER.map(
          (
            // @ts-expect-error
            { href, title, children }
          ) => (
            <a router-ignore href={href}>
              {title}
              {children && <img alt="" src={chevronDown} />}
              {children && (
                <Dropdown>
                  <div>
                    {/* @ts-expect-error */}
                    {children.map(({ title, description, image, href }) => (
                      <DropdownItem href={href}>
                        <img alt="" src={image} />
                        <article>
                          <span role="heading">{title}</span>
                          <span>{description}</span>
                        </article>
                      </DropdownItem>
                    ))}
                  </div>
                </Dropdown>
              )}
            </a>
          )
        )}
        <Login>
          <a router-ignore href={`${uris.solutions.login}/${type}`} id="login">
            Log In
          </a>
          <a
            router-ignore
            href={`${uris.solutions.register}/${type}`}
            id="register"
            data-is-classic={type === "classic"}
            data-is-purple={type === "coinDeveloper"}
          >
            Sign Up
          </a>
        </Login>
      </Header>
    </Container>
  );
};

interface ContainerProps {
  isHidden: boolean;
}

const DropdownItem = styled.a`
  display: flex;
  gap: 24px;

  text-decoration: none;

  padding: 12px 24px;

  &:hover {
    background-color: rgba(107, 160, 181, 0.1);

    span[role="heading"] {
      color: ${(props) => props.theme.cyan};
    }
  }

  article {
    display: flex;
    flex-direction: column;
    gap: 2px;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #646464;

      &[role="heading"] {
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        color: ${(props) => props.theme.black};
      }
    }
  }
`;

const Container = styled.div<ContainerProps>`
  position: fixed;
  width: 100%;
  z-index: 9999;

  & + div {
    margin-top: 135px;
  }

  @media (max-width: 998px) {
    & + div {
      margin-top: 119px;
    }
  }

  @media (max-width: 576px) {
    & + div {
      margin-top: 83px;
    }
  }
`;

const Dropdown = styled.div`
  position: absolute;
  display: none;

  width: 120px;
  padding-top: 64px;

  & > div {
    position: absolute;
    background: ${(props) => props.theme.white};
    box-shadow: 0px 25px 40px rgba(189, 190, 199, 0.75);
    border-radius: 8px;
    padding: 4px 0;
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.div<NavigationProps>`
  background-color: ${(props) => props.theme.white};
  padding: 0 120px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 32px;
  height: 99px;

  box-shadow: ${(props) =>
    props.type === "classic" ? "0px 1px 3px rgba(0, 0, 0, 0.15)" : null};

  #logo {
    margin-right: 48px;
  }

  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #646464;
    display: flex;
    align-items: center;
    gap: 16px;
    white-space: nowrap;

    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.cyan};

      div {
        display: flex !important;
      }
    }

    &:hover {
      color: ${(props) =>
        props.type === "coinDeveloper" ? "#4F23FF" : props.theme.black};
    }
  }

  @media (max-width: 1200px) {
    a:not([role="img"]) {
      display: none;
    }
  }

  @media (max-width: 998px) {
    height: 83px;
    padding: 24px 16px;
  }
`;

interface NavigationProps {
  type: string;
}

const Navigation = styled.nav<NavigationProps>`
  padding: ${(props) =>
    props.type === "classic" ? "13px 120px" : "12px 120px"};
  width: 100%;

  background-color: ${(props) =>
    props.type === "classic" ? props.theme.white : props.theme.black};

  border-bottom: ${(props) =>
    props.type === "classic" ? "solid 1px #F4F4F4" : null};

  display: flex;
  gap: 16px;

  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: ${(props) => (props.type === "classic" ? "#5A6374" : "#AEAEAE")};

    &.active,
    &:hover {
      color: ${(props) =>
        props.type === "classic" ? props.theme.black : props.theme.white};
    }
  }

  @media (max-width: 998px) {
    padding: 12px 16px;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

const Login = styled.div`
  margin-left: auto;
  display: flex;
  gap: 12px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 22px;

    padding: 14px 28px;

    border-radius: 4px;

    text-decoration: none;
    cursor: pointer;
  }

  #login {
    border: 1px solid #ebebeb;
  }

  #register {
    color: ${(props) => props.theme.white};
    background-color: #03a3d6;

    &[data-is-classic="true"] {
      background-color: #2254f6;
    }

    &[data-is-purple="true"] {
      background-color: #7957ff;
    }
  }

  @media (max-width: 998px) {
    display: none;
  }
`;
