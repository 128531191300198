import phonePic from "./img/phone.png";
import phoneMobilePic from "./img/phoneMobile.png";
import backgroundPic from "./img/background.svg";
import arrowIcon from "./img/arrow.svg?raw";
import "./styles.scss";
import { useWindowDimension } from "hooks/useWindowDimensions";
import { URLS } from "const";

export const BoostBalance = () => {
  const { width } = useWindowDimension();
  const isMobile = width <= 1280;

  return (
    <div className="cashback-wrapper">
      <div className="cashback-container">
        <img alt="cashback" src={backgroundPic} className="background" />
        <img
          alt="phone"
          src={isMobile ? phoneMobilePic : phonePic}
          className="phone-pic"
        />
        <div className="info-container">
          <div className="title">
            Try an additional way to boost your balance with our Cashback
          </div>
          <div className="sub-title">
            Increase your passive earnings by using our "shop to earn" feature.
            Mycointainer offers cashback from over 2300 global retailers.
          </div>
          {!isMobile && (
            <div className="buttons-container">
              <a href={URLS.LANDINGS.CASHBACK} className="btn">
                Learn More <img alt="arrow" src={arrowIcon} />
              </a>
            </div>
          )}
        </div>
        {isMobile && (
          <div className="buttons-container">
            <a href={URLS.LANDINGS.CASHBACK} className="btn">
              Learn More <img alt="arrow" src={arrowIcon} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
