import { FC } from "react";
import { Header } from "components/business/Header";
import { Info } from "./Info";
import styled from "styled-components";
import hero from "./img/hero.png";
import { Section } from "components/business/Section";
import { Solutions } from "./Solutions";
import { Partners } from "components/business/Partners";
import { Services } from "./Services";
import { Footer } from "components/business/Footer";
import { Questions } from "components/Questions";
import { GetInTouch } from "components/business/GetInTouch";
import { NavigationTop } from "components/NavigationTop";

export const Developers: FC = () => {
  return (
    <Container>
      <NavigationTop />
      <Header
        title={
          <Title>
            Take your coin
            <br /> to the moon
          </Title>
        }
        description="Take advantage of our solutions that will help your coins reach higher-highs."
        action="Explore solutions and services"
        image={<img alt="" src={hero} width="656" />}
        hidePrimaryAction
      />
      <Info />
      <Solutions />
      <Services />
      <Partners />
      <Section title="FAQ">
        <Questions
          purple
          questions={[
            {
              title: "What is the price of your services?",
              description:
                "Our pricing is based on various factors and is set per individual request. Thanks to that, we can provide you with the most satisfying price and match it with your needs. Please, apply using this form and specify what areas of the MyCointainer ecosystem you’re intrested in. Our business development specialist will come back to you as soon as possible.",
            },
            {
              title: "How long does it take to get integrated on the platform?",
              description:
                "On average, it takes from 2 to 4 weeks. However, ocassionally due to a higher demand or if your project requires a more custom approach to integration, it may take up to 6 weeks max before getting fully integrated on the platform.",
            },
            {
              title:
                "My blockchain solution doesn’t offer any staking or earning capabilities, can it be integrated?",
              description:
                "Of course. We build or help to build rewarding protocols to our partners. They can be used on our platform in a form of a ‘reward distribution’ or ‘pre-stake’ mechisms that our audience can take advantage of.<br /><br />Also, please note there are many ways to participate in MyCointainer ecosystem. First of all, your coin can be integrated for our exchange to be purchased using other cryptocurrencies and FIAT currencies. Second of all, we offer a wide range of marketing activities that can help to boost the presence of your among the MyCointainer audience and our media partners. Last but not least, you can use our Node Deployment solution to enable your project to be used and built for both individuals and businesses.",
            },
            {
              title:
                "How will I see the results of using any of the solutions or services of MyCointainer?",
              description:
                "Any solution or service that you will use we will provide you with detailed report. You can always also on demand us ask or get related informations that you’re intrested. Very often we also help partners with coming up with more accurate solutions or ideas to improve and maximize the effort or our collaboraiton.",
            },
            {
              title:
                "I’m unsure about the capabilities of my blockchain or project and weather it can be implemented in your Platform, what should I do?",
              description:
                "MyCointainer worked on 1:1 basis with integrations with over 50 partners since 2018 and we have managed to resolve plenty of integrational issues and agree on final impelementational solution. We also realize that blockchain and crytpo industry evolves very rapidly and changes very fast. You can always reach out to us on our dedicated Telegram account or simply send us an email.",
            },
          ]}
        />
      </Section>
      <GetInTouch
        title="Ready for take off?"
        description="Try MyCointainer Now"
        action="Talk to us"
        primaryAction="Get in touch"
      />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.purple};
`;

const Title = styled.p`
  @media (max-width: 998px) {
    br {
      display: none;
    }
  }

  @media (min-width: 998px) {
    white-space: nowrap;
  }
`;
