import { FC } from "react";
import styled from "styled-components";
import lockup from "./img/enjoy-staking/lockup.svg";
import newbies from "./img/enjoy-staking/newbies.svg";
import rewards from "./img/enjoy-staking/rewards.svg";
import selection from "./img/enjoy-staking/selection.svg";
import fees from "./img/enjoy-staking/fees.svg";
import t from "i18n";

const listing = [
  {
    image: lockup,
    title: t("staking.enjoy.lockup.title"),
    description: t("staking.enjoy.lockup.description"),
  },
  {
    image: rewards,
    title: t("staking.enjoy.dailyRewards.title"),
    description: t("staking.enjoy.dailyRewards.description"),
  },
  {
    image: newbies,
    title: t("staking.enjoy.newbies.title"),
    description: t("staking.enjoy.newbies.description"),
  },
  {
    image: selection,
    title: t("staking.enjoy.selection.title"),
    description: t("staking.enjoy.selection.description"),
  },
  {
    image: fees,
    title: t("staking.enjoy.fees.title"),
    description: t("staking.enjoy.fees.description"),
  },
];

export const Enjoy: FC = () => {
  return (
    <Container>
      <List>
        {listing.map(({ image, title, description }) => (
          <ListItem>
            <img src={image} alt="" />
            <Title>{title}</Title>
            <Description>{description}</Description>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-top: 60px;
  gap: 80px;
  max-width: 1200px;

  @media (max-width: 576px) {
    width: 375px;
    gap: 16px;
    margin-right: -32px;
    justify-content: flex-start;
  }

  @media (max-width: 376px) {
    margin: 0 16px !important;
    width: 100%;
  }
`;

const ListItem = styled.div`
  display: flex;
  width: 165px;
  flex-direction: column;
  align-items: center;

  img {
    width: 60px;
    height: 60px;
    margin-bottom: 24px;
  }

  @media (min-width: 768px) {
    width: 328px;
  }

  @media (max-width: 576px) {
    img {
      margin-top: 12px;
    }

    padding: 12px;
    border: 1px solid var(--color-monochrome--light-2);
    box-shadow: var(--shadow--low);

    &:nth-child(3) {
      margin-top: -16px;
    }

    &:nth-child(5) {
      margin-top: -40px;
    }
  }

  @media (max-width: 376px) {
    margin-top: 0 !important;
    width: 100%;
    margin: 0 16px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: var(--color--black);
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--color--secondary);
  margin-top: 8px;
`;
