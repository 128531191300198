import { MAILTO_CONTACT } from "const";

export const pages = [
  [
    {
      title: "Company",
      path: "/coin-developers/company",
      name: "company",
      list: [
        {
          page: "About Us",
          path: "/company",
        },
        {
          page: "Mission",
          path: "/company",
        },
        // {
        //   page: "Careers",
        //   path: `${location.href}/en/individuals/about-us/careers`,
        // },
        {
          page: "News",
          path: "https://company.mycointainer.com/",
        },
        {
          page: "Partnership",
          path: "https://forms.gle/a8FrvG5DiRTr9Hn27",
        },
        {
          page: "Investors",
          path: "https://forms.gle/VmgxjQYKbszi6Qh27",
        },
        // {
        //   page: 'Press'
        // },
        {
          page: "Contact",
          path: MAILTO_CONTACT,
        },
      ],
    },
    {
      title: "Resources",
      name: "resources",
      path: "/assets",
      list: [
        {
          page: "Assets List",
          path: "/assets",
        },
        {
          page: "Terms & Conditions",
          path: "/agreement/terms-conditions/",
        },
        {
          page: "Fees",
          path: "/fees-and-pricing",
        },
        {
          page: "Privacy",
          path: "/agreement/privacy-policy/",
        },
        // {
        //   page: 'Cookies'
        // },
        // {
        //   page: 'Sitemap'
        // }
      ],
    },
  ],
  [
    {
      title: "Earn",
      name: "earn",
      list: [
        {
          page: "Staking",
          path: "/staking",
        },
        {
          page: "Cold Staking",
          path: "/cold-staking",
        },
        {
          page: "Exchange",
          path: "/exchange",
        },
        {
          page: "Airdrops & Giveaways",
          path: "/airdrops",
        },
        {
          page: "Cashback",
          path: "/cashback",
        },
        // {
        //   page: 'Node Deployment',
        //   path: '/developers/node-deployment'
        // }
      ],
    },
    // {
    //   title: 'Community',
    //   name: 'community',
    //   list: [
    //     {
    //       page: 'Community Portal'
    //     },
    //     {
    //       page: 'Ambassadors & Influencers'
    //     }
    //   ]
    // },
    {
      title: "Support",
      name: "support",
      path: "https://desk.zoho.eu/portal/mycointainerbusiness/en/home",
      list: [
        {
          page: "Support Center",
          path: "https://support.mycointainer.com/en/",
        },
        // {
        //   page: "Create Ticket",
        //   path: "https://www.mycointainer.com/en/individuals/contact",
        // },
        {
          page: "Send Feedback",
          path: "https://mycointainer.canny.io/",
        },
      ],
    },
  ],
  [
    {
      title: "Developers",
      name: "developers",
      path: "https://mycointainer.canny.io/bug-reports",
      list: [
        {
          page: "Bug Bounty Program",
          path: "https://mycointainer.canny.io/bug-reports",
        },
      ],
    },
    {
      title: "Learn",
      name: "learn",
      path: "/insight",
      list: [
        {
          page: "Video Guides",
          path: "https://www.youtube.com/watch?v=b4o_hm8LnnA&list=PLsei49aSqpvniGbk2ADg22QK1cC-dHnNz",
        },
        {
          page: "How to stake Cartesi",
          path: "/insight/cartesi-staking-guide/",
        },
        {
          page: "How to stake Cardano",
          path: "/insight/cardano-staking-guide/",
        },
        {
          page: "How to stake Waves",
          path: "/insight/waves-staking-guide/",
        },
        {
          page: "More...",
          path: "/insight/tag/guides-and-tutorials/",
        },
      ],
    },
    {
      title: "Get Insight",
      name: "getInsight",
      path: "/insight",
      list: [
        {
          page: "Top staking coins this week",
          path: "/insight/tag/earn/",
        },
        {
          page: "Hottest airdrops of the week",
          path: "/insight/tag/giveaways-and-airdrops/",
        },
        {
          page: "More...",
          path: "/insight",
        },
      ],
    },
    {
      title: "Power",
      name: "power",
      path: "/power",
      list: [
        {
          page: "Get Ultimate Benefits",
          path: "/power",
        },
      ],
    },
  ],
];
