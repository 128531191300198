import { Button } from "components/business/Button";
import {
  List,
  ListItem,
  ListItemTitle,
  ListItemDescription,
} from "components/business/List";
import { Section } from "components/business/Section";
import listing from "./img/listing.svg";
import airdrops from "./img/airdrops.svg";
import nodes from "./img/nodes.svg";
import power from "./img/power.svg";

export const Services = () => {
  return (
    <Section
      title="Services for Coin Developers"
      description="See how we can supprt you in many other ways."
    >
      <List hideBackground>
        <ListItem purple size={2}>
          <img alt="" src={listing} />
          <ListItemTitle>
            <Button variant="text">Coin Listing</Button>
          </ListItemTitle>
          <ListItemDescription purple>
            Enable the full potential of your coin by introducing it to a wide
            range audiences. Make it exchangeable via crypto and FIAT. Enable
            rewarding mechanisms to attract users to your coin.
          </ListItemDescription>
        </ListItem>
        <ListItem purple size={2}>
          <img alt="" src={airdrops} />
          <ListItemTitle>
            <Button variant="text">Airdrops & Giveaways</Button>
          </ListItemTitle>
          <ListItemDescription purple>
            The best way to reach over 500 000 users and raise awarness of your
            coin or token. All it takes on MyCointainer are just a few simple
            steps.
          </ListItemDescription>
        </ListItem>
        <ListItem purple size={2}>
          <img alt="" src={nodes} />
          <ListItemTitle>
            <Button variant="text">Nodes & Validator Partner</Button>
          </ListItemTitle>
          <ListItemDescription purple>
            We’ve been supporting partners in creating their masternodes, nodes
            & validators – in both testnet and mainet. With our support from the
            very start, you will launch your network faster.
          </ListItemDescription>
        </ListItem>
        <ListItem purple size={2}>
          <img alt="" src={power} />
          <ListItemTitle>
            <Button variant="text">POWER Codes</Button>
          </ListItemTitle>
          <ListItemDescription purple>
            Provide your customers with extra value by enhancing your own
            offering with our Value Added Service (VAS).
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
