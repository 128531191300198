import { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"
import t from "i18n";
import { useParams } from "react-router-dom";
import cn from 'classnames';
import styled from "styled-components";

import { StandardLayout } from "components/StandardLayout";
import { Container } from "components/Container";
import { Footer } from "components/Footer";
import { Questions } from "components/Questions";
import { Spinner } from "components/Spinner";

import { useIsMobile } from "hooks/useIsMobile";

import { chevron } from "assets";

import { ReactComponent as WalletIcon } from './img/wallet-icon.svg'
import { ReactComponent as HeaderImage } from './img/header-image.svg'

import { useCoins } from "../ColdStaking/useCoins";

import AssetData from './AssetData'
import Table from './Table'

import styles from './styles.module.scss'

const randomFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;

  & > div {
    margin-top: 0 !important;
  }
`;

const AssetPage = () => {
  const isMobile = useIsMobile({ mobileWidth: 1200 });
  const { code } = useParams();
  const { coins, loading } = useCoins();
  const [coin, setCoin] = useState(null)

  useEffect(() => {
    if (!loading) {
      setCoin(coins.find(coin => coin.code === code))
    }
  }, [loading])

  if (!coin) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  
  const assetData = {
    logo: coin.imageUrl,
    name: coin.name,
    code: coin.code,
    network: {
      imageUrl: coin.imageUrl,
      name: coin.name
    },
    TVL: '164,123.213',
    product: 'Flexible /Locked',
    type: 'DeFi Staking',
    APR: coin.yearlyProfit
  }

  const possibleMethods = ['Locked Staking', 'Flexible Staking']
  const possibleLockupPeriods = ['90 Days', '60 Days', '30 Days', 'Flexible']

  const baseStakingFields = {
    name: coin.name,
    code: coin.code,
    logo: coin.imageUrl,
    APR: coin.yearlyProfit,
  }

  const stakingOptions = [
    {
      ...baseStakingFields,
      method: possibleMethods[Math.round(Math.random())],
      lockupPeriod: possibleLockupPeriods[randomFromInterval(0, 3)],
      rewardsLeft: {
        total: 10000,
        left: 8000
      }
    },
    {
      ...baseStakingFields,
      method: possibleMethods[Math.round(Math.random())],
      lockupPeriod: possibleLockupPeriods[randomFromInterval(0, 3)],
      rewardsLeft: {
        total: 10000,
        left: 0
      }
    },
    {
      ...baseStakingFields,
      method: possibleMethods[Math.round(Math.random())],
      lockupPeriod: possibleLockupPeriods[randomFromInterval(0, 3)],
      rewardsLeft: {
        total: 10000,
        left: 4000
      }
    },
    {
      ...baseStakingFields,
      method: possibleMethods[Math.round(Math.random())],
      lockupPeriod: possibleLockupPeriods[randomFromInterval(0, 3)],
      rewardsLeft: {
        total: 10000,
        left: 1000
      }
    }
  ]

  return (
    <StandardLayout>
      <Helmet>
        <title>
          {t("meta.title.coldStaking")}
        </title>
        <meta name="description" content={t("meta.description.coldStaking")} />
      </Helmet>
      <Container>
        <header className={styles.header}>
            <div className={styles['header-left-column']}>
              <h1 className={styles['main-title']}>
                <p>
                  <span className={styles['main-title-first-line']}>Stake <span className={styles['accent-text']}>{code}</span> in DeFi way -</span>
                  <span className={styles['main-title-second-line']}>Earn Up to <span className={styles['accent-text']}>{coin.yearlyProfit}%</span> APR</span>
                </p>
              </h1>
              <p className={styles['header-description']}>
                Stake your ThunderCore coins directly from 
                your own wallets and earn in a non-custodial way. 
                Your keys, your crypto rewards.
              </p>
              {
                isMobile
                  ? null
                  :
                    (
                      <button className={styles["header-button"]}>
                        <WalletIcon />
                        Connect Wallet
                        <img alt="" src={chevron} />
                      </button>
                    )
              }
            </div>
            <div className={styles["header-right-column"]}>
              <HeaderImage className={styles['header-image']} />
              {
                isMobile
                  ?
                    (
                      <button className={styles["header-button"]}>
                        <WalletIcon />
                        Connect Wallet
                        <img alt="" src={chevron} />
                      </button>
                    )
                  : null
              }
            </div>
            <AssetData {...assetData} />
        </header>
        <Table stakingOptions={stakingOptions} />
        <section className={styles['coin-description-section']}>
          <h3 className={styles['title']}>What is ThunderCore?</h3>
          <p className={cn(styles['description'], styles['main-description'])}>
            Solana cryptocurrency is a platform that runs in a multi-thread blockchain Proof of Stake consensus.
            It focuses on providing scalability without having to give up user’s security or decentralization
          </p>
          <div className={styles['two-column-text']}>
            <div className={styles['left']}>
              <b className={styles['secondary-title']}>
                Project in a nutshell
              </b>
              <p className={styles['description']}>
                The Solana project was created to provide a scalable platform that will not disintegrate the system security or decentralization. It runs in Proof of Stake consensus, single-chain system.
                The platform was established in 2017 by the engineers who formerly worked with Dropbox, Qualcomm, and Intel, companies.
                Solana's key to the scaling solution is their Proof of History consensus. It allowed them to find the solutions in areas where there are non trusted sources of time.
                By introducing and applying the verifiable delay functions, it has improved the overall network efficiency. Each node were able to function and generate timestamps with the SHA256 computing system, eliminating the need for the system to broadcast it across other networks
              </p>
            </div>
            <div className={styles['right']}>
              <b className={styles['secondary-title']}>
                Interesting Facts
              </b>
              <p className={styles['description']}>
                Its blockchain is currently supporting transactions that speed Up to more than 50,000 per second at 400ms block times.
                It is the world’s first web-scale blockchain that facilitates high and large performance.
                The platform continuously runs over 3 billion of total transactions, a block height of 37 million, and 175 Global Validators.
                Solana has 8 core innovations known as Archivers, Pipelining, Cloudbreak, Gulf Stream, Sealevel, Tower BFT, Turbine, and Proof of History, which makes its transaction speed process possible
              </p>
            </div>
          </div>
        </section>
        <section className={styles.section}>
          <h3 className={styles['section-title']}>{t('common.faq')}</h3>
          <Questions
            questions={[
              {
                title: t("staking.cold.q.whatIsStaking"),
                description: t("staking.cold.q.whatIsStaking.description"),
              },
              {
                title: t("staking.cold.q.howItWork"),
                description: t("staking.cold.q.howItWork.description"),
              },
              {
                title: t("staking.cold.q.isAuto"),
                description: t("staking.cold.q.isAuto.description"),
              },
              {
                title: t("staking.cold.q.averageYield"),
                description: t("staking.cold.q.averageYield.description"),
              },
            ]}
          />
        </section>
        <Footer />
      </Container>
    </StandardLayout>
  );
};

export default AssetPage;
