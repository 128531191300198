import PropTypes from "prop-types";

import styled from "styled-components";

const StyledDiv = styled.div`
  --color: var(--color--gray);
  background-color: var(--color);
  border-radius: 16px;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    var(--color);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s skeleton ease-in-out infinite;

  @keyframes skeleton {
    to {
      background-position-x: -20%;
    }
  }
`;

export const Skeleton = (props) => {
  const { children, style, ...styleProps } = props;

  return (
    <StyledDiv
      role="alert"
      aria-busy="true"
      style={{ ...(style ?? {}), ...(styleProps ?? {}) }}
    >
      {children}
    </StyledDiv>
  );
};

Skeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.node,
};
