import styled from "styled-components";
import { assignmentsAirdrops, incomeAirdrops, choiceAirdrops } from "assets";

import t from "i18n";

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 81px;

  padding: 0 32px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0 16px;
    gap: 8px;
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  img {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 1024px) {
    gap: 20px;
    flex-direction: row;
    background: var(--color--white);
    border: 1px solid var(--color-monochrome--light-2);
    padding: 8px 12px;
    box-shadow: var(--shadow--stroke--dash);
    border-radius: 8px;

    img {
      width: 32px;
      height: 32px;
    }
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2,
  p {
    text-align: center;
    letter-spacing: -0.015em;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    color: var(--color--black);
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: var(--color--secondary);
  }

  @media (min-width: 1024px) {
    max-width: 358px;
  }

  @media (max-width: 1024px) {
    gap: 0;

    h2,
    p {
      text-align: left;
    }

    h2 {
      font-size: 16px;
      line-height: 1.5;
    }

    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const Benefits = () => {
  return (
    <List>
      <Item>
        <img alt="" src={assignmentsAirdrops} />
        <Description>
          <h2>{t("airdrops.benefits.assignments")}</h2>
          <p>{t("airdrops.benefits.assignments.description")}</p>
        </Description>
      </Item>
      <Item>
        <img alt="" src={choiceAirdrops} />
        <Description>
          <h2>{t("airdrops.benefits.choice")}</h2>
          <p>{t("airdrops.benefits.choice.description")}</p>
        </Description>
      </Item>
      <Item>
        <img alt="" src={incomeAirdrops} />
        <Description>
          <h2>{t("airdrops.benefits.income")}</h2>
          <p>{t("airdrops.benefits.income.description")}</p>
        </Description>
      </Item>
    </List>
  );
};
