import { useCallback, useEffect, useState } from "react";

import { Spinner } from "components/Spinner";
import { List } from "./List";
import { Table } from "./Table";
import { Widget } from "./Widget";
import { Input } from "./Input";

import { Button } from "components/Button";

import styled from "styled-components";

import * as api from "api";

import { chevronDown, chevronUp, search, checked, filters, filtersApplied } from "assets";

import { STRAPI_CATEGORIES } from "const";

import { useIsMobile } from "hooks/useIsMobile";

import t from "i18n";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: -0.015em;
  color: var(--color--secondary);

  margin-top: -16px;

  @media (max-width: 1024px) {
    padding: 0 16px;
    text-align: left;
  }

  @media (min-width: 1024px) {
    width: 914px;
    margin: -16px auto 0;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  gap: 24px;
`;

const SearchInput = styled(Input)`
  background: url(${search}) no-repeat;
  background-position: calc(100% - 16px);
  padding-right: 36px;

  @media (max-width: 1024px) {
    width: 100%;
    margin-right: 12px;
  }
`;

const DropdownInput = styled(Input)`
  // When using both images with specifying position allow to get rid of flash 
  // while image is loading
  background: ${props => props.isOpen ? `url(${chevronUp}) calc(100% - 16px)` : `url(${chevronDown}) calc(100% - 16px) no-repeat, url(${chevronUp}) 250%`} no-repeat;
  padding-right: 36px;
  cursor: pointer;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  width: 221px;
`;

const Dropdown = styled.div`
  position: relative;
  top: 115px;
  background: var(--color--white);
  z-index: 2;
  box-shadow: 0px 1px 12px var(--color-monochrome--light-3);
  border-radius: 4px;
  width: 221px;
`;

const DropdownItems = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: -0.002em;
  color: var(--color-monochrome--light-5);
  text-transform: capitalize;

  padding: 8px 12px;

  cursor: pointer;

  &:hover {
    background-color: #f5f9fc;
  }
`;

const DropdownItemCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? "#1446f5" : "var(--color--white)")};
  border: 1px solid
    ${(props) =>
      props.checked ? "#1446f5" : "var(--color-monochrome--light-3)"};
  border-radius: 4px;
`;

const Filters = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1024px) {
    width: 1024px;
  }

  @media (max-width: 1024px) {
    padding: 0 16px;
    width: 100%;
  }
`;

const ApplyFiltersButton = styled(Button)`
  background-color: #1446f5;
  width: 100%;
  height: 50px;
  margin-top: 32px;
  color: white;

  &:hover {
    background: rgb(4, 17, 79);
    transition: all 0.3s ease 0s;
  }
`;

const CleanSelection = styled.p`
  margin: 16px 0 !important;

  text-align: center;
  font-weight: 400 !important;
  font-size: 0.875rem;
  line-height: 1.57;
  color: #1446f5 !important;
`;

const CategoryNumber = styled.div`
  font-size: 0.875rem;
  line-height: 1.57;
  color: var(--color-monochrome--light-5);

  margin-left: auto;
`;

/**
 * React@17 doesn't support startTransition(), so sorting direction and
 * attribute name are in one enum
 *
 * @enum
 */
const Sorting = {
  NameAsc: "title",
  NameDesc: "-title",
  PriceAsc: "price",
  PriceDesc: "-price",
  YearlyProfitAsc: "yearlyProfit",
  YearlyProfitDesc: "-yearlyProfit",
};

const DROPDOWN_ID = "dropdown";

const CATEGORIES = [
  "Staking",
  "Cold Staking",
  "Exchange",
  "Stablecoin",
  "Coming Soon",
];

export const Assets = () => {
  const isMobile = useIsMobile();

  const [query, setQuery] = useState("");

  const [assets, setAssets] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);

  const [internalWidgetState, setInternalWidgetState] = useState([]);

  const [initialWidgetState, setInitialWidgetState] = useState([]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  const [sorting, setSorting] = useState(null);

  const [loading, setLoading] = useState(true);

  const [categoryCoinsNumber, setCategoryCoinsNumber] = useState({});

  const onClickOut = useCallback(
    (e) => {
      if (isDropdownOpen && !e.target.closest(`#${DROPDOWN_ID}`)) {
        setIsDropdownOpen(false);
      }
    },
    [isDropdownOpen]
  );

  useEffect(() => {
    window.addEventListener("click", onClickOut);

    api.coins.find().then((assets) => {
      setAssets(assets);
      setCategoryCoinsNumber(
        assets.reduce((obj, coin) => {
          coin.categories.forEach((category) => {
            if (!(category in obj)) {
              obj[category] = 0;
            }
            ++obj[category];
          });
          return obj;
        }, {})
      );
      setLoading(false);
    });

    return () => {
      window.removeEventListener("click", onClickOut);
    };
  }, [onClickOut]);

  useEffect(() => {
    if (!sorting) {
      return null;
    }

    const direction = sorting.startsWith("-") ? -1 : 1;
    const attribute = sorting.replace("-", "");

    setAssets((assets) =>
      [...assets].sort((a, b) => {
        if (attribute === "title") {
          return direction * a.title.localeCompare(b.title);
        }
        return direction * (a[attribute] > b[attribute] ? -1 : 1);
      })
    );
  }, [sorting]);

  const assetsWithAppliedQuery = assets.filter((asset) => {
    return (
      `${asset.title}-${asset.slug}`.toLowerCase().includes(query.toLowerCase()) &&
      (selectedCategories.includes(STRAPI_CATEGORIES.ALL) || !selectedCategories.length || asset.categories.some((category) => selectedCategories.includes(category)))
    );
  });

  const onQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const onSortByPrice = () => {
    if (sorting === Sorting.PriceAsc) {
      setSorting(Sorting.PriceDesc);
    } else {
      setSorting(Sorting.PriceAsc);
    }
  };

  const onSortByYearlyProfit = () => {
    if (sorting === Sorting.YearlyProfitAsc) {
      setSorting(Sorting.YearlyProfitDesc);
    } else {
      setSorting(Sorting.YearlyProfitAsc);
    }
  };

  const onSortByName = () => {
    if (sorting === Sorting.NameAsc) {
      setSorting(Sorting.NameDesc);
    } else {
      setSorting(Sorting.NameAsc);
    }
  };

  const onClickCategory = (category) => {
    const state = isMobile ? internalWidgetState : selectedCategories;
    const setState = isMobile ? setInternalWidgetState : setSelectedCategories;

    if (state.includes(category)) {
      setState((categories) => {
        const copiedCategories = [...categories];
        copiedCategories.splice(copiedCategories.indexOf(category), 1);
        return copiedCategories;
      });
    } else {
      setState((categories) => [...categories, category]);
    }
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen);
  };

  const onOpenWidget = () => {
    setIsWidgetOpen(true);
  };

  const renderCategoriesList = () => {
    return CATEGORIES.map((category) => {
      const isChecked = (
        isMobile ? internalWidgetState : selectedCategories
      ).includes(category);

      return (
        <DropdownItem onClick={() => onClickCategory(category)} key={category}>
          <DropdownItemCheckbox checked={isChecked}>
            {isChecked && <img alt="" src={checked} />}
          </DropdownItemCheckbox>
          {category}
          <CategoryNumber>{categoryCoinsNumber[category]}</CategoryNumber>
        </DropdownItem>
      );
    });
  };

  const onClickWidget = (e) => {
    e.stopPropagation();
  };

  const onClickWidgetOverlay = () => {
    setIsWidgetOpen(false);
    setInternalWidgetState(initialWidgetState);
  };

  const cleanSelection = () => {
    setInternalWidgetState([]);
  };

  const onApplyWidget = () => {
    setInitialWidgetState(internalWidgetState);
    setSelectedCategories(internalWidgetState);
    setIsWidgetOpen(false);
  };

  const categoriesText = (
    isMobile ? internalWidgetState : selectedCategories
  ).join(", ");

  return (
    <>
      <Widget
        isOpen={isWidgetOpen}
        onClickOverlay={onClickWidgetOverlay}
        onClick={onClickWidget}
        title={t("assets.main.widget.title")}
      >
        {internalWidgetState.length > 0 && (
          <CleanSelection onClick={cleanSelection}>
            {t("assets.main.widget.clean", {
              countOfFilters: internalWidgetState.length,
            })}
          </CleanSelection>
        )}
        {renderCategoriesList()}
        <ApplyFiltersButton onClick={onApplyWidget}>
          {t("assets.main.applyFilters")}
        </ApplyFiltersButton>
      </Widget>
      <Container>
        <Description
          dangerouslySetInnerHTML={{
            __html: t("assets.main.description"),
          }}
        />
        {loading ? (
          <Spinner />
        ) : (
          <TableWrapper>
            <Filters>
              {!isMobile && (
                <>
                  <DropdownInput
                    onClick={toggleDropdown}
                    readOnly
                    value={categoriesText}
                    placeholder="Type"
                    isOpen={isDropdownOpen}
                  />
                  {isDropdownOpen && (
                    <DropdownWrapper>
                      <Dropdown>
                        <DropdownItems id={DROPDOWN_ID}>
                          {renderCategoriesList()}
                        </DropdownItems>
                      </Dropdown>
                    </DropdownWrapper>
                  )}
                </>
              )}
              <SearchInput
                placeholder="Search crypto asset"
                value={query}
                onChange={onQueryChange}
              />
              {isMobile && (
                <img
                  onClick={onOpenWidget}
                  alt=""
                  src={selectedCategories.length ? filtersApplied : filters}
                />
              )}
            </Filters>
            {isMobile ? (
              <List assets={assetsWithAppliedQuery} />
            ) : (
              <Table
                assets={assetsWithAppliedQuery}
                onSortByPrice={onSortByPrice}
                onSortByYearlyProfit={onSortByYearlyProfit}
                onSortByName={onSortByName}
              />
            )}
          </TableWrapper>
        )}
      </Container>
    </>
  );
};
