import PropTypes from "prop-types";

import styled from "styled-components";

import { copy } from "assets";

import { Button } from "components/Button";

import { COPIED_TOOLTIP_DURATION, URLS } from "const";

import t from "i18n";
import { useEffect, useRef, useState } from "react";
import { useForceUpdateOnResize } from "hooks/useOnResize";

const Coin = styled.div`
  background: var(--color--gray);
  border-radius: var(--rounding--medium);

  align-items: center;
  display: flex;
  gap: 80px;

  margin: 0 auto;

  padding: 48px;

  width: 1178px;

  @media (max-width: 1178px) {
    flex-direction: column;
    width: calc(100% - 32px);
    padding: 19px 26px;
    margin: 0 16px;
    gap: 0;
  }
`;

const CoinDetail = styled.div`
  display: flex;
  gap: 16px;

  img {
    width: 60px;
    height: 60px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  h1 {
    font-weight: 700;
    font-size: 2.125rem;
    line-height: 1.41;
    color: #253b6c;
  }

  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 132%;
    color: var(--color-monochrome--light-5);
  }

  @media (min-width: 1178px) {
    p {
      display: flex;
      align-items: center;

      padding: 8px 12px;
      box-sizing: border-box;

      min-width: 71px;
      justify-content: center;
      height: 48px;

      background: var(--color-monochrome--light-2);

      border-radius: var(--rounding--small);
    }
  }

  @media (max-width: 1178px) {
    padding-bottom: 16px;
    box-shadow: var(--shadow--cell-border-down);
    width: 100%;

    gap: 8px;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }

    h1 {
      font-size: 0.875rem;
      line-height: 1.57;
    }

    p {
      padding: 0;
      font-size: 0.75rem;
      line-height: 1.3;
      color: var(--color-monochrome--light-4);
    }

    div {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  }
`;

const CoinAttributes = styled.div`
  display: flex;
  gap: 80px;

  & + a {
    margin-left: auto;
  }

  @media (max-width: 1178px) {
    margin: 16px 0;
    gap: 36px;
    width: 100%;
  }
`;

const CoinAttribute = styled.div`
  display: flex;
  flex-direction: column;

  p:first-child {
    font-size: 1rem;
    line-height: 1.5;
    color: var(--color-monochrome--light-5);
    white-space: nowrap;
  }

  p:last-child {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.32;
    color: #253b6c;
    white-space: nowrap;
  }

  @media (max-width: 1178px) {
    p:first-child {
      font-size: 0.75rem;
      line-height: 1.3;
    }

    p:last-child {
      font-size: 0.875rem;
      line-height: 1.57;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: var(--color--primary);

  &:hover,
  &:active {
    background-color: var(--color--primary-dark);
  }

  width: 156px !important;
  flex-shrink: 0;
  margin-left: 16px;

  @media (max-width: 1178px) {
    width: max(295px, 100%) !important;
    margin-left: 0;
  }
`;

const ColdStaking = styled(Coin)`
  flex-direction: column;
  margin-top: 24px;
  padding: 48px 24px;
  gap: 0;
  align-items: flex-start !important;

  @media (max-width: 1178px) {
    padding: 16px 24px;
  }
`;

const ColdStakingDetail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  @media (min-width: 1178px) {
    width: 462px;
    gap: 62px;
    justify-content: normal;
    align-items: center;
  }

  section {
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.6;
    color: var(--color--black);
    margin-bottom: 4px;
  }

  p {
    font-size: 1rem;
    line-height: 1.12;
    color: var(--color--secondary);
  }

  @media (min-width: 1178px) {
    section {
      height: 53px;

      &:last-child {
        justify-content: space-between;
      }
    }
  }

  @media (max-width: 1178px) {
    padding-bottom: 16px;
    box-shadow: var(--shadow--cell-border-down);

    h1 {
      font-size: 0.875rem;
      line-height: 1.57;
      margin-bottom: 0;
    }

    p {
      font-size: 0.75rem;
      line-height: 1.3;
      color: var(--color-monochrome-light-4);
    }
  }
`;

const YearlyProfit = styled.p`
  font-weight: 500;
  font-size: 1.5rem !important;
  color: var(--color--primary) !important;

  @media (max-width: 1178px) {
    font-size: 0.875rem !important;
    line-height: 1.57 !important;
  }
`;

const EstimatedYearlyProfit = styled.p`
  line-height: 1.5 !important;
  color: var(--color-monochrome--light-5) !important;

  @media (max-width: 1204px) {
    line-height: 1.3 !important;
  }
`;

const Input = styled.input`
  width: 534px;
  height: 40px;
  border: solid 1px var(--color-monochrome--light-2);
  border-radius: var(--rounding--small);
  outline: none;

  padding: 16px 48px 16px 16px;

  background: url(${copy}) no-repeat, var(--color--white);
  background-position: calc(100% - 16px);

  cursor: pointer;

  @media (max-width: 1178px) {
    width: 100%;
    height: 40px;
    margin-top: 16px;
  }
`;

const Address = styled.p`
  margin: 24px 0 8px;

  font-size: 1rem;
  line-height: 1.12;
  color: var(--color--secondary);

  @media (max-width: 1178px) {
    display: none;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1178px) {
    flex-direction: column;
    gap: 32px;
  }
`;

const ColdStakingActions = styled.div`
  display: flex;
  gap: 12px;

  button {
    width: 156px !important;
  }

  @media (min-width: 1178px) {
    margin-right: 24px;
  }

  @media (max-width: 1178px) {
    a {
      width: 50% !important;
    }
  }
`;

const SecondaryButton = styled(StyledButton)`
  background-color: #ebf6ff;
  color: #1068d9;

  &:hover,
  &:active {
    border: solid 1px var(--color--primary);
    background-color: #ebf6ff;
  }
`;

const YearlyProfitAttribute = styled.p`
  color: #00b1a5 !important;
`;

const YearlyProfitInfo = styled.p`
  color: var(--color-monochrome--dark-1);
  margin-top: 14px;
  font-size: 0.75rem;
  line-height: 1.3;
  width: 1178px;
  margin: 14px auto 0;

  @media (max-width: 1178px) {
    display: none;
  }
`;

const ColdStakingDescription = styled.p`
  color: var(--color-monochrome--light-5) !important;

  @media (max-width: 1178px) {
    color: var(--color-monochrome--light-4) !important;
    max-width: 152px;
  }
`;

const CopiedTooltip = styled.div`
  padding: 6px 12px;
  background-color: var(--color--white);
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: var(--color-monochrome--dark-3);
  position: absolute;

  transform: translateX(calc(-100% + 8px));

  box-shadow: 0px 4px 22px rgba(208, 216, 223, 0.4);

  margin-top: -16px;

  @media (min-width: 1178px) {
    margin-top: -32px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-top: solid 10px var(--color--white);
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
`;

export const Info = (props) => {
  const { asset } = props;

  const [isCopiedTooltipVisible, setIsCopiedTooltipVisible] = useState(false);

  const [tooltipAnimation, setTooltipAnimation] = useState(null);

  const inputRef = useRef();

  const inputClientRect = inputRef?.current?.getBoundingClientRect();

  // Force updating on window resizing will update inputClientRect and move
  // copied tooltip if user resizes window while it is visible
  useForceUpdateOnResize();

  useEffect(() => {
    return () => {
      clearTimeout(tooltipAnimation);
    };
  }, [tooltipAnimation]);

  const onCopyColdStakingAddress = () => {
    setIsCopiedTooltipVisible(true);
    window.navigator.clipboard.writeText(asset.coldStaking.address);
    setTooltipAnimation(
      setTimeout(() => {
        setIsCopiedTooltipVisible(false);
      }, COPIED_TOOLTIP_DURATION)
    );
  };

  return (
    <>
      <Coin>
        <CoinDetail>
          <img alt="" src={asset?.imageUrl} />
          <div>
            <h1>{asset?.title}</h1>
            <p>{asset?.slug}</p>
          </div>
        </CoinDetail>
        <CoinAttributes>
          <CoinAttribute>
            <p>{t("asset.info.attribute.yearlyProfit")}</p>
            <YearlyProfitAttribute>
              {asset?.yearlyProfit ?? 0}%
            </YearlyProfitAttribute>
          </CoinAttribute>
          <CoinAttribute>
            <p>{t("asset.info.attribute.price")}</p>
            <p>€{(asset?.price ?? 0)?.toFixed(2)}</p>
          </CoinAttribute>
          <CoinAttribute>
            <p>{t("asset.info.attribute.capitalization")}</p>
            <p>
              € {asset?.currentValuation?.value}{" "}
              {asset?.currentValuation?.measure}
            </p>
          </CoinAttribute>
        </CoinAttributes>
        <StyledButton role="link" href={URLS.SIGNUP}>
          {t("asset.info.action")}
        </StyledButton>
      </Coin>
      {asset?.coldStaking?.show && (
        <ColdStaking>
          <ColdStakingDetail>
            <section>
              <h1>Cold staking</h1>
              <ColdStakingDescription>
                Earn rewards and keep coins in your wallet
              </ColdStakingDescription>
            </section>
            <section>
              <EstimatedYearlyProfit>Est. APY</EstimatedYearlyProfit>
              <YearlyProfit>{asset?.yearlyProfit ?? 0}%</YearlyProfit>
            </section>
          </ColdStakingDetail>
          <Address>Address</Address>
          <InputWrapper>
            <Input
              onClick={onCopyColdStakingAddress}
              readOnly
              value={asset?.coldStaking?.address}
              ref={inputRef}
            />
            {isCopiedTooltipVisible && inputClientRect && (
              <CopiedTooltip
                style={{
                  left: inputClientRect.right,
                  top: inputClientRect.top + window.scrollHeight,
                }}
              >
                Copied
              </CopiedTooltip>
            )}
            <ColdStakingActions>
              <SecondaryButton role="link" href={URLS.LANDINGS.COLD_STAKING}>
                Explorer
              </SecondaryButton>
              <StyledButton role="link" href={asset.coldStaking.url}>
                Delegate
              </StyledButton>
            </ColdStakingActions>
          </InputWrapper>
        </ColdStaking>
      )}
      <YearlyProfitInfo
        dangerouslySetInnerHTML={{
          __html: t("assets.whatIsAPY"),
        }}
      />
    </>
  );
};

Info.propTypes = {
  asset: PropTypes.object,
};
