export const items = [
  {
    title: '366',
    description: 'trading pairs currently available'
  },
  {
    title: '~13',
    description: 'external exchanges connected'
  },
  {
    title: '$200M',
    description: 'paid in bonuses to users'
  }
];
