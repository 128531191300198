import React from 'react';
import { benefits } from './benefits';

import './styles.scss';

export const ExchangeBenefits = () => {
  return (
    <div className="exchange-benefits-container">
      <div className="title">Benefits of exchanging with Mycointainer</div>
      <div className="benefits-list">
        {benefits.map((item) => (
          <div className="benefit-item">
            <item.pic />
            <div className="info">
              <div className="name">{item.name}</div>
              <div className="description">{item.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
