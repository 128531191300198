import { FC } from "react";
import styled from "styled-components";
import { FAQ } from "./FAQ";
import { Header } from "components/business/Header";
import { Services } from "./Services";
import { Solutions } from "./Solutions";
import { GetInTouch } from "components/business/GetInTouch";
import { NavigationTop } from "components/NavigationTop";
import { Partners } from "components/business/Partners";
import imageHeader from "./img/header-image.webp";
import { useMedia } from "hooks";
import t from "i18n";
import { Footer } from "components/business/Footer";

export const Business: FC = () => {
  const isTablet = useMedia("(max-width: 998px)");

  const isMobile = useMedia("(max-width: 576px)");

  const onClick = () => {
    document.querySelector("#solutions")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <NavigationTop />
      <Header
        title={t("business.title", {
          truncated: isTablet,
        })}
        description={t("business.description", {
          truncated: isTablet,
        })}
        action={t("business.solutions")}
        image={<img width="648" height="408" alt="" src={imageHeader} />}
        onClick={onClick}
      >
        <Info>
          <h3>
            {t("business.participate", {
              truncated: isMobile,
            })}
          </h3>
          <p>
            {t("business.participate.description", {
              truncated: isMobile,
            })}
          </p>
        </Info>
      </Header>
      <Solutions />
      <Services />
      <Partners />
      <FAQ />
      <GetInTouch />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const Info = styled.section`
  padding: 64px 0;
  margin: 72px 120px 0;
  background: rgba(89, 100, 116, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  text-align: center;

  p {
    padding: 0 64px;
  }

  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }

  p {
    font-size: 18px;
    line-height: 142%;
    color: ${(props) => props.theme.darkGray};
  }

  @media (max-width: 998px) {
    margin: 40px 0 0 -24px;
    width: calc(100% + 48px);
    text-align: left;
    align-items: flex-start;

    h3,
    p {
      padding: 0 24px;
    }
  }
`;
