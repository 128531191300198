import { Section } from "components/business/Section";
import { FC } from "react";
import styled from "styled-components";
import nodes from "./img/nodes.webp";
import checked from "./img/checked.svg";
import wallet from "./img/wallet.webp";
import { useMedia } from "hooks";
import t from "i18n";
import { Button } from "components/business/Button";
import { uris } from "const";

export const Solutions: FC = () => {
  const isMobile = useMedia("(max-width: 768px)");

  return (
    <Section
      id="solutions"
      title={t("business.solutions.title", {
        truncated: isMobile,
      })}
      description={
        !isMobile
          ? "Easy to use blockchain solutions for your business."
          : void 0
      }
    >
      <Container>
        <List>
          <ListTitle>Node Deployments</ListTitle>
          <ListItem>
            Whether you wish to take advantange of staking, blockchain APIs or
            host your own node, this solution is for you.
          </ListItem>
          <ListItem>
            An accessible panel that’s easy to use for both business and
            technical people.
          </ListItem>
          <ListItem>No more expensive set ups and operational costs.</ListItem>
          <ListItem>Comprehensive information about your node.</ListItem>
          <ListItem>24/7 live node monitoring.</ListItem>
          <Button href={uris.business.register} variant="text">
            Start using now
          </Button>
        </List>
        <img width="549" height="536" alt="" src={nodes} />
      </Container>
      <Container reversed>
        <ImageFrame>
          <img width="588" height="405" alt="" src={wallet} />
        </ImageFrame>
        <List>
          <ListTitle>Crypto Wallets for all kinds of platforms</ListTitle>
          <ListItem>
            Provide your customers with over 100 crypto coins through a simple
            API. They can use them in your ecosystem as their Wallets for easy
            deposits.
          </ListItem>
          <ListItem>
            Let your customers pay for any service. Thanks to stable coins, you
            and your customers don’t have to worry about price fluctuations.
          </ListItem>
          <ListItem>
            With the power of our blockchain solutions, such as staking and
            other protocols, you can generate interests on your customers’
            balances – 5% APY average on popular assets.
          </ListItem>
          <Button href={uris.business.register} variant="text">
            Get free access
          </Button>
        </List>
      </Container>
    </Section>
  );
};

const ImageFrame = styled.div`
  margin-right: 44px;
  min-width: 345px;

  img {
    position: relative;
  }

  @media (min-width: 998px) and (max-width: 1200px) {
    img {
      left: -120px;
    }
  }

  @media (min-width: 768px) and (max-width: 998px) {
    img {
      left: -200px;
    }
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 998px) {
    gap: 24px;
  }
`;

const ListTitle = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  max-width: 524px;

  @media (max-width: 998px) {
    width: 100%;
    max-width: 100%;
  }
`;

const ListItem = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: ${(props) => props.theme.darkGray};
  max-width: 452px;

  display: flex;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: 998px) {
    width: 100%;
    max-width: 100%;
  }

  &:before {
    content: url(${checked});
    height: 14px;
    padding-top: 3px;
  }
`;

interface ContainerProps {
  reversed?: boolean;
}

const Container = styled.section<ContainerProps>`
  margin-top: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: ${(props) =>
      props.reversed ? "column" : "column-reverse"};

    margin-top: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
`;
