import PropTypes from "prop-types";

import styled from "styled-components";

import { plus, minus, whitePlus, whiteMinus } from "../assets";

const ANIMATION_DURATION = 150;

export const Questions = (props) => {
  const onClick = (event) => {
    const questionNode = event.target.closest("article");
    questionNode.classList.toggle("opened");
    setTimeout(() => {
      questionNode.querySelector("button").classList.toggle("opened");
    }, ANIMATION_DURATION);
    const answerNode = questionNode.querySelector("div");
    if (answerNode.style.maxHeight) {
      answerNode.style.maxHeight = null;
    } else {
      answerNode.style.maxHeight = `${answerNode.scrollHeight}px`;
    }
  };

  return (
    <Container purple={props.purple} dark={props.dark}>
      {props.questions.map((q) => (
        <Question purple={props.purple} dark={props.dark} key={q.title}>
          <Accordion purple={props.purple} dark={props.dark} onClick={onClick}>
            {q.title}
          </Accordion>
          <Answer purple={props.purple} dark={props.dark}>
            {typeof q.description === "string" ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: q.description,
                }}
              />
            ) : (
              <p>{q.description}</p>
            )}
          </Answer>
        </Question>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  width: 1024px;

  margin: 0 auto;

  gap: 12px;

  @media (max-width: 1024px) {
    ${(props) =>
      props.dark
        ? `
          padding: 0;
          margin-left: -24px;
          width: calc(100% + 48px);
        `
        : `
          width: 100%;
          padding: 0 16px;
        `}
  }
`;

const Accordion = styled.button`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  background-color: ${(props) =>
    props.purple
      ? props.theme.purple
      : props.dark
      ? props.theme.black
      : "var(--color--gray)"};

  border-radius: var(--rounding--large);

  &.opened {
    border-radius: var(--rounding--large) var(--rounding--large) 0 0;
  }

  border: none;
  outline: none;
  transition: 0.4s;

  font-weight: 500;
  font-size: 1.125rem;
  line-height: 152%;
  color: ${(props) =>
    props.purple || props.dark ? props.theme.white : "var(--color--black)"};
  text-align: left;

  padding: 22px 24px;

  &:after {
    display: block;
    float: right;
    cursor: pointer;

    font-size: 1.875rem;
    color: ${(props) =>
      props.dark || props.purple ? props.theme.white : "var(--color--black)"};
    font-weight: 700;

    transition: all 150ms ease-in-out;
  }

  @media (max-width: 1024px) {
    height: initial;
  }
`;

const Answer = styled.div`
  font-size: 1rem;
  line-height: 1.5;

  border-radius: 0 0 var(--rounding--large) var(--rounding--large);

  color: ${(props) =>
    props.purple
      ? "#A8ABD1"
      : props.dark
      ? props.theme.darkGray
      : "var(--color--secondary)"};

  background-color: ${(props) =>
    props.purple
      ? props.theme.purple
      : props.dark
      ? props.theme.black
      : "var(--color--gray)"};

  max-height: 0;
  overflow: hidden;

  transition: max-height 0.3s ease-out;

  box-sizing: border-box;

  ul,
  ol {
    padding-left: 25px;
    list-style: decimal;
  }

  div[role="list"] {
    padding: 8px 0;
    margin-left: -8px;
  }

  a {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625;
    color: ${(props) => (props.dark || props.purple ? "white" : "#1446f5")};
    transition: all 150ms ease;
    text-decoration: none;

    &:hover {
      color: ${(props) => (props.dark || props.purple ? "white" : "#04114f")}
      transition: all 150ms ease;
      text-decoration: underline;
    }
  }

  p {
    max-width: 85%;

    &:last-child {
      padding: 0px 24px 24px;
    }
  }

  @media (max-width: 1024px) {
    font-size: 0.875rem;
    line-height: 1.57;

    p {
      max-width: 100%;
    }
  }
`;

const Question = styled.article`
  border-radius: ${(props) =>
    props.dark || props.purple ? 0 : "var(--rounding--large)"};

  border-bottom: ${(props) =>
    props.purple
      ? "1px solid #413478"
      : props.dark
      ? "1px solid #434343"
      : null};

  button:after {
    content: "";
    background: url(${(props) =>
        props.dark || props.purple ? whitePlus : plus})
      no-repeat;
    width: 14px;
    height: 15px;
    flex-shrink: 0;
    margin-left: 8px;
  }

  @media (max-width: 998px) {
    &:first-child {
      border-top: ${(props) =>
        props.purple
          ? "1px solid #413478"
          : props.dark
          ? "1px solid #434343"
          : null};
      margin-top: ${(props) => (props.dark || props.purple ? "16px" : null)};
    }
  }

  &.opened {
    background-color: ${(props) =>
      props.purple
        ? props.theme.purple
        : props.dark
        ? props.theme.black
        : "var(--color--gray)"};

    button {
      &:after {
        background: url(${(props) =>
            props.purple || props.dark ? whiteMinus : minus})
          no-repeat;
        height: 2px;
      }
    }
  }
`;

Questions.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  dark: PropTypes.bool,
  purple: PropTypes.bool,
};
