import styled from "styled-components";

import { download, shop, earnCrypto, howItWorkArrows } from "assets";

import t from "i18n";

const Section = styled.div`
  background: linear-gradient(
      20.04deg,
      rgba(0, 177, 165, 0.11) -2.86%,
      rgba(224, 244, 243, 0) 54.16%
    ),
    var(--color--gray);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  width: min(100%, 1178px);
  height: 616px;

  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 0 16px;
  }

  @media (max-width: 998px) {
    background: none;
    height: fit-content;
    gap: 8px;
  }

  @media (min-width: 1178px) {
    border-radius: 16px;
    background-image: url(${howItWorkArrows});
    background-position: 0 64px;
    background-repeat: no-repeat;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 1.41;
  text-align: center;
  letter-spacing: -0.015em;
  color: var(--color--black);

  @media (min-width: 1024px) {
    margin-top: 80px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  letter-spacing: -0.015em;
  color: var(--color--secondary);

  @media (min-width: 1178px) {
    width: 833px;
  }
`;

const List = styled.ul`
  display: flex;
  gap: 64px;

  list-style-type: none;
  padding: 0;
  margin: 24px 0 0;

  @media (max-width: 998px) {
    width: 100%;
    flex-direction: column;
    gap: 8px;
  }
`;

const Item = styled.li`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (max-width: 998px) {
    width: 100%;
    gap: 17px;
    border: 1px solid var(--color-monochrome--light-2);
    box-shadow: 0px 4px 22px rgba(208, 216, 223, 0.2);
    border-radius: 6px;
    padding: 29px 0 24px 0;
  }
`;

const Img = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 100%;
  background-color: white;
  padding: 34px 27px 35px 28px;

  @media (max-width: 998px) {
    padding: 0;
    width: 78px;
    height: 68px;
  }
`;

const ItemTitle = styled.p`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  letter-spacing: -0.002em;
  color: var(--color--black);
`;

const ItemDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: var(--color--secondary);
  max-width: 236px;
  margin-top: -20px;

  @media (max-width: 998px) {
    margin-top: -13px;
  }
`;

const StepOuter = styled.div`
  position: absolute;

  @media (max-width: 998px) {
    display: none;
  }
`;

const Step = styled.p`
  width: 36px;
  height: 36px;

  position: relative;
  left: -48px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;

  color: var(--color--white);

  background-color: var(--color--teal);
`;

export const HowItWork = () => {
  return (
    <Section>
      <Title>{t("cashback.howItWork.title")}</Title>
      <Description>{t("cashback.howItWork.description")}</Description>
      <List>
        <Item>
          <Img alt="" src={download} />
          <StepOuter>
            <Step>1</Step>
          </StepOuter>
          <ItemTitle>{t("cashback.howItWork.download")}</ItemTitle>
          <ItemDescription>
            {t("cashback.howItWork.download.description")}
          </ItemDescription>
        </Item>
        <Item>
          <Img alt="" src={shop} />
          <StepOuter>
            <Step>2</Step>
          </StepOuter>
          <ItemTitle>{t("cashback.howItWork.shop")}</ItemTitle>
          <ItemDescription>
            {t("cashback.howItWork.shop.description")}
          </ItemDescription>
        </Item>
        <Item>
          <Img alt="" src={earnCrypto} />
          <StepOuter>
            <Step>3</Step>
          </StepOuter>
          <ItemTitle>{t("cashback.howItWork.earn")}</ItemTitle>
          <ItemDescription>
            {t("cashback.howItWork.earn.description")}
          </ItemDescription>
        </Item>
      </List>
    </Section>
  );
};
