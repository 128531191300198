import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  POLICY_COOKIE_KEY,
  POLICY_COOKIE_MAX_AGE,
  POLICY_COOKIE_VALUE,
} from "const";
import { getCookie, setCookie } from "../../cookies";
import { Cookies } from "../Cookies";
import { Footer } from "./components/Footer";
import { NavigationTop } from "./components/NavigationTop";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const StandardLayout = ({ children }) => {
  const [isCookiesEnabled, setIsCookiesEnabled] = useState(
    getCookie(POLICY_COOKIE_KEY) === POLICY_COOKIE_VALUE
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const onAcceptCookies = () => {
    setIsCookiesEnabled(true);
    setCookie(POLICY_COOKIE_KEY, POLICY_COOKIE_VALUE, POLICY_COOKIE_MAX_AGE);
  };

  return (
    <Container>
      {!isCookiesEnabled && <Cookies onAcceptCookies={onAcceptCookies} />}
      <NavigationTop />
      {children}
      <Footer />
    </Container>
  );
};
