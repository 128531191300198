import { useState, useRef, useEffect } from "react";

import { QrPopup } from "./QrPopup";

import styled from "styled-components";

import {
  footerImage,
  textureFooter,
  appStore,
  playMarket,
  qrCode,
} from "../assets";

import { URLS } from "const";

import t from "i18n";

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 128px;

  padding: 0 32px;

  background: linear-gradient(
    244.06deg,
    #c4d6f4 0.98%,
    #e4eefc 37.82%,
    #e7f0fd 61.8%,
    #d7e3f6 100%
  );

  margin-top: 140px;

  @media (max-width: 1024px) {
    margin-top: 80px;
    flex-direction: column;
    gap: 32px;
    overflow: hidden;
  }
`;

const Texture = styled.img`
  position: absolute;
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 2;

  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }

  @media (min-width: 1024px) {
    width: 635px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 34px;
  line-height: 48px;
  line-height: 1.41;
  text-align: left;
  letter-spacing: -0.015em;
  color: var(--color--black);

  z-index: 2;

  span {
    color: var(--color--primary);
  }

  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    margin-top: 60px;
  }
`;

const Description = styled.div`
  font-size: 20px;
  line-height: 1.4;
  text-align: left;

  z-index: 2;

  margin-top: 40px;

  letter-spacing: -0.015em;
  color: var(--color--black);

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    margin-top: 24px;
  }
`;

const Panel = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 12px;

  z-index: 2;

  img,
  a {
    cursor: pointer;
  }

  a {
    height: 51px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    & > img {
      display: none;
    }
  }
`;

const Image = styled.img`
  z-index: 2;

  margin-top: -88px;

  @media (min-width: 1024px) {
    height: 681px;
  }

  @media (max-width: 1024px) {
    width: 159px;
    margin-top: 4px;
  }

  @media (max-width: 386px) {
    margin-bottom: -110px;
  }
`;

export const Footer = () => {
  const [isQrPopupOpen, setIsQrPopupOpen] = useState(false);

  const ref = useRef(null);

  const sectionRef = useRef(null);

  const onMouseOnQrCode = () => {
    setIsQrPopupOpen(true);
  };

  const onMouseOutQrCode = () => {
    setIsQrPopupOpen(false);
  };

  const [height, setHeight] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (sectionRef.current) {
        setHeight(sectionRef.current.getBoundingClientRect().height);
      }
    };

    handleResize();

    const resizeObserver = new ResizeObserver(handleResize);

    if (sectionRef.current) {
      resizeObserver.observe(sectionRef.current.parentElement);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [sectionRef]);

  return (
    <>
      <Section ref={sectionRef}>
        {height && (
          <Texture
            style={{
              height,
              width: "100%",
            }}
            alt=""
            src={textureFooter}
          />
        )}
        <Detail>
          <Title
            dangerouslySetInnerHTML={{
              __html: t("footer.title"),
            }}
          />
          <Description>{t("footer.install")}</Description>
          <Panel>
            <a href={URLS.APP_STORE} target="_blank">
              <img alt="" src={appStore} />
            </a>
            <a href={URLS.GOOGLE_STORE} target="_blank">
              <img alt="" src={playMarket} />
            </a>
            <img
              ref={ref}
              onMouseEnter={onMouseOnQrCode}
              onMouseLeave={onMouseOutQrCode}
              alt=""
              src={qrCode}
            />
          </Panel>
        </Detail>
        <Image loading="lazy" alt="" src={footerImage} />
      </Section>
      {isQrPopupOpen && ref.current && (
        <QrPopup refBoundingRect={ref.current.getBoundingClientRect()} />
      )}
    </>
  );
};
