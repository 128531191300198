import { FC, useEffect } from "react";
import { URLS } from "const";

const ContactRedirect: FC = () => {
  useEffect(() => {
    if (localStorage.token) {
      return location.replace(URLS.DASHBOARD);
    } 
    window.location.replace(location.origin);
  }, []);

  return null;
};

export default ContactRedirect;
