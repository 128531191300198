import { FC, ReactNode, useEffect, useRef, useState, useCallback } from "react";

import styled from "styled-components";

import { Button } from "../Button";
import { Title, Description } from "../Section";

import img from "./img";

import { surface } from "../../assets";

import { URLS } from "const";

import t from "i18n";

import useMedia from "hooks/useMedia";
import { useTimer } from "./useTimer";

const Image = styled.img`
  width: 347px;

  @media (max-width: 1024px) {
    width: 250px;
  }
`;

const ImageAlignment = styled.div`
  overflow: hidden;
  margin: 0 auto;
`;

const Fading = styled.div`
  position: relative;
  width: 250px;
  height: 4rem;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgb(241 246 253) 100%
  );
  margin-top: -180px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin-top: 16px;
  }
`;

const ListItem = styled.div`
  display: flex;
  gap: 27px;
  align-items: center;

  margin: 0 auto;

  width: 460px;

  cursor: pointer;

  @media (max-width: 476px) {
    width: 100%;
    padding: 0 16px;
  }

  @media (max-width: 1024px) {
    text-align: left;
    gap: 16px;

    &:not(.active) div[role="term"] {
      font-size: 3.33rem;
    }
  }

  &.active {
    div[role="term"] {
      color: var(--color--black);
    }
    div[role="heading"] {
      color: var(--color--secondary);
    }
  }
`;

const Number = styled.div`
  font-size: 5rem;
  color: var(--color--cyanblue);

  &:before {
    content: "0";
  }
`;

const Heading = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  color: var(--color--cyanblue);
`;

const Container = styled.div`
  display: flex;
  justify-content: center;

  gap: min(80px, 13%);
  height: 699px;

  background: url(${surface}) no-repeat, var(--color--gray);
  background-size: 100% 100%;

  padding-top: 80px;

  background-position-y: 64px;

  img:not(:first-child) {
    z-index: 2;
  }

  @media (min-width: 1200px) {
    gap: 240px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: normal;
    height: fit-content;
    padding: 32px 0;
    background-position-y: 10em;
    background-size: 100%;

    img {
      margin: 0 auto;
      width: 250px;
    }
  }

  @media (max-width: 568px) {
    background-position-y: 11em;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 2;

  @media (min-width: 1024px) {
    align-items: flex-start;
  }

  @media (max-width: 1024px) {
    img {
      margin-top: 32px;
    }
  }
`;

const SizedButton = styled(Button)`
  width: 405px;
  height: 54px;
  margin-top: 24px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const DefaultGuideTitle = styled.div`
  padding: 0 16px;
  font-size: 26px;
  line-height: 32px;
  margin: 0 auto;
  width: 299px;

  @media (min-width: 1024px) {
    width: 565px;
    font-size: 34px;
    line-height: 48px;
    padding: 0;
    margin: 0;
    text-align: left;
  }
`;

const animationDuration = 2_000;

const guide = [
  {
    title: t("guide.register"),
    image: img.register,
  },
  {
    title: t("guide.deposit"),
    image: img.stake,
  },
  {
    title: t("guide.earn"),
    image: img.acquire,
  },
];

interface Props {
  title: ReactNode;
  action: string;
}

export const Guide: FC<Props> = (props) => {
  const { title, action } = props;

  const [animated, setAnimated] = useState(0);

  const isMobile = useMedia("(max-width: 1024px)");

  const moveForward = useCallback(() => {
    setAnimated((i) => (i + 1) % guide.length);
  }, []);

  const timer = useTimer(moveForward, animationDuration);

  const onMouseOn = (next: number) => {
    timer.clear();
    setAnimated(next);
  };

  const renderListItem = (i: number) => (
    <ListItem
      className={i === animated ? "active" : void 0}
      onMouseEnter={() => onMouseOn(i)}
      onMouseLeave={() => timer.begin()}
    >
      <Number role="term">{i + 1}</Number>
      <Heading role="heading">{guide[i].title}</Heading>
    </ListItem>
  );

  return (
    <Container>
      {!isMobile && <img alt="" width="348" src={guide[animated].image} />}
      <Main>
        <Title>
          {title === t("guide.title") ? (
            <DefaultGuideTitle>{title}</DefaultGuideTitle>
          ) : (
            title
          )}
        </Title>
        <Description>{t("guide.description")}</Description>
        {isMobile && (
          <ImageAlignment>
            <Image alt={guide[animated].title} src={guide[animated].image} />
            <Fading />
          </ImageAlignment>
        )}
        <List>
          {!isMobile ? (
            guide.map((_, i) => renderListItem(i))
          ) : (
            <>
              {renderListItem(animated)}
              {renderListItem((animated + 1) % guide.length)}
            </>
          )}
        </List>
        <SizedButton role="link" color="primary" href={URLS.SIGNUP}>
          {action}
        </SizedButton>
      </Main>
    </Container>
  );
};
