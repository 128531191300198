import { Header } from "components/business/Header";
import { NavigationTop } from "components/NavigationTop";
import { FC } from "react";
import { Benefits } from "./Benefits";
import styled from "styled-components";
import imageHeader from "./img/header-image.webp";
import { GetInTouch } from "components/business/GetInTouch";
import { HowToStart } from "./HowToStart";
import { FAQ } from "./FAQ";
import { Assets } from "./Assets";
import { Footer } from "components/business/Footer";
import { uris } from "const";

export const StakingNodes: FC = () => {
  const onClick = () => {
    document.querySelector("#benefits")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <NavigationTop />
      <Header
        title="Run Staking Nodes & Earn"
        description="Let your customers generate passive income by enabling earning mechanisms. Start with just a few simple clicks on the MyCointainer platform."
        action="Explore possibilities"
        onClick={onClick}
        image={<img alt="" src={imageHeader} width={783} height={485} />}
      />
      <Benefits />
      <Assets />
      <FAQ />
      <HowToStart />
      <GetInTouch
        title="Earn more thanks to crypto"
        description="Grow with MyCointainer"
        action="Ask a question"
        actionHref={uris.business.askQuestion}
        primaryAction="Get started now"
      />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.black};
`;
