import { FC } from "react";
import styled from "styled-components";
import hero from "./img/hero.png";
import { History } from "./History";
import { Team } from "./Team";
import { Benefits } from "./Benefits";
import { Section } from "./Section";
import { StandardLayout } from "components/StandardLayout";

export const Company: FC = () => {
  return (
    <StandardLayout>
      <Container>
        <Header>
          <Section
            title="Live a Rewarding Life"
            description="Our goal is to enable easy access to various rewarding crypto mechanisms for all. We want everyone to equally<br /> benefit from a changing financial landscape and speed up the global advent of crypto adoption."
          >
            <div role="img">
              <img alt="" width="614" src={hero} />
            </div>
          </Section>
        </Header>
        <Section
          title="About MyCointainer"
          description="We are a self-funded startup that has already grown into a profitable and scalable business in just over 2 years.<br /> Get familiar with our history and highlights as well as upcoming plans."
        >
          <History />
        </Section>
        <Section
          title="Leadership"
          description="We are a team of 25. With our hands-on experience, we aim to continue building an excellent product for both individuals and businesses."
        >
          <Team />
        </Section>
        <ColoredWrapper>
          <Section
            title="Engage with us"
            description="From developers and investors to new employees and businesses, we keep our doors open for anyone who wants to enhance the crypto industry with us."
          >
            <Benefits />
          </Section>
        </ColoredWrapper>
      </Container>
    </StandardLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColoredWrapper = styled.div`
  width: 100%;

  @media (min-width: 998px) {
    background-color: #f8fcfe;
  }
`;

const Header = styled.div`
  margin-top: 160px;

  section {
    padding-top: 32px !important;
  }

  img {
    margin-top: 64px;
    max-width: 100%;
    position: relative;
  }

  @media (min-width: 998px) {
    h1 {
      font-size: 76px;
      line-height: 93px;
    }
  }

  @media (max-width: 786px) {
    margin-top: 80px;

    section {
      padding-top: 16px !important;
    }
  }

  @media (max-width: 576px) {
    margin-top: 40px;

    img {
      min-width: 614px;
      left: -40%;
    }
  }
`;
