import PropTypes from "prop-types";
import styled from "styled-components";
import t from "i18n";

import { actions, calendar, share, winnersAirdrop } from "assets";
import { useIsMobile } from "hooks/useIsMobile";
import { Button } from "components/Button";

export const Container = styled.div`
  display: flex;
  padding: 24px 0px 24px 24px;
  border: 1px solid var(--color-monochrome--light-2);
  border-radius: 16px;

  gap: 24px;

  width: min(100%, 1200px);

  @media (max-width: 1024px) {
    padding: 24px 18px;
    box-shadow: 0px 4px 22px rgba(208, 216, 223, 0.2);
    border-radius: 6px;
  }
`;

const ImageWrapper = styled.div`
  width: 264px;
  height: 264px;

  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 264px;
    border-radius: 16px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.6;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: var(--color--black);

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 1024px) {
    gap: 4px;
    width: 100%;
  }
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.002em;
  color: var(--color--secondary);

  width: min(100%, 772px);

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Profit = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    img {
      display: none;
    }
  }
`;

const ProfitText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 2.57;
  letter-spacing: -0.002em;
  color: var(--color--black);

  span {
    font-weight: 400;
    line-height: 22px;
    color: var(--color--secondary) !important;
  }

  @media (max-width: 1024px) {
    line-height: 1.57;
    color: var(--color--teal);
  }
`;

const DetailList = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1024px) {
    box-shadow: var(--shadow--cell-border-down);
    padding-bottom: 8px;
    margin-bottom: 12px;
  }
`;

const DetailListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  box-shadow: inset 1px 0px 0px var(--color-monochrome--light-2);
  padding-left: 16px;
  height: 36px;

  p {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: -0.002em;
    color: var(--color--secondary);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  background-color: var(--color--primary);

  @media (max-width: 1024px) {
    border: 1px solid var(--color-monochrome--light-3);
    background: var(--color--white);
    border-radius: 3px;
    color: var(--color-monochrome--light-5);
    // 70px is a 140/2, where 140 is width of <StyledButton>, 36px is 72/2,
    // where 72 is a width added by inserting a <Logo /> (it is not a part of
    // flexbox)
    margin: 24px 0 0 calc(50% - 36px - 70px);

    &:hover {
      background-color: var(--color--white);
    }
  }
`;

const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  // Price for inserting <Logo /> (which is not a part of flexbox)
  width: calc(100% + 72px);
  margin-left: -72px;

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.002em;
    color: var(--color--secondary);

    span:first-child {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    span:last-child {
      font-weight: 700;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const Participants = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 16px;
    height: 16px;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  height: 60px;

  margin: -2px -24px 12px 0;
  padding: 0 12px 8px 0;

  box-shadow: var(--shadow--cell-border-down);
`;

const Footer = styled.div`
  display: flex;
  margin-top: auto;

  p {
    margin-left: 28px;

    display: flex;
    align-items: center;
    gap: 8px;

    font-weight: 500;
    font-size: 15px;
    line-height: 1.3;
    color: var(--color--secondary);
  }

  img {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    p {
      display: none;
    }
  }
`;

const format = (dateISO) => {
  return new Date(dateISO).toLocaleString("en", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const Airdrop = (props) => {
  const {
    name,
    description,
    reward,
    rewardCurrency,
    logo,
    creator,
    startDate,
    endDate,
    timeToEndSurvey,
    link,
    participants,
    image,
  } = props;

  const isMobile = useIsMobile();

  const period = [startDate, endDate].map(format).join(" - ");

  const participantsNode = (
    <Participants>
      {participants
        .filter((p) => p in actions)
        .map((p) => (
          <img alt="" src={actions[p]} />
        ))}
    </Participants>
  );

  return (
    <Container>
      <ImageWrapper>
        <img alt="" src={image} />
      </ImageWrapper>
      {isMobile && (
        <Logo>
          <img width="60" height="60" alt={creator} src={logo} />
        </Logo>
      )}
      <Detail>
        <Title>{name}</Title>
        <DetailList>
          <Profit>
            <img alt={creator} src={logo} />
            <ProfitText>
              {reward} {rewardCurrency}{" "}
              {isMobile && <span>{t("airdrops.profit.suffix")}</span>}
            </ProfitText>
          </Profit>
          <DetailListItem>
            <img alt="" src={winnersAirdrop} />
            <p>1000 {t("airdrops.winners")}</p>
          </DetailListItem>
          <DetailListItem>
            <img alt="" src={calendar} />
            <p>
              {t("airdrops.period", {
                period,
                timeToEndSurvey,
              })}
            </p>
          </DetailListItem>
        </DetailList>
        <Description>{description}</Description>
        <DetailColumn>
          <p>
            <span>
              <img alt="" src={winnersAirdrop} />
              {t("airdrops.numberOfWinners")}
            </span>
            <span>1000</span>
          </p>
          <p>
            <span>
              <img alt="" src={calendar} />
              {t("airdrops.airdropTime")}
            </span>
            <span>{period}</span>
          </p>
          <p>
            <span>
              <img alt="" src={share} />
              {t("airdrops.requiredToParticipate")}
            </span>
            {participantsNode}
          </p>
        </DetailColumn>
        <Footer>
          <StyledButton role="link" href={link}>
            {t(`airdrops.join${isMobile ? "" : ".large"}`)}
          </StyledButton>
          <p>
            <span>{t("airdrops.requiredToParticipate")}</span>
            {participantsNode}
          </p>
        </Footer>
      </Detail>
    </Container>
  );
};

Airdrop.propTypes = {
  creator: PropTypes.string,
  description: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  participantDescription: PropTypes.string,
  participants: PropTypes.any,
  reward: PropTypes.number,
  rewardCurrency: PropTypes.string,
  timeToEndSurvey: PropTypes.number,
};
