import { ReactComponent as lowestFeesPic } from './img/lowestFees.svg';
import { ReactComponent as assetPairsPic } from './img/assetPairs.svg';
import { ReactComponent as bonusPic } from './img/bonus.svg';

export const benefits = [
  {
    name: 'Lowest Fees',
    pic: lowestFeesPic,
    description:
      'Withdrawal and deposit operations are with the minimum amount of fees'
  },
  {
    name: 'Access to unique asset pairs',
    pic: assetPairsPic,
    description:
      'MyCointainer is a leader in featured assets with over 150 cryptocurrencies available on the platform'
  },
  {
    name: 'Bonus',
    pic: bonusPic,
    description: 'Extra coins are given for every trade. Swap and earn'
  }
];
