import { useEffect, useState } from "react";

export const useAsync = <T>(asyncFn: () => Promise<T>, initialValue: T) => {
  const [result, setResult] = useState<T>(initialValue);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    asyncFn()
      .then((result) => {
        setResult(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    result,
    loading,
  };
};
