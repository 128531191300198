import React from "react";
import { Helmet } from 'react-helmet'
import { Faq } from "./components/Faq";
import { Info } from "./components/Info";
import { TopInfo } from "./components/TopInfo";
import { HowToEarn } from "./components/HowToEarn";
import { ExchangeBenefits } from "./components/ExchangeBenefits";
import { ExchangeInSeconds } from "./components/ExchangeInSeconds";
import { StandardLayout } from "components/StandardLayout";

import t from "i18n";

import { Footer } from "components/Footer";

const Exchange = () => {
  return (
    <StandardLayout>
      <Helmet>
        <title>
          {t("meta.title.exchange")}
        </title>
        <meta name="description" content={t("meta.description.exchange")} />
      </Helmet>
      <TopInfo />
      <ExchangeBenefits />
      <ExchangeInSeconds />
      <Info />
      <HowToEarn />
      <Faq />
      <Footer />
    </StandardLayout>
  );
};

export default Exchange;
