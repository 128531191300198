import { ChangeEventHandler, useState } from "react";

import styled from "styled-components";

import { Button } from "components/Button";
import { SubscribedPopup } from "./SubscribedPopup";
import { Input } from "components/Input";

import { textureMissingAirdrops, mail } from "assets";

import * as api from "api";

import { SUBSCRIBED_POPUP_ANIMATION_DURATION, EMAIL_REGEX } from "const";

import t from "i18n";

import { useIsMobile } from "hooks/useIsMobile";

const Container = styled.div`
  background: linear-gradient(
      123.81deg,
      rgba(232, 245, 255, 0) 25.92%,
      rgba(0, 142, 255, 0.2) 86.44%
    ),
    var(--color--gray);

  width: 100%;
  height: 406px;

  @media (max-width: 1024px) {
    background: none;
    height: fit-content;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 34px;
  line-height: 1.41;
  text-align: center;
  letter-spacing: -0.015em;
  color: var(--color--black);

  margin-top: 128px;

  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 1.23;
    text-align: left;
    padding: 0 16px;
    margin-top: 0;
  }
`;

const Description = styled.div`
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  letter-spacing: -0.015em;
  color: var(--color--secondary);
  margin-top: 8px;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 4px;
    text-align: left;
    padding: 0 16px;
  }
`;

const Texture = styled.div`
  position: absolute;
  width: 100%;
  height: 406px;
  left: -25%;

  background-image: url(${textureMissingAirdrops});

  @media (max-width: 1024px) {
    display: none;
  }
`;

const MailContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 32px;

  @media (max-width: 1024px) {
    gap: 12px;
    margin-top: 24px;
    padding: 0 16px;
  }
`;

const StyledButton = styled(Button)`
  width: 169px;
  flex-shrink: 1;
  z-index: 2;
  cursor: pointer;
  background-color: var(--color--primary);

  &:hover,
  &:active {
    background-color: #006abf;
  }

  @media (max-width: 1024px) {
    height: 40px;
  }
`;

export const Subscribe = () => {
  const [email, setEmail] = useState("");

  const [isError, setIsError] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const isMobile = useIsMobile();

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (isError) {
      setIsError(false);
    }
    setEmail(e.target.value);
  };

  const onClick = () => {
    if (!EMAIL_REGEX.test(email)) {
      return setIsError(true);
    }

    api.airdrops.subscribeNewsletter(email).then(() => {
      setIsPopupOpen(true);
      setTimeout(() => {
        setIsPopupOpen(false);
      }, SUBSCRIBED_POPUP_ANIMATION_DURATION);
    });
  };

  return (
    <>
      <SubscribedPopup isOpen={isPopupOpen} />
      <Container>
        <Texture />
        <Title>{t("airdrops.missing")}</Title>
        <Description>{t("airdrops.missing.description")}</Description>
        <MailContainer>
          <Input
            error={isError}
            value={email}
            onChange={onChange}
            leading={mail}
            placeholder={t(
              `airdrops.missing.input.placeholder${isMobile ? ".mobile" : ""}`
            )}
          />
          <StyledButton onClick={onClick}>
            {t("airdrops.missing.subscribe")}
          </StyledButton>
        </MailContainer>
      </Container>
    </>
  );
};
