import { FC } from "react";
import styled from "styled-components";
import { Header } from "components/Header";
import { Calculator } from "components/Calculator";
import { StandardLayout } from "components/StandardLayout";
import { Footer } from "components/Footer";
import { Section } from "components/Section";
import { Guide } from "components/Guide";
import { Questions } from "components/Questions";
import { Popularity } from "./Popularity";
import { Coins } from "./Coins";
import { Stats } from "./Stats";
import { Enjoy } from "./Enjoy";
import { useMedia } from "hooks";
import t from "i18n";
import headerImg from "./img/header-image.webp";

const Staking: FC = () => {
  const isMobile = useMedia("(max-width: 1024px)");

  return (
    <StandardLayout>
      <Header
        title={
          <Title
            dangerouslySetInnerHTML={{
              __html: t(`staking.title${isMobile ? ".mobile" : ""}`),
            }}
          />
        }
        description={t(`staking.description${isMobile ? ".mobile" : ""}`)}
        actionText={t("staking.actionText")}
        color="var(--color--teal)"
        image={<Image width="526" alt="" src={headerImg} />}
      />
      <Coins />
      {!isMobile && (
        <Section>
          <Stats />
        </Section>
      )}
      <Section>
        <Guide title={t("guide.title")} action={t("guide.action")} />
      </Section>
      <Section title={t("staking.enjoy.title")}>
        <Enjoy />
      </Section>
      <Calculator />
      <Section>
        <Popularity />
      </Section>
      <Section title={t("common.faq")}>
        <Questions
          questions={[
            {
              title: t("staking.q.howItWork.title"),
              description: t("staking.q.howItWork.description"),
            },
            {
              title: t("staking.q.howIsStakingInitiated.title"),
              description: t("staking.q.howIsStakingInitiated.description"),
            },
            {
              title: t("staking.q.whatIsStaking.title"),
              description: t("staking.q.whatIsStaking.description"),
            },
            {
              title: t("staking.q.whatIsAPY.title"),
              description: t("staking.q.whatIsAPY.description"),
            },
            {
              title: t("staking.q.whenStartEarn.title"),
              description: t("staking.q.whenStartEarn.description"),
            },
          ]}
        />
      </Section>
      <Footer />
    </StandardLayout>
  );
};

const Title = styled.h1`
  color: var(--color--black);

  @media (min-width: 1024px) {
    width: 600px;
  }
`;

const Image = styled.img`
  max-width: 40%;

  @media (max-width: 1024px) {
    width: 361px;
  }
`;

export default Staking;
