import { FC } from "react";
import styled from "styled-components";
import { Header } from "components/business/Header";
import imageHeader from "./img/header-image.webp";
import { NavigationTop } from "components/NavigationTop";
import { Benefits } from "./Benefits";
import { Cases } from "./Cases";
import { GetInTouch } from "components/business/GetInTouch";
import { Partners } from "components/business/Partners";
import { Footer } from "components/business/Footer";

export const Nodes: FC = () => {
  const onClick = () => {
    document.querySelector("#benefits")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <NavigationTop />
      <Header
        image={<img alt="" src={imageHeader} width="745" height="568" />}
        title="Node Deployment"
        description="Gain access to the most popular crypto assets and Web3 Solutions. Whether you run crypto exchange, a staking platform or build a new blockchain data solution, you can have you infrastructure elements ready in a few clicks."
        action="Explore"
        onClick={onClick}
      />
      <Benefits />
      <Cases />
      <Partners />
      <GetInTouch title="Take a leap into a<br /> decentralized future" />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
`;
