import { useEffect, useRef, useState } from "react";

import styled from "styled-components";

const WidgetOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  transition: background-color 0.2s ease-out;
`;

const Container = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  height: fit-content;
  background-color: var(--color--white);
  border-radius: 18px 18px 0px 0px;
  transition: bottom 0.2s ease-out;

  @media (orientation: landscape) and (max-height: 368px) {
    max-height: 100%;
    border-radius: 0;
    overflow: auto;
  }
`;

const WidgetBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.57;
    color: #223240;
    margin: 16px 0 4px;
  }
`;

const WidgetHeader = styled.div`
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.6;
    color: #223240;
  }
`;

const WidgetScroller = styled.div`
  width: 70px;
  height: 4px;
  background: #223240;
  border-radius: 8px;
  margin: 8px 0 16px;
`;

const HEADER_CLASS = "scroll-and-shadow";

export const Widget = (props) => {
  const { isOpen, onClickOverlay, onClick, title, children } = props;

  const [widgetY, setWidgetY] = useState(null);

  const [isMoving, setIsMoving] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const scrollY = window.scrollY;

    if (isOpen && document.body.style.position === "fixed") {
      return;
    }

    setTimeout(() => {
      if (isOpen) {
        document.querySelector("header").classList.add(HEADER_CLASS);
      }
    }, 0);

    if (isOpen) {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = `-${scrollY}px`;
    } else {
      document.body.style.position = "";
      window.scrollTo(
        0,
        parseInt(parseFloat(document.body.style.top) || "0") * -1
      );
    }
  }, [isOpen]);

  const getStartY = () => {
    if (!ref.current) {
      return window.innerHeight;
    }
    return window.innerHeight - ref.current.getBoundingClientRect().height;
  };

  const onTouchStart = () => {
    if (!ref.current) {
      return;
    }
    setIsMoving(true);
  };

  const onTouchEnd = () => {
    setIsMoving(false);
    if (
      widgetY >
      getStartY() + ref.current.getBoundingClientRect().height / 2
    ) {
      onClickOverlay();
    }
    setWidgetY(getStartY());
  };

  const onTouchMove = (e) => {
    if (!isMoving || !ref.current) {
      return;
    }
    const y = e.touches.item(0).clientY;
    setWidgetY(Math.max(y, getStartY()));
  };

  return (
    <WidgetOverlay
      style={{
        background: isOpen ? "rgba(0, 0, 0, 0.4)" : "transparent",
        pointerEvents: isOpen ? "all" : "none",
      }}
      onClick={onClickOverlay}
    >
      <Container
        ref={ref}
        onClick={onClick}
        style={{
          [isMoving ? "top" : "bottom"]: isOpen
            ? isMoving
              ? widgetY
              : 0
            : -document.documentElement.scrollHeight * 2,
        }}
      >
        <WidgetBody>
          <WidgetHeader
            onTouchMove={onTouchMove}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
          >
            <WidgetScroller />
            <h2>{title}</h2>
          </WidgetHeader>
          {children}
        </WidgetBody>
      </Container>
    </WidgetOverlay>
  );
};
