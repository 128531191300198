import t from "i18n";

import { Spinner } from "components/Spinner";

import { useMedia } from "hooks";

import { Table } from "../Table";
import { MobileItems } from "../MobileItems";

import { useCoins } from "../useCoins";

import styles from './styles.module.scss'

export const Nodes = () => {
  const { coins, loading } = useCoins();

  const isMobile = useMedia("(max-width: 1248px)");
  const dataWithMissingFieldsMocked = coins.map((i, index) => {
    const isColdStaking = (i.coldStaking && i.coldStaking.show)
    return {
      name: i.name,
      code: i.code,
      logo: i.imageUrl,
      networkName: i.name,
      networkImage: i.imageUrl,
      type: isColdStaking ? 'Cold Staking' : 'DeFi Staking',
      method: isColdStaking ? 'Delegation' : 'Flexible, Locked',
      APR: i.yearlyProfit,
      isColdStaking,
      delegateUrl: i.coldStaking?.url
    }
  })
  if (dataWithMissingFieldsMocked.length > 1) {
    // dataWithMissingFieldsMocked[0].isColdStaking = false
    // dataWithMissingFieldsMocked[0].isColdStaking = false

    dataWithMissingFieldsMocked[1].delegateUrl = false
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.heading}>{t('staking.cold.nodes.title')}</h3>
      <small className={styles.description}>{t("staking.cold.nodes.description")}</small>
      {loading ? <Spinner /> : (isMobile ? <MobileItems data={dataWithMissingFieldsMocked} /> : <Table data={dataWithMissingFieldsMocked} />)}
    </div>
  );
};
