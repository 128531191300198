import { useEffect, useState } from "react";

const MOBILE_WIDTH = 1024;

export const useIsMobile = ({ mobileWidth = MOBILE_WIDTH } = {}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(window.innerWidth < mobileWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isMobile;
};
