import { Questions } from "components/Questions";
import { uris } from "const";
import { FC } from "react";
import styled from "styled-components";
import { Section } from "components/business/Section";

export const FAQ: FC = () => {
  return (
    <Section title="Getting started">
      <Questions
        dark
        questions={[
          {
            title: "What do I need to get started? ",
            description: `The first step is to simply <a router-ignore href="${uris.business.register}">set up your account</a>. You’ll need to provide a few details about your business. Then, we will provide all the solutions that you want to use.`,
          },
          {
            title:
              "Do I need to have experience with blockchain or crytpocurrencies to use your services?",
            description:
              "Experience would help, but it’s not necessary. Also, having an idea how and why you would like to use our solutions and for what purpose would be helpful. All this will simply make your integration and the entire process much easier and smoother.",
          },
          {
            title:
              "What If I don’t have experience but would like my business to “be in crypto”?",
            description:
              "Our mission at MyCointainer is to help all companies, regardless of their size and kind. We provide support through our expertise, components and tools to let you faster adopt crypto & blockchain solutions on a global scale. You can g with one of our representatives to discuss your business model – we will kindly help you find the right solution or simply let you know what we can provide you with.",
          },
          {
            title:
              "What if something goes wrong with the solution or I don’t know how to manage it after integration?",
            description:
              "As stated in the first paragraph on this page; one of our core missions us MyCointainer is to help all sizes and kinds of businesses with our expertise, components and tools to bring faster adoption of crytpto & blockchain solutions to global scale. We will be providing ongoing support to ensure your solution works smoothly.",
          },
          {
            title: "What types of businesses can apply?",
            description:
              "Most common types of businesses that use our solutions are advertisting platforms, crypto gaming platforms, staking platforms, crypto exchanges. At this stage, we verify each business on a case by case basis.",
          },
        ]}
      />
      <Tip>
        First of all, you will get access to shared masternodes staking for a
        variety of coins. We offer automated pool staking, therefore, your
        advantages are steadier, more predictable earnings and the fact that you
        don't need to take care of any technical details.
        <br />
        <br />
        Yes, your every reward is a subject to compound interest. Basically with
        a longer period of time you have higher chances of earning much more as
        this is how staking process works.
      </Tip>
    </Section>
  );
};

const Tip = styled.p`
  font-size: 16px;
  line-height: 152%;
  color: ${(props) => props.theme.darkGray};
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 40px;
    margin: 0;
  }
`;
