import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import './styles.scss';
import { ReactComponent as ArrowDown } from './img/arrow-down.svg';

export const ExchangeTo = ({
  buyAmount,
  buyableCoins,
  exchangeCoinTo,
  setExchangeCoinTo
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [, setSellAmount] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const selectRef = useRef(null);

  const setExchangeTo = (coin) => {
    setExchangeCoinTo(coin);
    setIsMenuOpened(false);
    setSellAmount(null);
  };

  const outsideClick = (event) => {
    if (!event.composedPath().includes(selectRef.current)) {
      setIsMenuOpened(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', outsideClick);

    return () => {
      document.body.removeEventListener('click', outsideClick);
    };
  }, []);

  return (
    <div className="exchange-coin" ref={selectRef}>
      <label
        for=""
        className="exchange-coin-label exchange-coin-label-separate"
      >
        To
      </label>
      <div className="input-container">
        <div className={cx('select-dd', { active: isMenuOpened })}>
          <div
            rel="noreferrer"
            onClick={() => {
              if (!buyableCoins.length) return;
              setIsMenuOpened(!isMenuOpened);
            }}
            className="coin-select"
          >
            <div className="coin-select-input-left-section">
              <div hidden={!exchangeCoinTo} className="coin-image-container">
                <img
                  className="coin-image-main"
                  src={exchangeCoinTo?.icon}
                  alt={exchangeCoinTo?.name}
                />
              </div>
              {exchangeCoinTo ? (
                <>
                  <div className="coin-info coin-info-desk">
                    <h4>{exchangeCoinTo?.name}</h4>
                    <span>{exchangeCoinTo?.code}</span>
                  </div>
                  <div className="coin-info coin-info-mobile">
                    <h4>{exchangeCoinTo?.code}</h4>
                  </div>
                </>
              ) : null}
              <div className="coin-loading-wrapper" hidden={!!exchangeCoinTo}>
                Select coin
              </div>
            </div>
            <div className="arrow-down">
              <ArrowDown />
            </div>
          </div>
          <ul>
            {buyableCoins.length <= 4 && searchInput ? null : (
              <li>
                <div className="search-wrap">
                  <input
                    type="text"
                    onChange={(e) =>
                      setSearchInput(e.target.value.toLowerCase())
                    }
                  />
                  <button type="button"></button>
                </div>
              </li>
            )}
            <div className="exchange-coin-dropdown">
              {buyableCoins.filter(
                (coin) =>
                  coin.name.toLowerCase().includes(searchInput) ||
                  coin.code.toLowerCase().includes(searchInput)
              ).length === 0 ? (
                <li className="exchange-coin-dropdown--noresult">
                  No results found
                </li>
              ) : null}
              {buyableCoins
                .filter(
                  (coin) =>
                    coin.name.toLowerCase().includes(searchInput) ||
                    coin.code.toLowerCase().includes(searchInput)
                )
                .map((coin) => (
                  <li onClick={() => setExchangeTo(coin)}>
                    <div className="icon-holder">
                      <img src={coin.icon} alt={coin.name} />
                    </div>
                    <div className="coin-info">
                      <h4>{coin.name}</h4>
                      <span>{coin.code}</span>
                    </div>
                    <div className="counter-wrap">
                      <span>{coin.balance}</span>
                    </div>
                  </li>
                ))}
            </div>
          </ul>
        </div>
        <div className="resulting-container">
          <div className="values-holder has-empty-subvalue">
            <input
              placeholder="0.0000"
              readonly="readonly"
              id="get"
              type="text"
              className="value"
              value={buyAmount}
            />
            <div className="value-naming">
              <p>{exchangeCoinTo?.code}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
