import localForage from "localforage";
import { setup } from "axios-cache-adapter";
import { Airdrop, RawCoin, StrapiCoin, Coin } from "types";
import { STRAPI_COINS_GQL } from "const";

const config = {
  baseUrl: "https://www.mycointainer.com/api",
  cmsApiUrl: "https://www.mycointainer.com/cmsAPI",
};

const cache = {
  maxAge: 60 * 60 * 1000,
  store: localForage,
};

const api = setup({
  baseURL: config.baseUrl,
  cache,
});

api.interceptors.response.use(
  (result) => {
    return result.data.data;
  },
  (e) => {
    console.error(`Fatal: failed to fetch ${e.config?.url}`, {
      originalError: e,
    });
  }
);

const cmsApi = setup({
  baseURL: config.cmsApiUrl,
  cache,
});

cmsApi.interceptors.response.use(
  (result) => {
    return result.data.data;
  },
  () => {}
);

const findAirdrops = async (): Promise<Array<Airdrop>> => {
  try {
    return (await api.get("/airdrops")) ?? [];
  } catch {
    return [];
  }
};

const subscribeNewsletter = async (email: string) => {
  return await api.post("/airdrops/subscribe-newsletter", {
    email,
  });
};

const format = (str: string) => {
  return str
    .toLowerCase()
    .replace("_", " ")
    .split(" ")
    .map((s: string) => s?.at(0)?.toUpperCase() + s?.slice(1))
    .join(" ");
};

const matchCoins = (
  coins: Record<string, RawCoin>,
  strapiCoins: Array<StrapiCoin>
): Array<Coin> => {
  const result: Array<Coin> = [];

  Object.keys(coins).forEach((coinName) => {
    const strapiMatch = strapiCoins.find(
      (coin) =>
        coin.coinName.toLowerCase() === coinName.toLowerCase() ||
        coin.coinName.toLowerCase() === coins[coinName].name.toLowerCase()
    );

    const price = strapiMatch?.coinOverview?.currentValuation?.value;

    if (!strapiMatch || !price) {
      return;
    }

    const { coinOverview, interestingFacts, projectNutshell, isComingSoon } =
      strapiMatch;

    const coin = {
      ...coins[coinName],
      isComingSoon,
      assetKey: coins[coinName].assetKey.toLowerCase().replaceAll("_", ""),
      interestingFacts: interestingFacts?.list ?? [],
      projectNutshell: projectNutshell?.list ?? [],
      description: coinOverview.summary ?? "",
      title: coinOverview.title || coins[coinName].name,
      slug: coins[coinName].code,
      currentValuation: coinOverview.currentValuation,
      yearlyProfit: coinOverview.yearlyReturn?.value || 0,
      imageUrl: coinOverview.image.url,
      rewardFee: coins[coinName].rewardFee / 10,
      popularity: strapiMatch.popularity,
      price: coins[coinName].price.display,
      categories: (strapiMatch?.categories ?? []).map(format),
    };

    result.push(coin);
  });

  return result.sort((a, b) => (a.popularity > b.popularity ? 1 : -1));
};

const TIME_TO_LIVE = 3_600_000;

const STRAPI_COINS_LOCAL_FORAGE_KEY = "STRAPI_COINS";

interface StrapiCache {
  expiration: number;
  result: Array<StrapiCoin>;
}

const findStrapiCoins = async () => {
  const stored: StrapiCache | null = await localForage.getItem(
    STRAPI_COINS_LOCAL_FORAGE_KEY
  );

  if (stored) {
    if (stored.expiration > new Date().getTime()) {
      return stored.result;
    }
  }

  const strapiCoins: Array<StrapiCoin> =
    (await cmsApi
      .post("", {
        query: STRAPI_COINS_GQL,
        variables: {
            where: {
                isVisibleAtAssetsPage: true
            },
            limit: -1
        }
      })
      // @ts-expect-error
      .then((result: { assets: Array<StrapiCoin> }) => result.assets)) ?? [];

  await localForage.setItem(STRAPI_COINS_LOCAL_FORAGE_KEY, {
    expiration: new Date().getTime() + TIME_TO_LIVE,
    result: strapiCoins,
  });

  return strapiCoins;
};

const findCoins = async (): Promise<Array<Coin>> => {
  const coins: Record<string, RawCoin> =
    (await api.get("/landing/coins")) ?? {};

  const strapiCoins = await findStrapiCoins();

  return matchCoins(coins, strapiCoins).sort((a, b) =>
    a.popularity > b.popularity ? 1 : -1
  );
};

export const airdrops = {
  find: findAirdrops,
  subscribeNewsletter,
};

export const coins = {
  find: findCoins,
};
