import { FC } from "react";
import { List, ListItem, ListItemDescription } from "components/business/List";
import { Button } from "components/business/Button";
import { Section } from "components/business/Section";
import giveaways from "./img/giveaways.svg";
import nodes from "./img/nodes.svg";
import marketing from "./img/marketing.svg";
import power from "./img/power.svg";
import { uris } from "const";

export const Services: FC = () => {
  return (
    <Section
      title="Business services"
      description="Start promoting your services to be more crypto."
    >
      <List>
        <ListItem size={2} href={uris.business.staking}>
          <img alt="" src={nodes} />
          <Button variant="text">Staking Nodes</Button>
          <ListItemDescription>
            Provide your customers with a convenient way to earn. They can stake
            their coins in order to get rewards. It works just like holding a
            share of a company that gives you dividends.
          </ListItemDescription>
        </ListItem>
        <ListItem size={2} href={uris.business.giveaways}>
          <img alt="" src={giveaways} />
          <Button variant="text">Airdrops & Giveaways</Button>
          <ListItemDescription>
            The best way to reach over 500 000 users to raise awarness about
            your product. On MyCointainer all it takes is just a few simple
            clicks.
          </ListItemDescription>
        </ListItem>
        <ListItem size={2} href={uris.business.marketing}>
          <img alt="" src={marketing} />
          <Button variant="text">Marketing Campaigns</Button>
          <ListItemDescription>
            Introduce your product or platform to new customers by having your
            campaigns customized in line with your needs. Thanks to our
            experience, you can rest assured and maximize your ROI.
          </ListItemDescription>
        </ListItem>
        <ListItem size={2} href={uris.business.power}>
          <img alt="" src={power} />
          <Button variant="text">POWER Codes</Button>
          <ListItemDescription>
            Add extra value to your customers by adding amazing VAS (value added
            service) to your existing offering.
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
