import { FC } from "react";
import { Section } from "components/business/Section";
// @ts-expect-error
import Slider from "infinite-react-carousel";
import styled from "styled-components";
import founder from "./img/founder.png";
import avalabsVpMarketing from "./img/avalabs-vp-marketing.png";
import ceoDock from "./img/ceo-at-dock.png";
import ceoCryptoadventure from "./img/ceo-cryptoadventure.png";
import cofounderNuls from "./img/cofounder-nuls.png";
import cofounderQtum from "./img/cofounder-qtum.png";
import cooCrdtpay from "./img/coo-crdtpay.png";
import directorItOps from "./img/director-itops.png";
import ltoAuthor from "./img/lto-author.png";

import eMoney from "./img/emoney.png";
import dock from "./img/dock.svg";
import avalabs from "./img/ava-labs.svg";
import cryptoAdventure from "./img/cryptoadventure.svg";
import itOps from "./img/it-ops.svg";
import crdtPay from "./img/crdtpay.svg";
import qtum from "./img/qtum.svg";
import nuls from "./img/nuls.svg";
import ltoNetwork from "./img/lto-network.svg";

import cartesi from "./img/partners/cartesi.png";
import crypto from "./img/partners/crypto.png";
import estonia from "./img/partners/estonia.png";
import harmony from "./img/partners/harmony.png";
import polygon from "./img/partners/polygon.png";
import thompson from "./img/partners/thompson.png";
import truelayer from "./img/partners/truelayer.png";
import trustly from "./img/partners/trustly.png";
import waves from "./img/partners/waves.png";
import zen from "./img/partners/zen.png";

const PARTNERS_LIST = [
  { image: zen, width: 158, height: 52 },
  { image: polygon, width: 193, height: 62 },
  { image: harmony, width: 206, height: 60 },
  { image: truelayer, width: 175, height: 32 },
  { image: thompson, width: 126, height: 46 },
  { image: waves, width: 128, height: 26 },
  { image: crypto, width: 212, height: 49 },
  { image: trustly, width: 180, height: 49 },
  { image: cartesi, width: 174, height: 59 },
  { image: estonia, width: 62, height: 78 },
];

const QUOTES_LIST = [
  {
    author: "<span>Martin Dyring-Andersen</span>, CEO and Founder",
    logoWhois: {
      image: eMoney,
      width: 102,
      height: 51,
    },
    image: founder,
    description:
      "“We are excited that our native token - NGM, is part of MyCointainer’s innovative Staking Service Platform, that provides users with a simple and easy way to earn passive income.”",
  },
  {
    author: "<span>Jay Kurahashi-Sofue</span>, VP of Marketing at AvaLabs",
    logoWhois: {
      image: avalabs,
      width: 71,
      height: 41,
      marginTop: 24,
    },
    image: avalabsVpMarketing,
    description:
      "“Working with the MyCointainer team has been seamless–from engineering to marketing. In addition to making it easy for anyone to stake AVAX, the team has done an incredible job with educational content to simplify the staking process.”",
  },
  {
    author: "<span>Nick Lambert</span>, CEO & Founder at Dock.io",
    logoWhois: {
      image: dock,
      width: 82,
      height: 27,
      marginTop: 21,
    },
    image: ceoDock,
    description:
      "“MyContainer makes staking easy, a very welcome addition to DOCK as we transition the network to Proof of Stake.“",
  },
  {
    author: "<span>Bogdan Dinu</span>, CEO & Founder at CrytpoAdventure",
    logoWhois: {
      image: cryptoAdventure,
      width: 167,
      height: 26,
      marginTop: 29,
    },
    image: ceoCryptoadventure,
    description:
      "“MyCointainer is the staking network that every cryptocurrency enthusiast should be using. They have set themselves apart by providing services that actively support not only the end users but also companies in simple but meaningful ways. Partnering with MyCointainer means you have a team that is fighting for your products every day. We were delightful that they chose Crypto Adventure to find new opportunities to grow their brand!“",
  },
  {
    author: "<span>John Poulmentis</span>, Director of IT Ops at Validity Tech",
    logoWhois: {
      image: itOps,
      width: 57,
      height: 57,
      marginTop: 19,
    },
    image: directorItOps,
    description:
      "“We've had the pleasure of working with MyCointainer since they began operations. Not only have they continued to improve their core platform and reliability, but they've also committed significant resources to expanding their staking offerings and security. We are proud to have partnered with Bartosz and the team over at MyCointainer and look forward to many more fruitful years in providing these services to our community.“",
  },
  {
    author: "<span>Daryn Doldashev</span>, COO at CRDTpay",
    logoWhois: {
      image: crdtPay,
      width: 43,
      height: 40,
      marginTop: 14,
    },
    image: cooCrdtpay,
    description:
      "“We share MyCointainer’s vision to help break barriers, giving equal opportunity to people all over the globe. MyCointainer is customer-centric and by doing so builds brand loyalty.“",
  },
  {
    author: "<span>Jordan Earls</span>, Co-Founder at QTUM",
    logoWhois: {
      image: qtum,
      width: 50,
      height: 44,
      marginTop: 21,
    },
    image: cofounderQtum,
    description:
      "“MyContainer is an innovative platform that has provided services to help people to stake their qtum and has improved the security of the Qtum network.”",
  },
  {
    author:
      "<span>Mario Blacutt</span>, West Regional Director & Co-Founder at Nuls",
    logoWhois: {
      image: nuls,
      width: 52,
      height: 52,
      marginTop: 49,
    },
    image: cofounderNuls,
    description:
      "“The key for survival in crypto is finding good partners and collaborators,  that you can rely on to face the huge challenges  upcoming in the future; partners like this are very rare but gladly, we found MyContainer to be such partner that makes us able to expect wonderful things in the upcoming years”",
  },
  {
    author: "Rick Schmitz, Founder & CEO at LTO Network",
    logoWhois: {
      image: ltoNetwork,
      width: 39,
      height: 34,
      marginTop: 66,
    },
    image: ltoAuthor,
    description:
      "“Great that LTO Network is being integrated on the MyCointainer's platform. Easy staking to earn passive income.”",
  },
];

const ANIMATION_DURATION = 4_000;

export const Partners: FC = () => {
  return (
    <Section
      light
      title="Working together with partners"
      description="Partners from various industries trust us and help us to create a reliable ecosystem<br /> that benefits both individuals and businesses."
    >
      <List>
        {PARTNERS_LIST.map(({ image, width, height }, i) => (
          <Frame>
            <img alt="" src={image} width={width} height={height} />
          </Frame>
        ))}
      </List>
      <Ruler />
      <StyledSlider
        dots
        dotsClass="swiper"
        pauseOnHover
        arrows={false}
        autoplay
        duration={100}
        autoplaySpeed={ANIMATION_DURATION}
      >
        {QUOTES_LIST.map(({ author, description, image, logoWhois }, i) => (
          <div key={author}>
            <Quote reverse={!!(i % 2)}>
              <Detail>
                <p>{description}</p>
                <address
                  dangerouslySetInnerHTML={{
                    __html: author,
                  }}
                />
                <img
                  width={logoWhois.width}
                  height={logoWhois.height}
                  style={{
                    marginTop: logoWhois?.marginTop ?? 0,
                  }}
                  alt=""
                  src={logoWhois.image}
                />
              </Detail>
              <img alt="" src={image} />
            </Quote>
          </div>
        ))}
      </StyledSlider>
    </Section>
  );
};

const StyledSlider = styled(Slider)`
  & > ul {
    width: 100%;
    display: flex !important;
    justify-content: center;
    gap: 8px;
    margin-top: 92px;

    li {
      width: 52px;
      height: 8px;
      border-radius: 2px;
      background-color: #ebebeb;

      button {
        width: 100%;
        position: relative;
        background-color: transparent;
        top: -8px;
        color: transparent;
      }
    }
  }

  .carousel-item {
    opacity: 1 !important;
  }

  .carousel-dots-active {
    background-color: black;
  }
`;

const Frame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(20% - 24px);

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    padding: 8px;
  }

  @media (max-width: 998px) {
    width: calc(100% / 4 - 24px);
  }

  @media (max-width: 768px) {
    width: calc(50% - 24px);
  }
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
  margin: 48px auto 0;
  gap: 30px;
`;

const Ruler = styled.div`
  max-width: 1100px;
  margin: 64px auto -16px;
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
`;

interface QuoteProps {
  reverse?: boolean;
}

const Quote = styled.div<QuoteProps>`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  justify-content: space-around;
  align-items: center;
  padding-top: 64px;

  @media (max-width: 998px) {
    flex-direction: column-reverse;
    justify-content: center;
    padding-top: 0;
    margin-top: 0;
    border: none;

    & > img {
      width: 182px;
      height: 188px;
      margin: 96px 0 56px;
    }
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 92px;

  margin: auto 0;

  * {
    color: ${(props) => props.theme.black};
  }

  p {
    width: 624px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    min-height: 147px;
  }

  address {
    font-size: 16px;
    font-style: normal;
    line-height: 152%;

    span {
      font-weight: 500;
    }
  }

  @media (max-width: 998px) {
    margin-bottom: 40px;

    p {
      width: 100%;
    }
  }
`;
