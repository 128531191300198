import cn from 'classnames'

import { useIsMobile } from "hooks/useIsMobile"

import styles from './styles.module.scss'

import { ReactComponent as Info } from '../../ColdStaking/img/info.svg'
import { ReactComponent as ArrowLeft } from '../img/arrow-left.svg'

export default ({ logo, name, code, APR, type = 'DeFi Staking', product = 'Flexible /Locked', network, TVL }) => {
  const isMobile = useIsMobile({ mobileWidth: 1228 });

  const { imageUrl, name: networkName } = network

  return (
    <div className={styles.container}>
      <a href='/cold-staking' className={styles['back-to-pools']}>
        <ArrowLeft />
        <p className={styles.back}>Back to Pools</p>
      </a>
      <div className={styles['data-cointainer']}>
        <div className={styles.top}>
          <div className={styles['top-left-column']}>
            <img className={styles.logo} src={logo} />
            <div className={styles['top-row']}>
              <p className={styles.name}>{name}</p>
              <small className={styles.code}>{code}</small>
            </div>
          </div>
          {
            isMobile
              ?
                (
                  <div className={styles['top-right-column']}>
                    <p className={styles.value}>{APR}%</p>
                    <div className={styles['max-apr-value-container']}>
                      <small className={cn(styles['field-name'], styles['apr-field-name'])}>Max APR</small>
                      <Info className={styles['info-icon']} />
                    </div>
                  </div>
                )
              : null
          }
        </div>
        <div className={styles.bottom}>
          {
            isMobile
              ? null
              :
                (
                  <div className={styles['data-item']}>
                    <small className={cn(styles['field-name'], styles['apr-field-name'])}>Max APR<Info className={styles['info-icon']} /></small>
                    <p className={styles.value}>{APR}</p>
                  </div>
                )
          }
          <div className={styles['data-item']}>
            <small className={styles['field-name']}>Type</small>
            <p className={styles.value}>{type}</p>
          </div>
          <div className={styles['data-item']}>
            <small className={styles['field-name']}>Product</small>
            <p className={styles.value}>{product}</p>
          </div>
          <div className={styles['data-item']}>
            <small className={styles['field-name']}>Network</small>
            <div className={styles['network-value']}>
              <img className={styles['network-image']} src={imageUrl} />
              <p className={styles.value}>{networkName}</p>
            </div>
          </div>
          <div className={styles['data-item']}>
            <small className={styles['field-name']}>Total value locked <Info className={styles['info-icon']} /></small>
            <p className={styles.value}>{TVL}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
