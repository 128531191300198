import React, { useMemo } from "react";

import "./styles.scss";
import { list } from "./news";
import { useWindowDimension } from "hooks/useWindowDimensions";

export const News = () => {
  const { width } = useWindowDimension();
  const isMobile = width <= 1280;

  const newsList = useMemo(() => {
    const [, ...otherNews] = list;

    if (isMobile) return list;
    else return otherNews;
  }, [isMobile]);

  return (
    <div className="news-wrapper">
      <div className="news-container">
        <div className="wrapper-title">Read news from the crypto world</div>
        <a href={list[0].link} className="news-big-item" target="__blank">
          {!isMobile && (
            <>
              <div className="date">{list[0].date}</div>
              <div className="title">{list[0].title}</div>
              <div className="description">{list[0].description}</div>
            </>
          )}
        </a>
        <div className="list-wrapper">
          {newsList.map((item) => (
            <a className="news-item" href={item.link} target="__blank">
              <div className="date">{item.date}</div>
              <div className="title">{item.title}</div>
              <div className="description">{item.description}</div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
