import { FC } from "react";
import styled from "styled-components";
import { Header } from "components/business/Header";
import imageHeader from "./img/header-image.webp";
import { NavigationTop } from "components/NavigationTop";
import { Partners } from "components/business/Partners";
import { GetInTouch } from "components/business/GetInTouch";
import { FAQ } from "./FAQ";
import { Benefits } from "./Benefits";
import { WhyMyCointainer } from "./WhyMyCointainer";
import { Assets } from "./Assets";
import { Footer } from "components/business/Footer";

export const Wallet: FC = () => {
  const onClick = () => {
    document.querySelector("#benefits")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <NavigationTop />
      <Header
        image={<img alt="" src={imageHeader} width="742" height="504" />}
        title="Crypto Wallets"
        description="Reach new customers and increase your money flow. Get  access to over 100 crypto wallets and easily enable them on your platform."
        action="Explore"
        onClick={onClick}
      />
      <Benefits />
      <Assets />
      <WhyMyCointainer />
      <Partners />
      <FAQ />
      <GetInTouch
        title="Enable Crytpo Wallets<br /> inside your platform"
        primaryAction="Get started"
      />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
`;
