import cn from 'classnames'

import { ReactComponent as CaretUpSvg }  from '../img/caret-up.svg'
import { ReactComponent as CaretDownSvg } from '../img/caret-down.svg'
import { ReactComponent as InfoIcon } from '../../ColdStaking/img/info.svg'

import styles from './styles.module.scss'

const columns = [
  'Token',
  'Network',
  'Type',
  'Method',
  'APR',
  'Action'
]

export const Table = ({ data }) => {
  return (
    <div className={styles.table}>
      <div className={styles['table-header']}>
        {columns.map(columnName => <div className={styles['header-item']}>
          {columnName === 'APR' ? <InfoIcon className={styles['info-icon']} /> : null}
          <span>{columnName}</span>
          <div className={styles.carets}>
            <CaretUpSvg className={styles.caret} />
            <CaretDownSvg className={styles.caret} />
          </div>
        </div>)}
      </div>
      {
        data.map(i => (
          <div className={styles['row']}>
            <div className={cn(styles['data-item'], styles.token)}>
              <img className={styles['data-item-img']} src={i.logo} />
              <div className={styles['text-column']}>
                <b className={styles['primary-text']}>{i.name}</b>
                <small className={styles['secondary-text']}>{i.code}</small>
              </div>
            </div>
            <div className={cn(styles['data-item'], styles.network)}>
              <img className={styles['network-img']} src={i.networkImage} />
              <b className={styles['primary-text']}>{i.networkName}</b>
            </div>
            <div className={cn(styles['data-item'], styles.type)}>
              <p className={styles['primary-text']}>{i.type}</p>
            </div>
            <div className={cn(styles['data-item'], styles.method)}>
              <p className={styles['primary-text']}>{i.method}</p>
            </div>
            <div className={cn(styles['data-item'], styles.APR)}>
              <p className={styles['APR-text']}>~{i.APR}%</p>
            </div>
            <div className={cn(styles['data-item'], styles.action)}>
              <a
                href={i.isColdStaking ? i.delegateUrl : `/cold-staking/asset/${i.code}`}
                className={cn(
                  styles['action-button'],
                  {
                    [styles['defi-staking-button']]: !i.isColdStaking,
                    [styles.disabled]: i.isColdStaking && !i.delegateUrl
                  }
                )}>
                {i.isColdStaking ? 'Delegate' : 'Stake'}
              </a>
            </div>
          </div>
        ))
      }
    </div>
  )
}
