import styled from "styled-components";

import { Description } from "components/Section";

import { compoundedRewards, everydayReward, instantAllocation } from "assets";

import t from "i18n";

const List = styled.div`
  display: flex;
  justify-content: center;
  gap: 81px;
  padding: 0 16px;

  box-sizing: border-box;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 12px;
    padding: 0 16px;
  }
`;

const Benefit = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  width: 328px;

  * {
    text-align: center;
  }

  img {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 1024px) {
    padding: 8px 12px;

    align-items: flex-start;
    flex-direction: row;
    gap: 12px;

    width: 100%;

    * {
      text-align: left;
    }

    img {
      width: 32px;
      height: 32px;

      margin-top: 4px;
    }

    background: var(--color--white);
    border: 1px solid var(--color-monochrome--light-2);
    box-shadow: var(--shadow--stroke--dash);
    border-radius: var(--rounding--medium);
  }
`;

const BenefitText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 1024px) {
    gap: 0;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.4;
  color: var(--color--black);

  @media (max-width: 1024px) {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const BenefitDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: var(--color--secondary);

  @media (max-width: 1024px) {
    font-size: 0.875rem;
    line-height: 1.57;
  }
`;

const TinyTitle = styled(Title)`
  @media (min-width: 1024px) {
    width: 250px;
    margin: 0 auto;
  }
`;

export const WhyWithUs = () => {
  return (
    <>
      <Description>{t("asset.whyWithUs.description")}</Description>
      <List>
        <Benefit>
          <img alt="" src={instantAllocation} />
          <BenefitText>
            <Title>{t("asset.whyWithUs.instantAllocation.title")}</Title>
            <BenefitDescription>
              {t("asset.whyWithUs.instantAllocation.description")}
            </BenefitDescription>
          </BenefitText>
        </Benefit>
        <Benefit>
          <img alt="" src={everydayReward} />
          <BenefitText>
            <TinyTitle>{t("asset.whyWithUs.everyDayRewards.title")}</TinyTitle>
            <BenefitDescription>
              {t("asset.whyWithUs.everyDayRewards.description")}
            </BenefitDescription>
          </BenefitText>
        </Benefit>
        <Benefit>
          <img alt="" src={compoundedRewards} />
          <BenefitText>
            <Title>{t("asset.whyWithUs.compoundedRewards.title")}</Title>
            <BenefitDescription>
              {t("asset.whyWithUs.compoundedRewards.description")}
            </BenefitDescription>
          </BenefitText>
        </Benefit>
      </List>
    </>
  );
};
