import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import styled from "styled-components";

import { useParams } from "react-router-dom";

import { StandardLayout } from "components/StandardLayout";
import { Container } from "components/Container";
import { Guide } from "components/Guide";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Section } from "components/Section";
import { Questions } from "components/Questions";
import { Calculator } from "components/Calculator";
import { Spinner } from "components/Spinner";
import { WhyWithUs } from "./WhyWithUs";
import { Info } from "./Info";
import { Overview } from "./Overview";

import t from "i18n";

import { URLS } from "const";

import { imageAssetsList } from "assets";

import * as api from "api";

import { useIsMobile } from "hooks/useIsMobile";

const TitleWrapper = styled.div`
  width: 232px;

  @media (min-width: 1024px) {
    width: 631px;
    font-size: 60px;
    line-height: 70px;
  }

  span {
    text-transform: capitalize;
  }
`;

const SizedImage = styled.div`
  z-index: 2;

  @media (min-width: 1024px) {
    width: min(50%, 700px);

    img {
      width: 700px;
    }
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;

  & > div {
    margin-top: 0 !important;
  }
`;

const Asset = () => {
  const { assetName } = useParams();

  const [asset, setAsset] = useState(null);

  const isMobile = useIsMobile();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.coins
      .find()
      .then((assets) => {
        setAsset(assets.find((asset) => asset.assetKey === assetName));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [assetName]);

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (!asset) {
    window.open(URLS.NOT_FOUND, "_self");
    return null;
  }

  const capitalizedAssetName =
    assetName.at(0).toUpperCase() + assetName.slice(1);

  return (
    <StandardLayout>
      <Helmet>
        <title>{t('meta.title.assets.item', { coin: capitalizedAssetName, coinTicker: asset?.code })}</title>
        <meta name="description" content={t('meta.description.assets.item', { coin: capitalizedAssetName, coinTicker: asset?.code })} />
        <meta name="keywords" content={t('meta.keywords.assets.item', { coin: capitalizedAssetName, coinTicker: asset?.code })} />
      </Helmet>
      <Container>
        <Header
          title={
            <TitleWrapper
              dangerouslySetInnerHTML={{
                __html: t(
                  asset?.yearlyProfit > 0
                    ? "asset.title"
                    : "asset.title.noYearlyProfit",
                  {
                    assetName: asset.name,
                    yearlyProfit: asset?.yearlyProfit,
                  }
                ),
              }}
            />
          }
          description={t(`asset.description${isMobile ? ".mobile" : ""}`, {
            slug: asset?.slug,
          })}
          image={
            <SizedImage>
              <img alt="" src={imageAssetsList} />
            </SizedImage>
          }
          actionText={t("asset.actionText")}
        />
        <Section>
          <Info asset={asset} />
        </Section>
        <Section title={t("asset.whyWithUs.title")}>
          <WhyWithUs />
        </Section>
        {asset.yearlyProfit > 0 && <Calculator hideSelector asset={asset} />}
        <Section>
          <Overview asset={asset} />
        </Section>
        <Section>
          <Guide
            title={t("asset.howTo.title", {
              assetName: capitalizedAssetName,
            })}
            action={t("asset.howTo.actionText", {
              assetName: capitalizedAssetName,
            })}
          />
        </Section>
        <Section title={t("common.faq")}>
          <Questions
            questions={[
              {
                title: t("asset.q.whatAreDigitalAssets.title"),
                description: t("asset.q.whatAreDigitalAssets.description"),
              },
              {
                title: t("asset.q.willYearlyProfitChange.title"),
                description: t("asset.q.willYearlyProfitChange.description"),
              },
              {
                title: t("asset.q.howAreStakingCalculated.title"),
                description: t("asset.q.howAreStakingCalculated.description"),
              },
              {
                title: t("asset.q.benefitsOfStaking.title"),
                description: t("asset.q.benefitsOfStaking.description"),
              },
            ]}
          />
        </Section>
        <Footer />
      </Container>
    </StandardLayout>
  );
};

export default Asset;
