import { useState } from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

import { ReactComponent as ArrowDown } from '../img/arrow-down.svg'
import { ReactComponent as Info } from '../img/info.svg'

export const MobileItem = ({ logo, name, code, APR, isColdStaking, method, type, networkName, delegateUrl }) => {
  const [open, setOpen] = useState(false)

  return (
    <li className={styles['item-container']}>
      <div className={styles['main-info']}>
        <div className={styles.token}>
          <img className={styles['img']} src={logo} />
          <div>
            <b className={styles['primary-text']}>{name}</b>
            <small className={styles['secondary-text']}>{code}</small>
          </div>
        </div>

        <div className={styles['APR-container']}>
          <p className={styles['APR-text']}>~{APR}%</p>
          <p className={cn(styles['secondary-text'], styles['APR-secondary-text'])}><span>APR</span><Info className={styles['info-icon']} /></p>
        </div>
      </div>
      <button disabled={isColdStaking && !delegateUrl} className={styles['action-button']}>
        <a href={isColdStaking ? delegateUrl : `/cold-staking/asset/${code}`}>
          {isColdStaking ? 'Delegate' : 'Stake'}
        </a>
      </button>
      <button onClick={() => setOpen(!open)} className={styles['details-button']}>
        <span>Details</span> {<ArrowDown className={cn(styles['arrow-down'], { [styles.active]: open })} />}
      </button>
      {
        open
        ?
          (
            <div className={styles['details-container']}>
              <p className={cn(styles['details-item'], styles['primary-text'])}>
                <small className={styles['details-item-name']}>Method</small>
                {method}
              </p>
              <p className={cn(styles['primary-text'], styles['details-item'])}>
                <small className={styles['details-item-name']}>Type</small>
                {type}
              </p>
              <p className={cn(styles['details-item'], styles['primary-text'])}>
                <small className={styles['details-item-name']}>Network</small>
                {networkName}
              </p>
            </div>
          )
        : null
      }
    </li>
  )
}

export const MobileItems = ({ data }) => {
  return (
    <ul className={styles.container}>
      {data.map(i => <MobileItem {...i} />)}
    </ul>
  )
}
