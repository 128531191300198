import {
  List,
  ListItem,
  ListItemDescription,
  ListItemTitle,
} from "components/business/List";
import { Section } from "components/business/Section";
import { FC } from "react";
import reachMillions from "./img/reach-millions.svg";
import provideGrowth from "./img/provide-growth.svg";
import stability from "./img/stability.svg";

export const WhyJoinUs: FC = () => {
  return (
    <Section
      title="Why join our network?"
      description="MyCointainer actively and constantly introduces crypto projects to businesses and individuals that wish to<br /> join the movement – that’s just a step away from discovering your project."
    >
      <List hideBackground>
        <ListItem purple size={3}>
          <img alt="" src={reachMillions} />
          <ListItemTitle>Reach millions of customers</ListItemTitle>
          <ListItemDescription purple>
            The MyCointainer node deployment network allows businesses to easily
            spin nodes with various solutions. We make it easier for businesses
            in our network to find out more about your services.
          </ListItemDescription>
        </ListItem>
        <ListItem purple size={3}>
          <img alt="" src={provideGrowth} />
          <ListItemTitle>Steady growth of your coin</ListItemTitle>
          <ListItemDescription purple>
            Any MyCointainer partner business can provide your blockchain
            services to their own audience. With such expansion, you’re able to
            increase awareness and attract new customers to your products and
            services.
          </ListItemDescription>
        </ListItem>
        <ListItem purple size={3}>
          <img alt="" src={stability} />
          <ListItemTitle>Stability of your network</ListItemTitle>
          <ListItemDescription purple>
            You can easily provide and maintain all the necessary information as
            well as make updates to your blockchain network – correct
            repositories, the latest updates, and new services to use.
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
