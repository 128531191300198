import { Section } from "components/business/Section";
// @ts-expect-error
import Slider from "infinite-react-carousel";
import styled from "styled-components";
import iocGiveaway from "./img/ioc-giveaway.png";
import ctsiGiveaway from "./img/ctsi-giveaway.png";
import xemGiveaway from "./img/xem-giveaway.png";
import check from "./img/check.svg";

const GIVEAWAYS_LIST = [
  {
    image: ctsiGiveaway,
    title: "Cartesi Giveway",
    prize: "22 000 CTSI",
    participants: 1245,
    actions: 8509,
    joined: 1079,
    twitter: {
      followers: 1124,
      retweets: 1024,
    },
  },
  {
    image: xemGiveaway,
    title: "XEM Giveaway",
    prize: "4500 XEM",
    participants: 1961,
    actions: 11947,
    joined: 1774,
    twitter: {
      followers: 1848,
      retweets: 1848,
    },
  },
  {
    image: iocGiveaway,
    title: "I/O Coin Giveway",
    prize: "7000 IOC",
    participants: 659,
    actions: 3728,
    joined: 540,
    twitter: {
      followers: 578,
      retweets: 427,
    },
  },
];

const formatNumberWithComma = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const ANIMATION_DURATION = 4_000;

export const PastGiveaways = () => {
  return (
    <Section
      title="Past Custom Giveaways"
      description="We run dozens of airdrops and giveaways which our communiy loves!"
    >
      <StyledSlider
        dots
        dotsClass="swiper"
        pauseOnHover
        arrows={false}
        autoplay
        duration={100}
        autoplaySpeed={ANIMATION_DURATION}
      >
        {GIVEAWAYS_LIST.map(
          (
            { image, title, prize, participants, actions, joined, twitter },
            i
          ) => (
            <div key={i}>
              <Giveaway>
                <GiveawayDetail>
                  <h3>{title}</h3>
                  <p>Rewards pool: {prize}</p>
                  <ul>
                    <li>
                      Active participants:{" "}
                      <span>{formatNumberWithComma(participants)}</span>
                    </li>
                    <li>
                      Performed actions:{" "}
                      <span>{formatNumberWithComma(actions)}</span>
                    </li>
                    <li>
                      New product clients:{" "}
                      <span>{formatNumberWithComma(joined)}</span>
                    </li>
                    <li>
                      Twitter follow reach:{" "}
                      <span>{formatNumberWithComma(twitter.followers)}</span>
                    </li>
                    <li>
                      Selected post reetwets:{" "}
                      <span>{formatNumberWithComma(twitter.retweets)}</span>
                    </li>
                  </ul>
                </GiveawayDetail>
                <img alt="" src={image} />
              </Giveaway>
            </div>
          )
        )}
      </StyledSlider>
    </Section>
  );
};

const GiveawayDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: white;
  }

  p {
    font-size: 20px;
    line-height: 152%;
    color: white;
    margin: 16px 0 24px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  li {
    color: #a8a8a8;

    background: url(${check}) no-repeat;
    padding-left: 32px;
    background-position: 0 6px;
    background-size: 14px 10px;

    span {
      color: white;
      font-weight: 700;
    }
  }
`;

const Giveaway = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 64px auto 0;

  img {
    height: 627px;
  }

  @media (max-width: 998px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 40px;

    img {
      height: 465px;
    }
  }

  @media (max-width: 576px) {
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const StyledSlider = styled(Slider)`
  & > ul {
    width: 100%;
    display: flex !important;
    justify-content: center;
    gap: 8px;
    margin-top: 92px;

    li {
      width: 52px;
      height: 8px;
      border-radius: 2px;
      background-color: rgba(156, 163, 169, 0.5);

      button {
        width: 100%;
        position: relative;
        background-color: transparent;
        top: -8px;
        color: transparent;
      }
    }
  }

  .carousel-dots-active {
    background-color: white;
  }
`;
