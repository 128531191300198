import styled from "styled-components";

import { useMedia } from "hooks";

import chevronRight from "./img/chevron-right.svg";

export const Node = (props) => {
  const {
    imageUrl,
    name,
    slug,
    yearlyProfit,
    isOffline,
    url,
    isComingSoon,
    chevron,
  } = props;

  const isMobile = useMedia("(max-width: 1200px)");

  const badgeNode = isOffline ? (
    <Offline>Temporarily offline</Offline>
  ) : (
    <Profit>{!isComingSoon ? `${yearlyProfit}%` : "Soon"}</Profit>
  );

  return (
    <Container
      style={{
        cursor: url ? "pointer" : "default",
      }}
      target="_blank"
      href={url}
    >
      <Image>
        <img alt={slug} src={imageUrl} />
      </Image>
      {!isMobile && badgeNode}
      <Column>
        <Title>{name}</Title>
        <Description>{!isMobile ? `(${slug})` : slug}</Description>
      </Column>
      {isMobile && (
        <>
          <Ending>
            {badgeNode}
            {!isOffline && <Description>APY</Description>}
          </Ending>
          {chevron && (
            <Chevron>
              <img alt="" src={chevronRight} />
            </Chevron>
          )}
        </>
      )}
    </Container>
  );
};

const Chevron = styled.div`
  margin: 0 8px 0 8px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.015em;
  color: #1e2427;

  @media (min-width: 1200px) {
    margin-top: 8px;
  }
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 2;
  letter-spacing: -0.015em;
  color: var(--color-monochrome--light-5);

  height: 1rem;
  line-height: 1.5;

  @media (min-width: 1200px) {
    text-align: center;
  }
`;

const Container = styled.a`
  background: var(--color--white);
  border: 1px solid var(--color-monochrome--light-2);
  box-shadow: 0px 10px 30px rgba(208, 216, 223, 0.25);
  border-radius: 8px;

  text-decoration: none;

  display: flex;
  flex-direction: column;
  width: 150px;
  height: 160px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    width: 100%;
    height: 78px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 0 12px;
  }
`;

const Image = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background-color: var(--color--white);

  img {
    width: 50px;
    height: 50px;
    padding: 2px;
    border-radius: 100%;
  }

  border: 1px solid var(--color-monochrome--light-2);

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    width: 30px;
    height: 30px;

    img {
      width: 28px;
      height: 28px;
    }
  }
`;

const Badge = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.71;
  text-align: center;
  letter-spacing: -0.015em;

  border-radius: 4px;

  height: 24px;

  @media (max-width: 1200px) {
    background: none !important;
    width: fit-content: !important;
    text-align: right;
    padding: 0 !important;
  }

  @media (min-width: 1200px) {
    position: relative;
    top: -8px;
  }
`;

const Profit = styled(Badge)`
  background: #e0fff7;
  width: 103px;
  color: var(--color--teal);
`;

const Offline = styled(Badge)`
  background: #fff9e1;
  padding: 0 8px;
  color: #ff8f33;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Ending = styled(Column)`
  margin-left: auto;
  text-align: right;
`;
