import React from 'react';
import { items } from './items';

import './styles.scss';

export const Info = () => {
  return (
    <div className="exchange-info-container">
      <div className="info-items-list">
        {items.map((item) => (
          <div className="info-item">
            <div className="title">{item.title}</div>
            <div className="description">{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
