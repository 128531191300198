import twitterPic from './img/twitter.svg';
import instagramPic from './img/instagram.svg';
import discordPic from './img/discord.svg';
import linkedinPic from './img/linkedin.svg';
import facebookPic from './img/facebook.svg';

export const socials = [
  {
    pic: twitterPic,
    link: 'https://twitter.com/mycointainercom'
  },
  {
    pic: instagramPic,
    link: 'https://www.instagram.com/mycointainer_com/'
  },
  {
    pic: discordPic,
    link: 'https://discord.com/invite/gNGu3ct'
  },
  {
    pic: linkedinPic,
    link: 'https://www.linkedin.com/company/mycointainer/'
  },
  {
    pic: facebookPic,
    link: 'https://www.facebook.com/mycointainer'
  }
];
