import translations from "./translations.json";

const t = (key, vars = {}) => {
  if (vars["truncated"]) {
    key += "_truncated";
  }
  let translated = translations[key] ?? key;
  for (let varName in vars) {
    translated = translated.replaceAll(`{${varName}}`, vars[varName]);
  }
  return translated;
};

export default t;
