import { Section } from "components/business/Section";
import { FC } from "react";
import {
  List,
  ListItem,
  ListItemTitle,
  ListItemDescription,
} from "components/business/List";
import activities from "./img/activities.svg";
import audience from "./img/audience.svg";
import results from "./img/results.svg";

export const Benefits: FC = () => {
  return (
    <Section
      id="benefits"
      title="Benefits"
      description="Spread the knowledge, earn trust of new customers and let them get familiar with your brand."
    >
      <List>
        <ListItem size={3}>
          <img width="48" height="48" alt="" src={audience} />
          <ListItemTitle>Engaged audience of 70 000 users</ListItemTitle>
          <ListItemDescription>
            From the very beginning, our goal was to provide mass audience with
            easy access to various crypto earning methods. They have become
            active and engaged participants that use these methods ever since.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img width="48" height="48" alt="" src={activities} />
          <ListItemTitle>Wide range of activities</ListItemTitle>
          <ListItemDescription>
            We’ll tell you exactly what to do step-by-step if you wish to run
            your own airdrop. Activities can include Twitter retweets, sign ups,
            Q&As, likes, follows and much more.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img width="48" height="48" alt="" src={results} />
          <ListItemTitle>Measurable results</ListItemTitle>
          <ListItemDescription>
            You decide what it takes for participants to get your perks. We
            provide more advanced analytics if you host your campaign within the
            MyCointainer platform.
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
