import reviewer1Pic from "./img/reviewer1.png";
import reviewer2Pic from "./img/reviewer2.png";
import reviewer3Pic from "./img/reviewer3.png";
import reviewer4Pic from "./img/reviewer4.png";
import reviewer5Pic from "./img/reviewer5.png";

const reviews = [
  {
    userName: "Luis E Cordova Esparza",
    image: reviewer1Pic,
    origin: "Trustpilot",
    title: "Its all too good to be true",
    description:
      "Its all too good to be true, i strongly recommend using mycointainer for easy stress free passive income",
  },
  {
    userName: "Wilfredo Rodriguez",
    image: reviewer2Pic,
    origin: "Trustpilot",
    title: "Excellent support job",
    description:
      "I've only recently been on the MyCointainer platform, and perhaps due to my inexperience I had difficulties. The support team was wonderful and solved the problem in less than 24 hours. Very satisfied with the platform and its work team. they have 5 stars.",
  },
  {
    userName: "Kevin Lange",
    image: reviewer3Pic,
    origin: "Trustpilot",
    title: "I have been with MyCointainer for 2+…",
    description:
      "I have been with MyCointainer for 2+ years and very seldom do they have any issues with their platform! The few times there was an issue it was fixed quickly and they are great with their communicating to you what the issue is & how they are solving it! Kudos to MyCointainer and their team of professionals!",
  },
  {
    userName: "Leevi Virta",
    image: reviewer4Pic,
    origin: "Trustpilot",
    title: "Cool & useful app for staking!",
    description:
      "Cool app that has multiple different functions, but the main one, staking, is what people come for!",
  },
  {
    userName: "Hai Muti",
    image: reviewer5Pic,
    origin: "Trustpilot",
    title: "Good Project",
    description: "This is excellent project for the future with strong team",
  },
];

export default reviews;
