import { useState, useEffect, useCallback } from "react";

export const useTimer = (timerFn: VoidFunction, duration: number) => {
  const [timer, setTimer] = useState(() =>
    window.setInterval(timerFn, duration)
  );

  const begin = () => {
    setTimer(window.setInterval(timerFn, duration));
  };

  const clear = () => {
    window.clearInterval(timer);
  };

  useEffect(() => {
    return () => {
      window.clearInterval(timer);
    };
  }, [timer]);

  return {
    begin,
    clear,
  };
};
