import './styles.scss';
import zen from './img/zen.png';
import polygon from './img/polygon.png';
import truelayer from './img/truelayer.png';
import thompson from './img/thompson.png';
import waves from './img/waves.png';
import crypto from './img/crypto.png';
import trusty from './img/trustly.png';
import cartesi from './img/cartesi.png';
import lion from './img/lion.png';
import cmc from './img/cmc.png';

export const PartnersSlider = () => {
  return (
    <div className="partners-slider-wrapper">
      <section className="partners">
        <h4 className="headerTitle">Working together with partners</h4>
        <p className="subHeaderTitle element-desktop">
          Partners from various industries trust us and help us to create a
          reliable ecosystem that benefits both individuals and businesses.
        </p>
        <div className="logos-section">
          <div className="img-wrap">
            <img src={zen} alt="Zen" style={{ maxWidth: '50%' }} />
            <p className="hidden-text">Financial Partner</p>
          </div>
          <div className="img-wrap">
            <img src={polygon} alt="Polygon" />
            <p className="hidden-text">Validator & Partner</p>
          </div>
          <div className="img-wrap">
            <img
              src={truelayer}
              alt="Truelayer"
              style={{ maxWidth: '175px' }}
            />
            <p className="hidden-text">Payment Partner</p>
          </div>
          <div className="img-wrap">
            <img src={thompson} alt="Thompson" style={{ maxWidth: '126px' }} />
            <p className="hidden-text">Legal & Auditing Partner</p>
          </div>
          <div className="img-wrap">
            <img src={waves} alt="Waves" style={{ maxWidth: '128px' }} />
            <p className="hidden-text">Blockchain Netowork Partner</p>
          </div>
          <div className="img-wrap">
            <img src={crypto} alt="Crypto" />
            <p className="hidden-text">Payment Partner</p>
          </div>
          <div className="img-wrap">
            <img src={trusty} alt="Trusty" />
            <p className="hidden-text">Payment Partner</p>
          </div>
          <div className="img-wrap">
            <img src={cartesi} alt="Cartesi" style={{ maxWidth: '174px' }} />
            <p className="hidden-text">Custodial Staking Partnership</p>
          </div>
          <div className="img-wrap">
            <img src={lion} alt="Lions" style={{ maxWidth: '30%' }} />
            <p className="hidden-text">Estonian Financial Intelligence Unit</p>
          </div>
          <div className="img-wrap">
            <img src={cmc} alt="Lions" style={{ maxWidth: '90%' }} />
            <p className="hidden-text">CoinMarketCap Partner</p>
          </div>
        </div>
      </section>
    </div>
  );
};
