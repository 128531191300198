import { FC } from "react";
import styled from "styled-components";
import { Footer } from "components/business/Footer";
import { NavigationTop } from "components/NavigationTop";
import { Header } from "components/business/Header";
import hero from "./img/hero.webp";
import { uris } from "const";
import { GetInTouch } from "components/business/GetInTouch";
import { GetStarted } from "./GetStarted";
import { Partners } from "components/business/Partners";
import { Info } from "./Info";
import { PartnersTicker } from "components/business/PartnersTicker";
import { Reviews } from "routes/Main/components/Reviews";

export const Marketing: FC = () => {
  return (
    <Container>
      <NavigationTop />
      <Header
        title="Marketing Campaigns"
        description="Spread the word about your business among the crypto community. Introduce your products and services to over 75,000 engaged crypto fans."
        action="Get a free quote"
        actionHref={uris.business.campaign}
        primaryAction="Explore possibilities"
        image={<img alt="" src={hero} width={672} height={496} />}
      />
      <Info />
      <PartnersTicker />
      <Reviews business />
      <Partners />
      <GetStarted />
      <GetInTouch
        title="Start your custom campaign"
        description="Grow with MyCointainer"
        action="Ask a question"
        actionHref={uris.business.askQuestion}
        primaryAction="Get a free quote"
        primaryActionHref={uris.business.campaign}
      />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
`;
