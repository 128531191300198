import { MouseEventHandler, useState } from "react";
import styled from "styled-components";
import alex from "./img/alex.jpg";
import bartosz from "./img/bartosz.png";
import ovidiu from "./img/ovidiu.png";
import close from "./img/close.svg";

const CEO_POPUP = {
  title: "Bartosz, CEO & Founder",
  description: `
    Bartosz has +15 years of experience in shaping global digital products for various industries and clients.  
    <br />
    <br />
    His experience spans UX design, creative design, as well as building financial services for banks and institutions.  
    <br />
    <br />
    Before even graduating from high school, Bartosz had already gained commercial experience in creating digital products. 
    Upon graduation, instead of following the steps of many, he decided to try his skills in the industry and moved to London at the age of 19.  
    <br />
    <br />
    In his early days in London, Bartosz worked in design and marketing agencies. For the next 3 years he worked for investment banks, such as Barclays & Deutsche Bank, trading companies like City Index, and fintech businesses like Rule Financial. At each company he was part of a product team responsible for software development. 
    <br />
    <br />
    In the recent years, he has gained even more experience in building successful products for an array of companies, from start-ups to +25 BLN USD valued corporations.      
  `,
};

export const Team = () => {
  const [isCeoPopupOpen, setIsCeoPopupOpen] = useState(false);

  const onPopupOpen = () => {
    setIsCeoPopupOpen(true);
  };

  const onPopupClose = () => {
    setIsCeoPopupOpen(false);
  };

  const onPaperClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {isCeoPopupOpen && (
        <PopupWrapper onClick={onPopupClose}>
          <Paper onClick={onPaperClick}>
            <PopupTitle>
              <h3>{CEO_POPUP.title}</h3>
              <img onClick={onPopupClose} alt="" src={close} />
            </PopupTitle>
            <p
              dangerouslySetInnerHTML={{
                __html: CEO_POPUP.description,
              }}
            />
          </Paper>
        </PopupWrapper>
      )}
      <Container>
        <section>
          <CeoAvatar onClick={onPopupOpen} alt="" src={bartosz} />
          <address>
            <p>Bartosz P.</p>
            <span>Founder & CEO</span>
          </address>
        </section>
        <section>
          <img alt="" src={alex} />
          <address>
            <p>Alex A.</p>
            <span>CTO</span>
          </address>
        </section>
        <section>
          <img alt="" src={ovidiu} />
          <address>
            <p>Ovidiu P.</p>
            <span>Head of DevOps</span>
          </address>
        </section>
      </Container>
    </>
  );
};

const PopupTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
`;

const Paper = styled.div`
  user-select: none;
  background: white;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  max-width: 584px;

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 152%;
  }

  p {
    font-size: 18px;
    line-height: 142%;
    color: #697386;
  }
`;

const PopupWrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.71);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CeoAvatar = styled.img`
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.3);
  }
`;

const Container = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin-top: 56px;

  user-select: none;

  section {
    overflow: hidden;
    width: 385px;
    height: 420px;
    border-radius: 6px;
  }

  img {
    border: 1px solid #e6e6e6;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    width: 385px;
    height: 420px;
  }

  address {
    margin: -68px 0 0 16px;
    position: absolute;
    font-style: normal;
    font-size: 21px;
    display: flex;
    flex-direction: column;

    p {
      font-weight: 500;
      font-size: 21px;
      line-height: 142%;
      color: white;
    }

    span {
      font-size: 18px;
      color: rgb(138, 138, 138);
    }
  }

  @media (max-width: 1200px) {
    margin-top: 40px;
  }

  @media (min-width: 1220px) {
    width: 1220px;
  }
`;
