import styled from "styled-components";

export const Button = styled((props) => {
  if (props.role === "link") {
    return <a {...props} />;
  }

  return <button {...props} />;
})`
  width: 176px;
  height: 50px;
  border-radius: 6px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  background-color: ${(props) =>
    props.color === "primary" ? "var(--color--primary)" : "var(--color--teal)"};

  text-decoration: none;

  color: var(--color--white);
  font-weight: 500;
  font-size: 15px;
  line-height: 1.3;

  &:hover,
  &:active {
    background-color: ${(props) =>
      props.color === "primary"
        ? "var(--color--primary-dark)"
        : "var(--color--teal-dark)"};
  }

  @media (max-width: 1024px) {
    margin: 0 auto;
    width: 140px;
  }
`;
