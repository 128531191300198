import { FC } from "react";
import styled from "styled-components";
import { StandardLayout } from "components/StandardLayout";
import { TermsContent } from "./terms-content";

export const Terms: FC = () => {
  return (
    <StandardLayout>
      <Container>
        <TermsContent />
      </Container>
    </StandardLayout>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 140px auto 0;
  max-width: 1200px;
  padding: 40px 24px 80px;
  position: relative;
`;
