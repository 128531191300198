import PropTypes from "prop-types";

import styled from "styled-components";

export const Title = styled.div`
  font-weight: 700;
  font-size: 34px;
  line-height: 1.41;
  text-align: center;
  letter-spacing: -0.015em;
  color: var(--color--black);

  margin-bottom: 48px;

  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 24px;
    padding: 0 16px;
    text-align: ${({ titleAlign }) => titleAlign}
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 149px;

  @media (max-width: 1024px) {
    margin-top: 80px;
  }
`;

export const Description = styled.div`
  font-size: 1.25rem;
  line-height: 1.4;
  color: var(--color--secondary);
  text-align: center;

  margin: -40px 0 48px;

  @media (max-width: 1024px) {
    font-size: 1rem;
    line-height: 1.5;
    padding: 0 16px;
    margin: -16px 0 32px;

    br {
      display: none;
    }
  }
`;

export const Section = (props) => {
  const { title, description, children, style, titleAlign } = props;

  return (
    <Container style={style}>
      {typeof title !== 'string' ? title : <Title titleAlign={titleAlign}>{title}</Title>}
      {description && (
        <Description
          {...(typeof description === "string"
            ? {
                dangerouslySetInnerHTML: {
                  __html: description,
                },
              }
            : {
                children: description,
              })}
        />
      )}
      {children}
    </Container>
  );
};

Section.propTypes = {
  title: PropTypes.node,
  style: PropTypes.object,
  children: PropTypes.node,
  description: PropTypes.node,
};
