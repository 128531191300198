import { FC } from "react";
import styled from "styled-components";
import arrowRight from "./img/arrowRight.svg";
import benefit from "./img/benefit.svg";

export const Benefits: FC = () => {
  return (
    <List>
      <ListItem href="/careers">
        <img width="36" height="40" alt="" src={benefit} />
        <h3>Careers</h3>
        <p>
          We always look forward to work with talented individuals. Send us your
          CV or a link to your portfolio and we will get back to you as soon as
          we can to find out if we match.
        </p>
      </ListItem>
      <ListItem href="https://docs.google.com/forms/u/1/d/e/1FAIpQLSd1NHRRnZE4v6HNDyDY5A1Htc74grreZUiy8O3S7tpqD_xh3w/viewform?usp=send_form">
        <img width="36" height="40" alt="" src={benefit} />
        <h3>Investors</h3>
        <p>
          As we're exploring a capital aqusition, we'd love to hear from various
          investors; from private to venture capitals that are looking to invest
          in MyCointainer.
        </p>
      </ListItem>
      <ListItem href="/business">
        <img width="36" height="40" alt="" src={benefit} />
        <h3>Businesses</h3>
        <p>
          We are always open for any conversation with businesses and platforms
          both crypto and non-crypto related. Are you interested in our
          services? We'd love to hear from you.
        </p>
      </ListItem>
      <ListItem href="/coin-developers">
        <img width="36" height="40" alt="" src={benefit} />
        <h3>Coin developers</h3>
        <p>
          Reach out to us if you'd like to boost your project with marketing
          campaigns, listings, or by integrating your crypto protocols to
          mutually benefit your coins and our audience.
        </p>
      </ListItem>
    </List>
  );
};

const ListItem = styled.a`
  background: #ffffff;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;
  flex-basis: 50%;
  max-width: 584px;
  height: 302px;
  padding: 64px 48px;

  img,
  h3 {
    transition: all 150ms ease 0s;
  }

  &:hover {
    img {
      margin-left: 8px;
    }

    h3 {
      padding-right: 40px;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 152%;
    background-image: url(${arrowRight});
    background-repeat: no-repeat;
    padding-right: 32px;
    width: fit-content;
    background-position: 100% 8px;
    color: black;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 142%;
    color: #697386;
  }

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    box-shadow: rgb(0 0 0 / 11%) 0px 25px 40px;

    &:hover {
      background-color: rgb(248, 252, 254);
    }
  }
`;

const List = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  width: calc(100% + 32px);
  margin-top: 56px;

  @media (max-width: 1200px) {
    margin-top: 40px;
  }

  @media (max-width: 998px) {
    width: calc(100% + 48px);
    margin-left: -24px;
    gap: 0;
  }
`;
