import styled from "styled-components";
import { Helmet } from 'react-helmet'
import { StandardLayout } from "components/StandardLayout";
import { Container } from "components/Container";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Section } from "components/Section";
import { Questions } from "components/Questions";
import { Banner } from "./Banner";
import { Assets } from "./Assets";

import t from "i18n";

import {
  iconColdStaking,
  iconExchange,
  iconStaking,
  imageAssetsList,
} from "assets";

import { URLS } from "const";

import { useIsMobile } from "hooks/useIsMobile";

const TitleWrapper = styled.h1`
  color: var(--color--black);
  @media (min-width: 1024px) {
    width: 501px;
  }
`;

const ImageWrapper = styled.div`
  z-index: 2;

  @media (min-width: 1024px) {
    width: min(50%, 700px);

    img {
      width: 700px;
    }
  }
`;

const IconButton = styled.a`
  background: var(--color--white);
  width: 50px;
  height: 50px;

  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;

  cursor: pointer;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.23;
    color: var(--color--black);
    white-space: nowrap;
    margin-left: 8px;
    padding: 0 16px 0 8px;
  }

  img {
    width: 32px;
    height: 32px;
  }

  p {
    display: none;
  }

  &:hover {
    width: fit-content;

    img {
      margin-left: 8px;
    }

    p {
      display: block;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const AssetsListTitleWrapper = styled.div`
  @media (max-width: 1024px) {
    text-align: left;
    padding: 0 16px;
  }
`;

const AssetsList = () => {
  const isMobile = useIsMobile();

  return (
    <StandardLayout>
      <Helmet>
        <title>
          {t("meta.title.assetsList")}
        </title>
        <meta name="description" content={t("meta.description.assetsList")} />
      </Helmet>
      <Container>
        <Header
          title={
            <TitleWrapper
              dangerouslySetInnerHTML={{
                __html: t("assets.title"),
              }}
            />
          }
          image={
            <ImageWrapper>
              <img alt="" src={imageAssetsList} />
            </ImageWrapper>
          }
          actions={
            <>
              <IconButton href={URLS.LANDINGS.STAKING}>
                <img alt="" src={iconStaking} />
                <p>{t("assets.heading.staking")}</p>
              </IconButton>
              <IconButton href={URLS.LANDINGS.COLD_STAKING}>
                <img alt="" src={iconColdStaking} />
                <p>{t("assets.heading.staking.cold")}</p>
              </IconButton>
              <IconButton href={URLS.LANDINGS.EXCHANGE}>
                <img alt="" src={iconExchange} />
                <p>{t("assets.heading.exchange")}</p>
              </IconButton>
            </>
          }
          description={t("assets.description")}
          actionText={t("assets.actionText")}
          hideAction={!isMobile}
        />
        <Section
          titleAlign='left'
          title={t("assets.main.title")}
        >
          <Assets />
        </Section>
        {!isMobile && (
          <Section>
            <Banner />
          </Section>
        )}
        <Section title={t("common.faq")}>
          <Questions
            questions={[
              {
                title: t("assets.q.howToEarn"),
                description: t("assets.q.howToEarn.description"),
              },
              {
                title: t("assets.q.howToUseMyCointainer"),
                description: t("assets.q.howToUseMyCointainer.description"),
              },
              {
                title: t("assets.q.howToCalculateProfit"),
                description: t("assets.q.howToCalculateProfit.description"),
              },
              {
                title: t("assets.q.whatIsBesideStaking"),
                description: t("assets.q.whatIsBesideStaking.description"),
              },
              {
                title: t("assets.q.costOfMyCointainer"),
                description: t("assets.q.costOfMyCointainer.description"),
              },
              {
                title: t("assets.q.isMyCointainerSafe"),
                description: t("assets.q.isMyCointainerSafe.description"),
              },
            ]}
          />
        </Section>
        <Footer />
      </Container>
    </StandardLayout>
  );
};

export default AssetsList;
