import { useCallback, useEffect, useState } from "react";

const PLATFORM_CURRENCIES  = {
  EUR: "EUR",
  BTC: "BTC"
}

const eurAsset = {
  name: "Euro",
  code: "EUR",
  yearlyReward: 0,
  icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAL1SURBVHgBrVZNTBNBFP5mZ4GCgBXUKMRYxIRCq0JSohdiMZHEEz141IQe4WIFoxcj5egBAxc9wsGDN/Gk8QAkXkxs0kZaLAe0GtuLCa00/NXurm82bVNhdynIlzTdeW/m+2bmvTczDPvAEQ7YG2q4V1PUbpVJdmGTNDXDuBTJ7iiLiZ6pjNV4ZuZwLwW84HwcGrywZpiDgunopclFY7fBjOur+Qx9+nAAaMCskpMn4j1PE6YCzvAjB6/OL5DRgUOARBIk0l8uwo6K3ExEKjqOglxAcMg1+ZliWxfoio4OVULeUnUCnrp2dNS0WHekxHAtjwYKgoArNvbNSuBOcx9GTg2gQaot2VJ/1jCXCeHFr/dmwzJyTmnjYvYSY0NmvR6eGdTJc6qCt+thzGdjOnlrVTNuNLqRyq1hZSdlNNSWr+YfZQbmNSMftHtwt6mPSNLwf39OxOmSr4HXYvC4B29+h8yGgymqT2aMXdFjX4YOWwuctlaa+U29PZ+NovfYxZI/vp3EynYKL9c+wBrMKxN5925zqyyCeYGC2oSssoV6btPbRWTzm1hBCvuB4mqXjRzJfBqhza/w2Xv1tvje7a8QJKBpGTBmL7c6KQ3F/orZi70eOTmAJAW2BE3Tt6gCZJg79iBstE0CInuG6Te/HsW9n7N7/CJWVkIU2Qg/PXy1k1ZwzahDnNLvVmMPLtedpyC3I5lLF4hbdfEnZ2/rq/y89cNM4x1zL415qZ4XzHqI6p0+56es2lu9nzZX8Tj56p/0LYfKma9QyaNUycwBC/TSEeGhVTRKNqyr2whtrOoCZtCgJWKuZ216FlHMJhjDjJWAILMi3AOJBcVf6bh2x8Ze44CXjBnE5RNzTfp1naKRDia/WBb+E4JjI6fcL7ZLAhG6vBVJ7RefOCREWgqO8oeA4aVPmRUk6XFUzEzFqjFx8Qd3u0xfFc4vAQdXeBBMu26aYQXivKzMxjunEkZdTAXK0RUNdHOVO+hBVDpSFKiRZffUvtv5F5xDJxNcQvpQAAAAAElFTkSuQmCC",
  isHotStaking: false,
  hotStakingMessage: "",
  coldStaking: {
    show: false,
  },
  instruction: null,
  key: "eur",
  exchangeAvailable: true,
  isBuyableBy: [],
  sellVia: null,
};

const useSellableCoins = () => {
  const [state, setState] = useState({
    loading: false,
    coins: null,
    error: null
  })

  const requestCoins = useCallback(async() => {
    setState({
      ...state,
      loading: true
    });

    try {
      const strapiCoins = await fetch(
        "https://myc-cms-test01.herokuapp.com/assets/?_limit=-1"
      ).then((res) => res.json());

      const { data } = await fetch(
        "https://www.mycointainer.com/api/landing/coins/"
      ).then((res) => res.json());

      const items = Object.values(data).map((coinFromAPI) => {
        const strapiCoin = strapiCoins.find(
          (i) => i.tickerCode.toLowerCase() === coinFromAPI.code.toLowerCase()
        );

        if (strapiCoin) {
          return {
            name: coinFromAPI.name,
            code: coinFromAPI.code,
            yearlyReward: strapiCoin?.coinOverview?.yearlyReturn?.value,
            icon: strapiCoin?.coinOverview?.image?.url,
            isHotStaking: coinFromAPI.isHotStaking,
            hotStakingMessage: coinFromAPI.hotStakingMessage,
            coldStaking: coinFromAPI.coldStaking,
            instruction: null,
            key: coinFromAPI.assetKey,
            strapiName: strapiCoin.coinName,
            exchangeAvailable: coinFromAPI.exchangeAvailable,
            sellVia: coinFromAPI.sellVia,
            isBuyableBy: coinFromAPI.isBuyableBy,
            price: coinFromAPI.price,
          };
        }

        return undefined;
      })

      const listOfCoins = [eurAsset, ...items]
        .filter(Boolean)
        .filter((c) => c.exchangeAvailable)
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0))
        .sort(function (a, b) {
          return a.code === PLATFORM_CURRENCIES.EUR ? -1 : b.code === PLATFORM_CURRENCIES.EUR ? 1 : 0;
        })
        .sort(function (a, b) {
          return a.code === PLATFORM_CURRENCIES.BTC ? -1 : b.code === PLATFORM_CURRENCIES.BTC ? 1 : 0;
        });


      setState({
          loading: false,
          coins: listOfCoins,
          error: null
        })

    } catch (err) {
      setState({
        loading: false,
        coins: [],
        error: err.message
      })
    }

  },[])

  useEffect(() => {
    requestCoins()
  }, [])


  return state
}

export default useSellableCoins
