import React, { useCallback, useEffect, useState } from "react";
import "./NotFound.style.scss";
import { chevron, notFound } from "assets";
import { Button } from "components/Button";
import styled from "styled-components";
import useMedia from "hooks/useMedia";
import { useOnResize } from "hooks/useOnResize";

const StyledButton = styled(Button)`
  width: 171px;
  height: 50px;

  transition: background-color 200ms;
  background-color: var(--color--primary);

  &:hover,
  &:active {
    background-color: var(--color--primary-dark);
    color: var(--color--white);
    border-color: var(--color--primary-dark);
  }
`;

const getHeight = () => {
  return document.documentElement.clientHeight;
};

const NotFound: React.FC = () => {
  const [size, setSize] = useState(0);

  const isCompact = useMedia("(max-width: 768px)");
  const handleResize = useCallback(() => setSize(getHeight()), [setSize]);
  useEffect(handleResize, [handleResize, isCompact]);
  useOnResize(handleResize);

  return (
    <>
      <div className="not-found-page" style={{ height: `${size}px` }}>
        <div>
          <div className="empty-state">
            <div className="empty-state__icon">
              <img src={notFound} alt={""} />
            </div>
            <div className="empty-state__title">
              Oops, this page doesn't exist.
            </div>
          </div>

          <div className="description">
            Sorry, but the page you are looking for cannot be found.
          </div>

          <div className="btn-wrapper">
            <StyledButton href={"/"} role="link">
              <img className={"btn-image"} alt="" src={chevron} />
              {"Go to Home"}
            </StyledButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
