import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import './styles.scss';
import { ReactComponent as ArrowDown } from './img/arrow-down.svg';

export const ExchangeFrom = ({
  isRequesting,
  sellableCoins,
  setSellAmount,
  sellAmount,
  exchangeCoinFrom,
  setExchangeCoinFrom,
}) => {
  const [isSellMenuOpened, setIsSellMenuOpened] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const selectRef = useRef(null);

  const setExchangeFrom = (coin) => {
    setExchangeCoinFrom(coin);
    setIsSellMenuOpened(false);
    setSellAmount(null);
  };

  const setAmount = (amount) => {
    setSellAmount(amount);
  };

  const validate = (s) => {
    var rgx = /^[0-9]*\.?[0-9]*$/;

    return s.match(rgx);
  };

  const outsideClick = (event) => {
    if (!event.composedPath().includes(selectRef.current)) {
      setIsSellMenuOpened(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', outsideClick);

    return () => {
      document.body.removeEventListener('click', outsideClick);
    };
  }, []);

  return (
    <div className="exchange-coin" ref={selectRef}>
      <label
        for=""
        className="exchange-coin-label exchange-coin-label-separate"
      >
        From
      </label>
      <div className="input-container">
        <div
          className={cx({
            'select-dd': true,
            active: isSellMenuOpened
          })}
        >
          <div
            rel="noreferrer"
            onClick={() => setIsSellMenuOpened(!isSellMenuOpened)}
            className="coin-select"
          >
            <div className="coin-select-input-left-section">
              <div hidden={!exchangeCoinFrom} className="coin-image-container">
                <img
                  className="coin-image-main"
                  src={exchangeCoinFrom?.icon}
                  alt={exchangeCoinFrom?.name}
                />
              </div>
              {exchangeCoinFrom ? (
                <>
                  <div className="coin-info coin-info-desk">
                    <h4>{exchangeCoinFrom?.name}</h4>
                    <span>{exchangeCoinFrom?.code}</span>
                  </div>
                  <div className="coin-info coin-info-mobile">
                    <h4>{exchangeCoinFrom?.code}</h4>
                  </div>
                </>
              ) : null}
              <div className="coin-loading-wrapper" hidden={!!exchangeCoinFrom}>
                {isRequesting ? 'Loading...' : 'Select currency'}
              </div>
            </div>
            <span className="arrow-down">
              <ArrowDown />
            </span>
          </div>
          <ul>
            {sellableCoins?.length <= 4 ? null : sellableCoins?.length ? (
              <li>
                <div className="search-wrap">
                  <input
                    type="text"
                    value={searchInput}
                    placeholder="Select currency"
                    disabled={!sellableCoins.length}
                    onChange={(e) =>
                      setSearchInput(e.target.value.toLowerCase())
                    }
                  />
                  <button type="button"></button>
                </div>
              </li>
            ) : null}
            <div className="exchange-coin-dropdown">
              {isRequesting ? (
                <li className="exchange-coin-dropdown--noresult">
                  Loading...
                </li>
              ): null}
              {sellableCoins?.filter(
                (coin) =>
                  coin.name.toLowerCase().includes(searchInput) ||
                  coin.code.toLowerCase().includes(searchInput)
              ).length === 0 ? (
                isRequesting && !sellableCoins?.length ? (
                  <li className="exchange-coin-dropdown--noresult deposit">
                    No results found
                    <a href="/app/deposit/cash" className="deposit-btn">
                      Deposit
                    </a>
                  </li>
                ) : (
                  <li className="exchange-coin-dropdown--noresult">
                    No results found
                  </li>
                )
              ) : null}
              {sellableCoins
                ?.filter(
                  (coin) =>
                    coin.name.toLowerCase().includes(searchInput) ||
                    coin.code.toLowerCase().includes(searchInput)
                )
                .map((coin) => (
                  <li
                    onClick={() => {
                      setExchangeFrom(coin);
                    }}
                  >
                    <div className="icon-holder">
                      <img src={coin.icon} alt={coin.name} />
                    </div>
                    <div className="coin-info">
                      <h4>{coin.name}</h4>
                      <span>{coin.code}</span>
                    </div>
                    <div className="counter-wrap">
                      <span>{coin.balance}</span>
                    </div>
                  </li>
                ))}
            </div>
          </ul>
        </div>
        <div className="from-to-divider"></div>
        <div className="resulting-container">
          <div className="values-holder">
            <input
              id="sell"
              type="text"
              value={sellAmount ?? ''}
              disabled={!sellableCoins?.length}
              onChange={(e) => {
                if (!validate(e.target.value)) {
                  e.target.value = e.target.value.slice(
                    0,
                    e.target.value.length - 1
                  );

                  return false;
                }

                setAmount(e.target.value);
              }}
              className="value dark"
              placeholder="0.0000"
            />

            <span
              hidden={exchangeCoinFrom}
              className="coin-max"
              onClick={() => setAmount(exchangeCoinFrom?.balanceFull)}
            >
              Max
            </span>
            <div className="value-naming">
              <p className="mobile-hide">{exchangeCoinFrom?.code}</p>
              <span
                className="mobile-hide"
                onClick={() => setAmount(exchangeCoinFrom?.balanceFull)}
              >
                {exchangeCoinFrom?.balance}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div hidden={exchangeCoinFrom} className="available">
        Available: {exchangeCoinFrom?.balance}
      </div>
    </div>
  );
};
