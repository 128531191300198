import React from "react";

import {
  PERSONAL_LINKS,
  BUSINESS_LINKS,
  DEVELOPERS_LINKS,
} from "./data-storage.js";
import logo from "./img/logo.svg";
import { ReactComponent as IconArrow } from "./img/icon-arrow.svg";
import { ReactComponent as IconAppStore } from "./img/appStore.svg";
import { ReactComponent as IconPlayMarket } from "./img/playMarket.svg";
import { ReactComponent as ArrowRight } from "./img/arrowRight.svg";
import logoClassic from "components/business/img/logoWhite.svg";
import "./styles.scss";
import { QrPopup } from "../../../QrPopup.js";
import { IS_MOBILE } from "const.js";
import cn from "classnames";
const __ = (a) => a;

export class NavigationTop extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      mobMenuActive: false,
      scrollAndShadow: false,
      scrollPosition: 0,
      subItemsToggle: {},
      isQrPopupOpen: false,
    };
    this.ref = React.createRef();
    this.onMouseOnQrCode = this.onMouseOnQrCode.bind(this);
    this.onMouseOutQrCode = this.onMouseOutQrCode.bind(this);
  }
  scrollHandle = () => {
    if (window.pageYOffset > (IS_MOBILE ? 36 : 138)) {
      this.setState({
        scrollAndShadow: true,
      });
    } else {
      this.setState({
        scrollAndShadow: false,
      });
    }
  };

  onMouseOnQrCode() {
    this.setState({
      isQrPopupOpen: true,
    });
  }

  onMouseOutQrCode() {
    this.setState({
      isQrPopupOpen: false,
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollHandle);
    document.body.addEventListener("click", this.outsideClick);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandle);
    document.body.removeEventListener("click", this.outsideClick);
  }
  mobileMenuOpenerHandler = () => {
    this.setState({
      mobMenuActive: !this.state.mobMenuActive,
      scrollPosition: document.scrollingElement.scrollTop,
    });
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    document.scrollingElement.scrollTop = 0;
    document.body.style.overflowY = "hidden";
  };
  mobileCloseMenuHandler = () => {
    this.setState({
      mobMenuActive: false,
    });
    document.body.style.backgroundColor = "transparent";
    document.body.style.overflowY = "auto";
    document.scrollingElement.scrollTop = this.state.scrollPosition;
  };

  outsideClick = (e) => {
    let paths = e.composedPath();
    this.outsideClickSubMenu(paths);
    this.outsideClickMobileMenu(paths);
  };
  outsideClickSubMenu = (paths) => {
    let found = false;
    paths.forEach((el) => {
      let elem = el;
      if (elem.classList && elem.classList.contains("hdr-nav_item-title")) {
        found = true;
      }
    });
    if (found) {
      return;
    }
    let subItemsToggle = this.state.subItemsToggle;
    for (let k in subItemsToggle) {
      subItemsToggle[k] = false;
    }
    this.setState({
      subItemsToggle,
    });
  };
  outsideClickMobileMenu = (paths) => {
    let found = false;
    let qrFound = false;

    paths.forEach((el) => {
      let elem = el;
      if (found && qrFound) {
        return;
      }
      if (elem.classList?.contains("apps")) qrFound = true;
      if (
        elem.classList &&
        (elem.classList.contains("hdr-nav_items") ||
          elem.classList.contains("hdr_mobile-opener") ||
          elem.classList.contains("hdr_mobile-closer"))
      ) {
        found = true;
      }
    });
    if (!qrFound && this.state.isQrPopupOpen) {
      this.setState({
        isQrPopupOpen: false,
      });
    }
    if (!found && this.state.mobMenuActive) {
      this.mobileCloseMenuHandler();
    }
  };
  renderLoginButtons = () => {
    const isCompany = location.href.includes("/company");

    return (
      <React.Fragment>
        <a
          href="/account/login/"
          router-ignore="true"
          className={cn({
            login: true,
            classic: isCompany,
          })}
        >
          {__("Log In")}
        </a>
        <a
          href="/signup/"
          router-ignore="true"
          className={cn({
            btn: true,
            dark: true,
            classic: isCompany,
          })}
        >
          {__("Get Started")}
        </a>
        {!isCompany && (
          <div
            className="apps"
            ref={this.ref}
            onMouseEnter={this.onMouseOnQrCode}
            onMouseLeave={this.onMouseOutQrCode}
          >
            <IconPlayMarket />
            <IconAppStore />
            {this.state.isQrPopupOpen && this.ref.current && (
              <QrPopup
                refBoundingRect={this.ref.current?.getBoundingClientRect()}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  };
  renderListItems = (type) => {
    let listData = PERSONAL_LINKS;

    if (type === "business") {
      listData = BUSINESS_LINKS;
    } else if (type === "developers") {
      listData = DEVELOPERS_LINKS;
    }

    const openHeaderItem = (item) => {
      if (!item.link) return;

      window.open(item.link, "_self");
    };

    return listData.map((listItem) => {
      return (
        <li
          className={`hdr-nav_item ${
            this.state.subItemsToggle[listItem.id] ? "active" : ""
          }`}
          id={listItem.id}
          key={listItem.id}
          onClick={() => openHeaderItem(listItem)}
        >
          <button
            className="hdr-nav_item-title"
            aria-expanded={this.state.subItemsToggle[listItem.id]}
            onClick={() => {
              this.openMenu(listItem);
            }}
          >
            {listItem.title}
            {listItem.subItems && <IconArrow></IconArrow>}
          </button>
          {listItem.subItems && (
            <div className="hdr-nav-item-content-wrapper">
              <div
                hidden={!this.state.subItemsToggle[listItem.id]}
                className={`hdr-nav_item-content ${
                  !!listItem.contentSize ? listItem.contentSize : ""
                } ${
                  this.state.subItemsToggle[listItem.id] ? "shown" : "hidden"
                }`}
                id={`${listItem.id}-content`}
              >
                {listItem.subItems
                  ? this.renderListSubItems(listItem.subItems)
                  : null}
              </div>
            </div>
          )}
        </li>
      );
    });
  };

  renderListSubItems = (listData) => {
    return listData.map((listItem) => {
      return (
        <div className="item" key={listItem.link}>
          <a
            href={listItem.link}
            router-ignore={(!!listItem.noRoute).toString()}
          >
            <div className="image">
              <img src={listItem.icon} alt={listItem.alt} />
            </div>
            <div className="linkText">
              <div className="title">
                {listItem.title} <ArrowRight />
              </div>
              {listItem.subtitle && (
                <div className="subtitle">{listItem.subtitle}</div>
              )}
            </div>
          </a>
        </div>
      );
    });
  };
  openMenu = (listItem) => {
    if (!this.state.mobMenuActive) return;

    const subItemsToggle = this.state.subItemsToggle;
    for (let k in subItemsToggle) {
      if (k !== listItem.id) {
        subItemsToggle[k] = false;
      }
    }
    subItemsToggle[listItem.id] = !subItemsToggle[listItem.id];
    this.setState({
      subItemsToggle,
    });
  };
  mobile = () => {
    return (
      <div className="hdr mobile">
        <a href="/" router-ignore="true" className="hdr_logo">
          <img src={logo} alt="logo" />
        </a>
        <div
          className="apps"
          onClick={() => window.open("https://app.mycointainer.com/", "_self")}
          ref={this.ref}
        >
          <IconPlayMarket />
          <IconAppStore />
          {this.state.isQrPopupOpen && this.ref.current && (
            <QrPopup
              refBoundingRect={this.ref.current?.getBoundingClientRect()}
            />
          )}
        </div>
        <div
          className="hdr_mobile-opener"
          onClick={this.mobileMenuOpenerHandler}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };

  render() {
    const isCompany = location.href.includes("/company");

    return (
      <nav
        className={cn("navigation-header", { "scroll-and-shadow": this.state.scrollAndShadow })}
      >
        <div
          className={`hdr desktop ${this.state.mobMenuActive ? "active" : ""}`}
        >
          <div
            className={`hdr-top ${isCompany ? "classic" : ""} ${
              this.props.type === "business" ? "dark" : ""
            }
            ${this.props.type === "developers" ? "pink" : ""}`}
          >
            <div className="container">
              <nav className="hdr-nav">
                <ul className="hdr-nav_items">
                  <li
                    className={`hdr-nav_item ${
                      this.props.type === "individuals" ? "active" : ""
                    }`}
                    id="individuals"
                  >
                    <a href="/" router-ignore="true">
                      {__("PERSONAL")}
                    </a>
                  </li>
                  <li
                    className={`hdr-nav_item ${
                      this.props.type === "business" ? "active" : ""
                    }`}
                    id="business"
                  >
                    <a href="/business" router-ignore="true">
                      {__("BUSINESS")}
                    </a>
                  </li>
                  <li
                    className={`hdr-nav_item ${
                      this.props.type === "developers" ? "active" : ""
                    }`}
                    id="developers"
                  >
                    <a href="/en/developers" router-ignore="true">
                      {__("COIN DEVELOPERS")}
                    </a>
                  </li>
                </ul>
              </nav>
              <div
                className="hdr_mobile-closer"
                onClick={this.mobileCloseMenuHandler}
              >
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          <hr className="hide-lg" />
          <div className="hdr-bottom">
            <div className="container">
              <div className="left">
                <a href="/" router-ignore="true" className="hdr_logo hide-xs">
                  <img
                    src={isCompany ? logoClassic : logo}
                    alt="Logo MyCointainer"
                  />
                </a>
              </div>
              <nav className="hdr-nav">
                <ul className="hdr-nav_items">
                  {this.renderListItems(this.props.type)}
                </ul>
              </nav>
              <div className="hdr_entry-buttons">
                {this.renderLoginButtons(this.props.type)}
              </div>
            </div>
          </div>
        </div>
        {this.mobile()}
      </nav>
    );
  }
}
