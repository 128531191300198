import { Header } from "components/business/Header";
import { NavigationTop } from "components/NavigationTop";
import { FC } from "react";
import { Benefits } from "./Benefits";
import styled from "styled-components";
import imageHeader from "./img/header-image.webp";
import { Footer } from "components/business/Footer";
import { Reviews } from "routes/Main/components/Reviews";
import { PastGiveaways } from "./PastGiveaways";
import { GetInTouch } from "components/business/GetInTouch";
import { uris } from "const";

export const Giveaways: FC = () => {
  const onClick = () => {
    document.querySelector("#benefits")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <NavigationTop />
      <Header
        title="Giveaways<br /> & Airdrops"
        description="Promote your products by providing value to new customers. They can earn attractive perks by completing various tasks."
        image={<img alt="" src={imageHeader} width={600} height={566} />}
        action="See all giveaways"
        onClick={onClick}
      />
      <Benefits />
      <Reviews business />
      <PastGiveaways />
      <GetInTouch
        title="Create your giveaway"
        description="Grow with MyCointainer"
        action="Ask a question"
        actionHref={uris.business.askQuestion}
        primaryAction="Get in touch"
        primaryActionHref="https://forms.gle/repLmbJx3JdHVZAt6"
      />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
`;
