// Personal
// Earning Widgets
import crypto from "./img/crypto.svg";
import exchange from "./img/exchange.svg";
import airdrops from "./img/airdrops.svg";
import coldstaking from "./img/coldstaking.svg";
import cashback from "./img/cashback.svg";
import mining from "./img/mining.svg";
// Support & Help
import helpDesk from "./img/helpDesk.svg";
import contactUs from "./img/contactUs.svg";
import sendFeedback from "./img/sendfeedback.svg";
// More
// import community from './img/community.svg';
import power from "./img/power.svg";
import guidesNews from "./img/guidesandnews.svg";
import aboutUs from "./img/aboutus.svg";
import companyUpdates from "./img/companyupdates.svg";
import contact from "./img/contact.svg";
// Business
// Solutions
import nodeDeployments from "./img/nodedeployments.svg";
import cryptoWallets from "./img/cryptowallets.svg";
// Services
import stakingNodes from "./img/stakingnodes.svg";
import airdropsBlack from "./img/airdropsblack.svg";
import marketingCampaigns from "./img/marketingcampaigns.svg";
// Developers
// Solutions
import exchangeDev from "./img/exchangedev.svg";
import stakingManingDev from "./img/stakingmaningdev.svg";
import airdropsDev from "./img/airdropsdev.svg";
import nodeDeploymentsDev from "./img/nodedeploymentsdev.svg";
// Services
import nodeAndValidator from "./img/nodeandvalidator.svg";
import coinListing from "./img/coinlisting.svg";
import { URLS } from "const";
// TODO: Localization
const __ = (a) => a;
const localizeLink = (a) => a;

const linkColdStakingPersonal = "/cold-staking";
const linkCashbackPersonal = "/cashback";

// const linkSendFeedbackPersonal =
//   'https://surveys.hotjar.com/56e6b8c2-e05f-4211-b1b2-670ff21d1fce';
// More
// const linkCommunityPersonal = '/community/';
// const linkPowerSubscriptionPersonal = '/power/';
const linkGuidesNewsPersonal = "/insight";
const linkAboutUsPersonal = "/company";
const linkCompanyUpdatesPersonal = "https://company.mycointainer.com/";
// const linkContactPersonal = '/individuals/contact';

// Business
// Solutions
const linkNodeDeploymentsBusiness = "/business/nodes-deployment";
const linkCryptoWalletsBusiness = "/business/crypto-wallets";
// Services
const linkStakingNodesBusiness = "/business/running-staking-nodes";
const linkAirdropsGiveawaysBusiness =
  "/business/giveaways-and-airdrops-business";
const linkMarketingCampaignsBusiness = "/business/marketing-campaigns-business";
const linkPowerCodesBusiness = "/business/power-codes-business";
// Assets
// Support & Help
const linkHelpCenterBusiness =
  "https://desk.zoho.eu/portal/mycointainerbusiness/en/home";
const linkSendFeedbackBusiness =
  "https://surveys.hotjar.com/3d6435be-f10c-46fe-bb53-fe152c4de400";
// const linkContactUs = '/';
// const linkHelpDesk = '/';
// More
const linkAboutUsBusiness = "/business/company";
const linkCompanyUpdatesBusiness = "https://company.mycointainer.com/";
const linkContactBusiness = "/business/contact";

// Developers
// Solutions
const linkExchangeDevs = "/developers/exchange";
const linkStakingMiningDevs = "/developers/staking-and-mining";
const linkAirdropsGiveawaysDevs =
  "/developers/giveaways-and-airdrops-developers";
const linkNodeDeploymentsDevs = "/developers/node-deployment";
// Services
const linkCoinListingDevs = "/developers/coin-listing";
const linkMarketingCampaignsDevs = "/developers/marketing-campaigns-developers";
const linkNodeAndVPartnerDevs = "/developers/node-and-validator-partner";
const linkPowerCodesDevs = "/developers/power-codes-developers";
// Support and Help
const linkHelpCenterDevs =
  "https://desk.zoho.eu/portal/mycointainerbusiness/en/home";
const linkSendFeedbackDevs =
  "https://surveys.hotjar.com/3d6435be-f10c-46fe-bb53-fe152c4de400";
// Assets
const linkAssetsDevs = "/assets";
// More
const linkAboutUsDevs = "/developers/company";
const linkCompanyUpdatesDevs = "https://company.mycointainer.com/";
const linkContactDevs = "/developers/contact";

const routeIgnore = "router-ignore";

export const PERSONAL_LINKS = [
  {
    title: __("Earn"),
    id: "apps-p",
    expanded: true,
    contentSize: "lg",
    subItems: [
      {
        title: __("Staking"),
        subtitle: __("Multiply your crypto and get more rewards"),
        icon: crypto,
        alt: __("Crypto"),
        link: localizeLink("/staking"),
        noRoute: routeIgnore,
      },
      {
        title: __("Cashback"),
        subtitle: __("Earn crypto from every purchase"),
        icon: cashback,
        alt: __("Cashback"),
        link: localizeLink(linkCashbackPersonal),
        noRoute: routeIgnore,
      },
      {
        title: __("Cold Staking"),
        subtitle: __("Delegate coins from your wallet & earn rewards"),
        icon: coldstaking,
        alt: __("Cold Staking"),
        link: localizeLink(linkColdStakingPersonal),
        noRoute: routeIgnore,
      },
      {
        title: __("Airdrops"),
        subtitle: __("Take part in various activities & get rewarded"),
        icon: airdrops,
        alt: __("Airdrops"),
        link: localizeLink("/airdrops"),
      },
      {
        title: __("Exchange"),
        subtitle: __("Get the best rates & extra rewards"),
        icon: exchange,
        alt: __("Exchange"),
        link: localizeLink("/exchange"),
      },
      {
        title: __("Mining"),
        subtitle: __("Mine any crypto with our help"),
        icon: mining,
        alt: __("Mining"),
        link: localizeLink("/mining"),
        noRoute: routeIgnore,
      },
    ],
  },
  {
    title: __("Assets"),
    id: "assets-p",
    expanded: false,
    link: localizeLink(URLS.LANDINGS.ASSETS),
    noRoute: routeIgnore,
  },
  {
    title: __("Support & Help"),
    id: "support-p",
    expanded: true,
    contentSize: "md",
    subItems: [
      {
        title: __("Help Center"),
        icon: helpDesk,
        alt: __("Help Center"),
        link: localizeLink("https://support.mycointainer.com/en/"),
        noRoute: routeIgnore,
      },
      {
        title: __("Give Feedback"),
        icon: sendFeedback,
        alt: __("Give Feedback"),
        link: localizeLink("https://mycointainer.canny.io/"),
        noRoute: routeIgnore,
      },
    ],
  },
  {
    title: __("More"),
    id: "more-p",
    expanded: true,
    contentSize: "md",
    subItems: [
      // {
      //   title: __('Power Subscriptions'),
      //   icon: power,
      //   alt: __('Power Subscription'),
      //   link: localizeLink(linkPowerSubscriptionPersonal),
      //   noRoute: routeIgnore
      // },
      {
        title: __("Guides & News"),
        icon: guidesNews,
        alt: __("Guides & News"),
        link: localizeLink(linkGuidesNewsPersonal),
        noRoute: routeIgnore,
      },
      {
        title: __("Company Updates"),
        icon: companyUpdates,
        alt: __("Company Updates"),
        link: linkCompanyUpdatesPersonal,
        noRoute: routeIgnore,
      },
      // {
      //   title: __('Contact'),
      //   icon: contact,
      //   alt: __('Contact'),
      //   link: localizeLink(linkContactPersonal)
      // },
      {
        title: __("About Us"),
        icon: aboutUs,
        alt: __("About Us"),
        link: localizeLink(linkAboutUsPersonal),
      },
    ],
  },
];
export const BUSINESS_LINKS = [
  {
    title: __("Solutions"),
    id: "solutions-b",
    expanded: true,
    contentSize: "lg",
    subItems: [
      {
        title: __("Node Deployments"),
        subtitle: __("Create nodes in an instant"),
        icon: nodeDeployments,
        alt: __("Node Deployments"),
        link: localizeLink(linkNodeDeploymentsBusiness),
      },
      {
        title: __("Crypto Wallets"),
        subtitle: __("Over 100 crypto wallets for your platform"),
        icon: cryptoWallets,
        alt: __("Crypto Wallets"),
        link: localizeLink(linkCryptoWalletsBusiness),
      },
    ],
  },
  {
    title: __("Services"),
    id: "services-b",
    expanded: true,
    contentSize: "md",
    subItems: [
      {
        title: __("Staking Nodes"),
        icon: stakingNodes,
        alt: __("Staking Nodes"),
        link: localizeLink(linkStakingNodesBusiness),
      },
      {
        title: __("Airdrops & Giveaways"),
        icon: airdropsBlack,
        alt: __("Airdrops & Giveaways"),
        link: localizeLink(linkAirdropsGiveawaysBusiness),
      },
      {
        title: __("Marketing Campaigns"),
        icon: marketingCampaigns,
        alt: __("Marketing Campaigns"),
        link: localizeLink(linkMarketingCampaignsBusiness),
      },
      {
        title: __("POWER Codes"),
        icon: power,
        alt: __("Power Codes"),
        link: localizeLink(linkPowerCodesBusiness),
      },
    ],
  },
  {
    title: __("Assets"),
    id: "assets-b",
    expanded: false,
    link: localizeLink(URLS.LANDINGS.ASSETS),
    noRoute: routeIgnore,
  },
  {
    title: __("Support & Help"),
    id: "support-b",
    expanded: true,
    contentSize: "md",
    subItems: [
      {
        title: __("Contact Us"),
        icon: contactUs,
        alt: __("Help Center"),
        link: linkHelpCenterBusiness,
        noRoute: routeIgnore,
      },
      {
        title: __("Send Feedback"),
        icon: sendFeedback,
        alt: __("Send Feedback"),
        link: linkSendFeedbackBusiness,
        noRoute: routeIgnore,
      },
    ],
  },
  {
    title: __("More"),
    id: "more-b",
    expanded: true,
    contentSize: "md",
    subItems: [
      {
        title: __("About Us"),
        icon: aboutUs,
        alt: __("About Us"),
        link: localizeLink(linkAboutUsBusiness),
      },
      {
        title: __("Company Updates"),
        icon: companyUpdates,
        alt: __("Company Updates"),
        link: linkCompanyUpdatesBusiness,
        noRoute: routeIgnore,
      },
      {
        title: __("Contact"),
        icon: contact,
        alt: __("Contact"),
        link: localizeLink(linkContactBusiness),
      },
    ],
  },
];

export const DEVELOPERS_LINKS = [
  {
    title: __("Solutions"),
    id: "solutions-d",
    expanded: true,
    contentSize: "lg",
    subItems: [
      {
        title: __("Exchange"),
        subtitle: __("Enable your coin on our exchange"),
        icon: exchangeDev,
        alt: __("Exchange"),
        link: localizeLink(linkExchangeDevs),
      },
      {
        title: __("Staking & Mining"),
        subtitle: __("Let your users earn from your protcol"),
        icon: stakingManingDev,
        alt: __("Staking & Mining"),
        link: localizeLink(linkStakingMiningDevs),
      },
      {
        title: __("Airdrops & Giveaways"),
        subtitle: __("Promote your coin"),
        icon: airdropsDev,
        alt: __("Airdrops & Giveaways"),
        link: localizeLink(linkAirdropsGiveawaysDevs),
      },
      {
        title: __("Node Deployement"),
        subtitle: __("Enable your projects in our network"),
        icon: nodeDeploymentsDev,
        alt: __("Node Deployement"),
        link: localizeLink(linkNodeDeploymentsDevs),
      },
    ],
  },
  {
    title: __("Services"),
    id: "services-d",
    expanded: true,
    contentSize: "md",
    subItems: [
      {
        title: __("Coin Listing"),
        icon: coinListing,
        alt: __("Coin Listing"),
        link: localizeLink(linkCoinListingDevs),
      },
      {
        title: __("Marketing Campaigns"),
        icon: marketingCampaigns,
        alt: __("Marketing Campaigns"),
        link: localizeLink(linkMarketingCampaignsDevs),
      },
      {
        title: __("Node & Validator Partner"),
        icon: nodeAndValidator,
        alt: __("Node & Validator Partner"),
        link: localizeLink(linkNodeAndVPartnerDevs),
      },
      {
        title: __("POWER Codes"),
        icon: power,
        alt: __("Power Codes"),
        link: localizeLink(linkPowerCodesDevs),
      },
    ],
  },
  {
    title: __("Assets"),
    id: "assets-d",
    expanded: false,
    link: localizeLink(linkAssetsDevs),
    noRoute: routeIgnore,
  },
  {
    title: __("Support & Help"),
    id: "support-d",
    expanded: true,
    contentSize: "md",
    subItems: [
      {
        title: __("Help Center"),
        // icon: helpCenter,
        alt: __("Help Center"),
        link: linkHelpCenterDevs,
        noRoute: routeIgnore,
      },
      {
        title: __("Send Feedback"),
        icon: sendFeedback,
        alt: __("Send Feedback"),
        link: linkSendFeedbackDevs,
        noRoute: routeIgnore,
      },
    ],
  },
  {
    title: __("More"),
    id: "more-d",
    expanded: true,
    contentSize: "md",
    subItems: [
      {
        title: __("About Us"),
        icon: aboutUs,
        alt: __("About Us"),
        link: localizeLink(linkAboutUsDevs),
      },
      {
        title: __("Company Updates"),
        icon: companyUpdates,
        alt: __("Company Updates"),
        link: linkCompanyUpdatesDevs,
        noRoute: routeIgnore,
      },
      {
        title: __("Contact"),
        icon: contact,
        alt: __("Contact"),
        link: localizeLink(linkContactDevs),
      },
    ],
  },
];
