import React, { useState } from "react";

import "./styles.scss";
import { tabs } from "./tabs";
import cx from "classnames";
import { useWindowDimension } from "hooks/useWindowDimensions";

export const Security = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const currentTab = tabs[currentTabIndex];

  const { width } = useWindowDimension();
  const isMobile = width <= 1280;

  return (
    <div className="security-wrapper">
      <div className="tabs-container">
        <div className="container-title">Focusing on your security</div>
        <div className="container-sub-title">
          Familiarize yourself with the measures we take to provide you with the
          highest security
        </div>
        {isMobile && (
          <div className="tab-image">
            <img src={currentTab.pic} alt="Tab" />
          </div>
        )}
        <div className="tabs-wrapper">
          <div className="tabs-header">
            {tabs.map((tab, index) => (
              <div
                className={cx("tab-name", {
                  active: index === currentTabIndex,
                })}
                onClick={() => setCurrentTabIndex(index)}
              >
                {isMobile ? tab.nameMobile || tab.name : tab.name}
              </div>
            ))}
          </div>
          <div className="tab-content">{currentTab.description}</div>
        </div>
      </div>
      {!isMobile && (
        <div className="tab-image">
          <img src={currentTab.pic} alt="Tab" />
        </div>
      )}
    </div>
  );
};
