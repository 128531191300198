export const list = [
  {
    date: 'AUGUST 2022',
    title: 'Crypto staking explained: How to stake crypto?',
    link: '/insight/crypto-staking-explained-how-to-stake-crypto/',
    description:
      'As a crypto investor who wishes to test the waters in the new industry or a crypto enthusiast who wants to build an amazing portfolio, staking can be one such way to gain invaluable experience and revenue in the long run.'
  },
  {
    date: 'September 2022',
    title: 'August in Review',
    link: '/insight/august-in-review/',
    description:
      'The summer season is almost behind us and we have to say time flies when we do something we love. A lot has happened over the last scorching month. So, it is time to look back on what productive August brought for MyCointainer.'
  },
  {
    date: 'September 2022',
    title: '5 Best crypto staking coins for passive income',
    link: '/insight/5-best-crypto-staking-coins-for-passive-income/',
    description:
      'Putting your idle cryptocurrencies to work and maintaining full ownership of your coins. Yes, we are talking about crypto staking.'
  },
  {
    date: 'August 2022',
    title: 'Top 10 staking coins Week # 34',
    link: '/insight/top-10-staking-coins-week-34-3/',
    description:
      'There is a bundle of coins currently available for staking, so picking the best cryptocurrency options out there can be pretty challenging, especially if you are a beginner.'
  }
];
