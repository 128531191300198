import { FC } from "react";
import { Section } from "components/Section";
import { Button } from "components/Button";
import styled from "styled-components";
import zero from "./img/zero.svg";
import max from "./img/max.svg";
import percent from "./img/percent.svg";
import included from "./img/included.svg";
import excluded from "./img/excluded.svg";

const PACKAGES_LIST = [
  {
    title: "ZERO",
    description: "Starter",
    image: zero,
    price: "€7.90",
    benefits: 2,
  },
  {
    title: "MAX",
    description: "Standart",
    image: max,
    price: "€14.90",
    benefits: 5,
  },
  {
    title: "PERCENT",
    description: "Professional",
    image: percent,
    price: "€29.90",
    benefits: 7,
  },
];

const BENEFITS = [
  {
    title: "No Exchange Fee",
    description: "Exchange as much times as you want",
  },
  {
    title: "No Staking Fee",
    description: "Out of every rewards 100% goes to you",
  },
  {
    title: "No Withdrawal Fee",
    description: [
      "Zero withdrawal fee unavailable",
      "15 000 EUR per month limit",
      "150 000 EUR per month limit",
    ],
  },
  {
    title: "VIP Giveaways & Offers",
    description: "Every month a giveaway worth min $200",
  },
  {
    title: "Rewards Match Promise",
    description:
      "We will match rewards versus solo<br /> masternode hosting & staking",
  },
  {
    title: "Partners Rewards",
    description:
      "Automatic deposits of extra coins to<br /> your account. New coins added at least<br /> once every few weeks",
  },
  {
    title: "MyCointainer Token<br /> Discount & VIP Priority",
    description: "Up to 24% discount on MyCointainer Token",
  },
];

export const Packages: FC = () => {
  return (
    <Section
      title="Choose Your Power Plan"
      description={
        <Description>
          Enjoy amazing benefits & bonuses with your
          <br /> POWER subscription
        </Description>
      }
    >
      <List>
        {PACKAGES_LIST.map(
          ({ title, description, image, price, benefits }, i) => (
            <ListItem key={title}>
              <Packet>
                <img alt="" src={image} width="56" height="54" />
                <PacketName>
                  <h3>{title}</h3>
                  <p>{description}</p>
                </PacketName>
                <PacketPrice>
                  <h3>{price}</h3>
                  <p>/month</p>
                  <PlusVat>+ VAT</PlusVat>
                </PacketPrice>
              </Packet>
              {BENEFITS.map(({ title, description }, j) => (
                <Benefit key={title}>
                  <img
                    width="18"
                    height="18"
                    src={benefits > j ? included : excluded}
                  />
                  <BenefitDetail>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: Array.isArray(description)
                          ? description[i]
                          : description,
                      }}
                    />
                  </BenefitDetail>
                </Benefit>
              ))}
              <StyledButton role="link" href="/signup" color="primary">Subscribe</StyledButton>
            </ListItem>
          )
        )}
      </List>
    </Section>
  );
};

const Description = styled.p`
  @media (min-width: 360px) and (max-width: 998px) {
    br {
      display: block !important;
    }
  }

  @media (min-width: 998px) {
    br {
      display: none !important;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: auto;
  width: 100%;
  height: 50px !important;
  flex-shrink: 0;
`;

const Benefit = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 0 8px;
`;

const BenefitDetail = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    line-height: 22px;
    color: #253b6c;
  }

  p + p {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #556e83;
  }

  @media (max-width: 998px) {
    br {
      display: none;
    }
  }
`;

const PlusVat = styled.div`
  padding: 4px;
  background: #ebf6ff;
  border-radius: 4px;
  color: #008eff;
  font-weight: 500;
  font-size: 12px;
  line-height: 132%;
  margin-top: 4px;
  text-align: center;
  width: 40px;
  height: 24px;
`;

const PacketName = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #253b6c;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #556e83;
  }
`;

const PacketPrice = styled(PacketName)`
  margin-left: auto;
  align-items: flex-end;
`;

const Packet = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 16px;
  margin-bottom: 24px;
`;

const List = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  margin: 0 auto;
  padding: 0 16px;
  overflow: auto hidden;

  width: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1200px) {
    justify-content: center;
  }

  @media (max-width: 998px) {
    gap: 8px;
  }
`;

const ListItem = styled.div`
  background: white;
  border: 1px solid #eceff2;
  box-shadow: 0px 10px 30px rgba(208, 216, 223, 0.4);
  border-radius: 16px;
  min-width: 384px;
  min-height: 709px;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;

  & > div:not(:nth-child(8)) {
    box-shadow: inset 0px -1px 0px #eceff2;
  }

  & > div:nth-child(8) {
    padding-bottom: 48px;
  }

  @media (max-width: 998px) {
    min-width: 300px;
  }
`;
