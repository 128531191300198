import { Questions } from "components/Questions";
import { uris } from "const";
import { FC } from "react";
import styled from "styled-components";
import { Section } from "components/business/Section";

export const FAQ: FC = () => {
  return (
    <Section title="Getting started">
      <Questions
        dark
        questions={[
          {
            title: "What crytpocurrencies are supported?",
            description:
              "You can see the latest and updated list of supported cryptocurrencies on the assets page. Those cryptocurrencies are offered to MyCointainer platform users, so you can support them too.",
          },
          {
            title: "How will I deal with my taxes and revenues?",
            description:
              "You will have access to all transactions on your dashboard. You can then simply download all your transactions as a statement in a CSV format for any accounting purpose you require.",
          },
          {
            title: "How to start the integration?",
            description: `First, <a href="${uris.business.register}" router-ignore>create an account</a>. You can then enable access to your account for crypto wallets. During the integration our technical department will support you to ensure you can successfully finalize your integration.`,
          },
        ]}
      />
      <Tip>
        First of all, you will get access to shared masternodes staking for a
        variety of coins. We offer automated pool staking, therefore, your
        advantages are steadier, more predictable earnings and the fact that you
        don't need to take care of any technical details.
        <br />
        <br />
        Yes, your every reward is a subject to compound interest. Basically with
        a longer period of time you have higher chances of earning much more as
        this is how staking process works.
      </Tip>
    </Section>
  );
};

const Tip = styled.p`
  font-size: 16px;
  line-height: 152%;
  color: ${(props) => props.theme.darkGray};
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 40px;
    margin: 0;
  }
`;
