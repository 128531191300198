import { Section } from "components/business/Section";
import { FC } from "react";
import {
  List,
  ListItem,
  ListItemTitle,
  ListItemDescription,
} from "components/business/List";
import flow from "./img/flow.svg";
import crypto from "./img/crypto.svg";
import audience from "./img/audience.svg";

export const Benefits: FC = () => {
  return (
    <Section
      id="benefits"
      title="What are the benefits?"
      description="With the growing trend for crypto, stay ahead of the rest by enabling even more payments on your platform."
    >
      <List>
        <ListItem size={3}>
          <img alt="" src={flow} />
          <ListItemTitle>Improve money flow</ListItemTitle>
          <ListItemDescription>
            Increase the cash flow on your platform. Choose from over 100 coins
            or use “stable coins”, such as USDN or USDT to avoid price
            fluctuations.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={audience} />
          <ListItemTitle>Reach a wider audience</ListItemTitle>
          <ListItemDescription>
            Everyone is welcome to use crypto – nobody is excluded. Get ready
            for the future of payments that allows the unbanked to join global
            economies.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={crypto} />
          <ListItemTitle>Use an alternative to FIAT payments</ListItemTitle>
          <ListItemDescription>
            Many businesses struggle to obtain payment processors that come with
            large comissions & lenghty verifications. Crypto eliminates these
            obstacles.
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
