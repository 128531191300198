import React, { useEffect, useState, useContext } from "react";

import "./styles.scss";
import { list, stableList } from "./cryptos";
import { ReactComponent as Arrow } from "./img/arrow.svg";
import { ReactComponent as ArrowRight } from "./img/arrow-right.svg";
import { useWindowDimension } from "hooks/useWindowDimensions";
import { AssetsContext } from 'contexts/assets';
import { URLS } from "const";

export const CryptosList = () => {
  const { width } = useWindowDimension();
  const isMobile = width <= 1280;
  const [stableAssets, setStableAssets] = useState(stableList);
  const [regularAssets, setRegularAssets] = useState(list);

  const { assets } = useContext(AssetsContext);

  useEffect(() => {
      const newStablesData = []
      for (const asset of stableAssets) {
          newStablesData.push({
              ...asset,
              percent: assets.find(a => a.code === asset.code)?.yearlyProfit || asset.percent
          });
      }
      setStableAssets(newStablesData);

      const newRegularsData = []
      for (const asset of regularAssets) {
          newRegularsData.push({
              ...asset,
              percent: assets.find(a => a.code === asset.code)?.yearlyProfit || asset.percent
          });
      }
      setRegularAssets(newRegularsData);
  }, [assets]);

  const renderMobileCard = (item, ind) => {
    if (ind === regularAssets.length - 1) return null;

    return (
      <a className="card-container" href={URLS.SIGNUP} key={`am-${ind}`}>
        <div className="name-container">
          <img alt="percent" src={item.icon} />
          <div className="name-wrapper">
            <div className="name">{item.name}</div>
            <div className="short-code">{item.short}</div>
          </div>
        </div>
        <div className="invest-container">
          <div className="invest-wrapper">
            <div className="percent">
              {item.percent ? `${item.percent}%` : "-/-"}
            </div>
            <span>APY</span>
          </div>
          <Arrow />
        </div>
      </a>
    );
  };

  const renderDesktopCard = (item, ind) => {
    const isLastItem = ind === regularAssets.length - 1;

    return (
      <div className="card-container" key={`a-${ind}`}>
        <div className="percent-container">
          <img alt="percent" src={item.icon} />
          <div className="percent">
            {item.percent ? `${item.percent}%` : "-/-"}
          </div>
        </div>
        <div className="name">{item.name}</div>
        <div className="short-code">{item.short}</div>
        <a
          className="button"
          href={isLastItem ? URLS.LANDINGS.ASSETS : URLS.SIGNUP}
        >
          {isLastItem ? "See all assets" : "Invest"}
        </a>
      </div>
    );
  };

  return (
    <div className="crypto-list-container">
      <div className="title">
        All of the most popular and profitable crypto in your hand
      </div>
      <div className="sub-title">
        Check out our expanding portfolio of coins and highly profitable
        rewards. Pick one, transfer it to your wallet and start earning staking
        interests today.
      </div>
      <div className="list-title">
        Choose one of the stablecoins, transfer it to your wallet and start
        earning in a predictable way
      </div>
      {!isMobile && (
        <div className="list-container">
          {stableAssets.map((item, ind) =>
            isMobile ? (
              <>{renderMobileCard(item, ind)}</>
            ) : (
              renderDesktopCard(item, ind)
            )
          )}
        </div>
      )}
      <div className="list-title">
        or choose our staking assets and earn the biggest yields in the market
      </div>
      <div className="list-container">
        {regularAssets.map((item, ind) =>
          isMobile ? (
            <>{renderMobileCard(item, ind)}</>
          ) : (
            renderDesktopCard(item, ind)
          )
        )}
        {isMobile && (
          <a className="see-more" href={URLS.LANDINGS.ASSETS}>
            See more <ArrowRight />
          </a>
        )}
      </div>
    </div>
  );
};
