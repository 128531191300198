import { Section } from "components/business/Section";
import { Questions } from "components/Questions";
import { FC } from "react";

export const FAQ: FC = () => {
  return (
    <Section title="FAQ">
      <Questions
        dark
        questions={[
          {
            title: "Do I need a developer to integrate it on my Platform?",
            description:
              "If you wish to enable staking nodes to be used as an integration within your platform then the short answer is yes. Inside the panel you will gain access to API keys and documentation that you should share with your developers. Nevertheless, from a business person perspective, you will be able to see active nodes and monitor their performance in Dashbaord. This will allow you to keep things under control and know what is going on.",
          },
          {
            title: "What crytpocurrencies are supported?",
            description:
              "You can see the latest and updated list of supported crytpocurrencies on Assets page. Those crytpocurrencies are offered to MyCointainer platform users, hence you can support them too.",
          },
          {
            title:
              "Can you suppoort a custom coin that is not available on your platform?",
            description:
              "Yes, that’s possible. Please reach out to us and specifiy which coins or asset you wish us to enable and we will add this to our backlog.",
          },
          {
            title:
              "Are running nodes available for integration with exchange related platforms or can you provide a custom solutions?",
            description:
              "We mainly collaborate with crytpo exchange and alike. However we’re very flexible on the solution and are happy to discuss your integration. Broadly speaking you will be given an access to active nodes with API calls that enable you possibilities that you could use for various purposes you wish.",
          },
          {
            title:
              "What if I encouter any issues during integration and after my nodes are running?",
            description:
              "During the integration you will be supported by our technical department to ensure you can successfully finalize your integration.",
          },
          {
            title: "How do I start?",
            description:
              "First, please  create an account. You will be presented with an option to enable an access for your account for node deployment. If you have any questions you can always reach us via this form.",
          },
        ]}
      />
    </Section>
  );
};
