import { FC } from "react";
import styled from "styled-components";
import image from "./img/staking-popularity.webp";
import mobileImage from "./img/staking-popularity-1024px.webp";
import t from "i18n";

export const Popularity: FC = () => {
  return (
    <Container>
      <Overview>
        <Title>{t("staking.popularity.reasoning")}</Title>
        <Description
          dangerouslySetInnerHTML={{
            __html: t("staking.popularity.reasoning.description"),
          }}
        />
        <Title>{t("staking.popularity.slogan")}</Title>
        <Description
          dangerouslySetInnerHTML={{
            __html: t("staking.popularity.slogan.description"),
          }}
        />
      </Overview>
      <Heading>{t("staking.popularity.title")}</Heading>
      <Picture>
        <picture>
          <source media="(max-width: 1024px)" srcSet={mobileImage} />
          <img src={image} alt="" />
        </picture>
      </Picture>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  gap: 54px;
  padding: 0 16px;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 24px;
  }
`;

const Picture = styled.div`
  max-width: 100%;
  flex-basis: 100%;

  img {
    height: 343px;
  }

  @media (min-width: 1024px) {
    max-width: 50%;
    flex-basis: 50%;

    img {
      height: 636px;
    }
  }
`;

const Overview = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  flex-basis: 50%;
  gap: 24px;

  @media (max-width: 1024px) {
    max-width: 100%;
    flex-basis: 100%;
    gap: 8px;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--color--black);
  line-height: 1.3;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 150%;
    margin-top: 16px;
    color: var(--color--secondary);
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: var(--color--secondary);

  ul {
    list-style-type: disc;
    margin-left: 1.5em;
  }
`;

const Heading = styled.h2`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: var(--color--black);

  @media (min-width: 1024px) {
    display: none;
  }
`;
