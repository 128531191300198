import { Button } from "components/business/Button";
import { uris } from "const";
import { FC } from "react";
import styled from "styled-components";
import background from "./img/background.svg";

export const Assets: FC = () => {
  return (
    <Container>
      <Title>Over 100 assets supported</Title>
      <Description>
        Choose from over 100 assets including “stable coins”, such as USDN, USDT
        and many
        <br /> more. Forget about currency fluctuations and make your earnings
        more predictable.
      </Description>
      <Action>
        <Button variant="primary" href={uris.landings.assets}>
          View all
        </Button>
      </Action>
    </Container>
  );
};

const Action = styled.div`
  margin-top: 56px;
  display: flex;
  gap: 16px;

  @media (max-width: 998px) {
    width: 100%;

    a {
      width: 100%;
    }
  }
`;

const Container = styled.div`
  height: 429px;
  background: url(${background}) no-repeat;
  background-position: center;
  background-size: 1246px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.white};
  text-align: center;
  padding: 0 24px;

  @media (max-width: 998px) {
    text-align: left;
    background-size: 100%;
    align-items: flex-start;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 46px;
  line-height: 56px;

  @media (max-width: 998px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 142%;
  color: ${(props) => props.theme.darkGray};

  @media (max-width: 998px) {
    br {
      display: none;
    }
  }
`;
