import React from "react";

import pic from "./img/pic.png";
import "./styles.scss";
import arrowIcon from "./img/arrow.svg?raw";
import { useWindowDimension } from "hooks/useWindowDimensions";
import { URLS } from "const";

export const FreeCoins = () => {
  const { width } = useWindowDimension();
  const isMobile = width <= 1280;

  return (
    <div className="free-coins-wrapper">
      <div className="info-container">
        <div className="title">Get free coins with Airdrops!</div>
        <div className="sub-title">
          Hunt for gifts and promos from the crypto community. Increase your
          wallet balance with these free gifts. See all the best airdrops on the
          web. Never miss your chance to earn more!
        </div>
        {!isMobile && (
          <div className="buttons-container">
            <a className="btn" href={URLS.LANDINGS.AIRDROPS}>
              Learn More <img alt="arrow" src={arrowIcon} />
            </a>
          </div>
        )}
      </div>
      <img alt="free-coins" src={pic} className="free-coins-pic" />
      {isMobile && (
        <div className="buttons-container">
          <div className="btn">
            Learn More <img alt="arrow" src={arrowIcon} />
          </div>
        </div>
      )}
    </div>
  );
};
