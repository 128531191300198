import { FC } from "react";
import styled from "styled-components";
import { Footer } from "components/business/Footer";
import { Header } from "components/business/Header";
import { NavigationTop } from "components/NavigationTop";
import { Benefits } from "./Benefits";
import imageHeader from "./img/header-image.webp";
import { FAQ } from "./FAQ";
import { uris } from "const";
import { GetInTouch } from "components/business/GetInTouch";

export const Power: FC = () => {
  const onClick = () => {
    document.querySelector("#benefits")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <NavigationTop />
      <Header
        image={<img alt="" src={imageHeader} width="448" />}
        title={<NowrapTitle>POWER Codes</NowrapTitle>}
        description="The POWER subscription enables lucrative benefits for individual users on the MyCointainer Platform. Increase the offering of your product or service by adding an extra value service from MyCointainer. "
        action="Explore benefits"
        primaryAction="Request free codes"
        primaryActionHref={uris.business.requestPOWER}
        onClick={onClick}
      />
      <Benefits />
      <FAQ />
      <GetInTouch
        title="Earn more thanks to crypto"
        description="Grow with MyCointainer"
        action="Ask Question"
        actionHref={uris.business.askQuestion}
        primaryAction="Request codes"
        primaryActionHref={uris.business.requestPOWER}
      />
      <Footer />
    </Container>
  );
};

const NowrapTitle = styled.p`
  @media (min-width: 360px) {
    white-space: nowrap;
  }
`;

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
`;
