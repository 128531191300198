import { useRef, useState } from "react";

import { Button } from "components/Button";

import styled from "styled-components";

import {
  iconColdStaking,
  iconExchange,
  iconStaking,
  info,
  sorter,
} from "assets";

import { STRAPI_CATEGORIES, URLS } from "const";

import t from "i18n";

const Container = styled.table`
  border-collapse: collapse;
  width: 1024px;
`;

const TableHeader = styled.tr`
  background: var(--color-monochrome--light-1);
  border-radius: 4px;

  height: 50px;

  border-top: 1px solid var(--color-monochrome--light-2);
  border-bottom: 1px solid var(--color-monochrome--light-2);

  th {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.29;
    color: var(--color--secondary);

    height: 50px;

    vertical-align: middle;
    padding-left: 16px;
  }
`;

const Row = styled.tr`
  height: 64px;
  box-shadow: var(--shadow--cell-border-down);
  cursor: pointer;

  &:hover {
    background-color: #f5f9fc;
  }

  td {
    font-size: 16px;
    line-height: 1.5;
    color: #10181f;
    padding-left: 16px;
    vertical-align: middle;
  }
`;

const AssetName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #10181f;
`;

const AssetSlug = styled.div`
  font-size: 14px;
  line-height: 1.57;
  color: var(--color-monochrome--light-4);
  margin-left: 8px;
`;

const AssetImage = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  margin-right: 12px;
  background-size: 28px 28px;
`;

const Asset = styled.div`
  display: flex;
  align-items: center;
`;

const AssetCategory = styled.div`
  width: 24px;
  height: 24px;
  background: var(--color--white);
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const AssetCategories = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledButton = styled(Button)`
  width: 156px;
  height: 40px;

  background: var(--color--white);
  color: var(--color-monochrome--light-5);

  border: 1px solid var(--color-monochrome--light-3);
  border-radius: 4px;

  &:hover,
  &:active {
    background-color: var(--color--primary);
    color: var(--color--white);
    border-color: var(--color--primary);
  }
`;

const ActionTd = styled.td`
  width: 188px;
`;

const AssetTd = styled.td`
  width: 463px;
`;

const CategoriesTd = styled.td`
  width: 130px;
`;

const PriceTd = styled.td`
  width: 211px;
  text-align: right;
`;

const YearlyProfitTd = styled.td`
  width: 186px;
  text-align: center;
`;

const AssetTh = styled.th`
  width: 463px;
`;

const CategoriesTh = styled.th`
  width: 130px;
`;

const PriceTh = styled.th`
  width: 211px;
`;

const YearlyProfitTh = styled.th`
  width: 186px;
  text-align: center !important;
`;

const ActionTh = styled.th`
  width: 188px;
`;

const ThWithSorter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;

  img {
    cursor: pointer;
  }
`;

const AssetNameThWithSorter = styled(ThWithSorter)`
  justify-content: flex-start;
`;

const PriceThWithSorter = styled(ThWithSorter)`
  justify-content: flex-end;
`;

const PopupInfo = styled.div`
  position: absolute;
  background-color: var(--color--white);
  padding: 16px;
  width: 260px;
  border-radius: 8px;
  font-size: 14px;
  transform: translate(calc(-50% + 16px), calc(-100% - 16px));
  color: var(--color--black);
  line-height: 1.5;
  box-shadow: 0px 4px 22px rgba(208, 216, 223, 0.8);
`;

export const Table = (props) => {
  const { assets, onSortByYearlyProfit, onSortByPrice, onSortByName } = props;

  const [isMouseOnInfo, setIsMouseOnInfo] = useState(false);

  const infoRef = useRef();

  const onMouseOnInfo = () => {
    setIsMouseOnInfo(true);
  };

  const onMouseOutInfo = () => {
    setIsMouseOnInfo(false);
  };

  const onClick = (assetKey) => {
    window.open(`${URLS.LANDINGS.ASSETS}/${assetKey}`, "_self");
  };

  return (
    <>
      {infoRef.current && isMouseOnInfo && (
        <PopupInfo
          style={{
            left: infoRef.current.getBoundingClientRect().x,
            top: window.scrollY + infoRef.current.getBoundingClientRect().y,
          }}
          dangerouslySetInnerHTML={{
            __html: t("assets.whatIsAPY"),
          }}
        />
      )}
      <Container>
        <TableHeader>
          <AssetTh onClick={onSortByName}>
            <AssetNameThWithSorter>
              <p>Asset Name</p>
              <img alt="" src={sorter} />
            </AssetNameThWithSorter>
          </AssetTh>
          <CategoriesTh>Type</CategoriesTh>
          <PriceTh onClick={onSortByPrice}>
            <PriceThWithSorter>
              <p>Price</p>
              <img alt="" src={sorter} />
            </PriceThWithSorter>
          </PriceTh>
          <YearlyProfitTh onClick={onSortByYearlyProfit}>
            <ThWithSorter>
              <img
                onMouseEnter={onMouseOnInfo}
                onMouseLeave={onMouseOutInfo}
                ref={infoRef}
                alt=""
                src={info}
              />
              <p>APY</p>
              <img alt="" src={sorter} />
            </ThWithSorter>
          </YearlyProfitTh>
          <ActionTh>Action</ActionTh>
        </TableHeader>
        {assets.map(
          ({
            title,
            slug,
            imageUrl,
            price = 0,
            yearlyProfit,
            categories = [],
            assetKey,
          }) => (
            <Row
              key={assetKey}
              data-href={`${URLS.LANDINGS.ASSETS}/${assetKey}`}
              tabindex="0"
              role="link"
              onClick={() => onClick(assetKey)}
            >
              <AssetTd>
                <Asset>
                  <AssetImage
                    data-title={title}
                    data-image-url={imageUrl}
                    style={{
                      backgroundImage: `url("${imageUrl}")`,
                    }}
                  />
                  <AssetName>{title}</AssetName>
                  <AssetSlug>{slug}</AssetSlug>
                </Asset>
              </AssetTd>
              <CategoriesTd>
                <AssetCategories>
                  {categories.includes(STRAPI_CATEGORIES.STAKING) && (
                    <AssetCategory>
                      <img width="16" height="18" alt="" src={iconStaking} />
                    </AssetCategory>
                  )}
                  {categories.includes(STRAPI_CATEGORIES.COLD_STAKING) && (
                    <AssetCategory>
                      <img
                        width="15"
                        height="18"
                        alt=""
                        src={iconColdStaking}
                      />
                    </AssetCategory>
                  )}
                  {categories.includes(STRAPI_CATEGORIES.EXCHANGE) && (
                    <AssetCategory>
                      <img width="18" height="15" alt="" src={iconExchange} />
                    </AssetCategory>
                  )}
                </AssetCategories>
              </CategoriesTd>
              <PriceTd>
                {price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} EUR
              </PriceTd>
              <YearlyProfitTd>{yearlyProfit}%</YearlyProfitTd>
              <ActionTd>
                <StyledButton role="link" href={URLS.SIGNUP}>
                  Deposit or Buy
                </StyledButton>
              </ActionTd>
            </Row>
          )
        )}
      </Container>
    </>
  );
};
