import { FC } from "react";
import styled, { keyframes } from "styled-components";
import { Section } from "./Section";
import airdropalert from "./img/ticker/airdropalert.png";
import coindoo from "./img/ticker/coindoo.png";
import cryptoadventures from "./img/ticker/cryptoadventures.png";
import cryptoslate from "./img/ticker/cryptoslate.png";
import cryptounfolded from "./img/ticker/cryptounfolded.png";
import cryptovirally from "./img/ticker/cryptovirally.png";
import freecoins24 from "./img/ticker/freecoins24.png";
import hackernoon from "./img/ticker/hackernoon.png";
import techbullion from "./img/ticker/techbullion.png";
import thecapital from "./img/ticker/thecapital.png";
import zexprwire from "./img/ticker/zexprwire.png";
import zycrypto from "./img/ticker/zycrypto.png";
import cryptopotato from "./img/ticker/cryptopotato.png";
import coinscribble from "./img/ticker/coinscribble.png";

const LINES = [
  [
    { url: "hackernoon.com", deal: 30, image: hackernoon },
    { url: "cryptoadventure.org", deal: 50, image: cryptoadventures },
    { url: "cryptoslate.com", deal: 40, image: cryptoslate },
    { url: "cryptopotato.com", deal: 30, image: cryptopotato },
    { url: "zycrypto.com", deal: 20, image: zycrypto },
  ],
  [
    { url: "techbullion.com", deal: 35, image: techbullion },
    { url: "freecoins24.io", deal: 65, image: freecoins24 },
    { url: "cryptovirally.com", deal: 40, image: cryptovirally },
    { url: "coinscribble.com", deal: 50, image: coinscribble },
    { url: "thecapital.io", deal: 25, image: thecapital },
  ],
  [
    { url: "airdropalert.com", deal: 45, image: airdropalert },
    { url: "coindoo.com", deal: 40, image: coindoo },
    { url: "zexprwire.com", deal: 35, image: zexprwire },
    { url: "cryptounfolded.com", deal: 35, image: cryptounfolded },
    { url: "thecapital.io", deal: 25, image: thecapital },
  ],
];

export const PartnersTicker: FC = () => {
  return (
    <Section
      title="Media partners"
      description="With our trusted and extensive network of partners you get better prices and a greater reach."
    >
      <Lines>
        {LINES.map((line, i) => (
          <Line key={i}>
            {line.map(({ url, deal, image }) => (
              <Partner key={url}>
                {image && <img alt="" src={image} />}
                <a target="_blank" router-ignore href={`//${url}`}>
                  {url}
                </a>
                <Deal>-{deal}%</Deal>
              </Partner>
            ))}
          </Line>
        ))}
      </Lines>
    </Section>
  );
};

const swipe = keyframes`
  0%, 50%, 100% {
    left: 0;
  }
  
  25% {
    left: 10%;
  }
  
  75% {
    left: -10%;    
  }
`;

const secondSwipe = keyframes`
  0% {
    left: 10%;
  }
  
  50% {
    left: -10%;
  }
  
  100% {
    left: 10%;
  }
`;

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
`;

const Line = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  animation: 10s linear infinite ${swipe};
  position: relative;

  &:nth-child(2) {
    animation: 4s linear infinite ${secondSwipe};
  }

  &:nth-child(3) {
    animation: 5s linear infinite ${swipe};
  }
`;

const Partner = styled.div`
  padding: 0;
  border: 1px solid rgba(107, 160, 181, 0.4);
  border-radius: 4px;
  padding: 24px;
  width: 319px;
  height: 72px;

  display: flex;
  align-items: center;

  user-select: none;

  img {
    max-width: 20px;
    height: auto;
    margin-right: 8px;
  }

  a {
    font-size: 17px;
    text-decoration: none;
    line-height: 22px;
    color: ${({ theme }) => theme.darkGray};
  }

  a:hover {
    color: white;
  }
`;

const Deal = styled.p`
  font-weight: 700;
  color: white;
  margin-left: auto;
  padding-left: 8px;
`;
