import React from 'react';
import './styles.scss';

export const Faq = () => {
  const handleAccordion = (event) => {
    let panel = event.target
      .closest('.content-holder')
      .querySelector('.expanded_content');
    // let accordion = event.target
    //     .closest('.content-holder')
    //     .querySelector('.accordion');

    let content = event.target.closest('.content-holder');
    content.classList.toggle('active');

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  };

  return (
    <div className="exchange-faq-wrapper">
      <div className="separator">
        <h4>FAQ</h4>
        <div className="content">
          <div className="content-holder">
            <button className="accordion" onClick={handleAccordion}>
              What are the benefits of using MyCointainer Exchange?
            </button>
            <div className="expanded_content">
              <p>
                You can earn passive income with crypto thanks to the
                Proof-of-Stake (PoS) consesus model which is the alternative
                mechanism for Proof-of-Work (PoW) that is described as a concept
                that lets an individual to mine or validate block transactions
                based on the number of coins that are held. Thanks to the
                assuring security and liquidity for a given network, we can be
                awarded with rewards in form of 'stakes'. Staking refers to
                holding crypto coins to verify transactions and support the
                network on Proof-of-Stake (PoS) blockchain, which allows to
                generate a passive income.
              </p>
            </div>
          </div>
          <div className="content-holder">
            <button className="accordion" onClick={handleAccordion}>
              Can all coins be exchanged on MyCointainer?
            </button>
            <div className="expanded_content">
              <p>
                It doesn’t require much time and advanced skills to learn how to
                earn interest by using our service. We built an easy-to-navigate
                and user-friendly interface with basic features of the website.
                First, create an account on the platform and select coins for
                staking. There are two ways to add funds to the coin of your
                choice: 1. Open the platform exchange and buy cryptocurrency for
                fiat 2. For other assets, transfer funds to the MyCointainer
                wallet from the other one. For each staking coin we support, we
                have created a{' '}
                <a
                  className="link"
                  href="/insight/tag/guides-and-tutorials/"
                  router-ignore
                  rel="noreferrer"
                >
                  guide that illustrates{' '}
                </a>
                the entire process even more. Coins starts earning immediately
                when they arrive in the wallet. You will see your rewards in
                your dashboard as they come.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
