import bnbPic from './img/bnb.svg';
import ethPic from './img/eth.svg';
import polkadotPic from './img/polkadot.svg';
import polygonPic from './img/polygon.svg';
import avalanchePic from './img/avalanche.svg';
import allAssetsPic from './img/allAssets.png';
import tetherPic from './img/tether.svg';
import usdPic from './img/usd.svg';
import daiPic from './img/dai.svg';

export const stableList = [
  {
    name: 'Tether',
    short: '(USDT)',
    icon: tetherPic,
    percent: 10,
    code: 'USDT'
  },
  {
    name: 'USD Coin',
    short: '(USDC)',
    icon: usdPic,
    percent: 10,
    code: 'USDC'
  },
  {
    name: 'Dai',
    short: '(DAI)',
    icon: daiPic,
    percent: 10,
    code: 'DAI'
  }
];

export const list = [
  {
    name: 'BNB',
    short: '(BNB)',
    icon: bnbPic,
    percent: 5.15,
    code: 'BNB'
  },
  {
    name: 'Ethereum',
    short: '(ETH)',
    icon: ethPic,
    code: 'ETH'
  },
  {
    name: 'Polkadot',
    short: '(DOT)',
    percent: 3.9,
    icon: polkadotPic,
    code: 'DOT'
  },
  {
    name: 'Polygon',
    short: '(MATIC)',
    percent: 14.8,
    icon: polygonPic,
    code: 'MATIC'
  },
  {
    name: 'Avalanche',
    percent: 11.4,
    short: '(AVAX)',
    icon: avalanchePic,
    code: 'AVAX'
  },
  {
    name: 'All assets',
    percent: '',
    short: 'See the entire collection of our coins and buy',
    icon: allAssetsPic
  }
];
