import { Section } from "components/business/Section";
import { FC } from "react";
import {
  List,
  ListItem,
  ListItemTitle,
  ListItemDescription,
} from "components/business/List";
import partnership from "./img/partnership.svg";
import payments from "./img/payments.svg";
import preach from "./img/preach.svg";
import saver from "./img/saver.svg";
import security from "./img/security.svg";
import support from "./img/support.svg";

export const Benefits: FC = () => {
  return (
    <Section
      id="benefits"
      title="Why use node deployment?"
      description="With hands-on experience in the crypto industry we’ll gladly share our components and experience with you."
    >
      <List>
        <ListItem size={3}>
          <img width="48" height="48" alt="" src={saver} />
          <ListItemTitle>Save money & time</ListItemTitle>
          <ListItemDescription>
            Based on our own experience, we know that running and constantly
            upgrading your own infrastructure requires a lot of resources, time
            and knowledge.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img width="48" height="48" alt="" src={preach} />
          <ListItemTitle>We practice what we preach</ListItemTitle>
          <ListItemDescription>
            MyCointainer is built on the same components you will gain access
            to. With battle-tested modules you can utilize our experience and
            avoid costly mistakes.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img width="48" height="48" alt="" src={partnership} />
          <ListItemTitle>Strong partnerships</ListItemTitle>
          <ListItemDescription>
            MyCointainer collaborates with over 100 crypto blockchains,
            developers and partners. Thanks to that, we can provide you with
            active and up-to-date technologies.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img width="48" height="48" alt="" src={payments} />
          <ListItemTitle>Access to easy payments</ListItemTitle>
          <ListItemDescription>
            Thanks to our financial licenses we can provide you with a wide
            range of global payment methods.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img width="48" height="48" alt="" src={security} />
          <ListItemTitle>Strong security</ListItemTitle>
          <ListItemDescription>
            MyCointainer’s solutions have been transferring and securing
            millions of dollars in transfers across many blockchain networks.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img width="48" height="48" alt="" src={support} />
          <ListItemTitle>Technical support</ListItemTitle>
          <ListItemDescription>
            We will provide you with dedicated support to help you set up your
            solution exactly the way you want.
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
