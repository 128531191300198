import { FC } from "react";
import t from "i18n";
import cn from 'classnames';
import { useIsMobile } from "hooks/useIsMobile";

import { StandardLayout } from "components/StandardLayout";
import { Footer } from "components/Footer";
import { Questions } from "components/Questions";

import downloadCircleSvg from "./img/download-circle.svg"
import checkboxCircleSvg from "./img/checkbox-circle.svg";
import bitcoinCircleSvg from "./img/bitcoin-circle.svg";
import giftCircleSvg from "./img/gift-circle.svg";

import downloadSvg from "./img/download.svg";
import checkbox from './img/checkbox.svg';
import { chevron } from "assets";

import miningAppScreenshot from "./img/mining-app-screenshot.png"
import miningDashboardScreenshot from "./img/mining-dashboard-screenshot.png";

import headerImage from './img/header-image.png'

import styles from './styles.module.scss'

const listing = [
  {
    image: downloadCircleSvg,
    title: t("mining.how-it-works.download.title"),
    description: t("mining.how-it-works.download.description"),
  },
  {
    image: checkboxCircleSvg,
    title: t("mining.how-it-works.install.title"),
    description: t("mining.how-it-works.install.description"),
  },
  {
    image: bitcoinCircleSvg,
    title: t("mining.how-it-works.start.title"),
    description: t("mining.how-it-works.start.description"),
  },
  {
    image: giftCircleSvg,
    title: t("mining.how-it-works.rewards.title"),
    description: t("mining.how-it-works.rewards.description"),
  }
];

const pros = [
  t('mining.dashboard.list.choose'),
  t('mining.dashboard.list.automatically'),
  t('mining.dashboard.list.intuitive'),
  t('mining.dashboard.list.live')
]

const Mining: FC = () => {
  const isMobile = useIsMobile({ mobileWidth: 1200 });
  
  const titleFieldName = isMobile ? 'mining.title.mobile' : 'mining.title'

  const firstTitlePart = t(titleFieldName).split(' ').slice(0, 2).join(' ')
  const secondTitlePart = t(titleFieldName).split(' ').slice(2).join(' ')

  return (
    <StandardLayout>
      <header className={styles.header}>
        <div className={styles['header-left-column']}>
          <h1 className={styles['main-title']}>
            <span className={styles['main-title-first-part']}>{firstTitlePart}</span>
            <span className={styles['main-title-second-part']}>{secondTitlePart}</span>
          </h1>
          <p className={styles['header-description']}>{t('mining.description')}</p>
          {
            isMobile
              ? null
              :
                (
                  <a className={styles["header-button"]} href='/signup'>
                    Start Mining
                    <img alt="" src={chevron} />
                  </a>
                )
          }
        </div>
        <div className={styles["header-right-column"]}>
          <img className={styles['header-image']} src={headerImage} alt="header mining image" />
          {
            isMobile
              ?
                (
                  <button className={styles["header-button"]}>
                    Start Mining
                    <img alt="" src={chevron} />
                  </button>
                )
              : null
          }
        </div>
      </header>
      <section className={styles.section}>
        <h2 className={styles.title}>{t("mining.app-details.title")}</h2>
        <p className={styles.description}>{t("mining.app-details.description")}</p>
          <a className={styles['download-button']} href='/signup' role="link">
            <img alt="" src={downloadSvg} />
            Download for MacOS
          </a>
          <img className={styles['mining-app-screenshot']} src={miningAppScreenshot} alt="" />
      </section>
      <section className={styles.section}>
        <h2 className={cn(styles.title, styles['how-it-works-title'])}>{t("mining.how-it-works.title")}</h2>
        <ul className={styles['how-it-works-list']}>
          {listing.map(({ image, title, description }) => (
            <li className={styles['how-it-works-list-item']}>
              <img className={styles['how-it-works-list-img']} src={image} alt="" />
              <div className={styles['how-it-works-list-item-right-col']}>
                <h3 className={styles['how-it-works-list-title']}>{title}</h3>
                <p className={styles['how-it-works-list-description']}>{description}</p>
              </div>
            </li>
          ))}
        </ul>
      </section>
      <section className={cn(styles.section, styles['mining-dashboard-section'])}>
        <div>
          <h3 className={cn(styles.title, styles['mining-dashboard-title'])}>{t("mining.dashboard.title")}</h3>
          <p className={cn(styles['mining-dashboard-description'], styles['mining-dashboard-description'])}>{t("mining.dashboard.description")}</p>
          <ul className={styles['mining-dashboard-list']}>
            {pros.map(text => (
              <li className={styles['mining-dashboard-list-item']}>
                <img className={styles['mining-dashboard-list-img']} src={checkbox} alt="" />
                {text}
              </li>
            ))}
          </ul>
        </div>
        <img className={styles['mining-dashboard-screenshot']} src={miningDashboardScreenshot} alt="" />
      </section>

      <section className={cn(styles.section, styles['how-it-works-section'])}>
        <h2 className={cn(styles.title, styles['how-it-works-title'])}>{t("mining.faq.title")}</h2>
        <Questions
          questions={[
            {
              title: t("mining.faq.what-is.title"),
              description: t("mining.faq.what-is.description"),
            },
            {
              title: t("mining.faq.what-coins.title"),
              description: t("mining.faq.what-coins.description"),
            },
            {
              title: t("mining.faq.myc.title"),
              description: t("mining.faq.myc.description"),
            },
            {
              title: t("mining.faq.rewards.title"),
              description: t("mining.faq.rewards.description"),
            },
          ]}
        />
      </section>
      <Footer />
    </StandardLayout>
  );
};

export default Mining;
