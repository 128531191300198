import { FC } from "react";
import styled from "styled-components";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { StandardLayout } from "components/StandardLayout";
import { Packages } from "./Packages";
import hero from "./img/hero.png";

export const PowerList: FC = () => {
  return (
    <StandardLayout>
      <Header
        title={
          <Title>
            <span>Earn more</span>
            <br /> with POWER
            <br /> subscription
          </Title>
        }
        actionText="Get POWER"
        image={<Hero alt="" src={hero} />}
      />
      <Packages />
      <Footer />
    </StandardLayout>
  );
};

const Title = styled.p`
  white-space: nowrap;
`;

const Hero = styled.img`
  width: 602px;
  margin-left: 80px;

  @media (min-width: 1024px) {
    max-width: 50%;
  }

  @media (max-width: 1200px) {
    margin-left: 0;
  }

  @media (max-width: 1024px) {
    width: 316px;
  }
`;
