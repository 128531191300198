import PropTypes from "prop-types";

import styled from "styled-components";

import t from "i18n";

const Container = styled.div`
  padding: 0 16px;

  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 1.23;
    color: var(--color--black);
  }

  h2 {
    margin-top: 24px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--color--black);
  }

  p,
  li {
    margin-top: 8px;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--color--secondary);
  }

  ul {
    list-style-type: disc;
    padding-left: 1.5em;
  }

  @media (min-width: 1178px) {
    width: 1178px;
    align-items: center;
    text-align: left;
    margin: 0 auto;

    h1,
    h2,
    p,
    li {
      width: 100%;
    }

    h2 {
      margin-top: 16px;
    }

    p,
    li {
      margin-top: 4px;
    }
  }
`;

export const Overview = (props) => {
  const { interestingFacts, projectNutshell, description, slug } =
    props.asset ?? {};

  return (
    <Container>
      <h1>
        {t("asset.overview.title", {
          slug,
        })}
      </h1>
      <p>{description}</p>
      <h2>{t("asset.overview.projectNutshell")}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: projectNutshell
            ?.map((paragraph) => `${paragraph} <br />`)
            ?.join(""),
        }}
      />
      <h2>{t("asset.overview.interestingFacts")}</h2>
      <ul>
        {interestingFacts?.map((fact) => (
          <li key={fact}>{fact}</li>
        ))}
      </ul>
    </Container>
  );
};

Overview.propTypes = {
  asset: PropTypes.object,
};
