import React from "react";

import mobile from "assets/img/topBlockImage.png";
import desktop from "./img/desktop.png";
import topBackground from "assets/img/topBlockBackground.svg";
import "./styles.scss";
import { ReactComponent as IconAppStore } from "./img/appStore.svg";
import { ReactComponent as IconPlayMarket } from "./img/playMarket.svg";
import { ReactComponent as ArrowIcon } from "./img/arrow.svg";
import { URLS } from "const";
import mainHeaderImg from "../../img/header-image.webp";

export const TopInfo = () => {
  return (
    <div className="top-info-wrapper">
      <div className="separator">
        <img alt="Top info" className="top-background" src={topBackground} />
        <div className="left_side">
          <h1 className="title">
            <span>We made crypto</span>
            <br />
            earning simple
          </h1>
          <span className="subtitle">
            Leverage crypto staking, yield farming, and cashback to earn passive
            income. Make your crypto work for you.
          </span>
          <div className="buttons">
            <a
              className="primary_button"
              href="/signup"
              router-ignore
              rel="noreferrer"
            >
              Get Started <ArrowIcon />
            </a>
            <a
              href={URLS.APP_STORE}
              className="app_button"
              router-ignore
              rel="noreferrer"
              target="__blank"
            >
              <IconAppStore />
            </a>
            <a
              href={URLS.GOOGLE_STORE}
              className="app_button"
              router-ignore
              rel="noreferrer"
              target="__blank"
            >
              <IconPlayMarket />
            </a>
          </div>
          <div className="image mobile">
            <img loading="lazy" src={mainHeaderImg} alt="Coins" />
          </div>
        </div>
        <div className="image">
          <img loading="lazy" src={desktop} alt="coins" />
        </div>
      </div>
    </div>
  );
};
