import { FC } from "react";
import { Header } from "components/business/Header";
import styled from "styled-components";
import hero from "./img/hero.png";
import { Section } from "components/business/Section";
import { Partners } from "components/business/Partners";
import { Footer } from "components/business/Footer";
import { Questions } from "components/Questions";
import { GetInTouch } from "components/business/GetInTouch";
import { NavigationTop } from "components/NavigationTop";
import {
  List,
  ListItem,
  ListItemDescription,
  ListItemTitle,
} from "components/business/List";
import focusedAudience from "./img/focusing.svg";
import stability from "./img/stability.svg";
import verifiedCustomers from "./img/verified-customers.svg";

export const DevelopersStaking: FC = () => {
  return (
    <Container>
      <NavigationTop />
      <Header
        title="Staking & Mining"
        description="Integrate the full power of your cryptocurrency in the MyCointainer Ecosystem. Let your users get extra rewards with the earning protocol offered by your blockchain."
        action="Explore more"
        image={<img alt="" src={hero} width="638" />}
        primaryAction="Get in touch"
      />
      <Section
        title="Why should you integrate Staking & Mining?"
        description="MyCointainer actively and constantly introduces crypto projects to businesses and individuals that wish to<br /> join the movement – that’s just a step away from discovering your project."
      >
        <List hideBackground>
          <ListItem purple size={3}>
            <img alt="" src={focusedAudience} />
            <ListItemTitle>Earning focused audience</ListItemTitle>
            <ListItemDescription purple>
              From the very beginning, our main goal was to enable easy access
              to various earning protocols in crypto – so that everyone can
              actively buy coins and earn rewards from such protocols. We take
              pride in our strong niche and active customers.
            </ListItemDescription>
          </ListItem>
          <ListItem purple size={3}>
            <img alt="" src={verifiedCustomers} />
            <ListItemTitle>Verified customers</ListItemTitle>
            <ListItemDescription purple>
              Our verified customers can buy your crypto with BTC, USDT, EUR and
              many more currencies as well as various payment methods.
            </ListItemDescription>
          </ListItem>
          <ListItem purple size={3}>
            <img alt="" src={stability} />
            <ListItemTitle>Stability & growth</ListItemTitle>
            <ListItemDescription purple>
              Staking works to the benefit of every coin owner – the longer the
              coin is locked, the bigger the reward. Staking also works in favor
              of developers. In case fewer coins are available, the demand for
              your coin increases.
            </ListItemDescription>
          </ListItem>
        </List>
      </Section>
      <Section title="FAQ">
        <Questions
          purple
          questions={[
            {
              title:
                "Our coin doesn’t have any staking protocol enabled for rewarding. Are you able to help?",
              description:
                "Yes, of course. We have developed such solutions for a few partners. We can run a “reward distribution” or “pre-stake” with our mechanisms. Please speak to our partnership specialist on Telegram or send us an message.",
            },
            {
              title: "What kind of rewarding protocols do you support?",
              description:
                "Most of our coins follow the PoS consensus mechanism. We support both masternodes and validators. Recently, we have implemented the first DeFi protocols, including the liquidity mining protocol.",
            },
            {
              title:
                "My blockchain solution doesn’t offer any staking or earning capabilities, can it be integrated?",
              description:
                "Of course. We build or help to build rewarding protocols to our partners. They can be used on our platform in a form of a ‘reward distribution’ or ‘pre-stake’ mechisms that our audience can take advantage of.<br /><br />Also, please note there are many ways to participate in MyCointainer ecosystem. First of all, your coin can be integrated for our exchange to be purchased using other cryptocurrencies and FIAT currencies. Second of all, we offer a wide range of marketing activities that can help to boost the presence of your among the MyCointainer audience and our media partners. Last but not least, you can use our Node Deployment solution to enable your project to be used and built for both individuals and businesses.",
            },
            {
              title:
                "How does this work in practice for both, the customer and a coin developer?",
              description:
                "First, we analyze your coin and look at the rewards mechanism distribution. Meanwhile we will prepare a dedicated asset page to let users learn more about your project. We will launch the mechanism as soon as it is properly integrated on our platform. Users are then able to either buy or deposit your coin. They will earn rewards instantly and the distribution will take place daily.",
            },
            {
              title: "Is your rewarding service custodial or non-custodial?",
              description:
                "MyCointainer offers both custodial and non-custodial services. You can choose how you wish to earn rewards. See the list of our non-custodial validators.",
            },
            {
              title: "Where do I start?",
              description:
                "Start your integration by sending us an email with some details about your project or talk to our partnership specialist on Telegram. Once we estimate and analyze your project, we will let you know about the next steps.",
            },
          ]}
        />
      </Section>
      <Partners />
      <GetInTouch
        title="Integrate Staking & Mining on the MyCointainer Platform"
        description="Grow with MyCointainer"
        action="Ask a question"
        primaryAction="Get in touch"
      />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.purple};
`;
