import { incomesList } from './incomesList';
import './styles.scss';

export const PassiveIncomes = () => {
  return (
    <div className="incomes-wrapper">
      <div className="title">Passive Income that comes naturally</div>
      <div className="sub-title">
        Earning crypto has never been easier and more secure. Just stake your
        assets and let the rewards roll in.
      </div>
      <div className="incomes-list">
        {incomesList.map((item) => (
          <div className={`income-item ${item.id}`}>
            <div className="name">{item.name}</div>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
            <img alt="Incomes" src={item.pic} />
          </div>
        ))}
      </div>
    </div>
  );
};
