import {
  enjoyBonusesImage,
  enjoyBonusesImageMobile,
  giveawayTicket,
  i50pFeesBack,
  skipStakingFeeToken
} from "assets";

import { useIsMobile } from "hooks";

import styles from './styles.module.scss'

export const Bonuses = () => {
  const isMobile = useIsMobile({ mobileWidth: 555 });

  return (
    <div className={styles.container}>
      <img className={styles.image} alt="" src={isMobile ? enjoyBonusesImageMobile : enjoyBonusesImage} />
      <div className={styles['right-column']}>
        <h3 className={styles.title}>Enjoy exclusive bonuses when you delegate</h3>
        <p className={styles['secondary-text']}>
          Others only multiply your coins. With MyCointainer, you also get
          exclusive bonuses just for delegating to our nodes.
        </p>
        <ul className={styles.list}>
          <li className={styles.item}>
            <img alt="" src={i50pFeesBack} />
            <div className={styles['list-item-text']}>
              <p className={styles['list-item-title']}>Cold Staking</p>
              <p className={styles['list-item-description']}>
                Delegating your coins to recommended validators will bring you rewards in fully decentralised manner. The process for doing this will depend on the specific network and wallet you are using.
              </p>
            </div>
          </li>
          <li className={styles.item}>
            <img alt="" src={skipStakingFeeToken} />
            <div className={styles['list-item-text']}>
              <p className={styles['list-item-title']}>Flexible Staking (DeFi Staking)</p>
              <p className={styles['list-item-description']}>
                Flexible Staking allows you to earn rewards on your assets while still maintaining the ability to access and use your funds at any time.
              </p>
            </div>
          </li>
          <li className={styles.item}>
            <img alt="" src={giveawayTicket} />
            <div className={styles['list-item-text']}>
              <p className={styles['list-item-title']}>Locked Staking (DeFi Staking)</p>
              <p className={styles['list-item-description']}>
                Locked staking is a way to increase the amount of coins you can stake and earn additional rewards on by locking them up for a set period of time.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
