import { Helmet } from "react-helmet"
import styled from "styled-components";
import { StandardLayout } from "components/StandardLayout";
import { Container } from "components/Container";
import { Section } from "components/Section";
import { Header } from "components/Header";
import { Benefits } from "./Benefits";
import { Subscribe } from "./Subscribe";
import { Questions } from "components/Questions";
import { Footer } from "components/Footer";

const TitleWrapper = styled.h1`
  color: var(--color--black);
`

import { Listing } from "./Listing";

import { useIsMobile } from "hooks/useIsMobile";

import { airdrops as imgAirdrops } from "assets";

import t from "i18n";

const HEADER_HEIGHT = 870;

const MOBILE_HEADER_HEIGHT = 772;

const IMAGE_WIDTH = 372;

const MOBILE_IMAGE_WIDTH = 250;

const Airdrops = () => {
  const isMobile = useIsMobile();

  return (
    <StandardLayout>
      <Helmet>
        <title>
          {t("meta.title.airDrops")}
        </title>
        <meta name="description" content={t('meta.description.airDrops')} />
      </Helmet>
      <Container>
        <Header
          height={isMobile ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT}
          title={
            <TitleWrapper
              dangerouslySetInnerHTML={{
                __html: t("airdrops.title"),
              }}
            />
          }
          image={
            <img
              alt=""
              src={imgAirdrops}
              width={isMobile ? MOBILE_IMAGE_WIDTH : IMAGE_WIDTH}
            />
          }
          description={t(`airdrops.description${isMobile ? ".large" : ""}`)}
          actionText={t("airdrops.action")}
        />
        <Section title={t("airdrops.benefits")}>
          <Benefits />
        </Section>
        <Section title={t("airdrops.ongoing")}>
          <Listing />
        </Section>
        <Section>
          <Subscribe />
        </Section>
        <Section title={t("common.faq")}>
          <Questions
            questions={[
              {
                title: t("airdrops.q.whatAreAirdrops"),
                description: t("airdrops.q.whatAreAirdrops.description"),
              },
              {
                title: t("airdrops.q.whatIsPoint"),
                description: t("airdrops.q.whatIsPoint.description"),
              },
              {
                title: t("airdrops.q.isItWorthMyTime"),
                description: t("airdrops.q.isItWorthMyTime.description"),
              },
              {
                title: t("airdrops.q.usingCoins"),
                description: t("airdrops.q.usingCoins.description"),
              },
            ]}
          />
        </Section>
        <Footer />
      </Container>
    </StandardLayout>
  );
};

export default Airdrops;
