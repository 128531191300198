import styled from "styled-components";

import { assetsListBannerImage, assetsListBannerTexture } from "assets";

import { URLS } from "const";

import { Button } from "components/Button";

const Container = styled.div`
  width: 100%;
  height: 515px;
  background: linear-gradient(
      69.57deg,
      rgba(196, 20, 245, 0.2) -14.86%,
      rgba(248, 228, 253, 0) 38.56%
    ),
    var(--color--gray);

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 112px;
`;

const Texture = styled.div`
  position: absolute;
  width: 956px;
  right: 115px;
  height: 515px;
  background: url(${assetsListBannerTexture}) no-repeat;
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  gap: 16px;

  z-index: 2;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 34px;
  line-height: 1.41;
  letter-spacing: -0.015em;
  color: var(--color--black);
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.015em;
  color: var(--color--secondary);
`;

const StyledButton = styled(Button)`
  margin-top: 32px;
  background: var(--color--primary);
  width: 246px;

  &:hover,
  &:active {
    background: var(--color--primary-dark) !important;
  }
`;

export const Banner = () => {
  return (
    <>
      <Texture />
      <Container>
        <img alt="" src={assetsListBannerImage} />
        <Detail>
          <Title>Make passive income with our Assets</Title>
          <Description>
            Built your investment portfolio with MyCointainer. Deposit or Buy
            favourite assets, discover the new ones, and earn highest rewards in
            the market.
            <br />
            <br />
            It is easy to earn with our broad collection of over thant +100
            assets and multiple earning methods
          </Description>
          <StyledButton role="link" href={URLS.SIGNUP}>
            Start investing
          </StyledButton>
        </Detail>
      </Container>
    </>
  );
};
