import { FC } from "react";
import { Section } from "components/business/Section";
import {
  List,
  ListItem,
  ListItemDescription,
  ListItemTitle,
} from "components/business/List";
import love from "./img/love.svg";
import saver from "./img/saver.svg";
import support from "./img/support.svg";

export const Benefits: FC = () => {
  return (
    <Section
      id="benefits"
      title="Benefits"
      description="Create a unique service for your customers that allows them to earn more."
    >
      <List>
        <ListItem size={3}>
          <img alt="" src={love} />
          <ListItemTitle>Desired by customers</ListItemTitle>
          <ListItemDescription>
            Earning protocols are the hottest features for your platform. Give
            your customers more opportunities and increase your reach with ease.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={saver} />
          <ListItemTitle>Save money & time</ListItemTitle>
          <ListItemDescription>
            Based on our own experience, we know that running and constantly
            upgrading your own infrastructure requires a lot of resources, time
            and knowledge.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={support} />
          <ListItemTitle>Technical support</ListItemTitle>
          <ListItemDescription>
            We will provide you with dedicated support to help you set up your
            solution exactly the way you want.
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
