import React, { useState, useEffect } from 'react';
import { Coin } from '../types';
import { coins } from '../api';

type AssetsContextType = {
  assets: Coin[],
  isAssetsLoading: boolean
};

export const AssetsContext = React.createContext<AssetsContextType>({ assets: [], isAssetsLoading: true });

export function AssetsContextProvider (args: any) {
  const [assets, setAssets] = useState<Coin[]>([]);
  const [isAssetsLoading, setIsAssetsLoading] = useState(true);

  useEffect(() => {
    fetchAssets();
    async function fetchAssets () {
      setIsAssetsLoading(true);
      setAssets(await coins.find());
      setIsAssetsLoading(false);
    }
  }, []);

  return (
    <AssetsContext.Provider value={{assets, isAssetsLoading}}>
      {args.children}
    </AssetsContext.Provider>
  );
}

