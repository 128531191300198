import { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 144px;
  margin: 0 auto;
  justify-content: center;
  padding: 0 16px;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 58px;
    color: var(--color--primary);
  }

  p {
    font-size: 20px;
    line-height: 28px;
    color: var(--color--secondary);
    width: 193px;
  }
`;

export const Stats: FC = () => {
  return (
    <Container>
      <Stat>
        <h2>~ 100</h2>
        <p>staking assets available for you</p>
      </Stat>
      <Stat>
        <h2>14%</h2>
        <p>of the average APY you can get</p>
      </Stat>
      <Stat>
        <h2>~ 7M EUR</h2>
        <p>in rewards given to our Users so far</p>
      </Stat>
      <Stat>
        <h2>&gt; 900</h2>
        <p>days with rewards given to Users so far</p>
      </Stat>
    </Container>
  );
};
