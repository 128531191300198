import React, { useState, useMemo } from "react";
import { socials } from "./socials";

import "./styles.scss";
import logo from "./img/logo.svg";
import { pages } from "./pages";
import trustpilotPic from "./img/trustpilot.svg";
import appStorePic from "./img/appStore.svg";
import playMarketPic from "./img/playMarket.svg";
import { useWindowDimension } from "hooks/useWindowDimensions";
import { chevronFooter } from "assets";
import arrowRight from "./img/arrow.svg";
import { URLS } from "const";

export const Footer = () => {
  const [openedItemsName, setOpenedItemsName] = useState("company");
  const { width } = useWindowDimension();
  const isMobile = width <= 1280;

  const AppsContainer = useMemo(
    () => () =>
      (
        <div className="apps-container">
          <img src={trustpilotPic} alt="Trustpilot" />
          <div className="mobile-apps-container">
            <div className="title">Mobile App</div>
            <a className="app" href={URLS.APP_STORE} target="__blank">
              <img alt="app-store" src={appStorePic} />
              <div>
                Download on the
                <span>App Store</span>
              </div>
            </a>
            <a className="app" href={URLS.GOOGLE_STORE} target="__blank">
              <img alt="play-market" src={playMarketPic} />
              <div>
                GET IT ON
                <span>Google Play</span>
              </div>
            </a>
          </div>
        </div>
      ),
    []
  );

  return (
    <div className="footer-wrapper">
      {isMobile && <img src={logo} className="logo" alt="logo" />}
      <div className="sections-container">
        <div className="sections-wrapper">
          <a className="section-item active" href="/">
            PERSONAL
          </a>
          <a className="section-item" href="/business">
            BUSINESS
          </a>
          <a className="section-item" href="/coin-developers">
            COIN DEVELOPERS
          </a>
        </div>
      </div>
      <div className="footer-container">
        <div className="info-container">
          <div className="pages-container">
            <div className="header">
              {!isMobile && <img src={logo} className="logo" alt="logo" />}
              <div className="socials-container">
                {socials.map((item) => (
                  <a target="__blank" key={item.link} href={item.link}>
                    <img src={item.pic} alt="social" />
                  </a>
                ))}
              </div>
            </div>
            <div className="pages-list-container">
              <div className="list-wrapper">
                {pages.map((row) => (
                  <div key={row.name} className="row-wrapper">
                    {row.map((page) => (
                      <div key={page.name} className="list-row">
                        <div
                          className="row-title"
                          onClick={() => setOpenedItemsName(page.name)}
                        >
                          {page.title}{" "}
                          {(!isMobile ||
                            (isMobile && openedItemsName === page.name)) && (
                            <img
                              src={isMobile ? chevronFooter : arrowRight}
                              alt=""
                            />
                          )}
                        </div>
                        {(openedItemsName === page.name || !isMobile) &&
                          page.list.map((listItem) => (
                            <a
                              className="list-item"
                              href={listItem.path}
                              key={listItem.page}
                            >
                              {listItem.page}
                            </a>
                          ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {isMobile && <AppsContainer />}
            </div>
          </div>
          {!isMobile && <AppsContainer />}
        </div>
      </div>
      <div className="copyright-container">
        © Copyright {new Date().getFullYear()} MyCointainer. All rights reserved. Various trademarks
        held by their respective owners.
      </div>
    </div>
  );
};
