import PropTypes from "prop-types";

import styled from "styled-components";

import { Button } from "./Button";

import { chevron, textureHeader } from "../assets";

import { URLS } from "const";

import { useIsMobile } from "hooks/useIsMobile";
import { useEffect, useRef } from "react";

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;

  padding: 0 32px;

  height: ${(props) => props.height}px;

  background: linear-gradient(
    244.06deg,
    #c4d6f4 0.98%,
    #e4eefc 37.82%,
    #e7f0fd 61.8%,
    #d7e3f6 100%
  );

  #image {
    max-width: 50%;
  }

  img {
    z-index: 2;
  }

  @media (max-width: 1024px) {
    padding-top: 72px;
    flex-direction: column;
    gap: 12px;
    overflow-x: hidden;
    position: relative;

    img {
      max-width: 100%;
    }
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-width: 573px;

  z-index: 2;

  @media (max-width: 1024px) {
    gap: 12px;
    text-align: center;
    align-items: center;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 80px;
  line-height: 1.12;
  letter-spacing: -0.02em;
  color: var(--color--black);

  z-index: 99999;

  span {
    color: ${({ color }) => color};
  }

  @media (min-width: 1024px) {
    width: 394px;
  }

  @media (max-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
  }
`;

const Description = styled.div`
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.015em;
  color: var(--color--secondary);

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledButton = styled(Button)`
  width: fit-content;
  padding: 13px 41px;
  cursor: pointer;

  background-color: ${({ color }) => color};

  &:hover,
  &:active {
    background-color: ${({ color }) =>
      color === "var(--color--primary)"
        ? "var(--color--primary-dark)"
        : "var(--color--teal-dark)"};
  }
`;

const Texture = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  background: url(${({ textureImage }) => textureImage}) no-repeat;
  position: absolute;
  top: 0;

  @media (max-width: 1024px) {
    left: -30%;
    width: 150%;
    position: absolute;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16px;
`;

const DEFAULT_HEADER_HEIGHT = 735;

const MAX_TITLE_HEIGHT = 108;

const TITLE_WIDTH = 631;

const AUTO_TITLE_WIDTH = 450;

export const Header = (props) => {
  const {
    title,
    description,
    image,
    textureImage = textureHeader,
    actionText,
    color = "var(--color--primary)",
    actions,
    hideAction,
    height = DEFAULT_HEADER_HEIGHT,
  } = props;

  const titleRef = useRef(null);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (!titleRef.current) {
      return;
    }

    const titleNode = titleRef.current.querySelector("div");

    if (!titleNode || !titleNode.style) {
      return;
    }

    if (!isMobile) {
      if (titleNode.style.maxWidth) {
        titleNode.style.removeProperty("max-width");
        titleNode.style.width = `${TITLE_WIDTH}px`;
        titleNode.style.removeProperty("padding");
      }
      return;
    }

    if (titleRef.current.getBoundingClientRect().height > MAX_TITLE_HEIGHT) {
      titleNode.style.maxWidth = `${AUTO_TITLE_WIDTH}px`;
      titleNode.style.width = "auto";
      titleNode.style.padding = "0 16px";
    }
  }, [titleRef, isMobile]);

  return (
    <Section height={height}>
      <Texture textureImage={textureImage} height={height} />
      <Detail>
        <Title ref={titleRef} color={color}>
          {title}
        </Title>
        <Description
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        {isMobile &&
          (typeof image === "string" ? <img alt="" src={image} /> : image)}
        <Actions>
          {!hideAction && (
            <StyledButton color={color} href={URLS.SIGNUP} role="link">
              {actionText}
              <img alt="" src={chevron} />
            </StyledButton>
          )}
          {actions}
        </Actions>
      </Detail>
      {!isMobile &&
        (typeof image === "string" ? <img alt="" src={image} /> : image)}
    </Section>
  );
};

Header.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  image: PropTypes.node,
  textureImage: PropTypes.string,
  actionText: PropTypes.string,
  actions: PropTypes.node,
  color: PropTypes.string,
  hideAction: PropTypes.bool,
  height: PropTypes.number,
};
