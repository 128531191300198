import faPic from './img/fa.png';
import sumsubPic from './img/sumsub.png';
import fireblocksPic from './img/fireblocks.png';

export const tabs = [
  {
    name: 'Fireblocks',
    description:
      'Mycointainer is partnered with Fireblock to keep your assets safe and secure',
    pic: fireblocksPic
  },
  {
    name: 'KYC/AML',
    description:
      'To comply with KYC/AML regulations, Mycointainer works with a Sumsub.',
    pic: sumsubPic
  },
  {
    name: '2-Factor Authentication',
    nameMobile: '2FA',
    description: 'Keep your assets even safer with 2-factor authentication.',
    pic: faPic
  }
];
