import PropTypes from "prop-types";

import styled from "styled-components";

import t from "i18n";

const Popup = styled.div`
  font-size: 14px;

  background-color: var(--color--white);
  border-radius: 6px;
  box-shadow: 0px 4px 22px rgba(208, 216, 223, 0.8);

  position: fixed;
  top: 16px;
  z-index: 2147483647;
  padding: 12px 16px;

  transition: right 0.3s linear;
`;

export const SubscribedPopup = (props) => {
  const { isOpen } = props;

  const style = {
    right: isOpen ? 16 : "-100%",
  };

  return <Popup style={style}>{t("airdrops.subscribedToNewsletter")}</Popup>;
};

SubscribedPopup.propTypes = {
  isOpen: PropTypes.bool,
};
