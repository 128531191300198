import PropTypes from "prop-types";

import { useContext, useEffect, useRef, useState, useMemo } from "react";

import { Section } from "./Section";
import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import { Skeleton } from "./Skeleton";
import { AssetsContext } from "contexts/assets";

import styled from "styled-components";
import t from "i18n";

import { CALCULATOR_COIN_SLUG, URLS } from "const";

import {
  chart,
  chartHover,
  chartMobile,
  chartMobileHover,
  chevronDown,
  search,
} from "../assets";

import { useIsMobile } from "hooks/useIsMobile";

import { useWindowDimension } from "hooks/useWindowDimensions";

const Description = styled.div`
  font-size: 1.25rem;
  line-height: 1.4;
  color: var(--color--secondary);
  text-align: center;

  margin: -40px 0 48px;

  @media (max-width: 1024px) {
    font-size: 1rem;
    line-height: 1.5;
    padding: 0 16px;
    margin: -16px 0 32px;
  }
`;

const Title = styled.div`
  @media (max-width: 1024px) {
    padding: 0 38px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: min(128px, 6%);

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const Input = styled.input`
  width: 358px;
  height: 56px;
  background: var(--color--white);
  border: 1px solid var(--color-monochrome--light-2);
  border-radius: var(--rounding--medium);

  outline: none;

  padding: 16px;

  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.32;
  color: var(--color--black);

  @media (max-width: 1024px) {
    width: 100%;
    height: 50px;
    position: relative;
  }
`;

const Selector = styled.div`
  width: 358px;
  height: 56px;
  border: 1px solid var(--color-monochrome--light-2);
  border-radius: var(--rounding--medium);

  cursor: ${(props) => (props.hideChevron ? "" : "pointer")};

  background: ${(props) =>
    props.hideChevron
      ? "var(--color--white)"
      : `url(${chevronDown}) no-repeat, var(--color--white)`};
  background-position: calc(100% - 16px);

  outline: none;

  padding: 16px;

  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.32;
  color: var(--color--black);

  @media (max-width: 1024px) {
    width: 100%;
    height: 50px;
  }
`;

const Aside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-size: 1rem;
    line-height: 1.5;
    color: var(--color--secondary);
  }

  @media (max-width: 1024px) {
    padding: 0 16px;

    input + p {
      margin-top: 12px;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
  width: 358px;
  height: 56px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const CheckboxContainer = styled.div`
  margin-top: 13px;
`;

const Disclaimer = styled.p`
  margin-top: 29px;
  width: 358px;
  color: var(--color--secondary);

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const CoinStyle = styled.div`
  display: flex;
  gap: 11px;

  height: 56px;
  margin-top: -18px;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    height: 50px !important;
  }
`;

const CoinDetail = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--color--black);
  }

  p {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.3;
    color: var(--color--secondary);
  }
`;

const CoinImage = styled.div`
  width: 31px;
  height: 31px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: var(--rounding--circle);

  border: solid 1px var(--color--gray);

  & > img {
    width: 100%;
    padding: 4px 8px;
  }
`;

const Deposit = styled.p`
  margin-top: 4px;
`;

const Chart = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 1rem;
    line-height: 1.5;
    color: var(--color--secondary);
  }

  h2 {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1.4;
    color: var(--color--black);
  }

  #graphics {
    margin-top: 55px;
    width: 550px;
    height: 247px;
    background: url(${chart}) no-repeat;
  }

  #mouseOverLine {
    position: absolute;
    height: 247px;

    div {
      position: relative;
      width: 2px;
      top: calc(2 * 100% / 6);
      height: calc(4 * 100% / 6);
      background-color: var(--color--primary);
    }
  }

  #legend {
    display: flex;
    justify-content: space-around;
    margin-top: 6px;

    p {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.32;
      letter-spacing: -0.002em;
      color: var(--color--black);
    }
  }

  #ticks {
    position: absolute;
    width: 550px;
    height: 247px;

    & > div {
      background-image: linear-gradient(to right, transparent 40%, #d0d7dd 40%);
      background-size: 10px 1px;
      background-repeat: repeat-x;
      height: calc(100% / 6);

      &:last-child {
        margin-top: -1px;
      }
    }
  }

  #mouseOver {
    overflow: hidden;
  }

  @media (min-width: 1024px) {
    width: 550px;
  }

  @media (max-width: 1024px) {
    padding: 0 16px;

    #graphics {
      margin: 26px auto 0;
    }

    #legend {
      width: 550px;
      margin: 6px auto 0;
    }
  }

  @media (max-width: 550px) {
    #graphics,
    #ticks {
      width: 343px;
    }

    #mouseOver,
    #graphics,
    #legend {
      max-width: 100%;
    }

    #ticks {
      max-width: calc(100% - 32px);
    }

    #legend {
      width: 343px;
    }

    #graphics {
      background: url(${chartMobile}) no-repeat;
    }
  }
`;

const DropdrownWrapper = styled.div`
  position: absolute;
  width: 358px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Dropdown = styled.div`
  position: relative;

  width: 100%;
  max-height: 190px;
  overflow: auto;

  z-index: 2147483647;

  margin-left: -16px;

  background-color: var(--color--white);

  border: 1px solid var(--color-monochrome--light-2);
  border-radius: var(--rounding-small);

  box-shadow: var(--shadow--middle);

  @media (max-width: 1024px) {
    width: calc(100% - 32px);
  }
`;

const DropdownItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownItem = styled.div`
  padding: 8px 16px 8px 8px;
  display: flex;
  gap: 11px;

  &:hover {
    background-color: var(--color-monochrome--light-1);
  }

  @media (max-width: 1024px) {
    height: 50px;
  }
`;

const DropdownItemDetail = styled(CoinDetail)`
  flex-direction: row;
  align-items: center;
  gap: 8px;

  p {
    color: #89909e !important;
  }
`;

const CoinYearlyProfit = styled.p`
  margin-left: auto;

  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 0.93rem !important;
  line-height: 1.54 !important;
  color: #89909e !important;
`;

const SearchInput = styled(Input)`
  margin: 8px;
  height: 40px;
  width: calc(100% - 16px);
  text-indent: 8px;
  font-size: 0.875rem;
  line-height: 1.57;
  font-weight: 400;
  background: url(${search}) no-repeat;
  background-position: calc(100% - 16px);
  padding-right: 36px;
`;

const NoAssets = styled.p`
  margin: 16px auto;
`;

const SelectedCoinYearlyProfit = styled.p`
  font-weight: 500;
  line-height: 1.32 !important;
  color: var(--color--teal) !important;
  margin: ${(props) =>
    props.isChevronHidden ? "0 0 0 auto" : " 0 24px 0 auto"};
`;

const EurSymbol = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.32;
  color: var(--color--black);

  margin-left: 12px;

  position: relative;
  top: -48px;

  width: 16px;

  @media (max-width: 1024px) {
    top: -45px;
  }
`;

const DepositInput = styled(Input)`
  text-indent: 12px;
`;

// TODO Move all constant below to an object

const DESKTOP_CHART_WIDTH = 550;

const MOBILE_CHART_WIDTH = 343;

const CHART_HEIGHT = 247;

const SELECT_HEIGHT = 56;

const MOBILE_SELECT_HEIGHT = 50;

const CHART_OFFSET = 55;

const MOBILE_CHART_OFFSET = 26;

export const Calculator = (props) => {
  const { assets, isAssetsLoading } = useContext(AssetsContext);
  const isMobile = useIsMobile();
  const SELECT_SKELETON_HEIGHT = isMobile
    ? MOBILE_SELECT_HEIGHT
    : SELECT_HEIGHT;

  const [assetsWithAPY, setAssetsWithAPY] = useState(assets);
  const { hideSelector, asset } = props;
  const { width } = useWindowDimension();
  const [deposit, setDeposit] = useState(1000);
  const [coin, setCoin] = useState(null);
  const [isUsingPower, setIsUsingPower] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [profit, setProfit] = useState(0);
  const [months, setMonths] = useState(36);
  const [mouseX, setMouseX] = useState(null);
  const graphicsRef = useRef();

  let maxProfit = deposit * (1 + (coin?.yearlyProfit - (isUsingPower ? 0 : coin?.rewardFee)) / 100) ** 3;

  if (Number.isNaN(maxProfit)) {
    maxProfit = deposit;
  }

  useEffect(() => {
    if (!coin) {
      return setProfit(0);
    }
    if (!mouseX || !graphicsRef.current) {
      setMonths(36);
      return setProfit(maxProfit);
    }
    const graphicsClientRect = graphicsRef.current.getBoundingClientRect();
    const relX = (mouseX - graphicsClientRect.x) / graphicsClientRect.width;
    const MAX_MONTHS = 36;
    const months = Math.min(Math.round(relX * MAX_MONTHS), MAX_MONTHS);
    setMonths(months);
    setProfit(deposit * (1 + (coin.yearlyProfit - (isUsingPower ? 0 : coin.rewardFee)) / 1200) ** months);
  }, [mouseX, graphicsRef, coin, deposit, isUsingPower]);

  const onClickOut = () => {
    setIsDropdownOpen(false);
    setQuery("");
  };

  useEffect(() => {
    window.addEventListener("click", onClickOut);
    const targetAssets = assets.filter(c => c.yearlyProfit > 0);
    setAssetsWithAPY(targetAssets);
    setCoin(targetAssets.find((coin) => coin.slug === CALCULATOR_COIN_SLUG) ?? targetAssets.at(0));
    return () => {
      window.removeEventListener("click", onClickOut);
    };
  }, [hideSelector, assets]);

  useEffect(() => {
    if (!coin) {
      return;
    }
    setProfit(
      deposit *
        (1 + (coin.yearlyProfit - (isUsingPower ? 0 : coin.rewardFee)) / 100) **
          3
    );
  }, [deposit, coin, isUsingPower]);

  useEffect(() => {
    if (asset) {
      setCoin(asset);
    }
  }, [asset]);

  const onChangeDeposit = (e) => {
    const value = e.target.value;
    if (value.includes(".") && value.indexOf(".") !== value.lastIndexOf(".")) {
      return;
    }
    if (value === ".") {
      return;
    }
    setDeposit(value.replace(/[^\d.]/g, ""));
  };

  const onToggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen((isDropdownOpen) => {
      if (isDropdownOpen) {
        setQuery("");
      }
      return !isDropdownOpen;
    });
  };

  const onClickCoin = (e, coin) => {
    e.stopPropagation();
    setCoin(coin);
    setIsDropdownOpen(false);
  };

  const onMouseMoveOnCanvas = (e) => {
    setMouseX(e.clientX);
  };

  const onMouseOutCanvas = () => {
    setMouseX(null);
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const onClickSearch = (e) => {
    e.stopPropagation();
  };

  const onTouchMoveOnCanvas = (e) => {
    const { left, right } = graphicsRef.current.getBoundingClientRect();
    let x = e.touches[0].clientX;
    if (x < left) {
      x = left;
    } else if (x > right) {
      x = right;
    }
    setMouseX(x);
  };

  const onTouchEndOnCanvas = () => {
    setMouseX(null);
  };

  const assetsWithAppliedQuery = assetsWithAPY.filter((asset) =>
    `${asset.title}-${asset.slug}`.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <Section
      style={{
        backgroundColor: "var(--color--gray)",
        padding: isMobile ? "32px 0" : "60px 16px",
      }}
      title={t("calculator.title")}
    >
      <Description>{t("calculator.description")}</Description>
      <Container>
        <Aside>
          <p>{t("calculator.asset")}</p>
          {isAssetsLoading ? (
            <Skeleton
              style={{
                "--color": "rgba(0, 0, 0, 0.04)",
              }}
              height={SELECT_SKELETON_HEIGHT}
            />
          ) : (
            <Selector hideChevron={hideSelector} onClick={onToggleDropdown}>
              {coin && (
                <>
                  <CoinStyle>
                    <CoinImage>
                      <img alt={coin.title} src={coin.imageUrl} />
                    </CoinImage>
                    <CoinDetail>
                      <h2>{coin.title}</h2>
                      <p>{coin.slug}</p>
                    </CoinDetail>
                    <SelectedCoinYearlyProfit isChevronHidden={hideSelector}>
                      {coin.yearlyProfit}%
                    </SelectedCoinYearlyProfit>
                  </CoinStyle>
                  {isDropdownOpen && !hideSelector && (
                    <DropdrownWrapper>
                      <Dropdown>
                        <SearchInput
                          placeholder={t("calculator.search")}
                          value={query}
                          onChange={onQueryChange}
                          onClick={onClickSearch}
                        />
                        <DropdownItems>
                          {assetsWithAppliedQuery.length > 0 ? (
                            assetsWithAppliedQuery.map((asset) => (
                              <DropdownItem
                                key={asset.slug}
                                onClick={(e) => onClickCoin(e, asset)}
                              >
                                <CoinImage>
                                  <img alt={asset.title} src={asset.imageUrl} />
                                </CoinImage>
                                <DropdownItemDetail>
                                  <h2>{asset.title}</h2>
                                  <p>{asset.slug}</p>
                                </DropdownItemDetail>
                                <CoinYearlyProfit>
                                  {asset.yearlyProfit}%{" "}
                                  {t("calculator.yearlyProfit")}
                                </CoinYearlyProfit>
                              </DropdownItem>
                            ))
                          ) : (
                            <NoAssets>{t("calculator.nothing")}</NoAssets>
                          )}
                        </DropdownItems>
                      </Dropdown>
                    </DropdrownWrapper>
                  )}
                </>
              )}
            </Selector>
          )}
          <Deposit>{t("calculator.deposit")}</Deposit>
          <DepositInput value={deposit} onChange={onChangeDeposit} />
          <EurSymbol>€</EurSymbol>
          <StyledButton role="link" href={URLS.SIGNUP}>
            {t("calculator.action")}
          </StyledButton>
          <CheckboxContainer>
            <Checkbox value={isUsingPower} onChange={setIsUsingPower}>
              {t("calculator.checkbox")}
            </Checkbox>
          </CheckboxContainer>
          <Disclaimer>{t("calculator.disclaimer")}</Disclaimer>
        </Aside>
        <Chart>
          <p>
            {t("calculator.profit", {
              months: Math.max(months, 1),
              pluralOrSingle:
                months > 1 ? t("calculator.months") : t("calculator.month"),
            })}
          </p>
          <h2>
            €
            {Math.min(Math.max(profit, deposit), maxProfit)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h2>
          {isAssetsLoading ? (
            <Skeleton
              width={
                width > DESKTOP_CHART_WIDTH
                  ? DESKTOP_CHART_WIDTH
                  : MOBILE_CHART_WIDTH
              }
              height={CHART_HEIGHT}
              style={{
                "--color": "rgba(0, 0, 0, 0.02)",
                marginTop: isMobile ? MOBILE_CHART_OFFSET : CHART_OFFSET,
                maxWidth: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          ) : (
            <>
              <div
                id="graphics"
                onMouseMove={onMouseMoveOnCanvas}
                onMouseLeave={onMouseOutCanvas}
                onTouchMove={onTouchMoveOnCanvas}
                onTouchEnd={onTouchEndOnCanvas}
                ref={graphicsRef}
              >
                <div id="ticks">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                {graphicsRef.current && (!isMobile || mouseX > 0) && (
                  <div id="mouseOverLine">
                    <div
                      style={{
                        left: Math.min(
                          mouseX -
                            graphicsRef.current.getBoundingClientRect().x,
                          graphicsRef.current.getBoundingClientRect().width - 2
                        ),
                      }}
                    />
                  </div>
                )}
                <div
                  id="mouseOver"
                  style={{
                    width: mouseX
                      ? mouseX -
                        graphicsRef.current?.getBoundingClientRect()?.x +
                        2
                      : graphicsRef.current?.getBoundingClientRect()?.right,
                  }}
                >
                  <img
                    alt=""
                    src={
                      width > DESKTOP_CHART_WIDTH
                        ? chartHover
                        : chartMobileHover
                    }
                  />
                </div>
              </div>
              <div id="legend">
                <p />
                <p>6 months</p>
                <p>12 months</p>
                <p>24 months</p>
                <p>36 months</p>
              </div>
            </>
          )}
        </Chart>
      </Container>
    </Section>
  );
};

Calculator.propTypes = {
  hideSelector: PropTypes.bool,
  asset: PropTypes.object,
};

export default Calculator;
