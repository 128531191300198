import styled from "styled-components";

import { IS_MOBILE, STORES, URLS } from "const";

import { Button } from "components/Button";

import t from "i18n";
import { useIsMobile } from "hooks/useIsMobile";

const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: -0.015em;
  color: var(--color--secondary);

  text-align: center;

  @media (max-width: 1024px) {
    margin-top: -80px;
    font-size: 1rem;
    padding: 0 32px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1024px) {
    margin-top: 80px;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-top: -20px;
`;

const SizedButton = styled(Button)`
  width: 376px;
  margin-top: 32px;

  @media (max-width: 1024px) {
    width: 197px;
  }
`;

const List = styled.div`
  width: min(100%, 1176px);

  margin-top: 48px;

  display: flex;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    margin-top: 24px;
    padding: 0 16px;
    gap: 8px;
  }
`;

const Store = styled.a`
  width: 300px;
  height: 253px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-decoration: none;

  cursor: pointer;

  background: var(--color--white);
  border: 1px solid var(--color-monochrome--light-2);
  box-shadow: var(--shadow--middle);
  border-radius: 16px;
  padding: 38px 54px;

  @media (min-width: 1200px) {
    width: 376px;
  }

  @media (max-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    height: 62px;
    box-shadow: 0px 4px 22px rgba(208, 216, 223, 0.2);
    padding: 0;
    width: 100%;
  }
`;

const StoreImageContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-left: 12px;

  img {
    max-width: 100%;
  }

  @media (max-width: 1024px) {
    width: 54px;
    flex-shrink: 0;

    img {
      max-height: 42px;
      width: 100%;
    }
  }
`;

const StoreTitle = styled.p`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.4;
  text-align: center;
  letter-spacing: -0.002em;
  color: var(--color--black);

  @media (max-width: 1024px) {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
  }

  @media (min-width: 1024px) {
    margin-top: 24px;
  }
`;

const StoreEarning = styled.p`
  font-size: 1.25rem;
  line-height: 1.4;
  text-align: center;
  color: var(--color-monochrome--light-5);
  margin-top: 8px;

  span {
    font-weight: 700;
    color: #00ad6e;
  }

  @media (max-width: 1024px) {
    height: 100%;
    justify-content: center;
    margin-top: -4px;
    text-align: right;
    font-size: 0.75rem;
    line-height: 1.3;
    margin-left: auto;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 12px;

    span {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
`;

const StoreCategory = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.3;
  letter-spacing: -0.002em;
  color: var(--color-monochrome--light-4);

  @media (min-width: 1024px) {
    display: none;
  }
`;

const SizedDescription = styled(Description)`
  max-width: 833px;
`;

const StoreTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 1024px) {
    align-items: center;
  }
`;

export const Stores = () => {
  const isMobile = useIsMobile();

  return (
    <Section>
      <Detail>
        <SizedDescription>{t("cashback.stores.description")}</SizedDescription>
        <List>
          {STORES.map((store, i) => (
            <Store href={URLS.SIGNUP} key={i}>
              <StoreImageContainer>
                <img alt="" src={store.image} />
              </StoreImageContainer>
              <StoreTitleContainer>
                <StoreTitle>{store.name}</StoreTitle>
                <StoreCategory>{store.category}</StoreCategory>
              </StoreTitleContainer>
              <StoreEarning>
                {IS_MOBILE
                  ? t("cashback.stores.earnUpTo")
                  : t("cashback.stores.upTo")}{" "}
                <span>{store.profit}</span>
              </StoreEarning>
            </Store>
          ))}
        </List>
        <SizedButton role="link" href={URLS.SIGNUP}>
          {t(`cashback.stores.actionText${isMobile ? "" : ".large"}`)}
        </SizedButton>
      </Detail>
    </Section>
  );
};
