import React from "react";

import backgroundPic from "./img/background.png";
import topBackground from "assets/img/topBlockBackground.svg";
import { ReactComponent as ArrowRight } from "./img/arrow.svg";
import "./styles.scss";
import { useWindowDimension } from "hooks/useWindowDimensions";
import { URLS } from "const";

export const TopInfo = () => {
  const { width } = useWindowDimension();
  const isMobile = width <= 1280;

  const ExchangeButton = () => (
    <div className="buttons">
      <a
        className="primary_button"
        href={URLS.SIGNUP}
        router-ignore
        rel="noreferrer"
      >
        Exchange now <ArrowRight />
      </a>
    </div>
  );

  return (
    <div className="exchange-top-info-wrapper">
      <div className="separator">
        <img alt="Top info" className="top-background" src={topBackground} />
        <div className="left_side">
          <h1 className="title">
            <span>Crypto Exchange</span> at the best rates and earn rewards
          </h1>
          <span className="subtitle">
            Trade your favourite cryptocurrencies directly on MyCointainer.
            Discover new staking assets
          </span>
          {!isMobile && <ExchangeButton />}
        </div>
        <div className="image">
          <img
            src={backgroundPic}
            alt="background"
            className="background-pic"
          />
        </div>
        {isMobile && <ExchangeButton />}
      </div>
    </div>
  );
};
