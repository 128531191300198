import { FC } from "react";
import { Section } from "components/business/Section";
import {
  List,
  ListItem,
  ListItemDescription,
  ListItemTitle,
} from "components/business/List";
import node from "./img/node.svg";
import coins from "./img/coins.svg";
import login from "./img/login.svg";
import { uris } from "const";

export const HowToStart: FC = () => {
  return (
    <Section
      title="How to start?"
      description="Let your customers earn rewards from various rewarding blockchain protocols."
    >
      <List>
        <ListItem size={3}>
          <img alt="" src={login} />
          <ListItemTitle>Free sign-up</ListItemTitle>
          <ListItemDescription>
            <a href={uris.business.register}>Register</a> a MyCointainer
            account, by providing just a few details and you’re set.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={coins} />
          <ListItemTitle>Select coins</ListItemTitle>
          <ListItemDescription>
            We support over 100 coins and assets that you can enable for
            rewarding instantly.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={node} />
          <ListItemTitle>Run a node</ListItemTitle>
          <ListItemDescription>
            Get full information about the nodes that you run, topped off with
            easy payments and more. No more hassle.
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
