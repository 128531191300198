import { FC } from "react";
import styled from "styled-components";

export const Info: FC = () => {
  return (
    <Container>
      <h3>Your partner to sky-rocket your growth</h3>
      <p>
        Whether you’re just starting out, building new things, or have a
        well-developed blockchain, we can help with our expertise, products,
        solutions and experience that we gained in last years to speed up your
        growth. There are plenty of options to choose from to meet your current
        needs and future goals.
      </p>
      <StatsList>
        <Stat>
          <p>Registered users</p>
          <h3>76 000</h3>
        </Stat>
        <Stat>
          <p>Total reach with media partners</p>
          <h3>2,2 mln</h3>
        </Stat>
      </StatsList>
    </Container>
  );
};

const StatsList = styled.div`
  display: flex;
  border-top: 1px solid rgba(89, 100, 116, 0.4);
  width: calc(100% - 84px);
  margin: 0 42px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
    width: 100%;
    padding-bottom: 8px;
  }
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;

  padding: 44px 0 84px;

  p,
  h3 {
    color: ${(props) => props.theme.white} !important;
    text-align: left;
    padding: 0 !important;
  }

  flex-basis: 50%;

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 152%;
  }

  h3 {
    font-weight: 700;
    font-size: 72px !important;
    line-height: 88px !important;
  }

  @media (max-width: 576px) {
    h3 {
      font-size: 49px !important;
      line-height: 52px !important;
    }
  }

  @media (max-width: 768px) {
    margin: 0 24px;
    padding: 24px 0;
  }
`;

const Container = styled.div`
  padding: 64px 0 0;
  margin: 72px 120px;
  background: rgba(79, 35, 255, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  text-align: center;

  p {
    padding: 0 64px;
  }

  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: white;
  }

  p {
    font-size: 18px;
    line-height: 142%;
    color: #a8abd1;
  }

  @media (max-width: 998px) {
    margin: 40px 0 0 0;
    padding-top: 32px;
    width: 100%;
    text-align: left;
    align-items: flex-start;

    h3,
    p {
      padding: 0 24px;
    }
  }
`;
