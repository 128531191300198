import { FC } from "react";
import { Section } from "components/business/Section";
import {
  List,
  ListItem,
  ListItemDescription,
  ListItemTitle,
} from "components/business/List";
import sales from "./img/sales.svg";
import benefits from "./img/benefits.svg";
import codes from "./img/codes.svg";
import { uris } from "const";

export const Benefits: FC = () => {
  return (
    <Section
      id="benefits"
      title="Benefits"
      description="Add an additional layer of value to your product or service to make it stand out even more.<br />Every time a customer buys your product or service, they receive a POWER code."
    >
      <List>
        <ListItem size={3}>
          <img alt="" src={sales} />
          <ListItemTitle>Increase your sales</ListItemTitle>
          <ListItemDescription>
            OWER codes are free for you, yet valuable for your customers.
            Distribute them among your audience or add them to your product to
            increase its overall value.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={benefits} />
          <ListItemTitle>Wide range of benefits</ListItemTitle>
          <ListItemDescription>
            Meet various audience needs by offering a wide range of benefits,
            from free coin exchange and staking to participation in a $200 worth
            of giveaway. <a href={uris.landings.power}>See all benefits.</a>
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={codes} />
          <ListItemTitle>Simple codes</ListItemTitle>
          <ListItemDescription>
            All you have to do is to simply fill out the form and let us know
            how you want to use codes. Add them to products, distribute on
            Telegram or share on any other channel.
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
