import { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"
import t from "i18n";
import AliceCarousel from 'react-alice-carousel'

import { StandardLayout } from "components/StandardLayout";
import { Container } from "components/Container";
import { Questions } from "components/Questions";
import { Footer } from "components/Footer";

import { useIsMobile } from "hooks/useIsMobile";

import { chevron } from "assets";

import { HowItWork } from "./HowItWorks";
import { Nodes } from "./Nodes/index";
import { Bonuses } from "./Bonuses/index";

import { ReactComponent as HeaderImage } from './img/header.svg'

import styles from './styles.module.scss'

import 'react-alice-carousel/lib/alice-carousel.css'
import './styles.scss'

const getBannersTokens = async () => {
  const myHeadersMain = new Headers();
  myHeadersMain.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'client_credentials');
  urlencoded.append('client_id', '3b3e27f8-ead5-46db-8a0b-b23bd2489cf5');
  urlencoded.append('client_secret', 'o+BxkeBUqgJ1zAlXCR9ghsu6cZAd9UDweVl0EVgD7OE=');
  const requestOptionsTokens = {
    method: 'POST',
    headers: myHeadersMain,
    body: urlencoded,
    redirect: 'follow'
  };

  const responseTokens = await fetch('https://mcb-auth.v2.mycointainer.com/platformpanel/auth/tokens', requestOptionsTokens)
  const tokens = await responseTokens.json()

  const tokenType = tokens.token_type;
  const token = tokens.access_token;

  return { bannersToken: token, bannersTokenType: tokenType }
}

const loadBanners = async () => {
  const { bannersTokenType, bannersToken } = await getBannersTokens()

  let myHeaders = new Headers();
  myHeaders.append(
      'Authorization',
      `${bannersTokenType} ${bannersToken}`
  );

  const requestOptionsBanners = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
  };

  const responseBanners = await fetch(
      'https://mcb-api.v2.mycointainer.com/banners/api/banners/138226e3-d0ce-4c80-b61a-ebfa3a7a22b5',
      requestOptionsBanners
  );

  const bannersRaw = await responseBanners.json();


  return Promise.all(
    bannersRaw.items.map(async banner => {
      const imageUrl = await fetch(
        banner.imageUrl,
        requestOptionsBanners
      )
        .then((res) => res.blob())
        .then((imageBlob) => URL.createObjectURL(imageBlob));

      return { imageUrl, redirectUrl: banner.redirectUrl };
    })
  );
}

let isDragging = false

const handleDragStart = (e) => {
  isDragging = true
  e.preventDefault()
}

const onClickCapture = (e) => {
	if (isDragging) {
		isDragging = false
		e.preventDefault()
		return
	}
}

const ColdStaking = () => {
  const isMobile = useIsMobile({ mobileWidth: 1200 });
  const [banners, setBanners] = useState([])
  
  useEffect(() => loadBanners().then(setBanners), [])

  return (
    <StandardLayout>
      <Helmet>
        <title>
          {t("meta.title.coldStaking")}
        </title>
        <meta name="description" content={t("meta.description.coldStaking")} />
      </Helmet>
      <Container>
        <header className={styles.header}>
          <div className={styles['main-header']}>
            <div className={styles['header-left-column']}>
              <h1 className={styles['main-title']}>
                <span className={styles['main-title-first-part']}>{t('staking.cold.title.first')}</span>
                <span className={styles['main-title-second-part']}>{t('staking.cold.title.second')}</span>
              </h1>
              <p className={styles['header-description']}>{t('staking.cold.description')}</p>
              {
                isMobile
                  ? null
                  :
                    (
                      <button className={styles["header-button"]}>
                        {t('staking.cold.actionText')}
                        <img alt="" src={chevron} />
                      </button>
                    )
              }
            </div>
            <div className={styles["header-right-column"]}>
              <HeaderImage className={styles['header-image']} />
              {
                isMobile
                  ?
                    (
                      <button className={styles["header-button"]}>
                        Start Mining
                        <img alt="" src={chevron} />
                      </button>
                    )
                  : null
              }
            </div>
          </div>
          {
            isMobile
              ? null
              :  (
                  <AliceCarousel
                    autoWidth
                    infinite
                    mouseTracking
                    items={banners.map(banner => (
                      <a href={banner.redirectUrl} onDragStart={handleDragStart} onDrag={handleDragStart} draggable onClickCapture={onClickCapture}  style={{ width: 263, height: 130 }}>
                        <img style={{ width: 263, height: 130 }} src={banner.imageUrl} />
                      </a>
                    ))}
                    disableDotsControls
                    disableButtonsControls
                  />
                )
          }
        </header>
        {
          isMobile
            ?
              (
                <AliceCarousel
                  autoWidth
                  infinite
                  mouseTracking
                  items={banners.map(banner => (
                    <a href={banner.redirectUrl} onDragStart={handleDragStart} onDrag={handleDragStart} draggable onClickCapture={onClickCapture}  style={{ width: 263, height: 130 }}>
                      <img style={{ width: 263, height: 130 }} src={banner.imageUrl} />
                    </a>
                  ))}
                  disableDotsControls
                  disableButtonsControls
                />
              )
            : null
        }
        <HowItWork />
        <Nodes />
        <Bonuses />
        <section className={styles.section}>
          <h3 className={styles['section-title']}>{t('common.faq')}</h3>
          <Questions
            questions={[
              {
                title: t("staking.cold.q.whatIsStaking"),
                description: t("staking.cold.q.whatIsStaking.description"),
              },
              {
                title: t("staking.cold.q.howItWork"),
                description: t("staking.cold.q.howItWork.description"),
              },
              {
                title: t("staking.cold.q.isAuto"),
                description: t("staking.cold.q.isAuto.description"),
              },
              {
                title: t("staking.cold.q.averageYield"),
                description: t("staking.cold.q.averageYield.description"),
              },
            ]}
          />
        </section>
        <Footer />
      </Container>
    </StandardLayout>
  );
};

export default ColdStaking;
