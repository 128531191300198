import { useState } from "react";
import styled from "styled-components";

const YEARS = [2022, 2021, 2020, 2019, 2018];

const HISTORY = [
  [
    {
      title: "To get Series A Funding.",
      description:
        "MyCointainer has been a self-funded business since 2018. We postponed our funding to prove that our project is strong enough and presents a good investment opportunity.",
    },
    {
      title: "To launch our own coin.",
      description:
        "We want to create our own coin to support the MyCointainer ecosystem and beyond with decentralized solutions.",
    },
    {
      title: "To increase the number of supported earning assets to 200.",
      description:
        "We want to outpace and remain the go-to place for earning in crypto.",
    },
    {
      title: "Enable more earning services on the platform.",
      description: "Including DeFi protocols and more.",
    },
    {
      title: "To reach 750K users.",
      description:
        "With more services and spending capability, MyCointainer will focus on scaling and marketing our services to a wider audience.",
    },
  ],
  [
    {
      title: "Collaboration with coin developers gets stronger.",
      description:
        "Partnerhips with Cartesi, Matic Polygon, Harmony, Nuls & Avalanche announced.",
    },
    {
      title: "The launch of cold staking.",
      description:
        "Decentralized earning by delegating coins to our validators is now possible.",
    },
    {
      title: "The redesign of MyCointainer.",
      description:
        "One step closer towards enabling a microservice infrastrucutre and more services in the future.",
    },
    {
      title: "The launch of four B2B tools.",
      description:
        "Working towards bringing on board more businesses to the MyCointainer ecosystem and  produce mutual value for crypto-related businesess.",
    },
    {
      title: "We work towards a 150K user base. ",
      description: "As of June 2021, MyCointainer has ~85K active users.",
    },

    {
      title: "Open more services and collaborations with coin developers",
      description:
        "We aim to collaborate on implementing more coins, help with network validation and more.",
    },
  ],
  [
    {
      title: "Successful renewal of Financial License.",
      description:
        "Over 2 years have proven a compliant MyCointainer operations.",
    },
    {
      title: "53K registered users.",
      description:
        "Our niche staking service proves to gain a large number of early adopters.",
    },
    {
      title: "The number of supported assets grows to 113.",
      description: "MyCointainer becomes a leader in providing earning assets.",
    },
    {
      title: "Collaboration with many coin developers.",
      description:
        "Helping to secure blockchains and running marketing campaigns for NEM, Crypto.com, and Validity.",
    },
    {
      title: "The launch of POWER subscription.",
      description: "To enable various benefits for our audience.",
    },
  ],
  [
    {
      title: "Registration of a Chinese trademark.",
      description: "MyCointainer expands to selected markets.",
    },
    {
      title: "Built-in exchange added.",
      description: "Users can instantly swap and stake various assets.",
    },
    {
      title: "The launch of the Community Portal.",
      description: "Users are able to earn more with various activities.",
    },
    {
      title: "Partnerships with coin developers.",
      description: "Waves, Neturino USD, QTUM join forces with MyCointainer.",
    },
    {
      title: "The launch of Airdrops aggregator.",
      description: "Users gain more earnings possibilities than ever before.",
    },
  ],
  [
    {
      title: "The launch of the MyCointainer MVP.",
      description: "From the very beginning, 20 coins are supported.",
    },
    {
      title: "A Financial License in Estonia is obtained.",
      description:
        "MyCointainer gains an ability to trade FIAT and crypto in a compliant environment.",
    },
  ],
];

export const History = () => {
  const [chosenYear, setChosenYear] = useState(2022);

  return (
    <Container>
      <Years>
        {YEARS.map((year) => (
          <Year
            className={chosenYear === year ? "active" : void 0}
            onClick={() => setChosenYear(year)}
            key={year}
          >
            <Ellipsis />
            {year}
          </Year>
        ))}
        <Line />
      </Years>
      <List>
        {HISTORY[YEARS.indexOf(chosenYear)].map(({ title, description }) => (
          <Paper>
            <h3>{title}</h3>
            <p>{description}</p>
          </Paper>
        ))}
      </List>
    </Container>
  );
};

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  @media (min-width: 1200px) {
    width: 1008px;
  }
`;

const Paper = styled.div`
  background: #ffffff;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  width: 324px;
  height: auto;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: black;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 142%;
    color: #697386;
  }

  @media (max-width: 1200px) {
    width: 100%;
    height: fit-content;

    h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  gap: 99px;
  margin-top: 56px;

  @media (max-width: 1200px) {
    margin-top: 40px;
    gap: 32px;
  }
`;

const Years = styled.div`
  display: flex;
  flex-direction: column;
  color: #697386;
  gap: 44px;
`;

const Ellipsis = styled.div`
  border-radius: 100%;
  background-color: #e1e1e1;
  width: 10px;
  height: 10px;
  z-index: 999;
`;

const Line = styled.div`
  background-color: #e1e1e1;
  width: 2px;
  position: relative;
  height: 268px;
  margin-top: -320px;
  margin-left: 3.5px;
`;

const Year = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &:hover,
  &.active {
    color: black;

    div {
      background-color: black;
    }
  }
`;
