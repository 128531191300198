import { useAsync } from "hooks";
import * as api from "api";

const COINS_COUNT = 20;

const COMING_NEXT_COINS_COUNT = 6;

export const useCoins = () => {
  const {
    result: { coins, comingNext },
    loading,
  } = useAsync(
    async () => {
      const coins = await api.coins.find();

      return {
        coins: coins
          .filter(
            (coin) =>
              // EOS is using for icon for See all card
              !coin.isComingSoon && coin.yearlyProfit && coin.slug !== "EOS"
          )
          .slice(0, COINS_COUNT),
        comingNext: coins
          .filter((coin) => coin.isComingSoon)
          .slice(0, COMING_NEXT_COINS_COUNT),
      };
    },
    { coins: [], comingNext: [] }
  );

  return {
    coins,
    comingNext,
    loading,
  };
};
