import { FC, PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";
import t from "i18n";
import { useMedia } from "hooks";
import { Section } from "components/business/Section";
import { Button } from "./Button";
import { uris } from "const";

interface Props {
  image: ReactNode;
  title: ReactNode;
  description: string;
  action: string;
  actionHref?: string;
  primaryAction?: string;
  primaryActionHref?: string;
  hidePrimaryAction?: boolean;
  onClick?: () => void;
}

export const Header: FC<PropsWithChildren<Props>> = (props) => {
  const {
    image,
    title,
    description,
    action,
    onClick,
    children,
    actionHref,
    primaryAction,
    primaryActionHref,
    hidePrimaryAction = false,
  } = props;

  const isTablet = useMedia("(max-width: 998px)");

  const isPurple = location.href.includes(uris.developers.index);

  const imageNode = (
    <div role="img" data-is-purple={isPurple}>
      {image}
    </div>
  );

  return (
    <Section>
      <Container>
        <Detail>
          <Title
            {...(typeof title === "string"
              ? {
                  dangerouslySetInnerHTML: {
                    __html: title,
                  },
                }
              : {
                  children: title,
                })}
          />
          <Description
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          {isTablet && imageNode}
          <Action>
            {!hidePrimaryAction && (
              <Button
                href={primaryActionHref ?? uris.business.register}
                variant="primary"
                isPurple={isPurple}
              >
                {primaryAction ?? t("business.join")}
              </Button>
            )}
            <Button
              isPurple={isPurple}
              href={actionHref}
              onClick={onClick}
              variant="outlined"
              style={{
                margin: hidePrimaryAction && isTablet ? "0 auto" : void 0,
              }}
            >
              {action}
            </Button>
          </Action>
        </Detail>
        {!isTablet && imageNode}
      </Container>
      {children}
    </Section>
  );
};

const Action = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 32px;

  @media (min-width: 576px) and (max-width: 998px) {
    a {
      width: 50%;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;

  [role="img"] {
    width: min(648px, calc(100% - 482px - 36px));
    display: flex;
    justify-content: center;

    img {
      position: relative;
    }
  }

  @media (max-width: 998px) {
    flex-direction: column;

    [role="img"] {
      width: 100%;
      justify-content: center;
      display: flex;

      img {
        width: 70%;
        height: auto;
        margin-top: 24px;
      }
    }
  }

  @media (max-width: 576px) {
    [role="img"],
    img {
      width: 640px !important;
      height: auto;
    }

    img {
      left: -40%;
    }

    [data-is-purple="true"] {
      img {
        left: -20%;
      }
    }
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: ${(props) => props.theme.white};
  min-width: 448px;
  width: 448px;

  @media (max-width: 998px) {
    width: 100%;
    min-width: 100%;
    gap: 12px;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 72px;
  line-height: 83px;
  min-width: 482px;
  width: 482px;

  @media (max-width: 998px) {
    width: 100%;
    min-width: 100%;
    font-weight: 700;
    font-size: 45px;
    line-height: 55px;
  }
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 142%;

  @media (max-width: 998px) {
    font-size: 18px;
    line-height: 142%;
  }
`;
