import { Section } from "components/business/Section";
import { Questions } from "components/Questions";
import { uris } from "const";
import { FC } from "react";

export const FAQ: FC = () => {
  return (
    <Section title="FAQ">
      <Questions
        dark
        questions={[
          {
            title: "What is MyCointainer POWER?",
            description:
              "MyCointainer POWER is a paid subscription service offered on the MyCointainer Platform. However, you can activate it for free with a POWER code.",
          },
          {
            title: "What benefits does it offer?",
            description: `With the MyCointainer POWER subscription users can stake and exchange crypto for free. They can also a participate in a monthly $200 worth of giveaway. <a href="${uris.landings.power}">See all benefits.</a>`,
          },
          {
            title: "Why should I get MyCointainer POWER?",
            description:
              "We believe that MyCointainer POWER offers attractive benefits to anyone who is into crypto or fintech, from businesses to influencers as well as individual customers. In other words, POWER codes make your product or service more attractive. You can also engage your audience even more by running a simple code giveaway.",
          },
          {
            title: "How can I distribute codes to my audience?",
            description:
              "When you fill out the form and specify the purpose of your distribution we will share with you a CSV file with codes that you can simply distribute in any form you wish.",
          },
          {
            title: "Why do you offer POWER codes for free?",
            description:
              "We strongly believe in collaboration between participants in the cryptosphere. We know that POWER codes give amazing benefits that you can provide to your customers. In return we raise our brand awareness.",
          },
          {
            title: "How can I get POWER codes?",
            description: `Simply fill out <a router-ignore href="${uris.business.requestPOWER}">this form</a> ans specify your needs. Once we receive the request, you will receive the codes from our marketing representatives.`,
          },
        ]}
      />
    </Section>
  );
};
