import styled from "styled-components";
import {
  Container as AirdropContainer,
  Detail as AirdropDetail,
} from "./Airdrop";
import { Skeleton } from "components/Skeleton";

import { useIsMobile } from "hooks/useIsMobile";

const SkeletonContainer = styled(AirdropContainer)`
  border: none !important;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

const SkeletonMobileTop = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  padding: 0 12px 8px 0;
  box-shadow: var(--shadow--cell-border-down);
`;

const SkeletonMobileDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SkeletonMobileDetailList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AirdropSkeleton = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <SkeletonContainer>
        <SkeletonMobileTop>
          <Skeleton borderRadius="100%" width={60} height={60} />
          <SkeletonMobileDetail>
            <Skeleton width={239} height={22} />
            <Skeleton width={128} height={22} />
          </SkeletonMobileDetail>
        </SkeletonMobileTop>
        <SkeletonMobileDetailList>
          <Skeleton width="100%" height={22} />
          <Skeleton width="100%" height={22} />
          <Skeleton width="100%" height={22} />
        </SkeletonMobileDetailList>
      </SkeletonContainer>
    );
  }

  return (
    <SkeletonContainer>
      <Skeleton flexShrink="0" width={264} height={264} />
      <AirdropDetail>
        <Skeleton width={315} height={32} />
        <Skeleton width={564} height={36} />
        <Skeleton width={712} height={100} />
        <Skeleton marginTop="auto" width={174} height={40} />
      </AirdropDetail>
    </SkeletonContainer>
  );
};
