import t from "i18n"
import cn from 'classnames'

import styles from './styles.module.scss'

export const HowItWork = () => {
  return (
    <div className={styles.container}>
      <section className={cn(styles.card, styles['first-card'])}>
        <h2 className={styles.title}>{t("staking.cold.howItWork.ownership")}</h2>
        <p className={styles.details}>{t("staking.cold.howItWork.ownership.description")}</p>
      </section>
      <section className={cn(styles.card, styles['second-card'])}>
        <h2 className={styles.title}>{t("staking.cold.howItWork.stake")}</h2>
        <p className={styles.details}>{t("staking.cold.howItWork.stake.description")}</p>
      </section>
      <section className={cn(styles.card, styles['third-card'])}>
        <h2 className={styles.title}>{t("staking.cold.howItWork.earn")}</h2>
        <p className={styles.details}>{t("staking.cold.howItWork.earn.description")}</p>
      </section>
    </div>
  );
};
