import { FC, HTMLProps } from "react";
import styled from "styled-components";

type Props = HTMLProps<HTMLInputElement> & {
  error?: boolean;
  leading?: string;
};

const StyledInput = styled.input<Props>`
  z-index: 2;

  height: 50px;
  width: 398px;

  font-size: 0.875em;
  line-height: 1.23;

  background: var(--color--white);
  outline: none;
  border: 1px solid var(--color-monochrome--light-3);

  border-radius: var(--rounding--small);

  &::placeholder {
    color: var(--color-monochrome--light-4);
  }

  ${({ error }) => (error ? `border-color: var(--color--error)` : "")};

  ${({ leading }) =>
    leading
      ? `background: url(${leading}) no-repeat;
         background-position: 16px;
         background-color: white;
         text-indent: 54px;`
      : ""}

  @media (max-width: 1024px) {
    width: 100%;
    height: 40px;
    font-size: 0.875em;
    line-height: 22px;
  }
`;

export const Input: FC<Props> = (props) => {
  // @ts-expect-error
  return <StyledInput {...props} />;
};
