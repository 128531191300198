import { Footer } from "components/business/Footer";
import { GetInTouch } from "components/business/GetInTouch";
import { Header } from "components/business/Header";
import { Partners } from "components/business/Partners";
import { Section } from "components/business/Section";
import { NavigationTop } from "components/NavigationTop";
import { Questions } from "components/Questions";
import { FC } from "react";
import styled from "styled-components";
import { WhyJoinUs } from "./WhyJoinUs";
import hero from "./img/hero.png";

export const DevelopersNodes: FC = () => {
  return (
    <Container>
      <NavigationTop />
      <Header
        title="Enable your projects for instant use"
        description="Allow wide range of businesses, developers and invdividuals to use your projects right away. Join our network and strengthen your network at the same time."
        image={<img alt="" src={hero} width="784" />}
        primaryAction="Create an account"
        action="Explore more"
      />
      <WhyJoinUs />
      <Section title="FAQ">
        <Questions
          purple
          questions={[
            {
              title:
                "Are there any special requirements for developers to join?",
              description:
                "It’s free to join. The most important thing is that you create your project and maintain it so that everyone who wishes to use it can do so without any problems. Our DevOps team and technical assistance team can help you out as well to make sure things run smoothly.",
            },
            {
              title: "How will my project be used?",
              description:
                "Both individuals and businesses will use your project. Based on the specification of the project that you create those functionalities will be enabled. ",
            },
            {
              title:
                "Can you explain in more detail how will this service benefit my project?",
              description:
                "MyCointainer day by day focuses on growing our ecosystem to enable the best solutions in the cryptoshpere for both individuals and businesses. You can think of MyCointainer as a gateway to get exposure and intrest of various participants that constantly grow day by day. More exposure and intrest result in better awarness, stability and price of your coin.",
            },
            {
              title:
                "How can I enable my project for use in MyCointainer ecosystem?",
              description:
                "Simply <a router-ignore href='https://mcb.getcointainer.com/signin/coinDeveloper'>create your account</a>, fill in few details and you’re set. On the dashboard you will be able to request access to various sections that will enable you to create your own projects and manage them.",
            },
          ]}
        />
      </Section>
      <Partners />
      <GetInTouch
        title="Expand and grow with MyCointainer"
        description="Grow with MyCointainer"
        action="Ask a question"
        primaryAction="Get in touch"
      />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.purple};
`;
