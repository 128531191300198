import cn from 'classnames'

import { ReactComponent as CaretUpSvg }  from '../../ColdStaking/img/caret-up.svg'
import { ReactComponent as CaretDownSvg } from '../../ColdStaking/img/caret-down.svg'
import { ReactComponent as Info } from '../../ColdStaking/img/info.svg'

import styles from './styles.module.scss'

import { ReactComponent as WalletIcon } from '../img/wallet-gray.svg'

const columns = [
  { name: 'Token' },
  { name: 'Method' },
  { name: 'APR', info: true, sort: true },
  { name: 'Lockup Period', info: true },
  { name: 'Rewards left', info: true },
  { name: 'Action' }
]

const calculatePercentage = ({ total, left }) => {
  return (100 * left) / total
}

export default ({ stakingOptions }) => {
  return (
    <section className={styles.container}>
      <h3 className={styles.title}>Pick a pool & stake</h3>
      <p className={styles.description}>Select your preferred staking pool and meet all its details.</p>
      <div className={styles.table}>
        <div className={styles['table-header']}>
          {columns.map(
            ({ name: columnName, sort, info }) => {
              return (
                <div key={columnName} className={styles['header-item']}>
                  <span className={styles['column-name']}>{columnName}</span>
                  {
                    sort
                      ?
                        (
                          <div className={styles.carets}>
                            <CaretUpSvg className={styles.caret} />
                            <CaretDownSvg className={styles.caret} />
                            </div>
                        )
                      : null
                  }
                  {
                    info
                      ?
                        (
                          <Info className={styles['info-icon']} />
                        )
                      : null
                  }
                </div>
              )
            }
          )}
        </div>
        {
          stakingOptions.map((i, index) => (
            <div key={index} className={styles['row']}>
              <div className={cn(styles['data-item'], styles.token)}>
                <img className={styles['data-item-img']} src={i.logo} />
                <div className={styles['text-column']}>
                  <b className={styles['primary-text']}>{i.name}</b>
                  <small className={styles['secondary-text']}>{i.code}</small>
                </div>
              </div>
              <div className={cn(styles['data-item'], styles.method)}>
                <p className={styles['primary-text']}>{i.method}</p>
              </div>
              <div className={cn(styles['data-item'], styles.APR)}>
                <p className={styles['APR-text']}>~{i.APR}%</p>
              </div>
              <div className={cn(styles['data-item'])}>
                <b className={styles['primary-text']}>{i.lockupPeriod}</b>
              </div>
              <div className={cn(styles['data-item'], styles['rewards-left'])}>
                <div className={styles['rewards-text']}>
                  {`${i.rewardsLeft.left} ${i.code}`}
                  <small className={styles['secondary-text-rewards']}>
                    ({calculatePercentage(i.rewardsLeft)}% of all rewards)
                  </small>
                </div>
                <div className={styles.progress}>
                  <div className={styles.bar} style={{width: "25%"}} />
                </div>
              </div>
              <div className={cn(styles['data-item'], styles.action)}>
                <button
                  disabled={i.rewardsLeft.left === 0 }
                  className={styles['action-button']}
                >
                  <WalletIcon />
                  Connect Wallet
                </button>
              </div>
            </div>
          ))
        }
      </div>
    </section>
 )
}
