import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Section } from "components/business/Section";
import REVIEWS_LIST from "./reviews";
import arrowRight from "./img/arrowRight.svg";
import rating from "./img/rating.png";
import "./styles.scss";

/**
 * Real offset is depending on screen width, but re-calculating useEffect() is
 * too expensive, so 24px are enough (real ones are 24/48px, which means that
 * visual part won't be affected due to bigger offset)
 */
const LIST_OFFSET = 24;

export const Reviews = (props) => {
  const { business } = props;

  const listRef = useRef();

  const [isArrowVisible, setIsArrowVisible] = useState(true);

  const onArrowClick = () => {
    const listNode = listRef.current;

    if (!listNode) {
      return;
    }

    const liNodes = Array.from(listNode.querySelectorAll("li"));

    const { left, right } = listNode.getBoundingClientRect();

    for (const li of liNodes) {
      const { left: liLeft, right: liRight } = li.getBoundingClientRect();
      if (liLeft >= left && liRight <= right) {
        return li.nextElementSibling?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  };

  useEffect(() => {
    if (!listRef.current) {
      return;
    }

    const listNode = listRef.current;

    const handleScroll = () => {
      setIsArrowVisible(
        listNode.scrollWidth >
          listNode.scrollLeft + listNode.clientWidth + LIST_OFFSET
      );
    };

    listNode.addEventListener("scroll", handleScroll);

    return () => {
      listNode.removeEventListener("scroll", handleScroll);
    };
  }, [listRef]);

  if (business) {
    return (
      <Section
        title="Approved by the community"
        description="Our community not only never misses but also absolutely loves every airdrop or a giveaway that we run."
      >
        {isArrowVisible && (
          <ArrowRight onClick={onArrowClick}>
            <img alt="" src={arrowRight} />
          </ArrowRight>
        )}
        <List ref={listRef}>
          {REVIEWS_LIST.map(
            ({ image, userName, origin, title, description }) => (
              <ListItem>
                <Paper>
                  <Author>
                    <img alt="" src={image} />
                    <Article>
                      <h3>{userName}</h3>
                      <p>Source: {origin}</p>
                    </Article>
                  </Author>
                  <img width="162" height="31" src={rating} />
                  <Review>
                    <h3>{title}</h3>
                    <Description>{description}</Description>
                  </Review>
                </Paper>
              </ListItem>
            )
          )}
        </List>
      </Section>
    );
  }

  return (
    <div className="reviews-wrapper">
      <div className="test"></div>
      <div className="wrapper-title">
        Giving power to the people fulfills our purpose
      </div>
      <div className="wrapper-sub-title">
        Get behind the scenes of real stories from our customers. Discover how
        we empower them on their way to financial freedom.
      </div>
      <div className="reviews-list">
        {REVIEWS_LIST.map((item) => (
          <div className="review-card">
            <div className="review-info-container">
              <img className="reviewer-pic" src={item.image} alt="reviewer" />
              <div className="info-part">
                <div className="name">{item.userName}</div>
                <div className="source">{`Source: ${item.origin}`}</div>
              </div>
            </div>
            <img className="stars" src={rating} alt="stars" />
            <div className="short-review">{item.title}</div>
            <div className="long-review">{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 56px 0 0;

  width: calc(100% + 48px);
  margin-left: -24px;

  display: flex;
  flex-wrap: nowrap;
  gap: 12px;

  overflow: auto hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListItem = styled.li`
  padding: 0;
  margin: 0;

  &:first-child {
    margin-left: 48px;
  }

  &:last-child {
    margin-right: 48px;
  }

  @media (max-width: 998px) {
    &:first-child {
      margin-left: 24px;
    }

    &:last-child {
      margin-right: 24px;
    }
  }
`;

const Paper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 24px;

  background-color: white;
  width: 386px;
  height: 435px;
  border-radius: 8px;
  padding: 40px 32px;
  color: black;
`;

const Article = styled.article`
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.52;
  }

  p {
    font-size: 18px;
    line-height: 142%;
  }
`;

const Author = styled(Article)`
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 48px;
    height: 48px;
  }

  p {
    font-size: 16px;
  }
`;

const Review = styled(Article)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Description = styled.p`
  display: -webkit-box;
  height: 8.6em;
  line-height: 1.42;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ArrowRight = styled.div`
  position: absolute;
  margin-top: 256px;
  right: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 76px;
  height: 76px;
  background: white;
  border: 1px solid #cdcdcd;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);
  border-radius: 100%;

  @media (max-width: 998px) {
    display: none;
  }
`;
