import {
  dfg,
  bixin,
  bybIt,
  viaBtc,
  mirana,
  spartan,
  jsquare,
  ldCapital,
  mapleBlock,
  shimaCapital,
} from './img/index';

export const investorsList = [
  bybIt,
  shimaCapital,
  mapleBlock,
  spartan,
  mirana,
  bixin,
  ldCapital,
  jsquare,
  dfg,
  viaBtc
];
