import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

interface Props {
  title: string;
  description: string;
}

export const Section: FC<PropsWithChildren<Props>> = ({
  title,
  description,
  children,
}) => {
  return (
    <Container>
      {title && (
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      )}
      {description && (
        <Description
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
      {children}
    </Container>
  );
};

const Title = styled.h1`
  font-weight: 700;
  font-weight: 700;
  font-size: 46px;
  line-height: 56px;
  color: black;
  text-align: center;

  @media (max-width: 998px) {
    text-align: left;
    font-size: 45px;
    line-height: 55px;

    br {
      display: none;
    }
  }
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 142%;
  color: #697386;
  text-align: center;

  @media (max-width: 998px) {
    text-align: left;

    br {
      display: none;
    }
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 64px 24px;
  margin: 0 auto;
  max-width: 1200px;

  @media (max-width: 998px) {
    align-items: flex-start;
    padding: 64px 24px 0;
  }
`;
