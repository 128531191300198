import React, { lazy, Suspense } from "react";
import { Helmet } from 'react-helmet';

import { AssetsContextProvider } from 'contexts/assets';
import { TopInfo } from "./components/TopInfo";
import { PassiveIncomes } from "./components/PassiveIncomes";
import { EachRewards } from "./components/EachRewards";
import { BoostBalance } from "./components/BoostBalance";
import { FreeCoins } from "./components/FreeCoins";
import { PartnersSlider } from "./components/PartnersSlider";
import { OurInvestors } from "./components/OurInvestors";
import { Security } from "./components/Security";
import { CryptosList } from "./components/CryptosList";
import { News } from "./components/News";
import { Reviews } from "./components/Reviews";
import { StandardLayout } from "components/StandardLayout";

import { Footer } from "components/Footer";
import { Section } from "components/Section";
import { Guide } from "components/Guide";
import t from "i18n";
import { Questions } from "components/Questions";
import { Calculator } from "components/Calculator";

const Main = () => {
  return (
    <StandardLayout>
      <Helmet>
        <title>
          {t("meta.title.main")}
        </title>
        <meta name="description" content={t("meta.description.main")}/>
      </Helmet>
      <TopInfo />
      <PassiveIncomes />
      <Section>
        <Guide title={t("guide.title")} action={t("guide.action")} />
      </Section>
      <AssetsContextProvider>
          <EachRewards />
          <BoostBalance />
          <FreeCoins />
          <Calculator />
          <CryptosList />
          <Reviews />
          <Security />
          <PartnersSlider />
          <OurInvestors />
          <News />
      </AssetsContextProvider>
      <Section title={t("common.faq")}>
        <Questions
          questions={[
            {
              title: t("main.q.howToEarn.title"),
              description: t("main.q.howToEarn.description"),
            },
            {
              title: t("main.q.howToUseMyCointainer.title"),
              description: t("main.q.howToUseMyCointainer.description"),
            },
            {
              title: t("main.q.howToCalculateProfit.title"),
              description: t("main.q.howToCalculateProfit.description"),
            },
            {
              title: t("main.q.whatIsBesideStaking.title"),
              description: t("main.q.whatIsBesideStaking.description"),
            },
            {
              title: t("main.q.whatIsCostOfMyCointainer.title"),
              description: t("main.q.whatIsCostOfMyCointainer.description"),
            },
            {
              title: t("main.q.isMyCointainerSafe.title"),
              description: t("main.q.isMyCointainerSafe.description"),
            },
          ]}
        />
      </Section>
      <Footer />
    </StandardLayout>
  );
};

export default Main;
