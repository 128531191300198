import chevron from "./chevron.svg";
import textureHeader from "./header-texture.svg";
import airdrops from "./airdrops.svg";
import assignmentsAirdrops from "./airdrops-assignments.svg";
import choiceAirdrops from "./airdrops-choice.svg";
import incomeAirdrops from "./airdrops-income.svg";
import textureMissingAirdrops from "./airdrops-missing-texture.svg";
import imageCashback from "./cashback-header-image.png";
import minus from "./minus.svg";
import plus from "./plus.svg";
import whiteMinus from "./whiteMinus.svg";
import whitePlus from "./whitePlus.svg";
import mail from "./mail.svg";
import appStore from "./app-store.svg";
import playMarket from "./play-market.svg";
import qrCode from "./qr-code.svg";
import qr from "./qr.svg";
import footerBackground from "./footer-background.svg";
import footerImage from "./footer-image.png";
import textureFooter from "./footer-texture.svg";
import chrome from "./chrome.svg";
import edge from "./edge.svg";
import shop from "./cashback-shop.svg";
import download from "./cashback-download.svg";
import earnCrypto from "./cashback-earn-crypto.svg";
import howItWorkArrows from "./how-it-work-arrows.svg";
import calendar from "./calendar.svg";
import share from "./share.svg";
import telegram from "./telegram.svg";
import instagram from "./instagram.svg";
import email from "./email.svg";
import linkedIn from "./linkedin.svg";
import twitter from "./twitter.svg";
import accountCreation from "./account-creation.svg";
import discord from "./discord.svg";
import facebook from "./facebook.svg";
import reddit from "./reddit.svg";
import kyc from "./kyc.svg";
import winnersAirdrop from "./airdrop-winners.svg";
import imageColdStaking from "./cold-staking-image.svg";
import textureEnjoyBonuses from "./enjoy-bonuses-arrows.svg";
import enjoyBonusesImage from "./enjoy-bonuses-image.svg";
import enjoyBonusesImageMobile from "./enjoy-bonuses-image-mobile.svg";
import i50pFeesBack from "./50p-fees-back.svg";
import giveawayTicket from "./giveaway-ticket.svg";
import skipStakingFeeToken from "./skip-staking-fee-token.svg";
import imageCashbackMobile from "./cashback-header-image-mobile.png";
import imageAssetsList from "./assets-list-header-image.png";
import iconCashback from "./cashback-icon.svg";
import iconColdStaking from "./cold-staking-icon.svg";
import iconStaking from "./staking-icon.svg";
import iconAirdrops from "./airdrop-icon.svg";
import iconExchange from "./exchange-icon.svg";
import assetsListBannerTexture from "./assets-list-banner-texture.svg";
import assetsListBannerImage from "./assets-list-banner-image.svg";
import info from "./info.svg";
import sorter from "./sorter.svg";
import chevronDown from "./chevron-down.svg";
import chevronUp from "./chevron-up.svg";
import search from "./search.svg";
import filters from "./filters.svg";
import filtersApplied from "./filters-applied.svg";
import checked from "./checked.svg";
import chart from "./chart.svg";
import chartHover from "./chart-hover.svg";
import chartMobile from "./chart-mobile.svg";
import chartMobileHover from "./chart-mobile-hover.svg";
import mobileAppStore from "./mobile-app-store.svg";
import chevronFooter from "./chevron-footer.svg";
import mobileGoogleStore from "./mobile-google-store.svg";
import assetHeader from "./asset-header-image.svg";
import instantAllocation from "./icon-instant-allocation.svg";
import everydayReward from "./icon-everyday-reward.svg";
import compoundedRewards from "./icon-compounded-rewards.svg";
import howToImg from "./asset-how-to-image.svg";
import howToImgMobile from "./asset-how-to-image-mobile.svg";
import howToTexture from "./how-to-texture.svg";
import howToTextureMobile from "./asset-how-to-texture-mobile.svg";
import copy from "./copy.svg";
import surface from "./texture.svg";

import notFound from "./not-found.svg";

const actions = {
  telegram,
  instagram,
  twitter,
  email,
  linkedIn,
  discord,
  facebook,
  reddit,
  kyc,
  account_creation: accountCreation,
};

export {
  chevron,
  textureHeader,
  airdrops,
  assignmentsAirdrops,
  choiceAirdrops,
  incomeAirdrops,
  textureMissingAirdrops,
  mail,
  minus,
  plus,
  appStore,
  playMarket,
  qrCode,
  footerImage,
  footerBackground,
  textureFooter,
  qr,
  chrome,
  edge,
  download,
  shop,
  earnCrypto,
  calendar,
  share,
  howItWorkArrows,
  actions,
  winnersAirdrop,
  imageColdStaking,
  textureEnjoyBonuses,
  enjoyBonusesImage,
  enjoyBonusesImageMobile,
  i50pFeesBack,
  giveawayTicket,
  skipStakingFeeToken,
  imageCashback,
  imageCashbackMobile,
  imageAssetsList,
  iconCashback,
  iconAirdrops,
  iconColdStaking,
  assetsListBannerTexture,
  iconStaking,
  iconExchange,
  assetsListBannerImage,
  info,
  sorter,
  search,
  chevronDown,
  filters,
  checked,
  mobileAppStore,
  mobileGoogleStore,
  chevronFooter,
  chart,
  chartHover,
  chartMobile,
  chartMobileHover,
  filtersApplied,
  assetHeader,
  instantAllocation,
  everydayReward,
  compoundedRewards,
  howToImg,
  howToImgMobile,
  howToTexture,
  howToTextureMobile,
  copy,
  notFound,
  surface,
  whiteMinus,
  whitePlus,
  chevronUp,
};
