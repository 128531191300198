import pic from "./img/pic.jpg";
import "./styles.scss";
import arrowIcon from "./img/arrow.svg?raw";
import { useWindowDimension } from "hooks/useWindowDimensions";
import { URLS } from "const";

export const EachRewards = () => {
  const { width } = useWindowDimension();
  const isMobile = width <= 1280;

  return (
    <div className="rewards-wrapper">
      <div className="info-container">
        <div className="title">Exchange at the best rates and earn rewards</div>
        <div className="sub-title">
          We are connected to the top 10 exchanges! This way, you will get the
          best rates every time you buy or sell. Choose from 100 + assets and
          exchange one to another. Maximize your income with the extra bonus you
          receive with each trade.
        </div>
        {!isMobile && (
          <div className="buttons-container">
            <a className="btn" href={URLS.LANDINGS.EXCHANGE}>
              Learn More <img alt="arrow" src={arrowIcon} />
            </a>
          </div>
        )}
      </div>
      <img alt="rewards" src={pic} className="rewards-pic" />
      {isMobile && (
        <div className="buttons-container">
          <a className="btn" href={URLS.LANDINGS.EXCHANGE}>
            Learn More <img alt="arrow" src={arrowIcon} />
          </a>
        </div>
      )}
    </div>
  );
};
