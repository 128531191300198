import { FC } from "react";

import styled from "styled-components";

import { Airdrop } from "./Airdrop";
import { AirdropSkeleton } from "./AirdropSkeleton";
import { Button } from "components/Button";

import { useAsync } from "hooks/useAsync";

import * as api from "api";

import { AIRDROPS_DISPLAY_LIMIT, URLS } from "const";

import t from "i18n";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: var(--color--secondary);

  margin: -16px 0 48px;

  max-width: 580px;
`;

const List = styled.ul`
  list-style-type: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  padding: 0 32px;
  margin: 24px 0 60px;

  @media (max-width: 1024px) {
    gap: 8px;
    padding: 0 16px;
    width: 100%;
  }
`;

const SizedButton = styled(Button)`
  width: min(376px, calc(100% - 64px));
`;

const Aggregator = styled(Description)`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Listing: FC = () => {
  const { result: airdrops, loading } = useAsync(async () => {
    return await api.airdrops
      .find()
      .then((airdrops) => airdrops.slice(0, AIRDROPS_DISPLAY_LIMIT));
  }, []);

  return (
    <Container>
      <Description>{t("airdrops.ongoing.description")}</Description>
      <Aggregator>{t("airdrops.ongoing.aggregator")}</Aggregator>
      <List>
        {loading ? (
          <AirdropSkeleton />
        ) : (
          (airdrops ?? []).map((airdrop) => (
            <Airdrop {...airdrop} key={airdrop.name} />
          ))
        )}
      </List>
      <SizedButton role="link" color="primary" href={URLS.SIGNUP}>
        {t("airdrops.checkAll")}
      </SizedButton>
    </Container>
  );
};
