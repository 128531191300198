import { uris } from "const";
import { BUSINESS_NAVIGATION } from "../../navigation";
import { useMedia } from "hooks";
import { FC, useState } from "react";
import styled from "styled-components";
import logo from "./img/logo.svg";
import chevronDown from "./img/chevronDown.svg";

export const Footer: FC = () => {
  const isBusiness = location.pathname.includes("/business");

  const isDevelopers = location.pathname.includes("/coin-developers");

  const [opened, setOpened] = useState<undefined | number>(0);

  const isTablet = useMedia("(max-width: 998px)");

  const type = isBusiness ? "business" : "developers";

  return (
    <Container>
      <Row>
        <a href={`/${type}`}>
          <img width="147" height="23" alt="" src={logo} />
        </a>
        <div role="list">
          <a href="/">PERSONAL</a>
          <a
            href={uris.business.index}
            className={isBusiness ? "active" : void 0}
          >
            BUSINESS
          </a>
          <a
            href={uris.developers.index}
            className={isDevelopers ? "active" : void 0}
          >
            COIN DEVELOPER
          </a>
        </div>
      </Row>
      <Rows>
        {BUSINESS_NAVIGATION.FOOTER.map((column) => (
          <Column>
            {column.map(({ title, href, children, index }) => (
              <>
                <h3
                  onClick={() => setOpened(opened === index ? void 0 : index)}
                >
                  <a
                    href={isTablet ? void 0 : href}
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  />
                  <img
                    alt=""
                    style={
                      opened === index
                        ? {
                            transform: "scale(1, -1)",
                          }
                        : void 0
                    }
                    src={chevronDown}
                  />
                </h3>
                {children.map(({ title, href, ...child }) => (
                  <a
                    style={{
                      display: opened === index || !isTablet ? "block" : "none",
                    }}
                    href={href}
                  >
                    {/* @ts-expect-error */}
                    {child.icon && <img alt="" src={child.icon} />}
                    {title}
                  </a>
                ))}
              </>
            ))}
          </Column>
        ))}
      </Rows>
      <Copyright>
        © Copyright {new Date().getFullYear()} MyCointainer. All rights reserved. Various trademarks
        held by their respective owners.
      </Copyright>
    </Container>
  );
};

const Row = styled.div`
  padding: 48px 120px 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  border-bottom: 1px solid rgba(101, 210, 244, 0.3);

  div[role="list"] {
    display: flex;
    gap: 32px;

    a {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #99a6ba;
      cursor: pointer;
      text-decoration: none;

      &.active,
      &:hover {
        color: #65d2f4;
      }
    }
  }

  @media (max-width: 1200px) {
    padding: 36px 24px;
  }

  @media (max-width: 998px) {
    div[role="list"] {
      display: none;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 0 12px;

  h3,
  a {
    cursor: pointer;
    color: ${(props) => props.theme.white};
    user-select: none;
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;

    display: flex;
    align-items: center;
    gap: 8px;

    & > img {
      width: 20px;
      margin-top: 2px;
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        width: 12px;
      }
    }
  }

  & > a {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.darkGray};
    margin-bottom: 12px;

    &:hover {
      color: ${(props) => props.theme.white};
    }

    img {
      width: 13px;
      height: 13px;
      margin-right: 12px;
    }
  }

  a:not([style*="display: none"]) + h3 {
    margin-top: 8px;
  }

  @media (min-width: 998px) {
    h3 > img {
      display: none;
    }
  }

  @media (max-width: 998px) {
    width: 100%;

    h3 a img {
      display: none;
    }

    &:not(:first-child) {
      margin-top: 8px;
    }
  }
`;

const Copyright = styled.div`
  padding: 32px 120px;
  font-size: 14px;
  line-height: 17px;
  color: #76808f;

  @media (max-width: 998px) {
    padding: 36px 40px;
  }
`;

const Rows = styled.div`
  padding: 48px 120px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(118, 128, 143, 0.3);
  color: ${(props) => props.theme.white};
  display: flex;

  @media (max-width: 1200px) {
    padding: 48px 24px;
  }

  @media (max-width: 998px) {
    flex-direction: column;
    padding: 36px;
  }
`;

const Container = styled.footer`
  display: flex;
  background-color: ${(props) => props.theme.black};
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
