import { Section } from "components/business/Section";
import styled from "styled-components";
import integrationWithExchange from "./img/integration-with-exchange.png";
import nodeDeployment from "./img/node-deployment.png";
import stakingListing from "./img/staking-listing.png";
import checked from "./img/checked.svg";
import { useMedia } from "hooks";
import { Button } from "components/business/Button";
import { uris } from "const";

export const Solutions = () => {
  const isTablet = useMedia("(max-width: 998px)");

  const stakingFrame = (
    <StakingListingImageFrame>
      <img src={stakingListing} width="620" alt="" />
    </StakingListingImageFrame>
  );

  return (
    <Section title="Solutions for coin developers">
      <Container>
        <Solution>
          <List>
            <ListTitle>Integration within our exchange</ListTitle>
            <ListItem>
              Let our audiences gain easy access to your coin and the
              possibility to invest.
            </ListItem>
            <ListItem>
              We will enable your coin for exchange to trade against Bitcoin &
              various FIAT currencies.
            </ListItem>
            <ListItem>
              Gain worldwide reach with FIAT payment methods and sell your coins
              globally thanks to licensed operations.
            </ListItem>
            <Button variant="text" href={uris.developers.register}>
              Contact partnerships
            </Button>
          </List>
          <img src={integrationWithExchange} width="464" alt="" />
        </Solution>
        <Solution>
          {!isTablet && stakingFrame}
          <List>
            <ListTitle>
              Listing for staking or another rewarding protocol
            </ListTitle>
            <ListItem>
              Maintain stability of your coin and increase the offering to your
              potential audience at the same time.
            </ListItem>
            <ListItem>
              We help coin developers integrate their rewarding protocol into
              our automated rewarding mechanism.
            </ListItem>
            <ListItem>
              Supporting masternodes, validators, defi protocols, liqudity
              mining and much more.
            </ListItem>
            <Button variant="text" href={uris.developers.register}>
              Contact partnerships
            </Button>
          </List>
          {isTablet && stakingFrame}
        </Solution>
        <Solution>
          <List>
            <ListTitle>Node Deployment Participation</ListTitle>
            <ListItem>
              Make your nodes available for utilizaiton by hundreds of
              businesses and partners that work with MyCointainer.
            </ListItem>
            <ListItem>
              Secure your blockchain by enabling easy access for wider audiences
              to run nodes and support your chain.
            </ListItem>
            <ListItem>
              Let everyone help you run testnet, mainnets, data explorers as
              well as custom projects.
            </ListItem>
            <Button variant="text" href={uris.developers.register}>
              Get free access
            </Button>
          </List>
          <img src={nodeDeployment} width="549" alt="" />
        </Solution>
      </Container>
    </Section>
  );
};

const StakingListingImageFrame = styled.div`
  // Image is broken in Figma (it has too big padding-bottom)

  max-height: 420px;

  img {
    position: relative;
  }

  @media (max-width: 998px) {
    max-height: 300px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Solution = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;

  @media (min-width: 998px) {
    margin-top: 112px;

    img {
      margin-top: -32px;
    }
  }

  @media (max-width: 998px) {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 24px;

    img {
      max-width: 375px;
      width: 100%;
      margin-bottom: -8px;
    }
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 998px) {
    gap: 24px;
    width: 100%;
  }
`;

const ListTitle = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  max-width: 524px;
  margin-bottom: 12px;

  @media (max-width: 998px) {
    width: 100%;
    max-width: 100%;
  }
`;

const ListItem = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: #a8abd1;
  max-width: 452px;

  display: flex;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: 998px) {
    width: 100%;
    max-width: 100%;
  }

  &:before {
    content: url(${checked});
    width: 14px;
    height: 14px;
    padding-top: 6px;
  }
`;
