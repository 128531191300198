import t from "i18n";
import styled from "styled-components";
import { Spinner } from "components/Spinner";
import { Node } from "routes/ColdStaking/Node";
import { Section } from "components/Section";
import { Button } from "components/Button";
import { useCoins } from "./useCoins";
import { URLS } from "const";
import moreAssets from "./img/more-assets.webp";
import chevronRight from "routes/ColdStaking/img/chevron-right.svg";

export const Coins = () => {
  const { loading, coins, comingNext } = useCoins();

  return (
    <>
      <Section
        title={t("staking.assets.title")}
        description={t("staking.assets.description")}
      >
        <Container>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {coins.map((coin) => (
                <Node
                  url={`${URLS.LANDINGS.ASSETS}/${coin.assetKey}`}
                  chevron
                  {...coin}
                  key={coin.slug}
                />
              ))}
              <Card>
                <img alt="" src={moreAssets} />
                <Button role="link" color="primary" href={URLS.SIGNUP}>
                  See all
                  <img width="6" height="11" alt="" src={chevronRight} />
                </Button>
              </Card>
            </>
          )}
        </Container>
      </Section>
      {!loading && (
        <Section title={t("staking.assets.next")}>
          <Container>
            {comingNext.map((coin) => (
              <Node chevron {...coin} key={coin.slug} />
            ))}
          </Container>
        </Section>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;

  @media (max-width: 1200px) {
    padding: 0 16px;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--rounding--medium);

  padding: 0 16px;

  width: 100%;
  height: 78px;

  box-shadow: var(--shadow--middle);

  border: solid 1px var(--color-monochrome--light-1);

  img {
    height: 28px;
  }

  a[role="link"] {
    width: 92px;
    height: 40px;
    margin: 0;
  }

  @media (max-width: 1200px) {
    a[role="link"] {
      color: var(--color--primary);
      text-transform: capitalize;
      background-color: var(--color--white);
    }
  }

  @media (min-width: 1200px) {
    width: 150px;
    height: 160px;

    padding: 0;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    img {
      height: 50px;
    }

    a[role="link"] img {
      display: none;
    }
  }
`;
