import { FC } from "react";
import styled from "styled-components";
import { TermsContent } from './terms-content';

export const TermsBarebones: FC = () => {
  return (
    <Container>
      <TermsContent />
    </Container>
  );
};

export const Container = styled.div`
  padding: 40px 24px 80px;
`;
