import styled from "styled-components";

interface ListProps {
  hideBackground?: boolean;
}

export const List = styled.div<ListProps>`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  box-sizing: border-box;
  margin-top: 64px;

  @media (max-width: 768px) {
    background: ${(props) =>
      props.hideBackground ? "transparent" : props.theme.russianBlack};
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-bottom: -32px;
  }
`;

interface ListItemProps {
  size: number;
  purple?: boolean;
}

export const ListItem = styled.a<ListItemProps>`
  width: ${(props) => `calc(100% / ${props.size} - 12px)`};
  background: ${(props) =>
    props.purple ? "rgba(79, 35, 255, 0.3)" : props.theme.russianBlack};
  border-radius: 4px;
  padding: 64px 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  text-decoration: none;

  &:hover {
    background: ${(props) =>
      props.purple
        ? props.href
          ? "rgba(79, 35, 255, 0.5)"
          : "rgba(79, 35, 255, 0.3)"
        : props.theme.russianBlack};
  }

  img {
    width: 48px;
    height: 48px;
  }

  @media (max-width: 998px) {
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 32px 40px;
  }
`;

export const ListItemTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 152%;
  color: ${(props) => props.theme.white};
  white-space: nowrap;
`;

interface ListItemDescriptionProps {
  purple?: boolean;
}

export const ListItemDescription = styled.p<ListItemDescriptionProps>`
  font-size: 18px;
  line-height: 142%;
  color: ${(props) => (props.purple ? "#A8ABD1" : props.theme.darkGray)};

  a {
    color: ${(props) => props.theme.white};

    &:not(:hover) {
      text-decoration: none;
    }
  }
`;
