import styled from "styled-components";

import t from "i18n";

import { qr } from "../assets";

const Popup = styled.div`
  position: absolute;
  z-index: 2147483647;
  background-color: var(--color--white);
  padding: 8px 8px 12px;
  margin: 8px 0;
  box-shadow: 0px 10px 30px rgba(208, 216, 223, 0.25);
  border-radius: 6px;

  p {
    margin-top: 12px;
    width: 140px;
    font-size: 1rem;
    line-height: 1.57;
    text-align: center;
    color: var(--color--secondary);
  }
`;

export const QrPopup = ({ refBoundingRect }) => {
  const { x, y, height } = refBoundingRect;

  const style = {
    left: `${x}px`,
    top: `${window.scrollY + y + height}px`,
    transform: `translateX(calc(-50% + ${height}px / 2))`,
  };

  return (
    <Popup style={style}>
      <img alt="" src={qr} />
      <p>{t("footer.qr.scan")}</p>
    </Popup>
  );
};
