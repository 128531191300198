import { FC } from "react";
import {
  List,
  ListItem,
  ListItemDescription,
  ListItemTitle,
} from "components/business/List";
import { Section } from "components/business/Section";
import needs from "./img/needs.svg";
import campaign from "./img/campaign.svg";
import quote from "./img/quote.svg";

export const GetStarted: FC = () => {
  return (
    <Section
      title="How to get started?"
      description="Start your own custom campaign in a few simple steps."
    >
      <List>
        <ListItem size={3}>
          <img alt="" src={needs} />
          <ListItemTitle>Specify your needs</ListItemTitle>
          <ListItemDescription>
            Fill out this short form, answer a few questions, describe your
            needs and requirements so that we can create the perfect campaign
            for you.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={quote} />
          <ListItemTitle>Get a free quote</ListItemTitle>
          <ListItemDescription>
            We will answer your questions and will get back to you if we need to
            specify any details of your campaign. You will then get a free quote
            for your campaign.
          </ListItemDescription>
        </ListItem>
        <ListItem size={3}>
          <img alt="" src={campaign} />
          <ListItemTitle>Launch your campaign</ListItemTitle>
          <ListItemDescription>
            We will start working on your campaign as soon as possible and will
            let you know about the estimated time of launching. All set!
          </ListItemDescription>
        </ListItem>
      </List>
    </Section>
  );
};
