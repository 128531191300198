import { FC } from "react";
import styled from "styled-components";
import { Button } from "components/business/Button";
import { uris } from "const";

interface Props {
  title?: string;
  action?: string;
  actionHref?: string;
  description?: string;
  primaryAction?: string;
  primaryActionHref?: string;
}

export const GetInTouch: FC<Props> = (props) => {
  const {
    title,
    description,
    action,
    primaryAction,
    actionHref,
    primaryActionHref,
  } = props;

  const isPurple = location.href.includes(uris.developers.index);

  return (
    <Container purple={isPurple}>
      <Detail>
        <p>{description ?? "Try MyCointainer Business"}</p>
        <Title
          dangerouslySetInnerHTML={{
            __html: title ?? "Take a leap into your industry",
          }}
        />
        <GetStartedButton href={uris.business.register} variant="primary">
          Get Started
        </GetStartedButton>
      </Detail>
      <Action>
        <Button
          isPurple={isPurple}
          variant="outlined"
          href={actionHref ?? uris.business.enquiry}
        >
          {action ?? "Get in touch"}
        </Button>
        <Button
          isPurple={isPurple}
          variant="primary"
          href={primaryActionHref ?? uris.business.register}
        >
          {primaryAction ?? "Join now"}
        </Button>
      </Action>
    </Container>
  );
};

interface WithPurpleProps {
  purple?: boolean;
}

const GetStartedButton = styled(Button)`
  margin-top: 36px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Action = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 998px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.section<WithPurpleProps>`
  background-color: ${(props) =>
    props.purple ? "rgba(79, 35, 255, 0.4)" : props.theme.russianBlack};
  width: calc(100% + 48px);
  margin-left: -24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
  padding: 104px 132px;

  @media (max-width: 768px) {
    padding: 48px;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    color: ${(props) => props.theme.white};
    font-size: 20px;
    line-height: 1.52;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 46px !important;
  line-height: 56px !important;

  @media (max-width: 768px) {
    br {
      display: none;
    }
  }
`;
