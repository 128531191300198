import { useAsync } from "hooks";
import * as api from "api";
import iost from "./img/iost-coin.svg";

export const tutorials = {
  AVAX: "https://company.mycointainer.com/beginners-guide-to-avalanche-cold-staking/",
  BTSG: "https://company.mycointainer.com/beginners-guide-to-bitsong-cold-staking/xw",
  CTSI: "https://company.mycointainer.com/beginners-guide-to-cartesi-cold-staking/",
  DOCK: "https://company.mycointainer.com/beginners-guide-to-dock-cold-staking/",
  EGLD: "https://company.mycointainer.com/beginners-guide-to-elrond-cold-staking/",
  NGM: "https://company.mycointainer.com/beginners-guide-to-emoney-cold-staking/",
  FIO: "https://company.mycointainer.com/beginners-guide-to-fio-protocol-voting-block-producer/",
  LTO: "https://company.mycointainer.com/beginners-guide-to-ltonetwork-cold-staking/",
  NEBL: "https://company.mycointainer.com/beginners-guide-to-neblio-cold-staking/",
  XRPT: "https://company.mycointainer.com/beginners-guide-to-persistence-cold-staking/",
  PIVX: "https://company.mycointainer.com/beginners-guide-to-pivx-cold-staking/",
  MATIC:
    "https://company.mycointainer.com/beginners-guide-to-polygon-matic-cold-staking/",
  QTUM: "https://company.mycointainer.com/beginners-guide-to-qtum-cold-staking/",
  DEL: "https://company.mycointainer.com/beginners-guide-to-decimal-cold-staking/",
  IRIS: "https://company.mycointainer.com/beginners-guide-to-irisnet-cold-staking/",
  VLX: "https://company.mycointainer.com/beginners-guide-to-velas-cold-staking/",
  FUSE: "https://company.mycointainer.com/beginners-guide-to-fuse-cold-staking/",
  ONE: "https://company.mycointainer.com/beginners-guide-to-harmony-cold-staking/",
  NULS: "https://company.mycointainer.com/beginners-guide-to-nuls-cold-staking/",
  CUDOS:
    "https://company.mycointainer.com/beginners-guide-to-cudos-cold-staking/",
};

const offline = [
  {
    name: "IOST",
    slug: "IOST",
    imageUrl: iost,
    isOffline: true,
  },
];

export const useCoins = () => {
  const { result: coins, loading } = useAsync(async () => {
    const coins = await api.coins.find();
    return [...coins.filter((coin) => coin.coldStaking.show), ...offline];
  }, []);

  return {
    coins,
    loading,
    tutorials,
  };
};
