import styled from "styled-components";

export const Input = styled.input`
  background: var(--color--white);
  border: 1px solid var(--color-monochrome--light-2);
  border-radius: 4px;
  outline: none;

  width: 221px;
  height: 40px;

  text-indent: 8px;

  &::placeholder {
    color: var(--color--secondary);
  }
`;
