import { uris } from "const";
import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

type Props = PropsWithChildren<{
  title?: string;
  description?: string;
  id?: string;
  light?: boolean;
  purple?: boolean;
}>;

export const Section: FC<Props> = (props) => {
  const { children, title, description, id, light } = props;

  const isPurple = location.href.includes(uris.developers.index);

  return (
    <Container light={light} purple={isPurple} id={id}>
      {title && <Title>{title}</Title>}
      {description && (
        <Description
          light={light}
          purple={isPurple}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
      {children}
    </Container>
  );
};

const Container = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${(props) =>
    props.purple && !props.light
      ? props.theme.purple
      : props.light
      ? props.theme.white
      : props.theme.black};
  color: ${(props) => (props.light ? props.theme.black : props.theme.white)};
  padding: 72px 24px;

  @media (max-width: 998px) {
    padding: 32px 24px;
  }
`;

const Title = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 46px;
  line-height: 56px;

  @media (max-width: 998px) {
    text-align: left;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }
`;

const Description = styled.p<Props>`
  text-align: center;
  font-size: 18px;
  line-height: 142%;
  white-space: pre-wrap;
  color: ${(props) =>
    props.purple ? "#A8ABD1" : props.light ? "#697386" : props.theme.darkGray};

  @media (max-width: 998px) {
    text-align: left;

    br {
      display: none;
    }
  }
`;
