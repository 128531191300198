import React from "react";
import { URLS } from "const";
import coinPic from "./img/coin.png";

import "./styles.scss";

export const HowToEarn = () => {
  return (
    <div className="how-to-earn-container">
      <img src={coinPic} className="coin-pic" alt="coin-pic" />
      <div className="info-container">
        <div className="title">How to earn with Myсointainer exchange</div>
        <div className="description">
          We are connected to the top 13 exchanges, which is why you get the
          best rates every time you buy, sell or exchange one for another from
          our impressive list of over 100 assets.
          <br />
          <br />
          That's not all, every asset swap means bonus coins for you! There are
          many ways to earn with MyCointainer.
        </div>
        <a className="exchange-button" href={URLS.SIGNUP}>
          Exchange with bonus
        </a>
      </div>
    </div>
  );
};
