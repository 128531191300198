const MOBILE_WIDTH = 1024;

export const IS_MOBILE = window.innerWidth < MOBILE_WIDTH;

export const IS_BROWSER = typeof document !== "undefined";

export const MAILTO_CONTACT = "mailto:support@mycointainer.com";

const baseUrl =
  window.location.hostname === "localhost"
    ? "https://mycointainer-test.herokuapp.com"
    : window.location.origin;

export const POLICY_COOKIE_MAX_AGE = 720;
export const POLICY_COOKIE_VALUE = "true";
export const POLICY_COOKIE_KEY = "policy-approve";

export const COPIED_TOOLTIP_DURATION = 4_000;

export const SUBSCRIBED_POPUP_ANIMATION_DURATION = 4_000;

export const uris = {
  agreements: {
    termsAndConditions: "/agreement/terms-conditions",
    privacyPolicy: "/agreement/privacy-policy",
  },
  developers: {
    index: "/coin-developers",
    nodes: "/coin-developers/solutions/node-deployment",
    staking: "/coin-developers/solutions/staking-and-mining",
    login: "https://solutions.mycointainer.com/signin/coinDeveloper",
    register: "https://solutions.mycointainer.com/signup/coinDeveloper",
  },
  business: {
    index: "/business",
    login: "https://solutions.mycointainer.com/signin/business",
    register: "https://solutions.mycointainer.com/signup/business",
    staking: "/business/services/staking-nodes",
    nodes: "/business/solutions/node-deployment",
    giveaways: "/business/services/airdrops-and-giveaways",
    marketing: "/business/services/marketing-campaigns",
    power: "/business/services/power-codes",
    wallet: "business/solutions/crypto-wallets",
    enquiry:
      "https://docs.google.com/forms/d/e/1FAIpQLSdu9rS4Y4DBZyRXoT4f3GgiYQiR8sKstGoiKEd7UxNJnELOlg/viewform",
    askQuestion: "https://t.me/BartoszPozniak",
    campaign:
      "https://docs.google.com/forms/d/e/1FAIpQLSdbuKhSEREiv43uiZ3eGzwnqTxJIaxJSk0XhwH93t2xRH9l_Q/viewform",
    requestPOWER:
      "https://docs.google.com/forms/d/e/1FAIpQLSd6N-9Iqmebet-6TepjFMNHd_5m-AEj8ts37nmcb8tkcNIfrA/viewform",
  },
  solutions: {
    login: "https://solutions.mycointainer.com/signin",
    register: "https://solutions.mycointainer.com/signup",
  },
  landings: {
    assets: "/assets",
    power: "/power",
    exchange: "/exchange",
    airdrops: "/airdrops",
    power: "/power",
  },
};

export const URLS = {
  APP_STORE: "https://apps.apple.com/us/app/mycointainer/id1481210557",
  GOOGLE_STORE:
    "https://play.google.com/store/apps/details?id=com.mycontainer&hl=en&gl=US",
  BROWSER_EXTENSION:
    "https://chrome.google.com/webstore/detail/mycointainer-crypto-cashb/nbekdogaeddlaaopdfghbjjkakfajkbm",
  CASHBACK: `${baseUrl}/app/cashback/shops`,
  AIRDROPS: `${baseUrl}/app/airdrops`,
  COLD_STAKING: `${baseUrl}/app/cold-staking`,
  LOGIN: `${baseUrl}/account/login/`,
  SIGNUP: `${baseUrl}/signup/`,
  DASHBOARD: `${baseUrl}/app/dashboard`,
  NOT_FOUND: `${baseUrl}/individuals/page/404`,
  LANDINGS: {
    CASHBACK: "/cashback",
    AIRDROPS: "/airdrops",
    COLD_STAKING: "/cold-staking",
    STAKING: "/staking",
    ASSETS: "/assets",
    EXCHANGE: "/exchange",
  },
};

export const CALCULATOR_COIN_SLUG = "FIO";

export const AIRDROPS_DISPLAY_LIMIT = 4;

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const STORES = [
  {
    image:
      "https://cdn.admitad-connect.com/campaign/images/2020/9/30/6115-23f0d4d9fb4ec8d1.png",
    name: "AliExpress",
    profit: "6.9%",
    category: "Marketplace",
  },
  {
    image:
      "https://cdn.admitad-connect.com/campaign/images/2020/11/2/20881-ff9430e87ffe5736.png",
    name: "Alibaba",
    profit: "5.4%",
    category: "Marketplace",
  },
  {
    image:
      "https://cdn.admitad-connect.com/campaign/images/2021/5/27/21633-ce9a8e7ea4c8ad95.svg",
    name: "Eneba",
    profit: "5%",
    category: "Gaming",
  },
  {
    image:
      "https://cdn.admitad-connect.com/campaign/images/2020/9/30/15504-af674f26cd481a83.jpeg",
    name: "Dressilly",
    profit: "14%",
    category: "Marketplace",
  },
  {
    image:
      "https://cdn.admitad-connect.com/campaign/images/2021/9/24/25249-37aa6fea5015e46e.png",
    name: "Turbo VPN",
    profit: "46%",
    category: "Marketplace",
  },
  {
    image:
      "https://cdn.admitad-connect.com/campaign/images/2021/7/20/22448-cc343a99b9ffd534.png",
    name: "Udemy",
    profit: "7.69%",
    category: "Education",
  },
  {
    image:
      "https://cdn.admitad-connect.com/campaign/images/2021/5/27/24298-c3e04b4b7bfd00a8.svg",
    name: "Kinguin",
    profit: "4.16%",
    category: "Gaming",
  },
  {
    image:
      "https://cdn.admitad-connect.com/campaign/images/2020/9/30/15508-2ccf6db1de7fb4c0.png",
    name: "Zaful",
    profit: "10%",
    category: "Clothing",
  },
  {
    image:
      "https://cdn.admitad-connect.com/campaign/images/2022/5/31/15467-ebb8cebb230274fa.svg",
    name: "GeekBuying",
    profit: "8%",
    category: "Marketplace",
  },
];

export const STRAPI_CATEGORIES = {
  STAKING: "Staking",
  COLD_STAKING: "Cold Staking",
  EXCHANGE: "Exchange",
  ALL: "all",
};

export const STRAPI_COINS_GQL = `
  query ($where: JSON, $limit: Int) {
    assets (where: $where, limit: $limit) {
      coinName
      popularity
      isComingSoon
      coinOverview {
        title
        summary
        currentValuation {
          value
          measure
        }
        yearlyReturn {
          value
        }
        image {
          url
        }
      }
      categories
      interestingFacts {
        list
      }
      projectNutshell {
        list
      }
    }
  }
`;
