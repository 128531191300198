import { useState, useEffect } from "react";

export const useOnResize = (onResize: VoidFunction) => {
  return useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
};

export const useForceUpdateOnResize = () => {
  const [, forceUpdate] = useState(0);

  return useOnResize(() => {
    forceUpdate((c) => c + 1);
  });
};
