import varietyPic from './img/wide-variety.png';
import rewardsPic from './img/rewards.png';
import waysToEarnPic from './img/ways-to-earn.png';
import securityPic from './img/security.png';

export const incomesList = [
  {
    id: 'wide-variety',
    name: 'Wide variety of assets',
    description:
      'Research our collections of above 120 crypto assets in one place',
    pic: varietyPic
  },
  {
    id: 'highest-rewards',
    name: 'Highest rewards',
    description:
      'Increase value of your assets with our rewards. They give existing Users <b>an average of 14.1% APY</b>',
    pic: rewardsPic
  },
  {
    id: 'ways-to-earn',
    name: 'Multiple ways to earn',
    description:
      'Invest and earn in the way that is most convenient for you.  See our Earning Apps!',
    pic: waysToEarnPic
  },
  {
    id: 'security',
    name: 'Security',
    description:
      'We are the Fireblocks licensed Partner. We provide more security via Sumsub KYC, 2FA and Biometry',
    pic: securityPic
  }
];
