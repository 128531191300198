import { URLS } from "const";
import { StrictMode } from "react";
import reactDOM from "react-dom";
import { App } from "./App";

import "./assets/scss/styles.scss";

import "./styles.css";

const pathname = window.location.pathname;
if (pathname.includes("/agreement/terms-conditions")) {
  // do nothing
} else if (localStorage.getItem("token") && window.location.hostname !== "localhost") {
  window.open(URLS.DASHBOARD, "_self");
}



reactDOM.render(
    <App />,
  document.querySelector("#root")
);
