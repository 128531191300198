import nodes from "assets/navigation/nodes.svg";
import wallet from "assets/navigation/wallet.svg";
import about from "assets/navigation/about.svg";
import contact from "assets/navigation/contact.svg";
import feedback from "assets/navigation/feedback.svg";
import giveaways from "assets/navigation/giveaways.svg";
import helpDesk from "assets/navigation/helpDesk.svg";
import marketing from "assets/navigation/marketing.svg";
import power from "assets/navigation/power.svg";
import staking from "assets/navigation/staking.svg";
import updates from "assets/navigation/updates.svg";
import arrowRight from "assets/navigation/arrow.svg";
import { uris } from "const";
import twitter from "assets/navigation/social/twitter.svg";
import announcements from "assets/navigation/social/announcements.svg";
import blog from "assets/navigation/social/blog.svg";
import discord from "assets/navigation/social/discord.svg";
import facebook from "assets/navigation/social/facebook.svg";
import instagram from "assets/navigation/social/instagram.svg";
import telegram from "assets/navigation/social/telegram.svg";
import vk from "assets/navigation/social/vk.svg";
import youtube from "assets/navigation/social/youtube.svg";
import airdrops from "assets/navigation/airdrops.svg";
import defiFarming from "assets/navigation/defi-farming.svg";
import deployment from "assets/navigation/deployment.svg";
import exchange from "assets/navigation/exchange.svg";
import grow from "assets/navigation/grow.svg";

const COMMON_NAVIGATION_HEADER = [
  {
    title: "Assets",
    href: "/account/login",
  },
  {
    title: "Support & Help",
    children: [
      {
        title: "Help Center",
        href: "https://desk.zoho.eu/portal/mycointainerbusiness/en/home",
        image: helpDesk,
      },
      {
        title: "Send feedback",
        href: "https://surveys.hotjar.com/3d6435be-f10c-46fe-bb53-fe152c4de400",
        image: feedback,
      },
    ],
  },
  {
    title: "More",
    children: [
      {
        title: "About Us",
        href: "/company",
        image: about,
      },
      {
        title: "Company Updates",
        href: "/company",
        image: updates,
      },
      {
        title: "Contact",
        href: "/contact?who=business",
        image: contact,
      },
    ],
  },
];

export const CLASSIC_NAVIGATION = {
  HEADER: [
    {
      title: "Earning Widgets",
      children: [
        {
          title: "Grow Crypto",
          description: "Staking & Masternode rewards",
          image: grow,
          href: uris.business.staking,
        },
        {
          title: "DeFi Yield Farming",
          description: "Provide liqudity and earn intrest",
          image: defiFarming,
        },
        {
          title: "Exchange",
          description: "Best rates & extra reward",
          image: exchange,
          href: uris.landings.exchange,
        },
        {
          title: "Nodes Deployment",
          description: "Run on your own node & earn rewards",
          image: deployment,
          href: uris.business.nodes,
        },
        {
          title: "Airdrops & Giveaways",
          description: "Free coins & rewards",
          image: airdrops,
          href: uris.landings.airdrops,
        },
      ],
    },
    ...COMMON_NAVIGATION_HEADER,
  ],
};

export const BUSINESS_NAVIGATION = {
  HEADER: [
    {
      title: "Solutions",
      children: [
        {
          title: "Node Deployments",
          description: "Create nodes in an instant",
          image: nodes,
          href: "/business/solutions/node-deployment",
        },
        {
          title: "Crypto Wallets",
          description: "Over 100 crypto wallets for your platform",
          image: wallet,
          href: "/business/solutions/crypto-wallets",
        },
      ],
    },
    {
      title: "Services",
      children: [
        {
          title: "Staking Nodes",
          href: uris.business.staking,
          image: staking,
        },
        {
          title: "Airdrops & Giveaways",
          href: uris.business.giveaways,
          image: giveaways,
        },
        {
          title: "Marketing Campaigns",
          href: uris.business.marketing,
          image: marketing,
        },
        {
          title: "POWER Codes",
          href: uris.business.power,
          image: power,
        },
      ],
    },
    ...COMMON_NAVIGATION_HEADER,
  ],
  FOOTER: [
    [
      {
        title: "Company",
        index: 1,
        href: "/company",
        children: [
          { title: "About Us", href: "/company" },
          { title: "Mission", href: "/company" },
          { title: "Careers", href: "mailto:contact@mycointainer.com" },
          { title: "News", href: "https://company.mycointainer.com/" },
          { title: "Partnership", href: "https://forms.gle/a8FrvG5DiRTr9Hn27" },
          { title: "Investors", href: "https://forms.gle/VmgxjQYKbszi6Qh27" },
          { title: "Contact", href: "/contact" },
        ],
      },
      {
        title: "Resources",
        index: 2,
        href: "/assets",
        children: [
          { title: "Assets List", href: "/assets" },
          { title: "T&Cs", href: "/agreement/terms-conditions/" },
          { title: "Fees", href: "/fees-and-pricing/" },
          { title: "Privacy", href: "/insight/privacy-policy/" },
        ],
      },
    ],
    [
      {
        title: "Solutions",
        index: 3,
        href: "/business/solutions/node-deployment",
        children: [
          {
            title: "Node Deployments",
            href: "/business/solutions/node-deployment",
          },
          {
            title: "Crypto Wallets",
            href: "/business/solutions/crypto-wallets",
          },
        ],
      },
      {
        title: "Services",
        index: 4,
        href: "/business/services/staking-nodes",
        children: [
          { title: "Staking Nodes", href: "/business/services/staking-nodes" },
          {
            title: "Airdrops & Giveaways",
            href: "/business/services/airdrops-and-giveaways",
          },
          {
            title: "Marketing Campaigns",
            href: "/business/services/marketing-campaigns",
          },
          { title: "POWER Codes", href: "/business/services/power-codes" },
        ],
      },
    ],
    [
      {
        title: "Developers",
        index: 5,
        href: "https://surveys.hotjar.com/d263c6fb-a8b6-4696-879f-ef59d9912ba7",
        children: [
          {
            title: "Bug Bounty Program",
            href: "https://surveys.hotjar.com/d263c6fb-a8b6-4696-879f-ef59d9912ba7",
          },
        ],
      },
      {
        title: "Community",
        index: 6,
        href: "/community/",
        children: [
          {
            title: "Community Portal",
            href: "/community/",
          },
        ],
      },
    ],
    [
      {
        title: "Learn",
        index: 7,
        href: "/insight",
        children: [
          {
            title: "Video Guides",
            href: "https://www.youtube.com/watch?v=b4o_hm8LnnA&list=PLsei49aSqpvniGbk2ADg22QK1cC-dHnNz",
          },
          { title: "How Tos", href: "/insight/tag/guides-and-tutorials/" },
          {
            title: "How to stake Cartesi",
            href: "/insight/cartesi-staking-guide/",
          },
          {
            title: "How to stake Cardano",
            href: "/insight/cardano-staking-guide/",
          },
          {
            title: "How to stake Waves",
            href: "/insight/waves-staking-guide/",
          },
          { title: "More...", href: "/insight/tag/guides-and-tutorials/" },
        ],
      },
      {
        title: "Get Insight",
        href: "/insight",
        index: 8,
        children: [
          {
            title: "Top staking coins this week",
            href: "/insight/tag/earn/",
          },
          {
            title: "Hottest airdrops of the week",
            href: "/insight/tag/giveaways-and-airdrops/",
          },
          {
            title: "More...",
            href: "/insight",
          },
        ],
      },
    ],
    [
      {
        title: `Support <img alt="" src=${arrowRight} style="padding-top: 2px;" />`,
        index: 9,
        href: "https://desk.zoho.eu/portal/mycointainerbusiness/en/home",
        children: [
          {
            title: "Support Center",
            href: "https://desk.zoho.eu/portal/mycointainerbusiness/en/home",
          },
          {
            title: "Get in touch",
            href: "https://forms.gle/a8FrvG5DiRTr9Hn27",
          },
          {
            title: "Send Feedback",
            href: "https://surveys.hotjar.com/e25be213-b89a-4606-99c1-caa9576062f9",
          },
        ],
      },
      {
        title: "Follow",
        index: 10,
        href: "https://twitter.com/MyCointainerCom",
        children: [
          {
            title: "Twitter",
            icon: twitter,
            href: "https://twitter.com/MyCointainerCom",
          },
          {
            title: "Instagram",
            icon: instagram,
            href: "https://www.instagram.com/mycointainer_com/",
          },
          {
            title: "Discord",
            icon: discord,
            href: "https://discord.com/invite/gNGu3ct",
          },
          {
            title: "Telegram",
            icon: telegram,
            href: "https://t.me/mycointainer",
          },
          {
            title: "Facebook",
            icon: facebook,
            href: "https://www.facebook.com/mycointainer",
          },
          { title: "VK", icon: vk, href: "https://vk.com/mycointainer" },
          {
            title: "YouTube",
            icon: youtube,
            href: "https://www.youtube.com/channel/UCBiVJTVShKJvifeRv0wvVsg",
          },
          { title: "Blog", icon: blog, href: "/insight" },
          {
            title: "Announcements",
            icon: announcements,
            href: "https://discord.gg/tztRjT6Xhs",
          },
        ],
      },
    ],
  ],
};
