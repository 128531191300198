import { useEffect, useState } from "react";
import { useMedia } from "hooks";
import { Helmet } from "react-helmet";
import { StandardLayout } from "components/StandardLayout";
import { HowItWork } from "./HowItWork";
import { Stores } from "./Stores";
import { Header } from "components/Header";
import { Section } from "components/Section";
import { Questions } from "components/Questions";
import { Container } from "components/Container";
import { Footer } from "components/Footer";
import t from "i18n";
import { chrome, edge, imageCashback, imageCashbackMobile } from "assets";
import { URLS } from "const";
import styled from "styled-components";

const MIN_HEADER_HEIGHT = 790;

const Cashback = () => {
  const isTablet = useMedia("(max-width: 1024px)");

  const isMobile = useMedia("(max-width: 576px)");

  const getHeaderHeight = () => {
    return Math.max(window.innerHeight, MIN_HEADER_HEIGHT);
  };

  const [height, setHeight] = useState(getHeaderHeight);

  useEffect(() => {
    const onResize = () => {
      setHeight(getHeaderHeight());
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <StandardLayout>
      <Helmet>
        <title>{t("meta.title.cashback")}</title>
        <meta name="description" content={t("meta.description.cashback")} />
      </Helmet>
      <Container>
        <Header
          height={!isTablet ? height : void 0}
          color={"var(--color--teal)"}
          title={
            <TitleWrapper
              dangerouslySetInnerHTML={{
                __html: t("cashback.title", {
                  truncated: isMobile,
                }),
              }}
            />
          }
          image={
            <ImageWrapper>
              <Image
                src={isTablet ? imageCashbackMobile : imageCashback}
                alt=""
              />
            </ImageWrapper>
          }
          description={t(`cashback.description${isTablet ? "" : ".large"}`)}
          actionText={t(`cashback.action${isTablet ? "" : ".large"}`)}
          actions={
            <>
              <Browser href={URLS.BROWSER_EXTENSION}>
                <img alt="" src={chrome} />
              </Browser>
              <Browser href={URLS.BROWSER_EXTENSION}>
                <img alt="" src={edge} />
              </Browser>
            </>
          }
        />
        <Section>
          <HowItWork />
        </Section>
        <Section title={t("cashback.stores.title")}>
          <Stores />
        </Section>
        <Section title={t("common.faq")}>
          <Questions
            questions={[
              {
                title: t("cashback.q.whatIsCashback"),
                description: t("cashback.q.whatIsCashback.description"),
              },
              {
                title: t("cashback.q.howToPurchase"),
                description: (
                  <ol>
                    <li>{t("cashback.q.howToPurchase.description.login")}</li>
                    <li>
                      {t("cashback.q.howToPurchase.description.findStore")}
                    </li>
                    <li>
                      {t("cashback.q.howToPurchase.description.followOurRules")}
                    </li>
                    <li>
                      {t("cashback.q.howToPurchase.description.redirect")}
                    </li>
                    <li>
                      {t("cashback.q.howToPurchase.description.buyOnSite")}
                    </li>
                    <li>{t("cashback.q.howToPurchase.description.order")}</li>
                    <li>{t("cashback.q.howToPurchase.description.result")}</li>
                  </ol>
                ),
              },
              {
                title: t("cashback.q.howToWithdraw"),
                description: t("cashback.q.howToWithdraw.description"),
              },
              {
                title: t("cashback.q.howToReceiveInAnotherCurrency"),
                description: t(
                  "cashback.q.howToReceiveInAnotherCurrency.description"
                ),
              },
              {
                title: t("cashback.q.howToEarnMore"),
                description: (
                  <>
                    {t("cashback.q.howToEarnMore.description.begin")}
                    <ol>
                      <li>
                        {t("cashback.q.howToEarnMore.description.referal")}
                      </li>
                      <li>
                        {t("cashback.q.howToEarnMore.description.register")}
                      </li>
                      <li>
                        {t("cashback.q.howToEarnMore.description.purchasing")}
                      </li>
                      <li>
                        {t("cashback.q.howToEarnMore.description.receiving")}
                      </li>
                    </ol>
                  </>
                ),
              },
              {
                title: t("cashback.q.areThereAnyLimits"),
                description: t("cashback.q.areThereAnyLimits.description"),
              },
              {
                title: t("cashback.q.forgotActivate"),
                description: t("cashback.q.forgotActivate.description"),
              },
            ]}
          />
        </Section>
        <Footer />
      </Container>
    </StandardLayout>
  );
};

export default Cashback;

const TitleWrapper = styled.h1`
  color: var(--color--black);

  @media (min-width: 1024px) {
    width: 621px;
  }
`;

const Browser = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;

  cursor: pointer;

  border-radius: 8px;

  background-color: var(--color--white);

  box-sizing: border-box;

  &:hover {
    border: solid 1px var(--color--teal);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 590px;

  z-index: 2;

  @media (max-width: 1133px) {
    width: 500px;
  }
`;

const ImageWrapper = styled.div`
  z-index: 2;

  @media (max-width: 1024px) {
    img {
      height: 454px;
      width: auto;
      margin-bottom: -64px;
    }
  }

  @media (max-width: 454px) {
    img {
      min-width: calc(100% + 64px);
      height: auto;
      margin-left: -32px;
      margin-bottom: -64px;
    }
  }

  @media (min-width: 400px) and (max-width: 454px) {
    img {
      margin-bottom: -96px;
    }
  }

  @media (max-width: 300px) {
    img {
      margin-bottom: -32px;
    }
  }
`;
